import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import 'react-phone-input-2/lib/style.css';
import CreatableSelect from 'react-select/creatable';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import {TextField} from "@material-ui/core";
import _ from "lodash";
import {labels} from "../../../../constants/inputs/labels";
import Select from "react-select";
import {sectionTitles} from "../../../../constants/names/titles";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {endpoints} from "../../../../constants/endpoints";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const selectStyles = {
    menu: base => ({
        ...base,
        textAlign: 'left',
        zIndex: 100
    })
};


export default function OrganizationDetails(props) {
    const [activeModal, setActiveModal] = useState(false);
    const [status, setStatus] = useState({
        target: {
            checked: props.contract.active
        }
    });
    const classes = useStyles();

    const handleCloseModal = () => {
        setActiveModal(false);
    };
    const handleOpenModal = (e) => {
        setStatus(e);
        setActiveModal(true);
    };
    const changeStatus = () => {
        if (props.edit) {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.updateContractStatus, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    status: status.target.checked,
                    contract_id: props.contractId
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        props.handleChange(status);
                        setActiveModal(false);
                    } else {
                        console.log('err')
                    }
                })
                .catch()
        } else {
            props.handleChange(status);
        }
    };
    const sortedHospiceList = props.hospiceList.sort((a, b) => a.label.localeCompare(b.label));
    const sortedHospiceRegionsList = props.hospiceRegionsList.sort((a, b) => a.label.localeCompare(b.label));
    const sortedDmeList = props.dmeList.sort((a, b) => a.label.localeCompare(b.label));
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Contract Details
            </Typography>
            <Grid container spacing={3} className={classes.container}>
                <Grid item sm={6} xs={12}>
                    <div className={classes.select}>

                        <div className={classes.label}>
                            <span>Select Hospice Provider</span>
                        </div>
                        <CreatableSelect
                            isDisabled={props.edit}
                            isClearable
                            id={'selectHospice'}
                            onChange={(e) => props.handleOrganizationSelect(e, "Hospice")}
                            value={props.contract.hospice_id === '' ? null : [{
                                label: props.hospiceData[_.findIndex(props.hospiceData,
                                    {"hospice_organization_id": props.contract.hospice_id})].hospice_name,
                                value: props.contract.hospice_id,
                            }]}
                            options={sortedHospiceList}
                            styles={selectStyles}
                        />
                        <div style={{paddingTop: 16}}>
                            <div className={classes.label}>
                                <span>Select Hospice Provider Region</span>
                            </div>
                            <Select
                                id={'selectHospiceRegion'}
                                isClearable
                                isDisabled={props.edit}
                                onChange={(e) => props.handleOrganizationSelect(e, "Hospice Region")}
                                value={props.contract.hospice_region_id === '' ? null : [{
                                    label: props.hospiceRegionsData[_.findIndex(props.hospiceRegionsData,
                                        {"region_id": props.contract.hospice_region_id})].name,
                                    value: props.contract.hospice_region_id,
                                }]}
                                options={sortedHospiceRegionsList}
                                styles={selectStyles}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <div className={classes.select}>
                        <div className={classes.label}>
                            <span>Select DME Provider</span>
                        </div>
                        <CreatableSelect
                            id={'selectDme'}
                            isDisabled={props.edit}
                            isClearable
                            onChange={(e) => props.handleOrganizationSelect(e, "DME")}
                            value={props.contract.dme_id === '' ? null : [{
                                label: props.dmeData[_.findIndex(props.dmeData,
                                    {"dme_organization_id": props.contract.dme_id})].dme_name,
                                value: props.contract.dme_id,
                            }]}
                            options={sortedDmeList}
                            styles={selectStyles}
                        />
                    </div>
                    <div style={{padding: 20, display: "flex"}}>
                        <FormControl component="fieldset">
                            <FormLabel>{sectionTitles.contractStatus}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch color="primary"
                                                     checked={props.contract.active}
                                                     name={'active'}
                                                     onChange={(e) => {
                                                         handleOpenModal({
                                                             target: {
                                                                 name: 'active',
                                                                 checked: e.target.checked
                                                             }
                                                         })
                                                     }}
                                    />}
                                />
                            </FormGroup>
                            <FormHelperText>{getMessage(messageTypes.contractStatus, props.contract.active)}</FormHelperText>
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Contract Terms
            </Typography>
            <Grid container>
                <RadioGroup aria-label="quiz"
                            name="contract_terms"
                            value={props.contract.contract_terms}
                            onChange={(e) =>
                                props.handleChange(e)
                            }
                >
                    <div className={classes.radioButtons}>
                        <FormControlLabel
                            id={'patient_days'}
                            value={'patient_days'}
                            control={<Radio color="primary"/>}
                            label="Patient Days"
                        />
                        <FormControlLabel
                            value={'dme_days'}
                            control={<Radio color="primary"/>}
                            label="DME Days"
                        />
                        <FormControlLabel
                            value={'fee_for_service'}
                            control={<Radio color="primary"/>}
                            label="Fee for Service"
                        />
                    </div>
                </RadioGroup>
                <div className={'picker'} style={{paddingLeft:48}}>
                    <div className={'date_label'}>Renewal date:</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                placeholder={'MM/dd/yyyy'}
                                value={props.contract.renewal_date && props.contract.renewal_date}
                                onChange={(e) => {
                                    let obj = {target: {name: 'renewal_date', value: e}};
                                    props.handleChange(obj)
                                }}
                                KeyboardButtonProps={{'aria-label': 'change date',}}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
            </Grid>
            <Grid container>
                {props.contract.contract_terms === 'patient_days' && (
                    <TextField
                        id="per_diem_fee"
                        label="Per Diem Fee"
                        variant="outlined"
                        value={props.contract.per_diem_fee && props.contract.per_diem_fee}
                        size='small'
                        name='per_diem_fee'
                        onChange={(e) => props.handleChange(e)}
                    />
                )}
                {props.contract.contract_terms === 'dme_days' && (
                    <TextField
                        id="outlined-basic"
                        label="DME Days"
                        variant="outlined"
                        value={props.contract.dme_days && props.contract.dme_days}
                        size='small'
                        name='dme_days'
                        onChange={(e) => props.handleChange(e)}
                    />
                )}
            </Grid>
            <Grid container>


            </Grid>
            <Grid container className={classes.radio}>


                <FormLabel component="legend">{labels.subjectToTaxes}</FormLabel>
                <RadioGroup aria-label="quiz"
                            name="subject_to_taxes"
                            value={props.contract.subject_to_taxes}
                            onChange={(e) =>
                                props.handleChange(e)
                            }
                >
                    <div className={classes.radioButtons}>
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary"/>}
                            label="Yes"
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary"/>}
                            label="No"
                        />
                    </div>
                </RadioGroup>
                {props.contract.subject_to_taxes && (
                    <TextField
                        label="Tax Rate"
                        variant="outlined"
                        value={props.contract.tax_rate && props.contract.tax_rate}
                        size='small'
                        name='tax_rate'
                        onChange={(e) => props.handleChange(e)}
                    />
                )}
            </Grid>
            <Dialog
                open={activeModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title">{(status.target.checked) ? "Are you sure you want to make this contract Active?" : "Are you sure you want to make this contract Inactive?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you confirm this action, the contract status will be changed.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary" variant={'outlined'}>
                        No, return
                    </Button>
                    <Button onClick={changeStatus} color="primary" variant={'contained'}>
                        Yes, confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: 'left',
        alignItems: 'left',
        transition: 'all .5s ease-in-out',
        transitionDelay: '2s',
        paddingBottom: 12,
    },
    select: {
        width: 300,
    },
    label: {
        justifyContent: 'left',
        textAlign: "left",
        fontSize: 16,
        color: "#9E9E9E"
    },
    title: {
        textAlign: 'left',
        paddingBottom: 12
    },
    radio: {
        paddingTop: 16,
        height: 50,
        alignItems: 'center',
        alignContent: 'center'
    },
    radioButtons: {
        display: 'flex',
        paddingLeft: 12,
    },
    picker: {
        paddingTop:24,
        paddingLeft:24
    }
}));