import React, {useEffect, useState} from "react";
import {Table} from '../orderReview/reviewTable/table';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import {OrderNotes} from "./orderNotes/orderNotes";
import {labels} from "../../../constants/inputs/labels";
import Typography from "@material-ui/core/Typography";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {orderHeaderStyle} from "../orderItems/orderHeader/orderHeader.style";
import {orderReviewStyle} from "./orderReview.style";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import Checkbox from "@material-ui/core/Checkbox";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FileUpload from "../../orders/fileUploadOrders/FileIpload";

export const OrderReview = ({items, order, setOrder, files, setFiles}) => {
    const classes = useStyles();
    const [showClearConfirmation, setShowClearConfirmation] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const columns = [
        {title: 'Item Name', field: 'name', width: 500,
            headerStyle:{textAlign: 'center'},
        render: rowData => <Grid container style={{ display:'flex', alignContent:'center'}}>
            <Grid item style={{ display:'flex', alignContent:'center'}}>
                <CustomCheckbox
                    // checked
                    checked={selectedItems.some(e => e.tableData.id === rowData.tableData.id)}
                    onChange={(e)=> {
                        if (e.target.checked){
                            setSelectedItems([...selectedItems, rowData])
                        } else {
                            let index = selectedItems.findIndex(e => e.tableData.id === rowData.tableData.id)
                            let clone = _.cloneDeep(selectedItems);
                            clone.splice(index, 1);
                            setSelectedItems(clone);
                        }
                    }}
                />
            </Grid>
            <Grid item style={{ display:'flex', alignItems:'center'}}>
                {rowData.type ==="PD" ? <LocalOfferIcon style={{color: '#117C70'}}/> :
                    <React.Fragment>
                        { rowData.approval ? <LocalOfferIcon style={{color: '#CD1D32'}}/> : <LocalOfferIcon style={{color: '#004175'}}/>}
                    </React.Fragment>}
            </Grid>
            <Grid item style={{ display:'flex', alignItems:'center', paddingLeft:12, fontWeight:'bold',color:"#2E3236"}}>
                {rowData.approval ? <span style={{color:"#CD1D32"}}>
                    {rowData.name}
                </span> : <span>{rowData.name}</span>}

            </Grid>
        </Grid>},
        {
            title: 'Quantity', field: 'quantity', width: 140,
            headerStyle:{textAlign: 'center'},
            render: rowData => <Grid container>
                <div style={{display: "flex", alignItems: 'center'}}>
                    <IconButton
                        style={{color:'#8EB5C7'}}
                        onClick={() => {
                            if (rowData.quantity > 1) {
                                let orderClone = [...order.items];
                                orderClone[rowData.tableData.id].quantity = (orderClone[rowData.tableData.id].quantity - 1);
                                setOrder({...order, items: orderClone})
                            }
                        }}
                    >
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                    <div style={{width: '50px'}}>
                        <TextField
                            id="outlined-number"
                            disabled={rowData.hasAsset}
                            value={rowData.quantity}
                            inputProps={{min: 0, style: {textAlign: 'center'}}}
                            variant="outlined"
                            size={'small'}
                            onChange={(e)=> {
                                let orderClone = [...order.items];
                                orderClone[rowData.tableData.id].quantity = (Number(e.target.value));
                                setOrder({...order, items: orderClone})
                            }}
                        />
                    </div>
                    <Tooltip
                        title={rowData.quantity < rowData.special_item_info.max_quantity_per_item ?
                                labels.increase_quantity : labels.max_quantity_reached}
                    >
                        <span>
                            <IconButton
                                style={{color:'#8EB5C7'}}
                                disabled={rowData.quantity === rowData.special_item_info.max_quantity_per_item}
                                onClick={() => {
                                    let orderClone = [...order.items];
                                    let index = orderClone.findIndex(i => i.item_ID === rowData.item_ID);
                                    if (orderClone[rowData.tableData.id].quantity < orderClone[rowData.tableData.id].special_item_info.max_quantity_per_item){
                                        orderClone[rowData.tableData.id].quantity = (orderClone[rowData.tableData.id].quantity + 1);
                                        setOrder({...order, items: orderClone})
                                    }
                                }}
                            >
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </Grid>
        },
        {
            title: 'Clinical Settings', field: 'clinical_input', width: 240,
            headerStyle:{textAlign: 'center'},
            render: rowData => <div style={{textAlign: 'center', fontWeight:'bold'}}>
                {rowData.special_item_info.min_range && (
                    <React.Fragment>
                        <div> {`Min. ${rowData.special_item_info.min_range}`} </div>
                        <div> {`Max. ${rowData.special_item_info.max_range}`} </div>
                    </React.Fragment>
                )}
                {rowData.special_item_info.isContinuous === true && (
                        <div> {`With continuous oxygen therapy`} </div>
                )}
                {rowData.special_item_info.isContinuous === false && (
                         <div> {`With PRN oxygen therapy`} </div>
                )}
                {rowData.special_item_info.delivery_method === "Mask" && (
                    <div> {`Delivery Method: Mask`} </div>
                )}
                {rowData.special_item_info.delivery_method === "Nasal Cannula" && (
                    <div> {`Delivery Method: Nasal Cannula`} </div>
                )}
                {rowData.special_item_info.humidifier_for_compressor && (
                    <div> {`Humidifier bottle: ${rowData.special_item_info.humidifier_for_compressor}%`} </div>
                )}
                {rowData.special_item_info.weight && (
                    <div> {`Patient Weight: ${rowData.special_item_info.weight}`} </div>
                )}
                {rowData.special_item_info.size_lift && (
                    <div> {`Size: ${rowData.special_item_info.size_lift}`} </div>
                )}
            </div>
        },
        {
            title: 'Need Authorization', field: 'in_formulary', width: 200,
            headerStyle:{textAlign: 'center'},
            render: rowData => <div style={{fontWeight:'bold', textAlign: 'center'}}>
                {rowData.approval ? (
                    <span className={'inactive_status'}> Yes </span>
                ) : (
                    <span className={'active_status'}> No </span>

                )}
            </div>
        },
    ];

    const handleRemoveItem = () => {
        let cart = _.cloneDeep(order.items);
        let indexArr = [];
        selectedItems.map(item => indexArr.push(item.tableData.id));
        indexArr.sort((a, b)=> b-a);
        indexArr.map(e => {
            cart.splice(e,1);
        });
       setOrder({...order, items:cart});
       setSelectedItems([]);
    };
    return (
        <Grid conatainer
              // className={'order_container'}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',}}>
            <Grid item xs={12}>
                <Typography className={classes.h1}>
                    Order - New Delivery
                </Typography>
            </Grid>
            <Table columns={columns} items={order.items} order={order} setOrder={setOrder}/>
            <Grid item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                <Button variant={'outlined'} style={{
                    color:'#00223D',
                    fontWeight:'bold',
                    fontSize:12,
                    height:39,
                    border:'1px solid #00223D',
                    display:'flex',
                    alignContent:'center',
                    alignItems:'center',
                    justifyContent:'center'
                }} onClick={handleRemoveItem}
                ><CloseIcon style={{fontSize:18}}/><span style={{paddingTop:2}}>REMOVE SELECTED</span></Button>
                <Button
                    variant='outlined'
                    style={{
                        marginLeft:8,
                        display: 'flex',
                        padding:'6px 12px',
                        borderRadius: '4px',
                        borderColor:'#212121',
                        fontWeight:'bold',
                        fontSize:12,
                        cursor:'pointer',
                    }} onClick={()=> {
                    if (order.items.length > 0) {
                        setShowClearConfirmation(true)
                    }
                }}>
                    CLEAR CART
                </Button>
            </Grid>
            <Grid container>
                <Grid item style={{paddingTop:12, paddingBottom:12, color:'red'}}>
                    {order.items.some(item => item.prescription_required === true) && 'One or more items for cart require prescription. Please upload files below now, or after creating the order.'}
                </Grid>
            </Grid>
            <FileUpload files={files}
                        setFiles={setFiles}
            />
            <Grid item xs={12}>
            <Typography style={{fontSize:24, fontWeight:'bold', color:'#00223D', paddingTop:32}}>
                Order Notes
            </Typography>
            </Grid>
            <OrderNotes order={order} setOrder={setOrder} setSelectedItems={setSelectedItems}/>
            <Dialog
                open={showClearConfirmation}
                onClose={() => setShowClearConfirmation(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                        <span style={{fontSize:16, fontWeight:600}}>
                            All items for the cart will be removed. Do you want to proceed?
                        </span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowClearConfirmation(false)} variant='outlined' color="secondary" style={{ fontWeight:600}}>
                        No, return
                    </Button>
                    <Button
                        onClick={()=> {
                            setOrder({...order, items: []});
                            setShowClearConfirmation(false);
                        }}
                        variant='contained' color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>

    )
};

const useStyles = makeStyles((theme) => (orderReviewStyle(theme)));

const CustomCheckbox = withStyles({
    root: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        },
        "&:checked": {
            color: "#4F86A0"
        },
        "&:disabled": {
            color: "#4F86A0"
        }
    },
    checked: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    },
    disabled: {
        color:  "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    }
})(Checkbox);
