export const userProfileStyles = {

    root: {
        paddingTop: 48,
        maxWidth: '70%'
    },
    columnComponent: {
        // display:'flex',
        // justifyContent:'flex-start'
    },
    titleContainer: {
        border: '1px solid #D5D9DC',
        backgroundColor: 'white',
        textAlign: 'left',
    },
    textColor: {
        color: "#4F86A0",
        "&:hover": {
            color: "grey"
        },
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'flex-start',
        textTransform: 'none'
    },
    border: {
        border: '1px solid #D5D9DC'
    },
    gridContainer: {
        backgroundColor: 'white',
        textAlign: 'left',
    },
    container: {
        backgroundColor: 'white',
        paddingBottom: 37,
        height: 300,
        paddingRight: 51,
        maxWidth: 1800,
    },
    url: {
        paddingTop: 36,
        paddingLeft: 49,
    },
    url2: {
        paddingTop: 24,
        paddingLeft: 49,
    },
    inputContainer: {
        paddingLeft: '49px',
        paddingRight: '49px',
        paddingBottom: '49px'
    },
    title: {
        paddingLeft: 50,
        paddingTop: 48,
        paddingBottom: 24,
        fontSize: 24,
        fontWeight: 'bold',
        color: "#00223D"
    },
    userName: {
        paddingLeft: 49,
        paddingTop: 15,
        paddingBottom: 24,
        fontSize: 24,
        fontWeight: 'bold',
        color: "#00223D"
    },
    textItem: {
        maxWidth: '120%',
        paddingRight: 48,
    },
    links: {
        fontSize: 14,
        fontWeight: 'bold',
        color: "#4F86A0",
        textAlign: "left",
        "&:hover": {
            color: '#004175'
        }
    },

    flexStart: {
        justifyContent: 'flex-start'
    },
    h1: {
        textAlign: 'left',
        color: '#00223D',
        fontSize: 28,
        fontWeight: "bold",
        lineHeight: '38px',
        borderBottom: '1px solid #B2D1D9',
        marginBottom: 36
    },

    h4: {
        textAlign: 'left',
        color: '#1A1818',
        fontSize: 20,
        marginBottom: 2,
        fontWeight: "600",
        marginLeft: 49
    },
    h4NoColor: {
        textAlign: 'left',
        fontSize: 20,
        marginBottom: 2,
        fontWeight: "600",
        marginLeft: 49
    },
    image: {
        paddingLeft: 50,
        paddingTop: 30,
        marginLeft: 49,
        marginTop: 30
    },
    change: {
        marginTop:8,
        marginBottom:12,
        padding:'8px 16px',
        fontSize: 14,
        fontWeight: 'bold',
        color: "#4F86A0",
        borderColor:"#4F86A0",
        marginLeft: 50,
        "&:hover": {
            color: '#004175'
        }
    }
};