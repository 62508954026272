import React from "react";

export const BRDisplayQuarterTable = ({values}) => {
    return (
        <table style={{textAlign: "left"}}>
            <tr>
                <th>Total</th>
                <th>Type</th>
            </tr>
            <tr>
                <td>Total Patient Days</td>
                <td>{values.totalPatientDays}</td>
            </tr>
            <tr>
                <td>CPPD - Patient Days</td>
                <td>{values.cppdPrice}</td>
            </tr>
            <tr>
                <td>Total CPPD - Per Diem</td>
                <td>{values.totalCppdPrice}</td>
            </tr>
            <tr>
                <td>Total DME Spend</td>
                <td>{values.totalDmeSpend}</td>
            </tr>
            <tr>
                <td>Outlier Total</td>
                <td>{values.outlierTotal}</td>
            </tr>
            <tr>
                <td>Outlier % of Spend</td>
                <td>{values.outlierSpend}</td>
            </tr>
            <tr>
                <td>Cost Per Patient Day</td>
                <td>{values.costPerPatientDay}</td>
            </tr>
        </table>
    )
}