import React from 'react';
import './admin.css';
import {useSelector} from 'react-redux';
import {AdminDashboard} from "./adminDashboard/adminDashboard";

export default function Admin() {
    const isLogged = useSelector(state => state.isLogged);
    return (
        <div>
            {(isLogged) ? <div className='admin'>
                <AdminDashboard/>
            </div> : ''}
        </div>
    )
}