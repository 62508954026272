import React, {useEffect, useState} from 'react';
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {endpoints} from "../../../../constants/endpoints";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const AssetSelect = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [options, setOptions] = useState([]);
    const getAvailableAssets = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.availableAssets, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                dme_organization_id: props.dme_organization_id,
                item_name: props.item.name
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === 'success') {
                    let options = [];
                    response.data.map(i => {
                        options.push({label: i.asset, value: i.asset})
                    });
                    setOptions(options)
                }
            })
            .catch(err => console.log('error'))
    };

    useEffect(() => {
        if (options.length === 0) {
            getAvailableAssets();
        }
    }, []);

    return (
        <CreatableSelect
            formatCreateLabel={(value) =>
                <span>Add this asset to warehouse...</span>}
            value={props.item.asset ? [{value: props.item.asset, label: props.item.asset}] : null}
            onChange={(e) => {
                props.selectAssetHandle(e, props.index, 'asset', props.item.name, 'placeholderVal', false, props.item.special_item_id);
                props.setCurrentSpecialId(props.item.special_item_id);
            }}
            options={options}
        />
    )
};

export default AssetSelect;