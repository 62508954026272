import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {sectionTitles} from "../../../../constants/names/titles";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {endpoints} from "../../../../constants/endpoints";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import {CustomInputField} from "../../../../common/InputField/InputField";
import {validationTypes} from "../../../../constants/validations/validationTypes";
import {labels} from "../../../../constants/inputs/labels";
import {inputType} from "../../../../constants/types/inputType";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const GeneralInformation = (props) => {
    const {validType, dme, missingValues, setMissingValues, handleInput, loading} = props;
    const [phone, setPhone] = useState(true);
    const [phone2, setPhone2] = useState(true);
    const [validName, setValidName] = useState(true);
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {sectionTitles.generalInformation}
            </Typography>
           <Grid container spacing={3}
                  style={{paddingTop: '50px', display: "flex", justifyContent: 'left', alignItems: 'left'}}>
               <Grid item sm={9} style={{height: '420px'}}>
                    <Grid item sm={8} style={{height: '80px', display: 'flex'}}>
                        <div style={{width: '40%'}}>
                            <TextField
                                helperText={missingValues.dme_name && tooltips.isRequired}
                                error={missingValues.dme_name}
                                name="dme_name"
                                placeholder={placeholders.organizationName}
                                value={dme.dme_name}
                                variant="outlined"
                                size="small"
                                onChange={e => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                    setValidName(true);
                                    setMissingValues({...missingValues, dme_name: false})
                                }}
                                onBlur={e => {
                                    if (e.target.value !== '') {
                                        (async () => {
                                            const target = endpoints.dme_organizations + e.target.value;
                                            const token = JSON.parse(localStorage.getItem('key'));
                                            const getNamePromise = await fetch(target, {
                                                method: "GET",
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + token.accessToken
                                                },
                                            });
                                            const getNameResponse = await getNamePromise.json();
                                            if (getNameResponse.status !== 'success') {
                                                setValidName(true)
                                            } else {
                                                setValidName(false)
                                            }
                                        })()
                                    } else if (e.target.value.length < 1) {
                                        setMissingValues({...missingValues, dme_name: true});
                                        setValidName(true)
                                    }
                                }}
                            />
                            <FormHelperText
                                style={{color: 'red', textAlign: 'left'}}
                            >
                                {(!validName) && tooltips.nameAlreadyExists}
                            </FormHelperText>
                        </div>
                        <div style={{width: 440}}>
                            <FormControl component="fieldset">
                                <FormLabel style={{textAlign: 'left'}}>Provider Status</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                                         checked={dme.status}
                                                         name={"providerStatus"}
                                                         onChange={(e) => {
                                                             handleInput({
                                                                 ...e,
                                                                 inputEventType: inputType.standard
                                                             });
                                                         }}
                                        />}
                                    />
                                </FormGroup>
                                <FormHelperText>{getMessage(messageTypes.dmeStatus, {
                                    name: dme.dme_name,
                                    status: dme.status
                                })}</FormHelperText>
                            </FormControl>
                        </div>
                        <div style={{width: 200}}>{props.dmeId && props.dmeId !== 'new' && `Organization ID: #${props.dmeId}`}</div>
                    </Grid>
                    <Grid item sm={12} style={{display: 'flex', height: '55px'}}>
                        <CustomInputField
                            size={'small'}
                            isRequired={true}
                            isMissing={missingValues.address1}
                            name={'address1'}
                            label={'Address 1'}
                            value={dme.address1}
                            onChange={(e) => {
                                handleInput({
                                    ...e,
                                    inputEventType: inputType.required
                                });
                            }}
                            onBlur={(e) => {
                                handleInput({
                                    ...e,
                                    inputEventType: inputType.required
                                });
                            }}
                            isValid={true}
                        />
                        <div style={{width: '40px'}}></div>
                        <CustomInputField
                            size={'small'}
                            isRequired={false}
                            isMissing={missingValues.address}
                            name={'address2'}
                            label={'Address 2'}
                            value={dme.address2}
                            onChange={(e) => {
                                handleInput({
                                    ...e,
                                    inputEventType: inputType.standard
                                });
                            }}
                            onBlur={(e) => {
                                handleInput({
                                    ...e,
                                    inputEventType: inputType.standard
                                });
                            }}
                            isValid={true}
                        />
                    </Grid>
                    <Grid item sm={12} style={{height: '80px', display: 'flex'}}>
                        <Grid item sm={6} style={{paddingTop:16}}>
                            <CustomInputField
                                size={'small'}
                                isRequired={true}
                                isMissing={missingValues.city}
                                validationType={validationTypes.onlyLetters}
                                name={'city'}
                                label={'City'}
                                value={dme.city}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                isValid={validType.city}
                            />
                        </Grid>
                        <Grid item sm={5} style={{paddingLeft: '20px', paddingTop:16}}>
                            <CustomInputField
                                size={'small'}
                                isRequired={true}
                                isMissing={missingValues.state}
                                validationType={validationTypes.onlyLetters}
                                name={'state'}
                                label={'State'}
                                value={dme.state}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                isValid={validType.state}
                            />
                        </Grid>
                        <Grid item sm={5} style={{paddingLeft: '20px', paddingTop:16}}>
                            <TextField
                                size={'small'}
                                style={{width: '100%'}}
                                helperText={missingValues.zip && tooltips.isRequired}
                                error={missingValues.zip}
                                required
                                id="zip"
                                name="zip"
                                label="Zip"
                                value={dme.zip}
                                variant={'outlined'}
                                autoComplete="billing address-level2"
                                // onChange={(e) => {
                                //     if (/^[0-9]*$/.test(e.target.value)) {
                                //         props.handleZipInput(e.target.value, true)
                                //     } else {
                                //         props.handleZipInput(e.target.value, false)
                                //     }
                                // }}
                                // onBlur={(e) => {
                                //     if (/^[0-9]*$/.test(e.target.value)) {
                                //         props.handleZipInput(e.target.value, true)
                                //     } else {
                                //         props.handleZipInput(e.target.value, false)
                                //     }
                                // }}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                            />
                            <FormHelperText
                                style={{color: 'red', textAlign: 'left'}}
                            >
                                {(!missingValues.zip && !validType.zip) && tooltips.zipOnlyNumbers}
                            </FormHelperText>
                        </Grid>
                        <Grid item sm={1} style={{ paddingTop:4}}>
                            <Tooltip title={tooltips.zip_autocomplete} arrow
                                     placement="top-end">
                                <InfoOutlinedIcon style={{marginTop: '20px'}}/>
                            </Tooltip>
                        </Grid>
                        <Grid item sm={8} style={{paddingLeft: '20px'}}>
                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                                {labels.requiredPhone}
                            </InputLabel>
                            <PhoneInput
                                name="phone_number"
                                label='test'
                                country={'us'}
                                disableCountryCode={true}
                                placeholder={placeholders.phone}
                                onlyCountries={['us']}
                                value={dme.phone}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone(true)
                                    }
                                    handleInput({
                                        target:{
                                            name: "phone",
                                            value: e,
                                            level: "primary",
                                        },
                                        inputEventType: inputType.required
                                    });
                                }}
                                inputProps={{
                                    label: 'test',
                                    required: true,
                                    id: 'primary-phone'
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14) {
                                        setPhone(true)
                                    } else {
                                        setPhone(false)
                                    }
                                }}
                                isValid={phone}
                            />
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{!phone && tooltips.invalidPhone}</FormHelperText>
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{missingValues.phone && tooltips.isRequired}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item sm={4}>
                        <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                            {labels.secondaryPhone}
                        </InputLabel>
                        <PhoneInput
                            name="phone_number"
                            label='test'
                            country={'us'}
                            disableCountryCode={true}
                            placeholder={placeholders.phone}
                            onlyCountries={['us']}
                            value={dme.second_phone}
                            onChange={(e) => {
                                if (e.length === 10) {
                                    setPhone2(true)
                                }
                                handleInput({
                                    target:{
                                        name: "second_phone",
                                        value: e,
                                        level: "secondary"
                                    },
                                    inputEventType: inputType.standard
                                });
                            }}
                            inputProps={{
                                label: 'test',
                                required: true,
                                id: 'secondary-phone'
                            }}
                            onBlur={(e) => {
                                if (e.target.value.length === 14) {
                                    setPhone2(true)
                                } else {
                                    setPhone2(false)
                                }
                            }}
                            isValid={phone2}
                        />
                        <FormHelperText style={{
                            color: 'red',
                            textAlign: 'left'
                        }}>{!phone2 && tooltips.invalidPhone}</FormHelperText>
                    </Grid>
                </Grid>
                   <Backdrop className={classes.backdrop} open={loading} >
                       <CircularProgress color="inherit" />
                   </Backdrop>
            </Grid>
        </React.Fragment>
    );
}