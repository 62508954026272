import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import {getContractData, getDmeData, getHospiceData} from "./ContractPrintHelper";
import {exportPickupPDF} from "../../../../../orders/pickupOrder/printTemplate/printPickup";
import {orderReasons} from "../../../../../../constants/inputs/values";
import {exportContractDetails} from "./PrintContractDetails";

export const ContractPrintModal = ({contractId, hospiceId, dmeId}) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = useState(20);
    const steps = ["Retrieving Dme Data...", "Retrieving Hospice Data...", "Retrieving Contract Data...", "Building Report...", "Report Finished!"]
    const [activeStep, setActiveStep] = useState(0);
    const [dmeData, setDmeData] = useState({});
    const [hospiceData, setHospiceData] = useState({});
    const [contractData, setContractData] = useState({});
    const [reportBuilt, setReportBuilt] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
        setProgress(20);
        setDmeData({});
        setHospiceData({});
        setContractData({});
        setReportBuilt(false);
    };

    useEffect(() => {
        if (open === true) {
            getDmeData(setDmeData, dmeId);
            getHospiceData(setHospiceData, hospiceId);
            getContractData(setContractData, contractId);
        }
    }, [open])

    useEffect(() => {
        if(Object.keys(dmeData).length > 0){
            if(Object.keys(hospiceData).length > 0){
                if(Object.keys(contractData).length > 0){
                    setReportBuilt(true);
                    if(reportBuilt === true){
                        setActiveStep(4);
                        setProgress(100);
                        return;
                    }
                    setActiveStep(3);
                    setProgress(80);
                    return;
                }
                setActiveStep(2);
                setProgress(60);
                return;
            }
            setActiveStep(1);
            setProgress(40);
        }
    }, [dmeData, hospiceData, contractData, reportBuilt])

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Download PDF
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Preparing Download"}</DialogTitle>
                <DialogContent>
                    <Grid container justify={"center"} spacing={1}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <Box width="100%" mr={1}>
                                    <LinearProgress variant="determinate" value={progress}/>
                                </Box>
                                <Box minWidth={35}>
                                    <Typography variant="body2" color="textSecondary">{`${Math.round(
                                        progress,
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{justifyContent: "center"}}>
                            <Typography variant="body2" color="textSecondary">{steps[activeStep]}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        {reportBuilt ? "Close" : "Cancel"}
                    </Button>
                    <Button onClick={() => exportContractDetails("print", contractData, hospiceData[0], dmeData[0])} disabled={!reportBuilt} color="primary" autoFocus>
                        Print
                    </Button>
                    <Button onClick={() => exportContractDetails("download", contractData, hospiceData[0], dmeData[0])} disabled={!reportBuilt} color="primary" autoFocus>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}