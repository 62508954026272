import React from "react";
import Grid from "@material-ui/core/Grid";
import {labels} from "../../../../constants/inputs/labels";
import {TextField} from "@material-ui/core";
import {supportStyles} from "../Support.style";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {makeStyles, withStyles} from "@material-ui/core/styles";

export const SupportForm = ({handleInput, handleSubmit, values}) => {

    const classes = useStyles();
    return (
        <Grid container direction={"column"} spacing={3} justify={"center"} alignItems={"flex-start"}
              className={classes.inputContainer}>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"support_name"}
                    label={labels.name}
                    value={values.name}
                    name={"name"}
                    variant={"outlined"}
                    onChange={handleInput}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"support_email"}
                    name={"email"}
                    value={values.email}
                    label={labels.email}
                    variant={"outlined"}
                    onChange={handleInput}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"support_phone"}
                    name={"phone"}
                    label={labels.phone}
                    value={values.phone}
                    variant={"outlined"}
                    onChange={handleInput}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item className={classes.textItem} style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"support_organization"}
                    name={"organization"}
                    label={labels.organization}
                    value={values.organization}
                    variant={"outlined"}
                    onChange={handleInput}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item style={{width: '100%', paddingRight: 48}}>
                <CssTextField
                    id={"support_issue"}
                    name={"issue"}
                    label={labels.issueDescription}
                    values={values.issue}
                    variant={"outlined"}
                    multiline
                    rows={7}
                    className={classes.issueTextField}
                    onChange={handleInput}
                    size='small'
                    fullWidth
                    required
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit}
                    style={{marginBottom: 67}}
                >
                    {buttonText.submit}
                </Button>
            </Grid>
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (supportStyles(theme)));