import {endpoints} from "../../../../../../constants/endpoints";
import _ from "lodash";

export const getContractData = (setContractData, contractId) => {
    const token = JSON.parse(localStorage.getItem('key'));
    fetch(`${endpoints.getContractDetails}${contractId}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        }
    })
        .then(response => {
            return response.json()
        })
        .then(response => {
            let object = {
                pi:[],
                pd:[],
                lf:[],
                oof:[],
                noType: [],
            };
            object.hospice_id = Number(response.data[0].hospice_id);
            object.active = response.data[0].active;
            object.hospice_region_id = response.data[0].hospice_region_id ? Number(response.data[0].hospice_region_id) : "";
            object.dme_id = Number(response.data[0].dme_id);
            object.contract_id = response.data[0].contract_id;
            object.per_diem_fee = response.data[0].per_diem_fee;
            object.dme_days = response.data[0].dme_days;
            object.fee_for_service = response.data[0].fee_for_service;
            object.billing_terms = response.data[0].billing_terms;
            object.fiscal_year = response.data[0].fiscal_year;
            object.tax_rate = response.data[0].tax_rate;
            object.renewal_date = response.data[0].contract_renewal_date;
            object.discount_rate = response.data[0].discount_rate;
            response.data.map(item => {
                let existingItem = {item_type: {}};
                existingItem.category = item.category;
                existingItem.id = item.id;
                existingItem.image = item.image;
                existingItem.item_id = item.item_id;
                existingItem.item_type.can_be_pickup = item.can_be_pickup;
                existingItem.item_type.approval = item.approval;
                existingItem.item_type.not_contracted = item.not_contracted;
                existingItem.item_type.lf = item.type === 'LF';
                existingItem.item_type.pd = item.type === 'PD';
                existingItem.item_type.pi = item.type === 'PI';
                existingItem.item_type.oof = item.type === 'OOF';
                existingItem.item_type.type = item.type;
                existingItem.item_type.visible = item.visible;
                existingItem.item_type.monthly = item.monthly;
                existingItem.max_quantity_per_item = item.max_quantity_per_item;
                existingItem.require_clinical_input = item.require_clinical_input;
                existingItem.tags = item.tags;
                existingItem.priority_code = item.priority_code;
                existingItem.item_type.price = item.supplemental_price;
                existingItem.item_type.cppd = item.cppd;
                existingItem.item_type.item_type_id = item.item_type_id;
                existingItem.item_type.risk = item.risk;
                existingItem.item_type.contracted_quantity = item.contracted_quantity;
                existingItem.name = item.name;
                existingItem.price= item.supplemental_price;
                switch (item.type){
                    case "LF":
                        object.lf.push(existingItem);
                        break;
                    case "PD":
                        object.pd.push(existingItem);
                        break;
                    case "PI":
                        object.pi.push(existingItem);
                        break;
                    case "OOF":
                        object.oof.push(existingItem);
                        break;
                    default:
                        object.noType.push(existingItem);
                        break;
                }
            });
            if (object.dme_days !== null) {
                object.contract_terms = 'dme_days';
            } else if (object.per_diem_fee !== null) {
                object.contract_terms = 'patient_days';
            } else {
                object.contract_terms = 'fee_for_service';
            }
             setContractData(object);
        })
        .catch(err => console.log('error', err))
}

export const getHospiceData = (setHospiceData, hospiceId) => {
    const token = JSON.parse(localStorage.getItem('key'));
    fetch(`${endpoints.hospice_organization}${hospiceId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    })
        .then(response => {
            return response.json()
        })
        .then(res => {
            if (res.data !== undefined) {
                setHospiceData(res.data);
            }
        })
        .catch(err => {
            console.log(err, 'error');
        })
};
export const getDmeData = (setDmeData, dmeId) => {
    const token = JSON.parse(localStorage.getItem('key'));
    fetch(`${endpoints.dme_organization}${dmeId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    })
        .then(response => {
            return response.json()
        })
        .then(response => {
            if (response.data !== undefined) {
                setDmeData(response.data);
            }
        })
        .catch(err => {
            console.log(err, 'error');

        })
};