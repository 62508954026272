import React from 'react';
import MaterialTable from "material-table";
import jwt from "jwt-decode";
import Grid from "@material-ui/core/Grid";

export default function NewSearch(){
    return (
        <Grid container style={{paddingTop:24}}>
            <Grid item xs={12} style={{padding:24}}>
                <Grid container style={{ padding:24, backgroundColor:'white'}}>
                    <Grid item xs={6}>
                        Search
                    </Grid>
                    <Grid item xs={3}>
                        Custom select
                    </Grid>
                    <Grid item xs={3}>
                        Button
                    </Grid>
                </Grid>
            </Grid>


        <MaterialTable
            title="Remote Data Preview"
            columns={[
                {
                    title: 'Avatar',
                    field: 'avatar',
                    render: rowData => (
                        <img
                            style={{ height: 36, borderRadius: '50%' }}
                            src={rowData.avatar}
                        />
                    ),
                },
                { title: 'Id', field: 'patient_id' },
                { title: 'First Name', field: 'first_name' },
                { title: 'Last Name', field: 'last_name' },
            ]}
            data={query =>
                new Promise((resolve, reject) => {

                    console.log(query)
                    const token = JSON.parse(localStorage.getItem('key'));
                    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
                    let endpoint;
                    let result = 663 / query.pageSize;
                    let direction = 'asc';
                    if (result-1 < query.page){
                       direction = 'desc'
                    }
                    if (userData.hospice_organization_id !== null) {
                        endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/hospice/active/${userData.hospice_organization_id}/${query.pageSize}/${query.page}/${direction}`
                    }
                    if (userData.dme_organization_id !== null) {
                        endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/dme/active/${userData.dme_organization_id}/${query.pageSize}/${query.page}/${direction}`
                    }

                    fetch(endpoint, {
                        method: 'get',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                    })
                        .then(response => response.json())
                        .then(result => {
                            resolve({
                                data: result.data,
                                page: query.page ? query.page : 0,
                                totalCount: 663,
                            })
                        })
                })
            }
        />
        </Grid>
    )
}
    //
    //
    // .then(response => {
    //     return response.json()
    // })
    // .then(response => {
    //     setPatients(response.data);
    //     setFilteredPatients(response.data);
    //     setLoading(false);
    // })
    // .catch(err => {
    //     setLoading(false);
    //     console.log(err, 'error');
    //     // enqueueSnackbar("Something happened. Contact support.", {
    //     //     persist: false,
    //     //     variant: "error",
    //     // });
    // })
