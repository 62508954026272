import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {sectionTitles} from "../../../constants/names/titles";
import {Table} from "../../../common/table/table";
import {patientInventoryStyles} from "../requestExchangeStyles";
import {SelectItems} from "./orderExchangeItems/selectItems/selectItems";
import {TextField} from "@material-ui/core";
import Select from "react-select";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import {placeholders} from "../../../constants/inputs/paceholders";
import {ExchangeItemSuggestions} from "./ExchangeItemSuggestions";
import InputLabel from "@material-ui/core/InputLabel";
import {labels} from "../../../constants/inputs/labels";

export default function DeliveryComponent(props) {
    const classes = patientInventoryStyles();
    const {
        exchangeCart, exchangeCartTableColumns, masterList,
        setDeliveryItems, deliveryItems, handleInput, suggestions,
        categories, contractedProvidersList, setDmeId,
    } = props;


    return (
        <Grid container justify="center" alignitems="center" direction={"row"} spacing={5}>
            {suggestions.length > 0 && <Grid item className={classes.suggestionContainer}>
                <ExchangeItemSuggestions
                    suggestions={suggestions}
                    handleInput={handleInput}
                />
            </Grid>}
            <Grid item className={classes.center}>
                <Typography variant={"h6"}>
                    {sectionTitles.itemsToDeliver}
                </Typography>
                <Grid container justify={"center"} alignItems={"flex-end"} direction={"row"} spacing={2}>
                    {contractedProvidersList.length > 1 && <Grid item xs={12} sm={4} md={4} lg={3}>
                        <InputLabel>{labels.dmeProvider}</InputLabel>
                        <Select options={contractedProvidersList}
                                onChange={e => setDmeId(e.value)}
                                id={"dmeProvider"}
                            // value={{value: priority, label: contractedProviders[_.findIndex(contractedProviders),{dme_id:dmeId}].label}}
                        />
                    </Grid>}
                    <Grid md={4} xs={12} item>
                        <TextField
                            variant={"outlined"}
                            size={"small"}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon/>
                                    </InputAdornment>
                                ),
                            }}
                            name='search'
                            placeholder={placeholders.quickSearch}
                            onChange={(e) => handleInput(e)}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Select
                            placeholder={placeholders.categories}
                            className="basic-single"
                            classNamePrefix="select"
                            value={props.category !== '' && [{value: props.category, label: props.category}]}
                            options={categories}
                            onChange={(e) => {
                                let obj = {target: {name: 'category', value: e.label}};
                                handleInput(obj)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.masterList} style={{minWidth: 320}}>
                    <SelectItems
                        user={"hello"}
                        items={masterList}
                        order={deliveryItems}
                        setOrder={setDeliveryItems}
                        handleInput={handleInput}
                        isPace={props.isPace}
                    />
                </Grid>
            </Grid>
            <Grid item className={classes.center} style={{justifyContent:'flex-start',alignItems:'flex-start',}}>
                <Typography variant={"h6"}>
                    {sectionTitles.exchangeCart}
                </Typography>
                <Table columns={exchangeCartTableColumns} items={exchangeCart} isLoading={false} pageSize={10} backGroundColor={'#F2F6F7'} textColor={"#2E3236"}/>
            </Grid>
        </Grid>
    )
}