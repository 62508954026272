import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment";
import {Checkbox} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getMessage} from "../../../../../constants/messages/messages";
import {messageTypes} from "../../../../../constants/types/messageTypes";
import jwt from "jwt-decode"

export const OrderNotes = ({notes, setNotes, notifyHospice, handleInput}) => {
    const [currentNote, setCurrentNote] = React.useState();
    let token = JSON.parse(localStorage.getItem('key'));
    let currentUser = jwt(token.accessToken).payload;
    const addOrderNote = () => {
        let final = moment().format('LLL');
        const arr = [...notes];
        let input = {
            user_id: currentUser.user_id,
            creator: `${currentUser.first_name} ${currentUser.last_name}`,
            created_date: final,
            description: currentNote
        };
        arr.push(input);
        setNotes(arr);
        setCurrentNote('')
    };

    return (
        <div style={{paddingBottom: '20px'}}>
            <Paper elevation={3}>
                <div style={{padding: "16px 24px", fontSize: 20}}>Notes for order:</div>
                <div style={{padding: "16px 24px"}}>
                    {notes && (
                        <div>
                            {notes.map((note, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div style={{
                                            display: 'flex',
                                            border: ' solid 1px #CCCCCC',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: 4,
                                            padding: 16,
                                            marginBottom: 12,
                                            justifyContent: 'space-between'
                                        }}>

                                            <div>{note.description}</div>
                                            <div style={{
                                                fontStyle: 'italic',
                                                color: "#B6B6B6"
                                            }}>{note.creator + ", " + note.created_date}</div>
                                        </div>
                                    </React.Fragment>

                                )
                            })
                            }
                        </div>
                    )}

                    <TextField
                        placeholder="Add additional note..."
                        multiline
                        name='note'
                        rows={2}
                        rowsMax={9}
                        value={currentNote}
                        style={{
                            border: '1px solid gray',
                            padding: '6px',
                            borderRadius: '5px',
                            width: '99%'
                        }}
                        onChange={(e) => {
                            setCurrentNote(e.target.value)
                        }}
                    />
                    <div style={{
                        alignContent: 'right',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        paddingTop: '10px',
                        width: '100%'
                    }}>
                        <Grid item style={{
                            alignContent: 'right',
                            justifyContent: 'flex-end',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px',
                        }}>
                            <Checkbox color={"primary"}
                                      checked={notifyHospice}
                                      name={"notifyHospice"}
                                      onChange={(event) => {
                                          const obj = {
                                              ...event,
                                              target: {
                                                  ...event.target,
                                                  name: "notifyHospice",
                                              }
                                          };
                                          handleInput(obj)
                                      }}
                            />
                            <Typography>{getMessage(messageTypes.notice, "Hospice")}</Typography>
                        </Grid>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={addOrderNote}
                        >Add Note</Button>
                    </div>

                </div>
            </Paper>
        </div>
    )
};