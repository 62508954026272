import {endpoints} from "../../constants/endpoints";
import _ from "lodash";
import {emailValidation, usernameValidation} from "../../constants/validations/validationTypes";

export const getPossibleUsernames = async (objSetter, firstName, lastName) => {
    if (firstName !== "" && lastName !== "") {
        const token = JSON.parse(localStorage.getItem('key'));
        let promise = await fetch(endpoints.randomUsername, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                "firstName": firstName,
                "lastName": lastName,
            })
        });
        let response = await promise.json();
        let {data} = response;
        data.splice(0, 0, "Username Suggestions");
        objSetter(data);
    }
};
export const getRestrictedDomains = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.restrictedDomains, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    objSetter(data);
};

export const checkEmail = (email, restrictedList, validSetter) => {
    if (email !== "") {
        const emailComponents = email.split("@");
        if (emailComponents.length === 2) {
            const domain = emailComponents[1];
            if (_.findIndex(restrictedList, {"domain_name": domain}) === -1) {

                validSetter(emailValidation.success);
            } else {
                validSetter(emailValidation.invalidDomain)
            }
        } else {
            validSetter(emailValidation.invalidEmail);
            console.log("Email is not correct");
        }
    } else {
        validSetter(emailValidation.empty);
    }
};

export const checkUsername = (username, validSetter) => {
    if (username.includes("@")) {
        validSetter(usernameValidation.possibleEmail)
    } else {
        validSetter(usernameValidation.success)
    }
};