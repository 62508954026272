import React from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import {TextField} from "@material-ui/core";
import AssetSelect from "../components/assetSelect";
import _ from "lodash";
import moment from "moment";


const ItemsTable = props => {
    const classes = useStyles();
    return (
        <Grid container className={classes.wrapper}>
            <Grid container className={classes.container} justify={"space-between"}>
                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                    <Grid xs={1} item className={classes.row}></Grid>
                    <Grid xs={8} item className={classes.row}>Item name</Grid>
                    <Grid xs={6} item className={classes.row}>Asset #</Grid>
                    <Grid xs={4} item className={classes.row}>Qty</Grid>
                    <Grid xs={6} item className={classes.row}>Clinical setting</Grid>
                    <Grid xs={6} item className={classes.row}>Lot #</Grid>
                    <Grid xs={4} item className={classes.row}>Delivery date</Grid>
                    <Grid xs={4} item className={classes.row}>Pickup date</Grid>
                </Grid>
            </Grid>
            {props.items && (
                props.items.map((item, index) => {
                    let includes = _.findIndex(props.selectedItems, {'special_item_id': item.special_item_id}) !== -1;
                    return (
                        <Grid key={index} container justify={"space-between"} className={classes.rows}>
                            <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                                <Grid xs={1} item className={classes.checkbox}>
                                    <Checkbox
                                        color="primary"
                                        onChange={(e) => props.handleCheckboxChange(item, index, e.target.checked)}
                                        checked={includes}
                                    />
                                </Grid>
                                <Grid xs={8} item className={classes.row}>{item.name}</Grid>
                                <Grid xs={6} item className={classes.row}>
                                    {props.userType === 'DME' ? (
                                        <div style={{width: '100%'}}>
                                            <AssetSelect
                                                type={props.type}
                                                item={item}
                                                selectAssetHandle={props.selectAssetHandle}
                                                index={index}
                                                options={props.availableData}
                                                dme_organization_id={props.dme_organization_id}
                                                setCurrentSpecialId={props.setCurrentSpecialId}
                                            />
                                        </div>
                                    ) : item.inventory ? item.inventory.asset : ''}
                                </Grid>
                                <Grid xs={4} item className={classes.row}>{item.quantity ? item.quantity : 1}</Grid>
                                <Grid xs={6} item className={classes.row}>
                                    <div style={{textAlign: 'left'}}>
                                        {item.min_range && (
                                            <React.Fragment>
                                                <div> {`${item.liters_per_minute === true ? 'Liters Per Minute': 'Air PSI'}`} </div>
                                                <div> {`Min: ${item.min_range}`} </div>
                                                <div> {`Max: ${item.max_range}`} </div>
                                            </React.Fragment>
                                        )}
                                        {item.iscontinous === true && (
                                            <div> {`With continuous oxygen therapy`} </div>
                                        )}
                                        {item.humidifier_for_compressor && (
                                            <div> {`Humidifier bottle: ${item.humidifier_for_compressor}%`} </div>
                                        )}
                                        {item.weight  && (
                                            <div> {`Patient Weight: ${item.weight}`} </div>
                                        )}
                                        {item.size_lift && (
                                            <div> {`Size: ${item.size_lift}`} </div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid xs={6} item className={classes.row}>
                                    {props.userType === 'DME' ? (
                                        item.asset && (
                                            <div style={{width: '100%'}}>
                                                <TextField
                                                    placeholder="Add lot number"
                                                    size="small"
                                                    variant="outlined"
                                                    value={item.lot_no && item.lot_no}
                                                    onChange={(e) => {
                                                        props.selectAssetHandle(e, index, "lot", "name", item.asset, false, item.special_item_id, props.type);
                                                        props.setCurrentSpecialId(item.special_item_id);
                                                    }

                                                    }
                                                    onBlur={(e) => {
                                                        props.selectAssetHandle(e, index, "lot", "name", item.asset, true, item.special_item_id,  props.type);
                                                        props.setCurrentSpecialId(item.special_item_id);
                                                    }}
                                                />
                                            </div>
                                        )
                                    ) : item.inventory ? item.inventory.lot : ''}
                                </Grid>
                                <Grid xs={4} item
                                      className={classes.row}>{props.order.completed_date && moment(props.order.completed_date).format('L')}</Grid>
                                <Grid xs={4} item className={classes.row}></Grid>
                            </Grid>
                        </Grid>
                    )
                })
            )}
        </Grid>
    )
};

export default ItemsTable;


const useStyles = makeStyles((theme) => ({
    wrapper: {
        // borderRadius: 4,
        //         // border: '1px solid #E0E0E0'
        paddingTop:16
    },
    container: {
        height: 96,
        color: '#2E3236',
        fontSize: 13,
        fontWeight:'bold',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: '#EDF2F4B9',
        maxWidth: 1428,
        width: '101%',
        minWidth: 800,
        margin: -1,
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 16,
        fontSize: '0.875rem',
    },
    rows: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '1px solid #E0E0E0',
        backgroundColor:'#fff',
        fontWeight:600,
        fontSize:14
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 2,
        fontSize: '0.875rem',
    }
}));
