import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {Tooltip} from "@material-ui/core";
import {titles} from "../../../../constants/inputs/tableColumnTitles";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {orderHeaderStyle} from "./orderHeader.style";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Order from "../../../../assets/order_icon.svg";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {checkPaceHospice} from "../../../../common/utils/utils";


export const OrderHeader = ({ setActiveStep,total, user, handleInput, order,contractedProvidersList,categoriesList, addMore, setOrder}) => {
    const [label, setLabel] = useState();
    const [showAuthorization, setShowAuthorization] = useState(false);
    const [showClearConfirmation, setShowClearConfirmation] = useState(false);
    const classes = useStyles();

    useEffect(()=> {
        async function check() {
            let res = await checkPaceHospice(user.hospice_organization_id);
            setShowAuthorization(res);
        }
        check();
    }, []);
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className={classes.h1}>
                    {!addMore ? "Order - New Delivery" : "Add more items"}
                </Typography>
            </Grid>
            <Grid container style={{display:'flex', justify:'flex-start'}}>
                <Grid item
                      // lg={3} md={4} sm={12} xs={12}
                      style={{display:'flex', flexDirection:'column', paddingRight:36,}}>
                    <Typography className={classes.h4}>
                        {user.first_name + " " + user.mi + " " + user.last_name}
                    </Typography>
                    <Typography className={classes.h5}>
                        {user.address1 + ", " + user.address2 + ", " + user.city  + ", " + user.state+ " " + user.zip }
                    </Typography>
                </Grid>
                {(contractedProvidersList.length > 1 && !addMore) &&
                <Grid item
                      style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: 1,paddingRight:12,}}>
                    <span style={{
                        fontSize: 12,
                        color: "#979797"
                    }}>Select Provider</span>
                    <div style={{width: '210px'}}>
                        <Select
                            fullWidth
                            placeholder={'Select desired provider...'}
                            value={order.dme_organization_id !== '' && [{
                                value: order.dme_organization_id,
                                label: contractedProvidersList[_.findIndex(contractedProvidersList, {"value": order.dme_organization_id})].label
                            }]}
                            options={contractedProvidersList}
                            onChange={(e) => {
                                let obj = {target: {name: 'dme_organization_id', value: e.value}};
                                handleInput(obj);
                                setLabel(e.label)
                            }}
                        />
                    </div>
                </Grid>
                }
                {(!addMore && order.dme_organization_id !== '') && <React.Fragment>
                <Grid item style={{paddingRight:12, maxWidth:200}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker
                                size={"small"}
                                inputVariant="outlined"
                                disableToolbar
                                variant="inline"
                                label={'Preferred date'}
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                placeholder={'MM/dd/yyyy'}
                                value={order.preferred_date}
                                onChange={(e)=>{
                                    let obj = {target: {name:'preferred_date',value:e}};
                                    handleInput(obj)
                                }}
                                KeyboardButtonProps={{'aria-label': 'change date',}}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item style={{display:'flex', flexDirection:'column', alignItems:'flex-start',paddingTop:1,paddingRight:12,}}>
                    <span style={{
                        fontSize:12,
                        color:"#979797"
                    }}>Select Priority</span>
                    <div style={{width:'184px'}}>
                        <Select
                            fullWidth
                            placeholder={'Select...'}

                            value={order.priority_code !== '' && [{value:order.priority_code, label:order.priority_code}]}
                            options={[{label:"Routine", value:"Routine"},{label:"Priority", value:"Priority"}]}
                            onChange={(e)=> {
                                let obj = {target: {name:'priority_code',value:e.label}};
                                handleInput(obj)
                            }}
                        />
                    </div>

                </Grid>
                    {showAuthorization === true && (
                        <Grid item style={{display:'flex', flexDirection:'column', alignItems:'flex-start',paddingTop:16,}}>
                            <TextField
                                placeholder={'Authorization number'}
                                label={'Authorization number'}
                                variant={'outlined'}
                                size={'small'}
                                value={order.authorization_pace_no}
                                onChange={(e)=>{
                                    let obj = {target: {name:'authorization_pace_no',value:e.target.value}};
                                    handleInput(obj)
                                }}
                            />

                        </Grid>
                    )}

                </React.Fragment>
                }
            </Grid>
            <Grid container style={{paddingTop:24}} alignItems={'flex-end'} justify={'space-between'}>
                <Grid item style={{display:'flex', marginBottom:12}}>
                    <Grid container alignItems={'flex-end'}  spacing={2}>
                    <Grid item>
                    <TextField
                        className={classes.margin}
                        id="input-with-icon-textfield"
                        placeholder={placeholders.quickSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{fontSize:32,color: 'rgb(230,232,234)'}}/>
                                </InputAdornment>
                            ),
                        }}
                        style={{width:184}}
                        name={'search'}
                        onChange={handleInput}
                    />
                    </Grid>
                    <Grid item>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start',paddingTop:1,}}>
                        <span style={{
                            fontSize:12,
                            color:"#979797"
                        }}>Select Category</span>
                        <div style={{width:'184px'}}>
                            <Select
                                fullWidth
                                placeholder={'Select...'}

                                value={order.category !== '' && [{value:order.category, label:order.category}]}
                                options={categoriesList}
                                onChange={(e)=> {
                                    let obj = {target: {name:'category',value:e.label}};
                                    handleInput(obj)
                                }}
                            />
                        </div>
                    </div>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid item  md={6} sm={12} xs={12} style={{display:'flex', flexDirection:'column', alignItems:'flex-end', justify:'center', paddingBottom:8,}}>
                    <div style={{display:'flex',flexDirection:'row',  alignItems:'flex-end', alignContent:'flex-end', justify:'flex-end',}}>
                    <Button style={{
                        display: 'flex',
                        // text-align: center;
                        padding:8,
                        borderRadius: '4px',
                        cursor:'pointer',
                        "&:hover": {
                            backgroundColor: "blue"
                        },

                    }} onClick={()=> setActiveStep(1)}>
                            <img
                                src={Order}
                                alt="logo"
                                width={24}
                                className={classes.pointer}
                                style={{marginRight:8}}
                            />
                            <Typography style={{color:"#4F86A0", fontSize:20, fontWeight:600}}>{`Order items:   ${total} items`}</Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        style={{
                            marginLeft:8,
                        display: 'flex',
                        padding:'6px 12px',
                        borderRadius: '4px',
                        borderColor:'#212121',
                        cursor:'pointer',
                        "&:hover": {
                            backgroundColor: "blue"
                        },

                    }} onClick={()=> {
                        if (order.items.length > 0) {
                            setShowClearConfirmation(true)
                        }
                    }}>
                       Clear Cart
                    </Button>
                    </div>
                </Grid>
                <Dialog
                    open={showClearConfirmation}
                    onClose={() => setShowClearConfirmation(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <span style={{fontSize:16, fontWeight:600}}>
                            All items for the cart will be removed. Do you want to proceed?
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowClearConfirmation(false)} variant='outlined' color="secondary" style={{ fontWeight:600}}>
                            No, return
                        </Button>
                        <Button
                            onClick={()=> {
                                setOrder({...order, items: []});
                                setShowClearConfirmation(false);
                            }}
                                variant='contained' color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    )
};
const useStyles = makeStyles((theme) => (orderHeaderStyle(theme)));
