import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox} from "@material-ui/core";
import MaterialTable from "material-table";

export default function Review(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Review contract
            </Typography>
            <Grid container spacing={3}>
                <MaterialTable
                    title=""
                    columns={[
                        {title: 'Item Name', field: 'name', width: 200},
                        {
                            title: 'PD', field: 'pd', width: 15, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'pd'}
                                    checked={props.items[rowData.tableData.id].item_type.pd}
                                />
                        },
                        {
                            title: 'PI', field: 'pi', width: 15, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'pi'}
                                    checked={props.items[rowData.tableData.id].item_type.pi}
                                />
                        },
                        {
                            title: 'OOF', field: 'oof', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'oof'}
                                    checked={props.items[rowData.tableData.id].item_type.oof}
                                    disabled
                                />
                        },
                        {
                            title: 'LF', field: 'lf', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'lf'}
                                    checked={props.items[rowData.tableData.id].item_type.lf}
                                />
                        },
                        {
                            title: 'Monthly', field: 'monthly', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'lf'}
                                    checked={props.items[rowData.tableData.id].item_type.monthly}
                                />
                        },
                        {
                            title: 'Risk %', field: 'risk', width: 110,
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.risk}
                                    size='small'
                                    name='risk'
                                />
                            </div>
                        },
                        {
                            title: 'Qty', field: 'qty', width: 50,
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.qty}
                                    size='small'
                                    name='qty'
                                />
                            </div>
                        },
                        {
                            title: 'Cppd', field: 'cppd', width: 50,
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.cppd}
                                    size='small'
                                    name='cppd'
                                />
                            </div>
                        },
                        {
                            title: 'Price', field: 'price', width: 20,
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.price}
                                    size='small'
                                    name='price'
                                />
                            </div>
                        },
                        {
                            title: 'Approval', field: 'approval', width: 20, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'approval'}
                                    checked={props.items[rowData.tableData.id].item_type.approval}
                                /></div>
                        },
                        {
                            title: 'Visible', field: 'visible', width: 20, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'visible'}
                                    checked={props.items[rowData.tableData.id].item_type.visible}

                                /></div>
                        },
                        {
                            title: 'Not Contracted', field: 'not_contracted', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    disabled
                                    size='small'
                                    color={'primary'}
                                    name={'not_contracted'}
                                    checked={props.items[rowData.tableData.id].item_type.not_contracted}
                                /></div>
                        },
                        {
                            title: 'Available for pickup', field: 'can_be_pickup', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    size='small'
                                    disabled
                                    color={'primary'}
                                    name={'can_be_pickup'}
                                    checked={props.items[rowData.tableData.id].item_type.can_be_pickup}
                                /></div>
                        },
                        {
                            title: 'Prescription Required', field: 'prescription_required', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    size='small'
                                    disabled
                                    color={'primary'}
                                    name={'prescription_required'}
                                    checked={props.items[rowData.tableData.id].item_type.prescription_required}

                                /></div>
                        },
                    ]}
                    data={props.items}
                    options={{
                        pageSizeOptions: [5, 10, 20],
                        search: true,
                        headerStyle: {position: 'sticky', top: 0}, maxBodyHeight: '450px'
                    }}
                />
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'left',
        paddingBottom: 12
    },
    wrapper: {
        borderRadius: 4,
        border: '1px solid #E0E0E0'
    },
    container: {
        height: 56,
        fontSize: '0.875rem',
        border: '1px solid black',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        maxWidth: 1200,
        width: '101%',
        minWidth: 800,
    },
    row: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rows: {
        paddingTop: 8,
        paddingBottom: 8,
        border: '1px solid #E0E0E0',
        height: 380,
        overflow: 'auto',

    },
    checkbox: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rowCenter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rowCheckbox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
    }
}));
