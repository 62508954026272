import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode"
import {BRDmeView} from "./components/DmeView";
import {BRHospiceView} from "./components/HospiceView";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {reportTypes} from "../../../constants/types/reportTypes";
import moment from "moment";
import {endpoints} from "../../../constants/endpoints";
import {getDmes} from "../../patients/add-new-patient/patientHelper";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {getAllDmes, getAllHospices} from "../../utils/helpers/dataGetter";

export const BusinessReview = ({}) => {

    const token = JSON.parse(localStorage.getItem("key"));
    const userData = jwt(token.accessToken).payload;
    const history = useHistory();
    const dispatch = useDispatch();
    const [dmeOptions, setDmeOptions] = useState([]);
    const [hospiceOptions, setHospiceOptions] = useState([]);
    const [selectedDme, setSelectedDme] = useState();
    const [selectedHospice, setSelectedHospice] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [reportType, setReportType] = useState(reportTypes.quarterly);
    const [reportData, setReportData] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const tableColumnsQuarter = [
        {title: "Type", field: "type", width: 5,headerStyle: {textAlign: 'center'}},
        {title: 'Total', field: 'total',width: 120,headerStyle: {textAlign: 'center'}}
    ];
    const tableColumnsYear = [
        {title: "Type", field: "type", width: 5,headerStyle: {textAlign: 'center'}},
        {title: "Quarter 1", field: "q1", width: 5,headerStyle: {textAlign: 'center'}},
        {title: "Quarter 2", field: "q2", width: 5,headerStyle: {textAlign: 'center'}},
        {title: "Quarter 3", field: "q3", width: 5,headerStyle: {textAlign: 'center'}},
        {title: "Quarter 4", field: "q4", width: 5,headerStyle: {textAlign: 'center'}},
        {title: 'Totals/Avg', field: 'total',width: 120,headerStyle: {textAlign: 'center'}}
    ];

    const handleInput = (e) => {
        switch (e.name){
            case "selectDme":
                setSelectedDme(e);
                break;
            case "selectHospice":
                setSelectedHospice(e);
                break;
            case "reportType":
                setReportType(e.value)
                setReportData({});
                break;
            case "startDate":
                setStartDate(e.value);
                break;
            case "endDate":
                setEndDate(e.value);
                break;
            default:
                break;
        }
    }

    const getReport = async () => {

        setIsLoading(true);
        let promise = await fetch(endpoints.businessReviewReport, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                hospice_id: userData.type === "Hospice" ? userData.hospice_organization_id : selectedHospice.value,
                dme_id: userData.type === "DME" ? userData.dme_organization_id : selectedDme.value,
                report_type: reportType,
                start_date : startDate,
            })
        })
        let response = await promise.json()
        setReportData(response.data);
        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        if(userData.type === "Hospice") {
            getAllDmes(setDmeOptions);
        }
        if(userData.type === "DME") {
            getAllHospices(setHospiceOptions);
        }

    },[])

    return (
        <Grid container justify={"center"} alignContent={"center"} alignItems={"center"} spacing={1}>
            {userData.type === "DME" && <BRDmeView reportType={reportType}
                                                       hospiceOptions={hospiceOptions}
                                                       handleInput={handleInput}
                                                       startDate={startDate}
                                                       endDate={endDate}
                                                       getReport={getReport}
                                                       tableData={reportData}
                                                       isLoading={isLoading}

            />}
            {userData.type === "Hospice" && <BRHospiceView reportType={reportType}
                                                       dmeOptions={dmeOptions}
                                                       handleInput={handleInput}
                                                       startDate={startDate}
                                                       endDate={endDate}
                                                       getReport={getReport}
                                                       tableData={reportData}
                                                       isLoading={isLoading}

                                        />}
        </Grid>
    )
}