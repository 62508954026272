export const tableColumnTitles = {
    itemId: "Item ID",
    itemName: "Item Name",
    item: "Item",
    itemDescription: "Item Description",
    itemTags: "Item Tags",
    itemImageId: "Item Image ID",
    itemImagePreview: "Image Preview",
    clinicalInput: "Clinical Input",
    domain: "Domain",
    manufacturer: "Manufacturer",
    domainID: "Domain ID",
    manufacturerID: "Manufacturer ID",
    maxQuantity: "Maximum Quantity",
    priorityCode: "Priority Code",
    operation: "Action",
    username: "Username",
    statusCode: "Status for Action",
    createdDate: "Action Date",
    asset: "Asset",
    lot: "Lot",
    emailAddress: "Email Address",
    name: "Name",
    userGroup: "User Group",
    phoneNumber: "Phone Number",
    status: "Status",
    hospiceOrganization: "Hospice Organization",
    dmeOrganization: "DME Organization",
    edit: "Edit",
    nurse: "Nurse",
    orderType: "Order Type",
    orderId: "Order ID",
    orderStatus: "Order Status",
    priority: "Priority",
    requestedDate: "Requested Date",
    patient: "Patient",
    organization: "Organization",
    action: "Action",
    quantity: "Quantity",
    class: "Class",
    delivery: "Delivery",
    pickup: "Pickup",
    address: "Address",
    category: "Category",
    hospiceRegion: "Hospice Region",
    dmeWarehouses: "DME Warehouses",
    effectiveDate: "Effective Date",
    renewalDate: "Renewal Date",
    terminationDate: "Termination Date",
    description: "Description",
    featureId: "Feature Id",
    featureName: "Feature Name",


};