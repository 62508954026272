import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles} from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import readXlsxFile from 'read-excel-file';
import Grid from "@material-ui/core/Grid";
import {componentTitles} from "../../../../constants/names/titles";
import Typography from "@material-ui/core/Typography";
import {endpoints} from "../../../../constants/endpoints";
import {status} from "../../../../constants/status/status";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {useSnackbar} from "notistack";
import jwt from "jwt-decode";
import CircularProgress from '@material-ui/core/CircularProgress';
import {BulkStyle} from "./bulkUpload.style";

export const BulkUpload = () => {
    const fileRef = React.useRef();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = React.useState([]);

    const handleItemsUpload =(event, type) => {
        switch (type) {
            case 'Patients': {
                setIsLoading(true);
                readXlsxFile(event.target.files[0]).then((rows) => {
                    let arr = [];
                    rows.map((row,i) => {
                        if (i > 1 && row[3] !== null) {
                            let patientPhone = row[10];
                            let caregiverOnePhone = row[27];
                            let caregiverTwoPhone = row[32];
                            let caregiverThreePhone = row[37];
                            let dob = row[5];
                            if (patientPhone.length === 12){
                                patientPhone = patientPhone.replace(/-/g, "");
                            }
                            if (caregiverOnePhone && caregiverOnePhone.length === 12){
                                caregiverOnePhone = caregiverOnePhone.replace(/-/g, "");
                            }
                            if (caregiverTwoPhone && caregiverTwoPhone.length === 12){
                                caregiverTwoPhone = caregiverTwoPhone.replace(/-/g, "");
                            }
                            if (caregiverThreePhone && caregiverThreePhone.length === 12){
                                caregiverThreePhone = caregiverThreePhone.replace(/-/g, "");
                            }
                            let dobSplit = dob.split("/");
                            if (dobSplit[0].length === 1){
                                dob = `0${dobSplit[0]}/${dobSplit[1]}/${dobSplit[2]}`
                            }
                            arr.push({
                                medical_record: row[0]=== null ? "" : row[0],
                                first_name:row[2],
                                last_name:row[3],
                                MI:row[4] === null ? "" : row[4],
                                phone_number:patientPhone,
                                care_setting:row[12],
                                team_id:row[14],
                                facility_id:row[13],
                                date_of_birth:dob,
                                gender: row[6],
                                patient_weight: row[7],
                                hospice_organization_id:row[16],
                                region_id: row[17],
                                patient_height_in_feet: row[8],
                                patient_height_in_inches:row[9] === null ? "" : row[9],
                                zip:row[20],
                                city:row[21],
                                state:row[22],
                                nurse_id:row[15],
                                caregivers:[
                                    {
                                        caregiver_first_name:row[25],
                                        caregiver_last_name:row[26],
                                        caregiver_phone_number:caregiverOnePhone,
                                        caregiver_relation:row[29],
                                        type:"first"
                                    },
                                    {
                                        caregiver_first_name:row[30],
                                        caregiver_last_name:row[31],
                                        caregiver_phone_number:caregiverTwoPhone,
                                        caregiver_relation:row[34],
                                        type:"second"
                                    },
                                    {
                                        caregiver_first_name:row[35],
                                        caregiver_last_name:row[36],
                                        caregiver_phone_number:caregiverThreePhone,
                                        caregiver_relation:row[39],
                                        type:"third"
                                    }
                                ],
                                address:{
                                    address1:row[18],
                                    address2:row[19] === null ? "" : row[19],
                                    zip:row[20],
                                    city:row[21],
                                    state:row[22],
                                },
                                diseases:[
                                    row[24]
                                ],
                                user_id:row[23]
                            });
                        }
                    });
                    (async () => {
                        const availableToken = await checkTokenAvailability();
                        if (!availableToken) {
                            dispatch(logout());
                            history.push('/login');
                        }
                    })();
                    const token = JSON.parse(localStorage.getItem('key'));
                    let decoded = jwt(token.accessToken);
                    let payload = decoded.payload;

                    fetch(endpoints.bulkUploadPatients, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            items: arr,
                            resource_type: type,
                            email: payload.email
                        })
                    }).then(res => {
                            if (res.status === status.created) {
                                setItems([...items, ...arr]);
                            } else {
                                console.log("[Error]: Error encountered while uploading the file.");
                            }
                        enqueueSnackbar("Patients created with success", {
                            persist: false,
                            variant: "success",
                        });
                            setIsLoading(false);
                            fileRef.current.value = '';
                        }
                    ).catch(err => {

                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                        // fileRef.current.value = '';
                    })

                });




                break;
            }
            case 'Inventory': {
                readXlsxFile(event.target.files[0]).then((rows) => {
                    setIsLoading(true);
                    let arr = [];
                    rows.map((row,i) => {
                        if (i > 0 && row[0] !== null) {
                            arr.push({
                                item_name:row[0],
                                warehouse_id:row[1],
                                asset:row[3],
                                maintenance:row[6],
                                manufacturer_id:row[2],
                                serial_id:row[4],
                                status:row[7],
                                price:row[5],
                                dme_organization_id:row[8]
                            });
                        }
                    });
                    (async () => {
                        const availableToken = await checkTokenAvailability();
                        if (!availableToken) {
                            dispatch(logout());
                            history.push('/login');
                        }
                    })();
                    const token = JSON.parse(localStorage.getItem('key'));
                    let decoded = jwt(token.accessToken);
                    let payload = decoded.payload;

                    fetch(endpoints.bulkUploadPatients, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            items: arr,
                            resource_type: type,
                            email: payload.email
                        })
                    }).then(res => {
                            if (res.status === status.created) {
                                setItems([...items, ...arr]);
                            } else {
                                console.log("[Error]: Error encountered while uploading the file.");
                            }
                            enqueueSnackbar("Items created with success", {
                                persist: false,
                                variant: "success",
                            });
                            setIsLoading(false);
                            fileRef.current.value = '';
                        }
                    ).catch(err => {
                        console.log(err, 'error');
                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                        fileRef.current.value = '';
                    })

                });




                break;
            }
            case 'Delivery': {
                readXlsxFile(event.target.files[0]).then((rows) => {
                    setIsLoading(true);
                    let arr = [];

                    rows.map((row,i) => {
                        if (i > 1 && row[0] !== null) {
                            arr.push({
                                patient_id:row[0],
                                preferred_date:row[3],
                                priority_code:row[4],
                                hospice_id:row[2],
                                user_id:row[5],
                                dme_organization_id:row[1],
                                products: [
                                    {
                                        oxygen_quantity: row[10],
                                        min_range: row[11],
                                        max_range: row[12],
                                        isContinuous: row[13],
                                        psi_for_compressor: row[14],
                                        humidifier_for_compressor: row[15],
                                        weight: row[16],
                                        size_lift: row[17],
                                        item_id: row[8],
                                        quantity: row[6],
                                        type: row[7],
                                        liters_per_minute: row[18]
                                    }
                                ]
                            });
                        } else if(i > 1 && row[8] !== null){
                            if (arr[arr.length-1]) {
                                arr[arr.length-1].products.push({
                                    oxygen_quantity: row[10],
                                    min_range: row[11],
                                    max_range: row[12],
                                    isContinuous: row[13],
                                    psi_for_compressor: row[14],
                                    humidifier_for_compressor: row[15],
                                    weight: row[16],
                                    size_lift: row[17],
                                    item_id: row[8],
                                    quantity: row[6],
                                    type: row[7],
                                    liters_per_minute: row[18]
                                })
                            }
                        }
                    });
                    (async () => {
                        const availableToken = await checkTokenAvailability();
                        if (!availableToken) {
                            dispatch(logout());
                            history.push('/login');
                        }
                    })();
                    const token = JSON.parse(localStorage.getItem('key'));
                    let decoded = jwt(token.accessToken);
                    let payload = decoded.payload;

                    fetch(endpoints.bulkUploadPatients, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            items: arr,
                            resource_type: type,
                            email: payload.email
                        })
                    }).then(res => {
                            if (res.status === status.created) {
                                setItems([...items, ...arr]);
                            } else {
                                console.log("[Error]: Error encountered while uploading the file.");
                            }
                            enqueueSnackbar("Items created with success", {
                                persist: false,
                                variant: "success",
                            });
                            setIsLoading(false);
                            fileRef.current.value = '';
                        }
                    ).catch(err => {
                        console.log(err, 'error');
                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                        fileRef.current.value = '';
                    })

                });




                break;
            }
            default: {

            }
        }

    };

    return (
        <Grid container justify={"center"} alignItems={"center"} direction={"column"} className={classes.container}>
            {isLoading ? (
                <div style={{height:600, display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress />
                </div>
            ) : (
            <React.Fragment>
                <Grid item>
                    <Typography variant={"h6"} style={{fontWeight:'600'}}>
                        {componentTitles.bulkUpload}
                    </Typography>
                </Grid>
                <Grid container justify={"center"} alignItems={"flex-start"} direction={"row"}>
                    <Grid style={{background:'white', padding:'20px 48px',margin:12, marginTop:24, borderRadius:5, border:'1px solid lightgrey'}}>
                        <Typography  style={{fontWeight:'600', fontSize:18}}> Patients Upload</Typography>
                        <Grid item>
                            <input
                                ref={fileRef}
                                style={{display: 'none'}}
                                id="raised-button-file"
                                type="file"
                                onChange={(e) => handleItemsUpload(e, 'Patients')}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="outlined" component="span" className={classes.button}>
                                    <CloudUploadIcon className={classes.icon}/>
                                    {buttonText.uploadPatientList}
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <a href='https://masteritemsimages.s3.amazonaws.com/Patient_Bulk_Template_New.xlsx'
                               rel="noopener noreferrer" download style={{textDecoration: 'none'}}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                >
                                    <GetAppIcon className={classes.icon}/>
                                    Download template
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid style={{background:'white', padding:'20px 48px',margin:12, marginTop:24, borderRadius:5, border:'1px solid lightgrey'}}>
                        <Typography style={{fontWeight:'600', fontSize:18}}> Inventory Upload</Typography>
                        <Grid item>
                            <input
                                ref={fileRef}
                                style={{display: 'none'}}
                                id="inventory-button"
                                type="file"
                                onChange={(e) => handleItemsUpload(e, 'Inventory')}
                            />
                            <label htmlFor="inventory-button">
                                <Button variant="outlined" component="span" className={classes.button}>
                                    <CloudUploadIcon className={classes.icon}/>
                                    {buttonText.uploadInventory}
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <a href='https://masteritemsimages.s3.amazonaws.com/Inventory.xlsx'
                               rel="noopener noreferrer" download style={{textDecoration: 'none'}}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                >
                                    <GetAppIcon className={classes.icon}/>
                                    Download template
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid style={{background:'white', padding:'20px 48px',margin:12, marginTop:24, borderRadius:5, border:'1px solid lightgrey'}}>
                        <Typography style={{fontWeight:'600', fontSize:18}}> Delivery Orders Upload</Typography>
                        <Grid item>
                            <input
                                ref={fileRef}
                                style={{display: 'none'}}
                                id="delivery-button"
                                type="file"
                                onChange={(e) => handleItemsUpload(e, 'Delivery')}
                            />
                            <label htmlFor="delivery-button">
                                <Button variant="outlined" component="span" className={classes.button}>
                                    <CloudUploadIcon className={classes.icon}/>
                                    {buttonText.uploadDelivery}
                                </Button>
                            </label>
                        </Grid>
                        <Grid item>
                            <a href='https://masteritemsimages.s3.amazonaws.com/Delivery_Orders_Bulk_Template.xlsx'
                               rel="noopener noreferrer" download style={{textDecoration: 'none'}}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                >
                                    <GetAppIcon className={classes.icon}/>
                                    Download template
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid style={{background:'white', padding:'20px 48px',margin:12, marginTop:24, borderRadius:5, border:'1px solid lightgrey'}}>
                        <Typography style={{fontWeight:'600', fontSize:18}}> Medical Record Upload</Typography>
                        <Grid item>
                            <Button variant="outlined" component="span" className={classes.button} onClick={() => history.push('/admin/bulk/medicalrecords')}>
                                <CloudUploadIcon className={classes.icon}/>
                                    Upload Medical Records
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid style={{background:'white', padding:'20px 48px',margin:12, marginTop:24, borderRadius:5, border:'1px solid lightgrey'}}>
                        <Typography style={{fontWeight:'600', fontSize:18}}> Users Upload</Typography>
                        <Grid item>
                            <Button variant="outlined" component="span" className={classes.button} onClick={() => history.push('/admin/bulk/users')}>
                                <CloudUploadIcon className={classes.icon}/>
                                Upload Users
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>   )}
        </Grid>
    )
};


const useStyles = makeStyles((theme) => BulkStyle(theme));
