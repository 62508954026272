import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {endpoints} from "../../../../constants/endpoints";
import {CircularProgress} from "@material-ui/core";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import moment from "moment";

export default function Finalize(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [hospiceDetails, setHospiceDetails] = useState([]);
    const [dmeDetails, setDmeDetails] = useState([]);
    const getHospiceById = async () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.hospice_organization}${props.contract.hospice_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(res => {
                if (res.data !== undefined) {
                    setHospiceDetails(res.data);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const getDmeById = async () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.dme_organization}${props.contract.dme_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data !== undefined) {
                    setDmeDetails(response.data);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    useEffect(() => {
        (async () => {
            await getHospiceById();
            await getDmeById()
        })()

    }, []);
    return (
        <React.Fragment>
            <Grid container justify='flex-start' style={{paddingLeft: 12}}>
                <Typography variant="h5" gutterBottom>
                    Finalize Contract
                </Typography>
            </Grid>
            <Grid container justify='flex-start' style={{paddingLeft: 12}}>
                <Typography variant="h6" gutterBottom>
                    Organizations
                </Typography>
            </Grid>
            <Grid container justify='center'>
                <Grid item xs={5}
                      style={{border: '1px solid lightgrey', borderRadius: 10, padding: 10}}
                >
                    <div
                        // style={{display:'flex', flexDirection: "column", justifyContent:'left'}}
                    >
                        <Typography variant="h6" gutterBottom>
                            Hospice
                        </Typography>

                        {hospiceDetails.length > 0 ? (
                            <React.Fragment>
                                <div> {hospiceDetails[0].hospice_name}</div>
                                <div>{`${hospiceDetails[0].address1} ${hospiceDetails[0].address2}`}</div>
                                <div>{`${hospiceDetails[0].city}, ${hospiceDetails[0].state} ${hospiceDetails[0].zip}`}</div>
                            </React.Fragment>
                        ) : (<CircularProgress/>)}
                    </div>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}
                      style={{border: '1px solid lightgrey', borderRadius: 10, padding: 10}}
                >
                    <Typography variant="h6" gutterBottom>
                        DME Company
                    </Typography>
                    {
                        dmeDetails.length > 0 ? (
                            <React.Fragment>
                                <div> {dmeDetails[0].dme_name}</div>
                                <div>{`${dmeDetails[0].address1} ${dmeDetails[0].address2}`}</div>
                                <div>{`${dmeDetails[0].city}, ${dmeDetails[0].state} ${dmeDetails[0].zip}`}</div>
                            </React.Fragment>) : (<CircularProgress/>)
                    }
                </Grid>
            </Grid>
            <Grid container style={{padding: 12}}>
                <Typography variant="h6" gutterBottom>
                    Terms
                </Typography>
            </Grid>
            <Grid container justify='center'>
                <Grid item xs={11}
                      style={{border: '1px solid lightgrey', borderRadius: 10, padding: 10}}
                >
                    <Grid item xs={3}>
                        <div> Billing type: Patient Days</div>
                        <div>Taxes: {props.contract.subject_to_taxes ? "Yes" : "No"}</div>
                        <div> Renewal Date: {moment(props.contract.renewal_date).format("L")}</div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>Per Diem Fee: ${props.contract.per_diem_fee}</div>
                        <div>Taxes: ${props.contract.subject_to_taxes ? props.contract.tax_rate : "0"}</div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{padding: 12}}>
                <Typography variant="h6" gutterBottom>
                    Items
                </Typography>
                {props.items.map((item, i) => {
                    return (<Grid container
                                  style={{border: '1px solid lightgrey', borderRadius: 10, padding: 10}}
                        >
                            <div key={i}>
                                {item.name}</div>
                        </Grid>
                    )
                })}
            </Grid>
        </React.Fragment>
    );
}