import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox} from "@material-ui/core";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import _ from "lodash";

export default function ConfigureServiceAgreement(props) {
    const classes = useStyles();
    const [page, setPage ] = useState(0);
    const [sort, setSort] = useState(["","","", "", "","","","", "", "","","","", "", "", ]);
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom className={classes.title}>
                Contract Items and Terms
            </Typography>
            <div style={{display: "flex", marginTop: -10}}>
                <Button id={'allPd'} variant='outlined' style={{textTransform: "none"}} onClick={() => {
                    let e = {
                        target: {
                            name: 'allPd',
                            checked: true
                        }
                    };
                    props.handleChange(e, 0);
                }}>
                    Mark all items as PD
                </Button>
                <Button variant='outlined' style={{textTransform: "none", marginLeft: 8}}
                        onClick={() => {
                            let e = {
                                target: {
                                    name: 'allPi',
                                    checked: true
                                }
                            };
                            props.handleChange(e, 0);
                        }}
                >
                    Mark all items as PI
                </Button>
                <Button variant='outlined' style={{textTransform: "none", marginLeft: 8}}
                        onClick={() => {
                            let e = {
                                target: {
                                    name: 'allOof',
                                    checked: true
                                }
                            };
                            props.handleChange(e, 0);
                        }}
                >
                    Mark all items as OOF
                </Button>
                <Button variant='outlined' style={{textTransform: "none", marginLeft: 8}}
                        onClick={() => {
                            let e = {
                                target: {
                                    name: 'allLf',
                                    checked: true
                                }
                            };
                            props.handleChange(e, 0);
                        }}
                >
                    Mark all items as LF
                </Button>
            </div>
            <div style={{height: 20}}>

            </div>

            <Grid container spacing={3}>
                <MaterialTable
                    title=""
                    columns={[
                        {title: 'Item Name', field: 'name', width: 200, defaultSort:sort[0]},
                        {
                            title: 'PD', field: 'pd', width: 15, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'pd'}
                                    checked={props.items[rowData.tableData.id].item_type.pd}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                />
                        },
                        {
                            title: 'PI', field: 'tags', width: 15, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'pi'}
                                    checked={props.items[rowData.tableData.id].item_type.pi}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                />
                        },
                        {
                            title: 'OOF', field: 'oof', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'oof'}
                                    checked={props.items[rowData.tableData.id].item_type.oof}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                />
                        },
                        {
                            title: 'LF', field: 'lf', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'lf'}
                                    checked={props.items[rowData.tableData.id].item_type.lf}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                />
                        },
                        {
                            title: 'Monthly', field: 'monthly', width: 5, sorting: false,
                            render: rowData =>
                                <Checkbox
                                    size='small'
                                    color={'primary'}
                                    name={'monthly'}
                                    disabled={props.items[rowData.tableData.id].item_type.type === "PD" || props.items[rowData.tableData.id].item_type.type === "PI" }
                                    checked={props.items[rowData.tableData.id].item_type.monthly}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                />
                        },
                        {
                            title: 'Risk %', field: 'risk', width: 110, defaultSort:sort[5],
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf &&
                                    !props.items[rowData.tableData.id].item_type.monthly
                                    }
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.risk}
                                    size='small'
                                    name='risk'
                                    onChange={(e) => props.handleChange(e, rowData.tableData.id)}
                                />
                            </div>
                        },
                        {
                            title: 'Qty', field: 'qty', width: 100, defaultSort:sort[6],
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf &&
                                    !props.items[rowData.tableData.id].item_type.monthly
                                    }
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.contracted_quantity}
                                    size='small'
                                    name='qty'
                                    onChange={(e) => props.handleChange(e, rowData.tableData.id)}
                                />
                            </div>
                        },
                        {
                            title: 'Cppd', field: 'cppd', width: 50,defaultSort:sort[7],
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf &&
                                    !props.items[rowData.tableData.id].item_type.monthly
                                    }
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.cppd}
                                    size='small'
                                    name='cppd'
                                    onChange={(e) => props.handleChange(e, rowData.tableData.id)}
                                />
                            </div>
                        },
                        {
                            title: 'Price', field: 'price', width: 20, defaultSort:sort[8],
                            render: rowData => <div style={{padding: 4}}>
                                <TextField
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf &&
                                    !props.items[rowData.tableData.id].item_type.monthly
                                    }
                                    variant="outlined"
                                    value={props.items[rowData.tableData.id].item_type.price}
                                    size='small'
                                    name='price'
                                    onChange={(e) => props.handleChange(e, rowData.tableData.id)}
                                />
                            </div>
                        },
                        {
                            title: 'Approval', field: 'approval', width: 20, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf}
                                    size='small'
                                    color={'primary'}
                                    name={'approval'}
                                    checked={props.items[rowData.tableData.id].item_type.approval}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                /></div>
                        },
                        {
                            title: 'Visible', field: 'visible', width: 20, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    disabled={!props.items[rowData.tableData.id].item_type.pi &&
                                    !props.items[rowData.tableData.id].item_type.oof &&
                                    !props.items[rowData.tableData.id].item_type.pd &&
                                    !props.items[rowData.tableData.id].item_type.lf}
                                    // disabled
                                    size='small'
                                    color={'primary'}
                                    name={'visible'}
                                    checked={props.items[rowData.tableData.id].item_type.visible}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                /></div>
                        },
                        {
                            title: 'Not Contracted', field: 'not_contracted', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    size='small'
                                    disabled={!props.items[rowData.tableData.id].item_type.existent}
                                    color={'primary'}
                                    name={'not_contracted'}
                                    checked={props.items[rowData.tableData.id].item_type.not_contracted}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                /></div>
                        },
                        {
                            title: 'Available for pickup', field: 'can_be_pickup', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    size='small'
                                    disabled={!props.items[rowData.tableData.id].item_type.pi}
                                    color={'primary'}
                                    name={'can_be_pickup'}
                                    checked={props.items[rowData.tableData.id].item_type.can_be_pickup}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                /></div>
                        },
                        {
                            title: 'Prescription Required', field: 'prescription_required', width: 180, sorting: false,
                            render: rowData => <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox
                                    size='small'
                                    disabled={props.items[rowData.tableData.id].item_type.not_contracted}
                                    color={'primary'}
                                    name={'prescription_required'}
                                    checked={props.items[rowData.tableData.id].item_type.prescription_required}
                                    onChange={(e) => {
                                        props.handleChange(e, rowData.tableData.id)
                                    }}
                                /></div>
                        },
                    ]}
                    data={props.masterItems}
                    options={{
                        initialPage:page,
                        pageSizeOptions: [5, 10, 20],
                        search: true,
                        headerStyle: {position: 'sticky', top: 0}, maxBodyHeight: '450px'
                    }}
                    onOrderChange={(colId, ord) =>{
                        let sortClone;
                        if (colId !== -1) {
                            sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                            sortClone[colId] = ord;
                        }
                        else {
                            sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                        }
                        setSort(sortClone);
                    }}
                    onChangePage={(newPage) => {
                        setPage(newPage);
                    }}
                />
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'left',
        paddingBottom: 12
    },
    wrapper: {
        borderRadius: 4,
        border: '1px solid #E0E0E0'
    },
    container: {
        height: 56,
        fontSize: '0.875rem',
        border: '1px solid black',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        maxWidth: 1200,
        width: '101%',
        minWidth: 800,
    },
    row: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rows: {
        paddingTop: 8,
        paddingBottom: 8,
        border: '1px solid #E0E0E0',
        height: 380,
        overflow: 'auto',

    },
    checkbox: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rowCenter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    rowCheckbox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
    }
}));
