const userNavMenu = (state = false, action) => {
    switch (action.type) {
        case 'Clicked':
            return !state;
        case 'NotClicked':
            return state = false;
        default:
            return state;
    }
};

export default userNavMenu;