import {endpoints} from "../../constants/endpoints";

export const checkFeatures = async (featureName, type, id) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let dbResponse = await fetch(endpoints.getOrgFeaturesById, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({
            dme_id: type === 'DME' ? id : null,
            hospice_id: type === 'Hospice' ? id : null
        })
    }).then(res => {
        return res.json()}).catch(err => {return true});
        let a = dbResponse;
        for (let feature in a.data){
            if (a.data[feature].name  === featureName ) {
                return true
            }
        }
        return false;
};