import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import {
    logout,
    setCareSettingValue,
    setCriteriaValue,
    setInactiveValue,
    setInputValue,
    setNurseValue,
    setTeamValue
} from '../../redux/actions';
import {useHistory} from 'react-router-dom';
import './navbar.css';
import jwt from 'jwt-decode';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Hidden,
    IconButton,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {NavMenu} from "./menu/menu";
import {makeStyles} from "@material-ui/core/styles";
import SimpleLogo from "../../assets/logo_caption_dove_outline.svg";
import {routes} from "../../constants/routes/routes";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import {NotificationCenter} from "../notificationCenter/NotificationCenter";
import Order from '../../assets/order_icon.svg'
import {notificationAccordionSummaryTypes} from "../../constants/types/messageTypes";
import PatientIcon from '../../assets/patient_icon.svg'
import SurveyIcon from '../../assets/flash_on-white-18dp.svg'
import {endpoints} from "../../constants/endpoints";
import moment from 'moment';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import BigLogo from "../../assets/logo_caption@2x.png";
import NewNotificationCenter from "../newNotificationCenter/newNotificationCenter";
import {checkTokenAvailability} from "../utils/utils";
export default function NavBar(props) {
    const classes = useStyles();
    let token = localStorage.getItem('key');
    let username;
    if (JSON.parse(token) !== null) {
        token = JSON.parse(token);
        let user = jwt(token.accessToken);
        username = user.payload.username;
    }
    let history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isLogged = useSelector(state => state.isLogged);
    const [hasToken, setHasToken] = useState(false);
    const [number, setNumber] = useState(0);
    const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false);
    const [impersonateDmeIds, setImpersonateDmeIds] = useState([]);
    const [selectedImpersonation, setSelectedImpersonation] = useState({});
    const [impersonationData, setImpersonationData] = useState({});
    const [originalUserDmeData, setOriginalUserDmeData] = useState({});
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    async function getPendingOrders(){
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.getPendingOrders, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        let orders = [
        //     {
        //     type: "RequireAttention",
        //     message: "Order D100100/is in OpenState since/12.23.2020",
        //     id: 100100,
        //     messageType: notificationAccordionSummaryTypes.pendingOrder
        // }
        ];
        for (let deliveryOrder of response.data.delivery){
            orders.push({
                type: "RequireAttention",
                message: `Order D100${deliveryOrder.delivery_order_id}/is in Pending since/${moment(deliveryOrder.created_date).format("LL")}`,
                id: deliveryOrder.delivery_order_id,
                messageType: notificationAccordionSummaryTypes.pendingOrder
            })
        }
        setOrderNotifications([
            {
                subcategoryName: "Pending State",
                entries: orders
            },
        ])
    }

    // useEffect(()=> {
    //     let token = localStorage.getItem('key');
    //     if (token && token !== "null") {
    //         getPendingOrders();
    //     }
    // }, [])

    //notification center hooks start
    const [orderNotifications, setOrderNotifications] = useState([
    //     {
    //     subcategoryName: "Open State",
    //     entries: [{
    //         type: "RequireAttention",
    //         message: "Order D100100/is in OpenState since/12.23.2020",
    //         id: 100100,
    //         messageType: notificationAccordionSummaryTypes.openOrder
    //     }, {
    //         type: "Informational",
    //         message: "Order D100101/is in OpenState since/12.23.2020",
    //         id: 100101,
    //         messageType: notificationAccordionSummaryTypes.openOrder
    //     }, {
    //         type: "RequireAttention",
    //         message: "Order D100102/is in OpenState since/12.23.2020",
    //         id: 100102,
    //         messageType: notificationAccordionSummaryTypes.openOrder
    //     }]
    // },
        {
            subcategoryName: "Pending State",
            entries: [
            //     {
            //     type: "RequireAttention",
            //     message: "Order D100100/is in OpenState since/12.23.2020",
            //     id: 100100,
            //     messageType: notificationAccordionSummaryTypes.pendingOrder
            // }, {
            //     type: "Informational",
            //     message: "Order D100101/is in OpenState since/12.23.2020",
            //     id: 100101,
            //     messageType: notificationAccordionSummaryTypes.pendingOrder
            // }, {
            //     type: "RequireAttention",
            //     message: "Order D100102/is in OpenState since/12.23.2020",
            //     id: 100102,
            //     messageType: notificationAccordionSummaryTypes.pendingOrder
            // }
            ]
        },
        // {
        //     subcategoryName: "Processing State",
        //     entries: [{
        //         type: "RequireAttention",
        //         message: "Order D100100/is in OpenState since/12.23.2020",
        //         id: 100100,
        //         messageType: notificationAccordionSummaryTypes.processingOrder
        //     }, {
        //         type: "Informational",
        //         message: "Order D100101/is in OpenState since/12.23.2020",
        //         id: 100101,
        //         messageType: notificationAccordionSummaryTypes.processingOrder
        //     }, {
        //         type: "RequireAttention",
        //         message: "Order D100102/is in OpenState since/12.23.2020",
        //         id: 100102,
        //         messageType: notificationAccordionSummaryTypes.processingOrder
        //     }]
        // },
    ]);
    const [patientNotification, setPatientNotification] = useState([{
        subcategoryName: "Created Patient",
        entries: [{
            type: "Informational",
            message: "Patient with id:123/was created in/1.23.2021",
            id: 123,
            messageType: notificationAccordionSummaryTypes.patientCreation
        }, {
            type: "Informational",
            message: "Patient with id:234/was created in/1.23.2021",
            id: 234,
            messageType: notificationAccordionSummaryTypes.patientCreation
        }, {
            type: "Informational",
            message: "Patient with id:345/was created in/1.23.2021",
            id: 345,
            messageType: notificationAccordionSummaryTypes.patientCreation
        }]
    },
    ]);
    const [surveyNotification, setSurveyNotification] = useState([{
        subcategoryName: "User Responses",
        entries: [{
            type: "RequireAttention",
            message: "Survey 111/was answered by JaneDoe /111",
            id: 111,
            messageType: notificationAccordionSummaryTypes.surveyUserResponses
        }, {
            type: "RequireAttention",
            message: "Survey 112/was answered by JaneDoe /111",
            id: 112,
            messageType: notificationAccordionSummaryTypes.surveyUserResponses
        }, {
            type: "RequireAttention",
            message: "Survey 113/was answered by JaneDoe /111",
            id: 113,
            messageType: notificationAccordionSummaryTypes.surveyUserResponses
        }]
    },
    ]);

    //notification center props
    const [notificationDataRetrieval, setNotificationDataRetrieval] = useState([{
            endpoint: "",
            method: "",
            bodyContent: {},
            setterFunction: setOrderNotifications,
        }]
    );

    const [notificationCategories, setNotificationCategories] = useState([{
        categoryName: "Orders",
        categoryIcon: Order,
        categoryData: orderNotifications,
    },
    //     {
    //     categoryName: "Patients",
    //     categoryIcon: PatientIcon,
    //     categoryData: patientNotification,
    // }, {
    //     categoryName: "Surveys",
    //     categoryIcon: SurveyIcon,
    //     categoryData: surveyNotification,
    // },
    ]);


    useEffect(()=>{
        setNotificationCategories([{
            categoryName: "Orders",
            categoryIcon: Order,
            categoryData: orderNotifications,
        },
            //     {
            //     categoryName: "Patients",
            //     categoryIcon: PatientIcon,
            //     categoryData: patientNotification,
            // }, {
            //     categoryName: "Surveys",
            //     categoryIcon: SurveyIcon,
            //     categoryData: surveyNotification,
            // },
        ])
    }, [orderNotifications])
    //notification center hooks end
    //notification center functions start
    const getNotificationData = async () => {
        const token = JSON.parse(localStorage.getItem("key"));
        for (const item of notificationDataRetrieval) {
            let requestParams = {
                method: item.method,
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token.accessToken
                }
            };
            if(item.method === "POST"){
                requestParams["body"] = JSON.stringify(item.bodyContent);
            }
            const promise = await fetch(item.endpoint, requestParams);
            const response = await promise.json();
            if(response.status === "success"){
                item.setterFunction(response.data);
            }

        }
    }
    //notification center functions end
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onSignOut = () => {
        dispatch(logout());
        dispatch(setInputValue(''));
        dispatch(setCriteriaValue(['All']));
        dispatch(setNurseValue('All'));
        dispatch(setCareSettingValue('All'));
        dispatch(setTeamValue('All'));
        dispatch(setInactiveValue(false));
        history.push('/login');
        localStorage.setItem('key', JSON.stringify(null));
    };

    const checkUser = async () => {
        if (username) {
            setHasToken(true);
            const promise = await fetch(endpoints.getDMEUserDataById + jwt(token.accessToken).payload.user_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const response = await promise.json();
            if (response.status === 'success') {
                const {data} = response;
                setOriginalUserDmeData(data[0]);
            }

            const impersonationPromise = await fetch(endpoints.getUserImpersonations + jwt(token.accessToken).payload.user_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const impersonationResponse = await impersonationPromise.json();
            if (impersonationResponse.status === 'success') {
                const {data} = impersonationResponse;
                let values = [];
                if (data) {
                    for (let index = 0; index < data.length; index++) {
                        values.push({dme_name: data[index].dme_organization_name, dme_organization_id: Number(data[index].dme_organization_id)});
                    }
                }
                setImpersonateDmeIds(values);
            }
        }
        setNumber(number + 1);
    };

    const handleImpersonationOperation = async (isImpersonation) => {
        let payload;
        if (isImpersonation === true) {
            payload = {
                dmeData: impersonationData,
                refreshToken: token.refreshToken,
            }
        }
        if (isImpersonation === false) {
            payload = {
                dmeData: originalUserDmeData,
                refreshToken: token.refreshToken,
            }
        }
        const impersonationPromise = await fetch(endpoints.setDmeImpersonation, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: (JSON.stringify(payload))
        });
        const impersonationResponse = await impersonationPromise.json();
        localStorage.setItem('key', JSON.stringify(impersonationResponse));
        setImpersonateDialogOpen(false)
    }

    useEffect(() => {
        if (isLogged && !hasToken) {
            checkUser();
        } else if (!isLogged) {
            setHasToken(false);
        }
    }, [number, isLogged]);

    return (
        <header className={'header'}>
            <div className={classes.ct}>
                <div className={classes.logocontainer}>
                    {window.location.pathname.includes('/dashboard') &&
                        (<img src={BigLogo} className={classes.bigLogo} />)}
                </div>
                <div style={{width: 70, display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                    <Hidden mdUp>
                        <img
                            src={SimpleLogo}
                            alt="logo"
                            width={'52'}
                            onClick={() => history.push(routes.root)}
                            className={classes.simpleLogo}
                        />
                    </Hidden>
                </div>
                {isLogged && (
                    <div style={{display: "flex"}}>
                        {/* commented NUDGE feature !! Do Not Delete!!*/}
                        <NewNotificationCenter />
                        {/*<div style={{display: "flex"}} className={classes.search}>*/}
                        {/*    <IconButton*/}
                        {/*        children={<Badge badgeContent={orderNotifications[0].entries.length} max={99} variant={"standard"} color={"secondary"}*/}
                        {/*                         children={orderNotifications[0].entries.length > 0 ? <NotificationsActiveOutlinedIcon/> : <NotificationsNoneIcon/>}/>} aria-describedby={id}*/}
                        {/*        onClick={handleClick}/>*/}
                        {/*    <Popover*/}
                        {/*        id={id}*/}
                        {/*        open={open}*/}
                        {/*        anchorEl={anchorEl}*/}
                        {/*        onClose={handleClose}*/}
                        {/*        anchorOrigin={{*/}
                        {/*            vertical: 'bottom',*/}
                        {/*            horizontal: 'center',*/}
                        {/*        }}*/}
                        {/*        transformOrigin={{*/}
                        {/*            vertical: 'top',*/}
                        {/*            horizontal: 'right',*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <NotificationCenter*/}
                        {/*            categories={notificationCategories}*/}
                        {/*            refreshFunction={getNotificationData}*/}
                        {/*        />*/}
                        {/*    </Popover>*/}
                        {/*</div>*/}
                        <div style={{width: 110, display: 'flex', justifyContent: "flex-end"}}>

                            <React.Fragment>
                                <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                    <NavMenu type={'user'} name={username} onSignOut={onSignOut} setImpersonateDialogOpen={setImpersonateDialogOpen} impersonateDmeIds={impersonateDmeIds}/></div>
                            </React.Fragment>
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Hidden lgUp>
                                <MenuIcon onClick={() => props.setDrawerState(true)} style={{cursor: "pointer"}}/>
                            </Hidden>
                        </div>
                    </div>
                )}
            </div>
            <Dialog open={impersonateDialogOpen}>
                <DialogContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Impersonate Dme</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={selectedImpersonation} onChange={(event) => {
                            setSelectedImpersonation(event.target.value)
                            setImpersonationData(impersonateDmeIds.filter(obj => {
                                return obj.dme_name === event.target.value
                            })[0]);
                        }}>
                            {impersonateDmeIds.map((item, index) => {
                                return <FormControlLabel key={index} value={item.dme_name} control={<Radio />} label={item.dme_name} />
                            })}
                        </RadioGroup>
                    </FormControl>
                    <DialogActions>
                        {isLogged && hasToken && Number(originalUserDmeData.dme_organization_id) !== Number(jwt(token.accessToken).payload.dme_organization_id) && <Button variant={"outlined"} color={"primary"} onClick={() => handleImpersonationOperation(false)}>Revert
                            To Original</Button>}
                        <Button variant={"outlined"} color={"secondary"} onClick={() => setImpersonateDialogOpen(false)}>Cancel</Button>
                        <Button variant={"outlined"} color={"primary"} onClick={() => handleImpersonationOperation(true)}>Impersonate</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </header>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'black',
        "&:hover": {
            backgroundColor: '#f5f5f5'
        },
        height: 28,
        padding: '2px 4px',
        display: "flex",
        cursor: 'pointer',
        justifyContent: "center",
        alignItems: "center"
    },
    search: {
        [theme.breakpoints.down('md')]: {
            width: 50,
            paddingRight: 80
        },
        [theme.breakpoints.up('lg')]: {
            width: 50,
            paddingRight: 80
        },
    },
    header: {
        position: 'fixed'
    },
    bigLogo: {
        position: 'relative',
        left: '6%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    simpleLogo: {
        cursor: 'pointer',
        // [theme.breakpoints.down('sm')]:{
        //     position: 'absolute',
        //     left: '25px',
        //     top: '50%',
        //     transform: 'translateY(-50%)',
        // }
    },
    ct: {
        display: "flex",
        height: 65,
        padding: 8,
        width: '100vw',
        paddingBottom: 4,
        paddingTop: 4,
        justifyContent: "space-between",
        borderBottom: '1px solid #E5E8F1',
        backgroundColor: '#FFF',
    },
    logocontainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 0,
            display: 'none',
        }
    }

}));
// <Grid item>
//
//     <Button aria-describedby={id} onClick={handleClick}>
//         <Badge badgeContent={99} children={<NotificationsActiveOutlinedIcon/>}/>
//     </Button>
//     <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//         }}
//         transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//         }}
//     >
//         <Typography variant={"h4"}>The content of the Popover.</Typography>
//     </Popover>
// </Grid>
