import {messageTypes} from "../types/messageTypes";

export const getMessage = (type, value) => {
    switch (type) {
        case messageTypes.pickup:
            return `Pickup Order #${value} was successfully created!`;
        case messageTypes.item:
            return `Cannot keep "${value}" only if you expired, discharge or revoke.`;
        case messageTypes.problem:
            return "Present Issues:";
        case messageTypes.hospiceProfit:
            return `${value.hospice_name} is a ${value.status === true ? "Profit" : "Non-Profit"} organization`;
        case messageTypes.hospiceStatus:
            return `${value.hospice_name} is an ${value.status === true ? "Active" : "Inactive"} organization`;
        case messageTypes.notice:
            return `Notify the ${value} on this note`;
        case messageTypes.addToCart:
            return `Add ${value} to Cart`;
        case messageTypes.patientOrder:
            return `Would you like to place an order for this patient ?`;
        case messageTypes.noPatientData:
            return `N0 Patient Data Found For Patient Id Number: ${value}`;
        case messageTypes.dmeStatus:
            return ` The ${value.name} is an ${value.status === true ? "Active" : "Inactive"} organization`;
        case messageTypes.userStatus:
            return ` User is ${value === true ? "Active" : "Inactive"}`;
        case messageTypes.archived:
            return ` User is ${value === true ? "Archived" : "Unarchived"}`;
        case messageTypes.archivedPatient:
            return ` Patient is ${value === true ? "Archived" : "Unarchived"}`;
        case messageTypes.emailNotFound:
            return ` This email is not registered to a user with this role, email will be sent, are you sure ?`;
        case messageTypes.emailFound:
            return ` This email is registered to a user with this role`;
        case messageTypes.contractStatus:
            return ` Contract is ${value === true ? "Active" : "Inactive"}`;
        case messageTypes.deleteUser:
            return `Are you sure you want to delete this user ?`;
        case messageTypes.contractSelectItems:
            return `Are you sure you want to deselect all contract ${value} items ?`;
        case messageTypes.orderCreated:
            return `Order #${value} was successfully created!`;
        case messageTypes.disabledTechnician:
            return `User ${value === false ? "Inactive" : "Active"}`;
    }
};
