import React from "react";
import Grid from "@material-ui/core/Grid";
import {labels} from "../../constants/inputs/labels";
import {TextField} from "@material-ui/core";
import {userProfileStyles} from "./UserProfile.style";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import BootstrapInput from "../../common/bootstrapInput/bootstrapInput";
import MenuItem from "@material-ui/core/MenuItem";

export const UserInformationForm = ({handleInput, handleSubmit, values}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={3} className={classes.inputContainer}>
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.textItem} style={{width: '100%'}}>
                <CssTextField
                    id={"user_first_name"}
                    label={labels.first_name}
                    value={values.first_name}
                    name={"first_name"}
                    variant={"outlined"}
                    size='small'
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} className={classes.textItem} style={{width: '100%'}}>
                <CssTextField
                    id={"user_last_name"}
                    label={labels.last_name}
                    value={values.last_name}
                    name={"last_name"}
                    variant={"outlined"}
                    size='small'
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%'}}>
                <CssTextField
                    id={"username"}
                    label={labels.username}
                    value={values.username}
                    name={"username"}
                    variant={"outlined"}
                    size='small'
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%'}}>
                <CssTextField
                    id={"user_email"}
                    name={"email"}
                    value={values.email}
                    label={labels.user_email}
                    variant={"outlined"}
                    size='small'
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.textItem} style={{width: '100%'}}>
                <CssTextField
                    id={"user_phone"}
                    label={labels.phone}
                    value={values.phone}
                    name={"phone"}
                    variant={"outlined"}
                    size='small'
                    fullWidth
                    disabled
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel>Choose Time Zone</InputLabel>
                    <Select
                        labelId="timezone-select-label"
                        id="timezone_select"
                        name="timezone"
                        input={<BootstrapInput/>}
                        defaultValue={'All'}
                        value={values.timezone}
                        onChange={(e)=> handleInput(e)}
                    >
                        <MenuItem value="All"><em> Select Timezone</em></MenuItem>
                        <MenuItem value={`Hawaii Time [UTC-10]`}>Hawaii Time [UTC-10]</MenuItem>
                        <MenuItem value={`Alaska Time [UTC-9]`}>Alaska Time [UTC-9]</MenuItem>
                        <MenuItem value={`Pacific Time [UTC-8]`}>Pacific Time [UTC-8]</MenuItem>
                        <MenuItem value={`Mountain Time [UTC-7]`}>Mountain Time [UTC-7]</MenuItem>
                        <MenuItem value={`Central Time [UTC-6]`}>Central Time [UTC-6]</MenuItem>
                        <MenuItem value={`Eastern Time [UTC-5]`}>Eastern Time [UTC-5]</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="right"
            >
                <Grid item xs={12} sm={12} md={12} style={{paddingLeft:12, paddingRight:12}}>
                    <Button
                        fullWidth
                        variant={"contained"}
                        color={"primary"}
                        onClick={handleSubmit}
                    >
                        {buttonText.update_profile}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#A3C7D2',
        },
        '& label.Mui-focused': {
            color: '#A3C7D2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#A3C7D2',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#A3C7D2',
            },
            '&:hover fieldset': {
                borderColor: '#A3C7D2',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#A3C7D2',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (userProfileStyles));