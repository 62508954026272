export const permissions = {
    create_orders: "orders_create",
    read_orders: "orders_read",
    update_orders: "orders_update",
    cancel_orders: "orders_cancel",
    delete_orders: "orders_delete",
    create_patients: "patients_create",
    read_patients: "patients_read",
    update_patients: "patients_update",
    delete_patients: "patients_delete",
    create_inventory: "inventory_create",
    read_inventory: "inventory_read",
    update_inventory: "inventory_update",
    delete_inventory: "inventory_delete",
    create_reports: "reports_create",
    read_reports: "reports_read",
    update_reports: "reports_update",
    delete_reports: "reports_delete",
    create_admin: "admin_create",
    update_admin: "admin_update",
    read_admin: "admin_read",
    billing_report: "reports_billing_summary",
    active_rental_report: "reports_rental_active",
    oof_report: "reports_oof_items",
    approve_orders: "orders_approve",
    portal_create_careviger_account: "portal_create_caregiver_account",
    portal_create_patient_account: "portal_create_patient_account",
    portal_view_profile: "portal_view_patient_profile",
    reports_lost_item: "reports_lost_item",
};
