import React, {useEffect, useState} from "react";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../constants/names/titles";
import MaterialTable from "material-table";
import {getAllDomains} from "../helpers/dataGetter";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

export const EditDomains = (props) => {

    const columns = [
        {title: tableColumnTitles.domain, field: 'domain_name', width: 800},
    ];
    const {enqueueSnackbar} = useSnackbar();
    const [restrictedDomains, setRestrictedDomains] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllDomains(setRestrictedDomains);
    }, []);

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.domains}
                    </Typography>
                </Grid>
                <Grid item>
                    <MaterialTable
                        title={componentTitles.domains}
                        columns={columns}
                        data={restrictedDomains}
                        options={{
                            pageSize: 10,
                        }}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.addDomain, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify(newData)
                                    }).then(res => {
                                        if (res.status === status.created) {
                                            resolve();
                                            setRestrictedDomains((prevState) => {
                                                const data = [...prevState];
                                                data.push(newData);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.updateDomain + oldData.domain_name, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify(newData)
                                    }).then(res => {
                                        if (res.status === status.success) {
                                            resolve();
                                            if (oldData) {
                                                setRestrictedDomains((prevState) => {
                                                    const data = [...prevState];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return data;
                                                });
                                            }
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.removeDomain + oldData.domain_id, {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        }
                                    }).then(res => {
                                        if (res.status === status.nocontent) {
                                            resolve();
                                            setRestrictedDomains((prevState) => {
                                                const data = [...prevState];
                                                data.splice(data.indexOf(oldData), 1);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                        }}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};