import {endpoints} from "../../constants/endpoints";

export const checkDataAlteration = async (endpoint, time) => {

    // will return true if there have been modification of data on the route and false otherwise from the moment "time"
    const token = JSON.parse(localStorage.getItem("key"));
    const promise = await fetch(endpoints.checkForAlteration, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "authorization": "Bearer " + token.accessToken,
        },
        body: JSON.stringify({
            "route": endpoint,
            "timestamp": time,
        })
    });
    const result = await promise.json();
    return result.data.numberOfModifications > 0;

};
export const getUsersOnRoute = async (endpoint, time) => {

    // will return an array with all the users that are on the route from the moment "time"
    const token = JSON.parse(localStorage.getItem("key"));
    const promise = await fetch(endpoints.getUsersOnRoute, {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "authorization": "Bearer " + token.accessToken,
        },
        body: JSON.stringify({
            "route": endpoint,
            "timestamp": time,
        })
    });
    const result = await promise.json();
    return result.data;
};

export const getUserLastLocations = async (uids, time) => {

    // will return the last locations of the user from the given time
    const token = JSON.parse(localStorage.getItem("key"));
    const promise = await fetch(endpoints.getUserLastLocation, {
        method: "POST",
        body: JSON.stringify({
            "ids": uids,
            "timestamp": time,
        }),
        headers: {
            "content-type": "application/json",
            "authorization": "Bearer " + token.accessToken,
        }
    });

    const result = await promise.json();
    return result.data;
};
