import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {labels} from "../../../constants/inputs/labels";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from "react-select";
import {checkEmail, checkUsername} from "../createUserHelper";
import {getValidationMessage} from "../../../common/InputField/validationMessages/InputFieldErrorMessage";
import {placeholders} from "../../../constants/inputs/paceholders";
import _ from "lodash";
import {CircularProgress} from "@material-ui/core";
import {sectionTitles} from "../../../constants/names/titles";
import {tooltips} from "../../../constants/inputs/tooltips";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Dialog from "@material-ui/core/Dialog";
import {endpoints} from "../../../constants/endpoints";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })
};

export default function GeneralInformations(props) {
    const {
        rolesList, handleTitleSelect, handleInputChange, user, missingValues, possibleEmail,
        validEmail, setValidEmail, restrictedDomains, validUsername, setValidUsername,
        isEdit, hospiceData, checkAvailability, deleteUser,
    } = props;
    const [phone, setPhone] = useState(true);
    const [open, setOpen] = useState(false);

    const requestPasswordReset = async () => {
        const promise = await fetch(endpoints.requestPassword, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: {
                    username:user.username,
                    email:user.email
                }})
        });

        const response = await promise.json();
        // if (response.status === "success") {
        //     setSuccessMessage("success");
        // } else {
        //     setSuccessMessage("error");
        // }
    };

    if (hospiceData.length > 0) {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {sectionTitles.generalInformation}
                </Typography>
                <Grid container spacing={3} style={{display: "flex", justifyContent: 'left', alignItems: 'left'}}>
                    <Grid item sm={9} style={{height: '420px'}}>
                        <Grid item sm={6} style={{height: '80px', display: 'flex'}}>
                            <div style={{width: '70%'}}>
                                <TextField
                                    label={"Hospice"}
                                    disabled={true}
                                    fullWidth
                                    variant={"outlined"}
                                    size={"small"}
                                    value={props.hospice_organization_id && hospiceData.length > 0  && hospiceData[_.findIndex(hospiceData, {"hospice_organization_id": parseInt(props.hospice_organization_id)})].hospice_name}
                                />
                            </div>
                            {isEdit && <div style={{width: '30%'}}>
                                <FormControl component="fieldset">
                                    <FormLabel>{sectionTitles.deleteUserStatus}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                             checked={user.deleted}
                                                             onChange={() => setOpen(true)}
                                            />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>}
                        </Grid>
                        <Grid item sm={12} style={{display: 'flex', height: '130px'}}>
                            <div style={{justifyContent: "left"}}>
                                <div style={{width: '320px'}}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '260px', height: '50px'}}>
                                            <TextField
                                                label={labels.first_name}
                                                id={`first_name`}
                                                name="first_name"
                                                fullWidth
                                                placeholder={placeholders.addFirstName}
                                                value={props.first_name}
                                                disabled={true}
                                                variant="outlined"
                                                size="small"
                                                // onChange={handleInputChange}
                                                // onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                                            />
                                            {/*<FormHelperText style={{color: 'red', textAlign: 'left'}}>*/}
                                            {/*    {missingValues.first_name && tooltips.isRequired}*/}
                                            {/*</FormHelperText>*/}
                                        </div>
                                        <div style={{width: '40px'}}></div>
                                        <div style={{width: '260px', height: '50px'}}>
                                            <TextField
                                                label={labels.last_name}
                                                id={`last_name`}
                                                name="last_name"
                                                fullWidth
                                                disabled={true}
                                                placeholder={placeholders.addLastName}
                                                value={props.last_name}
                                                variant="outlined"
                                                size="small"
                                                // onChange={handleInputChange}
                                                // onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                                            />
                                            {/*<FormHelperText style={{color: 'red', textAlign: 'left'}}>*/}
                                            {/*    {missingValues.last_name && tooltips.isRequired}*/}
                                            {/*</FormHelperText>*/}
                                        </div>
                                    </div>
                                </div>
                                <Grid container alignContent='center' alignItems='center' style={{paddingTop:8}}>


                                <FormLabel component="legend" style={{paddingRight:12}}>{labels.createAccountBy}</FormLabel>
                                <RadioGroup aria-label="quiz"
                                            name="create_account_by"
                                            value={props.accountType}
                                            onChange={(e) =>
                                                props.setAccountType(e.target.value)
                                            }
                                >
                                    <div>
                                        <FormControlLabel
                                            value={'email'}
                                            control={<Radio color="primary"/>}
                                            label="Email"
                                        />
                                        <FormControlLabel
                                            value={'phone'}
                                            control={<Radio color="primary"/>}
                                            label="Phone Number"
                                        />
                                    </div>
                                </RadioGroup>
                                </Grid>
                                <div style={{width: '720px'}}>
                                    <div style={{display: 'flex', paddingTop: '14px', height: '70px'}}>

                                        {
                                            props.accountType !== '' && (
                                                <React.Fragment>
                                                <div style={{paddingTop: '20px', width: '260px'}}>
                                                    <Tooltip title={possibleEmail.map((item, index) => {
                                                        return <div key={index} style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>
                                                            {item}
                                                        </div>
                                                    })}
                                                    >
                                                        <FormControl>
                                                            <TextField
                                                                fullWidth
                                                                variant={"outlined"}
                                                                size={"small"}
                                                                id={"username"}
                                                                label={labels.username}
                                                                name={"username"}
                                                                value={user.username}
                                                                onChange={handleInputChange}
                                                                style={{width: "260px"}}
                                                                placeholder={placeholders.username}
                                                                onBlur={() => {
                                                                    checkUsername(user.username, setValidUsername);
                                                                    checkAvailability("username", user.username);

                                                                }}
                                                                disabled={isEdit}
                                                            />
                                                            {
                                                                getValidationMessage(validUsername)
                                                            }
                                                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                                                {missingValues.username && tooltips.isRequired}
                                                            </FormHelperText>
                                                        </FormControl>

                                                    </Tooltip>
                                                </div>
                                                <div style={{width: '20px'}}></div>
                                                </React.Fragment>
                                            )
                                        }
                                        { props.accountType === 'email' && (
                                            <React.Fragment>
                                                <div style={{paddingTop: '20px', width: '260px'}}>
                                                    <FormControl>
                                                        <TextField
                                                            label={labels.email}
                                                            id={`email`}
                                                            name="email"
                                                            fullWidth
                                                            placeholder={placeholders.email}
                                                            value={user.email}
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={handleInputChange}
                                                            style={{width: "260px"}}
                                                            onBlur={(event) => {
                                                                checkEmail(event.target.value, restrictedDomains, setValidEmail);
                                                                checkAvailability("email", user.email);
                                                            }}
                                                        />
                                                        {
                                                            getValidationMessage(validEmail)
                                                        }
                                                    </FormControl>
                                                    <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                                        {missingValues.email && tooltips.isRequired}
                                                    </FormHelperText>
                                                </div>
                                                <div style={{width: '20px'}}></div>
                                            </React.Fragment>
                                        )}
                                        {
                                            props.accountType === 'phone' && (
                                                <div style={{width: '140px'}}>
                                                    <InputLabel style={{
                                                        fontSize: '0.8em',
                                                        paddingBottom: '10px',
                                                        textAlign: 'left'
                                                    }}>{labels.requiredPhone}</InputLabel>
                                                    <PhoneInput
                                                        name="phone"
                                                        country={'us'}
                                                        disableCountryCode={true}
                                                        placeholder={placeholders.phone}
                                                        onlyCountries={['us']}
                                                        value={props.phone_number}
                                                        isValid={phone}
                                                        onChange={(e) => {
                                                            if (e.length === 10) {
                                                                setPhone(true);
                                                            }
                                                            let event = {
                                                                target: {
                                                                    name: "phone",
                                                                    value: e
                                                                }
                                                            };
                                                            handleInputChange(event)
                                                        }}
                                                        onBlur={(e) => {
                                                            if (e.target.value.length === 14) {
                                                                setPhone(true);
                                                            } else {
                                                                setPhone(false);
                                                            }
                                                        }}
                                                    />
                                                    <FormHelperText style={{
                                                        color: 'red',
                                                        textAlign: 'left'
                                                    }}>{!phone && "Invalid phone number"}</FormHelperText>
                                                    <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                                        {missingValues.phone && tooltips.isRequired}
                                                    </FormHelperText>
                                                </div>
                                            )
                                        }

                                    </div>
                                    {
                                        props.isEdit && (
                                            <div style={{display:'flex', paddingTop:36, paddingBottom:12}}>
                                                <Button
                                                    onClick={requestPasswordReset}
                                                    variant={"contained"}
                                                    style={{width:220,}}
                                                    color={"primary"}
                                                >
                                                    {buttonText.sendPasswordResetUser}
                                                </Button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item sm={12} style={{display: 'flex', height: '70px', paddingTop: '80px'}}>
                            <div style={{width: '30%'}}>
                                <Select
                                    styles={selectStyles}
                                    isSearchable
                                    fullWidth
                                    options={[
                                        {value: 'Mr', label: 'Mr'},
                                        {value: 'Mrs', label: 'Mrs'},
                                    ]}
                                    placeholder={placeholders.selectTitle}
                                    value={user.title === '' ? null : [{
                                        label: user.title,
                                        value: user.title
                                    }]}
                                    onChange={event => {
                                        handleTitleSelect(event)
                                    }}
                                />
                                <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                    {missingValues.title && tooltips.isRequired}
                                </FormHelperText>
                            </div>
                            <div style={{width: '3%'}}></div>
                            <div style={{width: '30%'}}>
                                {rolesList.length > 0 &&
                                <React.Fragment>
                                    <TextField
                                        variant={"outlined"}
                                        size={"small"}
                                        label={"Role"}
                                        disabled={true}
                                        fullWidth
                                        value={props.type}
                                    />
                                </React.Fragment>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getMessage(messageTypes.deleteUser)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant={"contained"} color={"primary"} autoFocus>
                            {buttonText.negative}
                        </Button>
                        <Button color={"secondary"} variant={"contained"} onClick={() => {
                            deleteUser();
                            setOpen(false);
                        }}>
                            {buttonText.proceed}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    } else {
        return <CircularProgress/>
    }
}