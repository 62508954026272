import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import CancelActionAlert from "../../../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../../../constants/alert/alertFlags";
import {endpoints} from "../../../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {TextField} from "@material-ui/core";
import {Table} from "../../../../../common/table/table";
import {checkTokenAvailability} from "../../../../../common/utils/utils";
import {logout} from "../../../../../redux/actions";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import {labels} from "../../../../../constants/inputs/labels";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        height: 840,
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function PatientDays(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [wasSuccessful, setWasSuccessful] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstStep, setFirstStep] = useState(false);
    const [secondStep, setSecondStep] = useState(true);
    const [entered, setEntered] = useState(false);

    const [previousDays, setPreviousDays] = useState([]);
    const [data, setData] = useState({
        hospice_organization_id: props.hospiceDetails.hospice_organization_id,
        month: '',
        year: '',
        days: '',
    });
    const getHospiceDays = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.hospiceDays}${props.hospiceDetails.hospice_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setPreviousDays(response.data);
            })
            .catch(err => console.log('error', err))
    };
    useEffect(() => {
        getHospiceDays();
    }, []);

    const steps = ['Enter Patient Days', 'Review', 'Finalize'];
    const columns = [
        {
            title: "Month", field: "month", width: 150,
            render: rowData => <div style={{textAlign: 'center'}}>
                {rowData.month}
            </div>
        },
        {
            title: "Year", field: "year", width: 150,
        },
        {
            title: "Days", field: "days", width: 150,
            render: rowData => <div style={{textAlign: 'center'}}>
                {rowData.days}
            </div>
        },
    ];

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={5} direction="column" justify="center">
                        <Grid container spacing={5} direction="row" justify="space-evenly">
                            <Grid item>
                                <ListItemText primary={labels.organization_name}
                                              secondary={props.hospiceDetails.hospice_name}/>
                            </Grid>
                            <Grid item>
                                <ListItemText primary={labels.organization_address}
                                              secondary={`${props.hospiceDetails.address1} ${props.hospiceDetails.address2}\n ${props.hospiceDetails.city}, ${props.hospiceDetails.state} ${props.hospiceDetails.zip}`}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={2}>
                                <Select
                                    className={classes.z}
                                    classNamePrefix="select"
                                    placeholder="Select month..."
                                    name="color"
                                    onChange={(e) => {
                                        setData({...data, month: e.value})
                                    }}
                                    value={data.month !== "" ? [{value: data.month, label: data.month}] : null}
                                    maxMenuHeight={150}
                                    options={[
                                        {value: 'January', label: 'January'},
                                        {value: 'February', label: 'February'},
                                        {value: 'March', label: 'March'},
                                        {value: 'April', label: 'April'},
                                        {value: 'May', label: 'May'},
                                        {value: 'June', label: 'June'},
                                        {value: 'July', label: 'July'},
                                        {value: 'August', label: 'August'},
                                        {value: 'September', label: 'September'},
                                        {value: 'October', label: 'October'},
                                        {value: 'November', label: 'November'},
                                        {value: 'December', label: 'December'},
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Select
                                    className={classes.z}
                                    classNamePrefix="select"
                                    placeholder="Select year..."
                                    onChange={(e) => {
                                        setData({...data, year: e.value})
                                    }}
                                    name="color"
                                    value={data.year !== "" ? [{value: data.year, label: data.year}] : null}
                                    options={[
                                        {value: 2020, label: '2020'},
                                        {value: 2021, label: '2021'},
                                        {value: 2022, label: '2022'},
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={data.days}
                                    onChange={(e) => {
                                        setData({...data, days: e.target.value})
                                    }}
                                    value={data.days}
                                    label={'Patient Days'}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={5} direction="column" justify="center">
                        <Grid container spacing={5} direction="row" justify="space-evenly">
                            <Grid item>
                                <ListItemText primary={labels.organization_name}
                                              secondary={props.hospiceDetails.hospice_name}/>
                            </Grid>
                            <Grid item>
                                <ListItemText primary={labels.organization_address}
                                              secondary={`${props.hospiceDetails.address1} ${props.hospiceDetails.address2}\n ${props.hospiceDetails.city}, ${props.hospiceDetails.state} ${props.hospiceDetails.zip}`}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={2}>
                                <TextField
                                    value={data.month}
                                    disabled={true}
                                    label={labels.month}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={data.year}
                                    disabled={true}
                                    label={labels.year}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={data.days}
                                    disabled={true}
                                    label={labels.patientDays}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Table
                                columns={columns}
                                items={previousDays}
                            />
                        </Grid>
                    </Grid>);
            case 2:
                return (
                    <Grid container spacing={5} direction="column" justify="center">
                        <Grid container spacing={5} direction="row" justify="space-evenly">
                            <Grid item>
                                <ListItemText primary={labels.organization_name}
                                              secondary={props.hospiceDetails.hospice_name}/>
                            </Grid>
                            <Grid item>
                                <ListItemText primary={labels.organization_address}
                                              secondary={`${props.hospiceDetails.address1} ${props.hospiceDetails.address2}\n ${props.hospiceDetails.city}, ${props.hospiceDetails.state} ${props.hospiceDetails.zip}`}/>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} justify={"center"}>
                            <Grid item xs={2}>
                                <TextField
                                    value={data.month}
                                    disabled={true}
                                    label={labels.month}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={data.year}
                                    disabled={true}
                                    label={labels.year}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={data.days}
                                    disabled={true}
                                    label={labels.patientDays}
                                    variant={'outlined'}
                                    size={'small'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>);
            default:
                throw new Error('Unknown step');
        }
    }
    const handleNext = () => {
        if (activeStep === 0) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 2) {
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.hospiceDays}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    props.setOpen(false)
                })
                .catch(err => console.log('error', err));
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <React.Fragment>
            {(isLogged) ? <React.Fragment>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                            Enter Patient Days
                        </Typography>
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    {isLoading ? (
                                        <div style={{display: "flex", justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <Typography variant="h5" gutterBottom>
                                                {(wasSuccessful && props.edit) && 'Success'}
                                                {(wasSuccessful && !props.edit) && 'Success'}
                                                {!wasSuccessful && props.edit && 'Unable to edit'}
                                                {!wasSuccessful && !props.edit && 'Unable to register'}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {(wasSuccessful && props.edit) && 'User has been updated.'}
                                                {wasSuccessful && !props.edit && 'User has been created.'}
                                                {!wasSuccessful && !props.edit && 'Please try again'}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <div className={classes.buttons}>
                                        {entered ? <CancelActionAlert
                                            flag={cancellingAlertFlags.newPatient}
                                            CSS={classes.button}
                                            alertLevel={alertLevel.warn}
                                            target={"/admin"}
                                        /> : <Button
                                            variant={'outlined'}
                                            color="primary"
                                            onClick={() => {
                                                history.push('/admin')
                                            }} className={classes.button}>
                                            Cancel
                                        </Button>
                                        }
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                        )}
                                        {activeStep === 0 && (
                                            <Tooltip
                                                title={firstStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={firstStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Post Days'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {activeStep === 1 && (
                                            <Tooltip
                                                title={secondStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Post Days'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {activeStep === 2 && (
                                            <Tooltip
                                                title={secondStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Post Days'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </main>
            </React.Fragment> : ''}
        </React.Fragment>
    );
}