import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwt from "jwt-decode";
import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import moment from "moment";
import {useHistory, useLocation} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

export default function ApproveOrders (props){
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const {orderId, orderType, token } = props.match.params;
    let decrypted = jwt(token);
    let actionType = location.pathname.split('/');
    let action = actionType[2];

    async function getKeyInfo() {
        const promise = await fetch(endpoints.getUserKeyDetails, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "POST",
            body: JSON.stringify({
                key: decrypted.key
            })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success && response.data.length > 0) {
           let res = response.data[0];
           if (parseInt(res.user_id) === decrypted.user_id){
               if (action === 'approve'){
                   let currentStatus = await getOrderStatus();
                   if (currentStatus.status === "Pending"){
                       changeOrderStatus("Open", res);
                       setMessage(`${orderType} Order ${orderId} was approved successfully`);
                   } else {
                       if (currentStatus.status === "Rejected"){
                           setMessage(`Order ${orderId} was already rejected`)
                       } else {
                           setMessage(`Order ${orderId} was already approved`)
                       }
                   }
               }
           } else if (action === 'reject'){
                   let currentStatus = await getOrderStatus();
                   if (currentStatus.status === "Rejected"){
                       setMessage(`Order ${orderId} was already rejected`)
                   } else {
                       if (currentStatus.status === "Pending") {
                           changeOrderStatus("Rejected", res);
                           setMessage(`${orderType} Order ${orderId} was rejected successfully`)
                       }
                   }
               }
           setLoading(false)
        } else {
            // console.log("[Error]: Error encountered while getting the manufacturers!");
        }
    }

    const getOrderStatus = async () => {
        const promise = await fetch(endpoints.getOrderStatus, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "POST",
            body: JSON.stringify({
                orderId:orderId.substring(4),
                orderType:orderType
            })
        });
        const response = await promise.json();
        console.log(response);
        if (response.status === endpointStatus.success && response.data.length > 0) {
            return response.data[0]
        } else {
            return null
        }
    };


    const changeOrderStatus = (newStatus, res) => {
        const status = {
            delivery_order_id: orderId.substring(4),
            status: newStatus,
            user: res,
        };
        if (orderType === 'Delivery'){
            fetch(endpoints.updateDeliveryOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(status)
            })
                .then(response => {
                    if (response.status === 200) {
                        addHistory(orderId, `Status Changed: ${res.first_name} ${res.last_name} ${action === 'approve' ? "approved" : "rejected"} order on ${moment(new Date()).format("LLL")}`);

                    }
                })
                .catch(err => console.log('error', err));
        } else if (orderType === 'Exchange'){
            fetch(endpoints.updateExchangeOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    exchangeOrderId:orderId.substring(4),
                    status: newStatus,
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        addHistory(orderId, `Status Changed: ${res.first_name} ${res.last_name} ${action === 'approve' ? "approved" : "rejected"} order on ${moment(new Date()).format("LLL")}`);

                    }
                })
                .catch(err => console.log('error', err));
        }

    };


    const addHistory = (order_id, description) => {
        if (orderType === 'Delivery'){
            fetch(endpoints.addDeliveryOrderHistory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    history: [{
                        description: description,
                    }],
                    deliveryOrderId: orderId.substring(4)
                })
            })
                .then(response => {
                    return response.json()
                })
                .catch(err => {
                    console.log(err, 'error');
                })
        } else if (orderType === 'Exchange'){
            fetch(endpoints.addExchangeOrderHistory, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                    history: [{
                        description: description,
                    }],
                    exchangeOrderId: orderId.substring(4)
                })
            })
                .then(response => {
                    return response.json()
                })
                .catch(err => {
                    console.log(err, 'error');
                })
        }

    };



    useEffect(()=> {
        getKeyInfo();
    },[]);

    return (
        <Grid container
              style={{
                  height:'100vh',
              }}
              justify={'center'}
              alignItems={'center'}
              alignContent={'center'}
        >
            {loading ? <CircularProgress/> : <Grid container direction={'column'}>
                <Typography
                    style={{
                        fontSize:24,
                        fontWeight:"bold",
                        color: action === 'approve' ? "green" : "red"
                    }}>
                        {message}
                </Typography>
                <Grid container style={{paddingTop:12}} justify={'center'}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={()=> history.push('/login')}
                    >
                        Go To Login
                    </Button>
                </Grid>

                </Grid>
              }
        </Grid>
    )
}
