import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import './possibleDuplicates.css';
import {endpoints} from "../../../../constants/endpoints";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function DuplicatesList({possibleDuplicates}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {possibleDuplicates !== [] && (
                possibleDuplicates.map(patient => (
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                className={classes.heading}>{patient.first_name + ' ' + patient.last_name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className={'table'} style={{width: "100%", fontSize: '0.9em',}}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>Medical Record No.</td>
                                        <td>{patient.medical_record}</td>
                                    </tr>
                                    <tr>
                                        <td>Name</td>
                                        <td>{patient.first_name + ' ' + patient.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>{patient.address1 + ' ' + patient.address2}</td>
                                    </tr>
                                    <tr>
                                        <td>Zip:</td>
                                        <td>{patient.zip}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div style={{
                                    padding: '8px',
                                    display: "flex",
                                    justifyContent: 'right',
                                    alignContent: 'right',
                                    paddingRight: 0,
                                    float: 'right'
                                }}>
                                    <Button
                                        onClick={() => window.open(`${endpoints.patient_profile}${patient.medical_record}`, "_blank")}
                                        variant="outlined"
                                        size={"small"}>
                                        Go to profile
                                    </Button>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))
            )}
        </div>
    );
}