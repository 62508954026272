export const viewPatientsStyle = (theme) => {
    return {
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        root: {
            flexGrow: 1,
            // maxWidth: 1920,
            height: '90vh',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },

        },

        flexStart: {
            justifyContent: 'flex-start'
        },
        h1: {
            paddingTop: 36,
            textAlign: 'left',
            color: '#00223D',
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: '38px',
            borderBottom: '1px solid #B2D1D9',
            marginBottom: 4,
            textTransform: "uppercase"
        },
        flexContainer: {
            justifyContent: 'flex-start',
        },
        generalSearchContainer: {
            justifyContent: 'flex-start',
            paddingTop:8
        },
        generalSearch: {
            display:"flex",
            justifyContent:"flex-start",
        },
        tabs: {
            border:'none',
            borderBottom:'1px solid #6E7A84',
            fontSize:15,
            fontWeight:'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize:12,
                padding:8,
            },
        },
        buttonContainer: {
            // paddingTop:8,
            paddingBottom:8,
        },
        button: {
            fontSize:12,
            fontWeight:'bold',
            padding:10
        },
        map: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex:300
        },
        tableContainer: {
            paddingTop:12,width:'50vw'
        },
        box: {
            height: 295,
            paddingBottom: 50,
            paddingRight: 12,
            maxWidth: 890,
            backgroundColor: '#FFF',
            border: '1px solid #D5D9DC',
        },
        h3: {
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 600,
            color: '#00223D',
            textAlign: 'left',
            paddingTop: 38,
            paddingLeft: 48
        },
        addRoute: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 36,
        },
        h4: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#4F86A0',
            textAlign: 'left',
            paddingTop: 4,
            paddingLeft: 17
        },
        phone: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 8
        },
        phoneText: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 400,
            color: '#4E6071',
            textAlign: 'left',
            paddingLeft: 43
        },
        emailContainer: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 24,
        },
        email: {
            cursor: 'pointer'
        },
        emailText: {
            cursor: 'pointer',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#4F86A0',
            textAlign: 'left',
            paddingLeft: 17,
            paddingTop:3
        },
        supportEmail: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 24
        },
        recent: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#686868',
            textAlign: 'left',
            paddingTop: 23,
            paddingLeft: 48
        },
        recentContainer: {
            display: 'flex',
        },
        recentItem: {
            cursor: 'pointer',
            fontSize: 14,
            color: '#3B748F',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
            paddingTop: 10,
            paddingLeft: 48
        },
        recentSpacing:{
            cursor: 'pointer',
            fontSize: 14,
            color: '#3B748F',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
            paddingTop: 10,
            paddingLeft: 24
        },
        container: {
            marginTop: 20,
            // maxWidth:1200,
            borderRadius: '4px',

        },
        item: {
            height: 300,
            padding: 30
        },
        lorin: {
            cursor: "pointer",
            backgroundColor: '#fafafa',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            border: '1px solid #e0e0e0',
            //borderRadius:4,
            height: '100%',
            "&:hover": {
                backgroundColor: '#f5f5f5',
            },

        },
        text: {
            fontSize: 28,
            fontWeight: 'bold',
            padding: 20,
        },
        paper: {
            height: 140,
            width: 100,
        },
        background: {
            backgroundPosition: 'right bottom',
            height:'100%'
        },
        view: {
            display:'flex',
            marginLeft:48,
            marginTop:36,
        },
        cursor: {
            cursor:"pointer"
        }
    }};
