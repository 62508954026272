import {emailValidation, usernameValidation} from "../../../constants/validations/validationTypes";
import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";

export const getValidationMessage = (type) => {
    switch (type) {
        case emailValidation.invalidEmail:
            return (
                <FormHelperText style={{color: 'red'}}>
                    Invalid Email
                </FormHelperText>
            );
        case emailValidation.emailAlreadyExists:
            return (
                <FormHelperText style={{color: 'red'}}>
                    This email is taken
                </FormHelperText>
            );
        case usernameValidation.usernameAlreadyExists:
            return (
                <FormHelperText style={{color: 'red'}}>
                    This username is taken
                </FormHelperText>
            );
        case emailValidation.invalidDomain:
            return (
                <FormHelperText style={{color: 'red'}}>
                    This domain is not allowed
                </FormHelperText>
            );
        case emailValidation.empty || usernameValidation.empty:
            return (
                <FormHelperText style={{color: 'red'}}>
                </FormHelperText>
            );
        case usernameValidation.possibleEmail:
            return (
                <FormHelperText style={{color: 'red'}}>
                    Please use other username than email
                </FormHelperText>
            );
        default:
            return null;
    }
};