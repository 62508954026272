export const sideDrawerStyle = (theme, drawerWidth) => {
    return {
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth + 30,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 20,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            [theme.breakpoints.down('md')]: {
                width: 0,
            },
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 80,
            [theme.breakpoints.down('md')]: {
                width: 0,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            height:60,
            width:220
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        item: {
            cursor: "pointer",
            padding: 10,
            borderBottom: '1px solid #e0e0e0',
            margin: 0,
            textAlign: "left",
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 12,
            color: 'black',
            "&:hover": {
                backgroundColor: '#f5f5f5'
            }
        },
        subItem: {
            cursor: "pointer",
            padding: 10,
            borderBottom: '1px solid #e0e0e0',
            margin: 0,
            fontSize: 14,
            textAlign: "center",
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            paddingLeft: 16,
            color: 'black',
            "&:hover": {
                backgroundColor: '#f5f5f5'
            }
        },
        noBorder: {
            border: 'none'
        },
        icon: {
            padding: 0,
            minWidth: 42
        },
        pointer: {
            cursor: "pointer"
        },
        list: {
            borderRight: '1px solid rgb(229,232,241)',
            paddingTop: 59
        },
        paddingL30: {
            paddingLeft: 30
        },
        height19: {
            height: 19
        },
        arrow: {
            color: '#77A6BC',
            fontSize: 38
        },
    }
};