import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import {endpoints} from "../../../../constants/endpoints";
import {useSnackbar} from "notistack";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../../constants/names/titles";
import MaterialTable from "material-table";
import CustomSelect from "../hospiceTypes/customSelect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {getAllHospices} from "../../../utils/helpers/dataGetter";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

export const HospiceLogoIndex = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedHospice, setSelectedHospice] = useState("");
    const [imageB64, setImageB64] = useState("");
    const [response, setResponse] = useState("");
    const [hospiceLogos, setHospiceLogos] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [hospices, setHospices] = useState([]);
    const token = JSON.parse(localStorage.getItem('key'));
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);

    const columns = [
        {title: 'Hospice Id', field: 'hospice_organization_id', width: 100, align: "center", headerStyle:{textAlign: 'center'}},
        {title: 'Organization', field: 'hospice_name', width: 200, align: "center", headerStyle:{textAlign: 'center'}},
        {title: 'File Type', field: 'logo_header', width: 100, sorting:false, headerStyle:{textAlign: 'center'},
            render: rowData => {
            let firstPart = rowData.logo_header.split("/");
            let secondPart = firstPart[1].split(";")
                return <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                    {secondPart[0]}
                </Grid>
            }
        },
        {title: 'Logo', field: 'logo', width: 200, sorting:false, headerStyle:{textAlign: 'center'},
            render: rowData => {
                return <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                    <img src={rowData.logo_header + "," + rowData.encode} alt={""} width={150} height={150}/>
                </Grid>
                
            }
        },
    ]

    const handleInput = (event) => {
        switch (event.target.name) {
            case "selectImage":
                setSelectedImage(event.target.files[0]);
                getImageBase64(event.target.files[0]);
                break;
        }
    }

    const getImageBase64 = (image) => {
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            setImageB64(reader.result);
        }
        reader.onerror = function (error) {
            console.log("Error: ",error);
        }
    }


    const handleUpload = async () => {
        if(selectedImage !== null && imageB64 !== "" && selectedHospice !== ""){
            setLoading(true);
            const promise = await fetch(endpoints.uploadHospiceLogo, {
                method:"POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body:JSON.stringify({
                    imageB64: imageB64,
                    hospice_id: selectedHospice,
                })
            })
            const response = await promise.json();
            if(response.status === "success"){
                await getAllLogos();
            }
            setSelectedHospice("");
            setSelectedImage(null);
            setLoading(false);
            setOpenDialog(false);
        }
    }
    const getAllLogos = async () => {
        const promise = await fetch(endpoints.getAllHospiceLogos, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const response = await promise.json();
        if(response.status === "success") {
            setHospiceLogos(response.data);
        }
    }

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllLogos();
        getAllHospices(setHospices);
    },[])

    return <React.Fragment>
        <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}} spacing={1}>
            <Grid item>
                <Typography variant={"h6"} style={{paddingBottom: 12}}>
                    {componentTitles.hospiceLogo}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" color="primary" onClick={() => setOpenDialog(true)}>
                    Upload Hospice Image
                </Button>
            </Grid>
            <Grid item xs={8}>
                <MaterialTable
                    title={componentTitles.hospiceLogo}
                    columns={columns}
                    data={hospiceLogos}
                    options={{
                        pageSize: 10,
                    }}

                />
            </Grid>
        </Grid>
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="draggable-dialog-title"
            maxWidth={"lg"}
            disableBackdropClick={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Subscribe
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1} direction={"column"} style={{minHeight: 500, minWidth: 500}}>
                  <Grid item>
                      <Select
                          disabled={loading}
                          options={hospices.map((item) => {
                                  return {value: item.hospice_organization_id, label: item.hospice_name}
                          })}
                          onChange={(event) => {
                              setSelectedHospice(event.value);
                          }}

                      >

                      </Select>
                  </Grid>
                  {selectedHospice !== "" && <Grid item>
                      <input type={"file"} name={"selectImage"} onChange={handleInput}
                             disabled={loading}
                             accept={".jpg,.png,.jpeg"}
                      />
                  </Grid>}
              </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => {
                    setOpenDialog(false);
                    setSelectedHospice("");
                }} color="secondary" variant={"contained"} disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={() => handleUpload()} disabled={selectedHospice === "" || selectedImage === null || loading === true} loading variant={"contained"} color="primary">
                    {loading === true ? <CircularProgress style={{color:'white'}} size={24}/> : "Upload"}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>
}