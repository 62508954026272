import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {green, red} from '@material-ui/core/colors';
import {useHistory} from "react-router-dom";
import {endpoints} from "../../constants/endpoints";
import jwt from 'jwt-decode';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

export default function ProfilePage({props}) {
    let history = useHistory();
    const [message, setMessage] = useState();
    const [lengthValidator, setLengthValidator] = useState("grey");
    const [characterValidator, setCharacterValidator] = useState("grey");
    const [password, setPassword] = useState({
        password: '',
        confirmation: '',
        currentPassword: '',
    });
    const [validPassword, setValidPassword] = useState({
        password: null,
        confirmation: '',
        currentPassword: null,
    });
    const classes = useStyles();
    const isLogged = useSelector(state => state.isLogged);
    const data = JSON.parse(localStorage.getItem('key'));
    const user = jwt(data.accessToken);
    const handleClick = () => {
        let payload = {password: password.password};
        fetch(`${endpoints.changePassword}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                },
                body: JSON.stringify(payload)
            }
        )
            .then(response => {
                if (response.status === 200) {
                    setMessage('Success');
                    setTimeout(function () {
                        history.push('/dashboard');
                    }, 2000);
                }
                if (response.status === 409) {
                    setMessage('Please enter a new password, you can not reuse one of the last three passwords.');
                }
                return response.json();
            }).then(response => {
        })
            .catch(err => console.log('error', err));
    };

    useEffect(() => {
        if (password.password.length > 0) {
            if (password.password.length < 8) {
                setLengthValidator("red");
            } else {
                setLengthValidator("green");
            }
            if(/\d/.test(password.password) && /[a-zA-Z]/.test(password.password) && /[#?!@$%^&*+-]/.test(password.password)) {
                setCharacterValidator("green");
            } else {
                setCharacterValidator("red");
            }
        } else {
            setCharacterValidator("grey");
            setLengthValidator("grey");
        }
    }, [password]);
    return (
        <div>
            {(isLogged) ? <Grid container className={classes.root}>
                    <Grid className={classes.container}>
                        <h3 className={classes.title}>
                            {user.payload.temporary_password ?
                                'Please change your password' :
                                'Change password:'}
                        </h3>
                        <h3>{message}</h3>
                        <Grid container justify={"center"}>
                            <TextField
                                variant={'outlined'}
                                size={'small'}
                                type={'password'}
                                error={!validPassword.password && password.password !== ""}
                                className={classes.input}
                                style={{width: '80%'}}
                                value={password.password}
                                label={'New password *'}
                                placeholder={'Enter a new password'}
                                onChange={(e) => {
                                    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+-]).{8,}$/.test(e.target.value)) {
                                        setValidPassword({...validPassword, password: true})
                                    } else {
                                        setValidPassword({...validPassword, password: false})
                                    }
                                    setPassword({...password, password: e.target.value})
                                }}
                            />
                            <div className={classes.icon}>{password.password !== '' && (
                                validPassword.password ? <CheckCircleIcon style={{color: green[500], fontSize: 26}}/> :
                                    <CancelIcon style={{color: red[500], fontSize: 26}}/>)
                            }
                            </div>
                            <Tooltip
                                title={'Password needs to have at least 8 characters, one special character, one number, one uppercase letter'}
                                arrow
                                placement="top-end">
                                <InfoOutlinedIcon style={{marginTop: '7px'}}/>
                            </Tooltip>
                            <Grid container justify={"flex-start"} style={{paddingLeft: 20, marginBottom: 20}}>
                                <FormHelperText
                                    style={{color: 'red', textAlign: 'left'}}
                                >
                                    {(!validPassword.password && password.password !== "") && (
                                        `Password doesn't meet security requirements`)}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container justify={"center"}>
                            <TextField
                                variant={'outlined'}
                                type={'password'}
                                size={'small'}
                                style={{width: '80%'}}
                                label={'Confirm New password *'}
                                placeholder={'Confirm your new password'}
                                className={classes.lastInput}
                                value={password.confirmation}
                                error={!validPassword.confirmation && password.confirmation !== "" && validPassword.confirmation !== ''}
                                onChange={(e) => {
                                    if (e.target.value === password.password) {
                                        setValidPassword({...validPassword, confirmation: true})
                                    }
                                    if (validPassword.confirmation) {
                                        if (e.target.value !== password.password) {
                                            setValidPassword({...validPassword, confirmation: false})
                                        }
                                    }
                                    setPassword({...password, confirmation: e.target.value})
                                }}
                                onBlur={(e) => {
                                    if (e.target.value === password.password) {
                                        setValidPassword({...validPassword, confirmation: true})
                                    } else {
                                        setValidPassword({...validPassword, confirmation: false})
                                    }
                                }}
                            />
                            <div
                                className={classes.icon}>{(password.confirmation !== '' && validPassword.confirmation !== '') && (
                                validPassword.confirmation ? <CheckCircleIcon style={{color: green[500], fontSize: 26}}/> :
                                    <CancelIcon style={{color: red[500], fontSize: 26}}/>)
                            }
                            </div>
                            <Tooltip
                                title={'Password needs to have at least 8 characters, one special character, one number, one uppercase letter'}
                                arrow
                                placement="top-end">
                                <InfoOutlinedIcon style={{marginTop: '7px'}}/>
                            </Tooltip>
                            <Grid container justify={"flex-start"} style={{paddingLeft: 20, marginBottom: 20}}>
                                <FormHelperText
                                    style={{color: 'red', textAlign: 'left'}}
                                >
                                    {(!validPassword.confirmation && password.confirmation !== "" && validPassword.confirmation !== '') && (
                                        `Password doesn't match`)}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container justify={"flex-start"} className={classes.button}>
                            <Button variant="contained" color="primary"
                                    disabled={!(validPassword.confirmation && validPassword.password && password.confirmation !== '')}
                                    onClick={handleClick}
                                    style={{padding:'10px 32px'}}
                            >Save</Button>
                        </Grid>
                        <Grid item style={{
                            padding:8,
                            paddingBottom:12,
                            textAlign:'left'
                        }}>
                            <Typography variant={"subtitle2"} style={{color: lengthValidator, paddingBottom:8}}>
                                Password should be bigger than 8 characters.
                            </Typography>
                            <Typography variant={"subtitle2"} style={{color: characterValidator, paddingBottom:8}}>
                                Password should contain at least one uppercase letter, one lowercase letter and one number.
                            </Typography>
                            <Typography variant={"subtitle2"} style={{color: '#7B99B5'}}>
                                Special characters allowed: # ? ! @ $ % ^ & * +
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                : ''}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 800,
        width: 320,
    },
    title: {
        padding: 16,
        width: '100%',
        fontSize:24,
        fontWeight:'bold'
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 8,
        height: 24,
        width: 24
    },
    button: {
        // width: '75%',
        marginLeft:8,
    },
    root: {
        flexGrow: 1,
        maxWidth: 1920,
        height: '90vh',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 48,
            paddingRight: 20,
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 48,
            paddingRight: 10,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },

    },
}));