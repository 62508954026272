import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import SentimentVeryDissatisfiedOutlinedIcon from "@material-ui/icons/SentimentVeryDissatisfiedOutlined";
import MoodBadOutlinedIcon from "@material-ui/icons/MoodBadOutlined";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import MoodOutlinedIcon from "@material-ui/icons/MoodOutlined";
import SentimentVerySatisfiedOutlinedIcon from "@material-ui/icons/SentimentVerySatisfiedOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

export default function ViewSurvey({rowData}) {

    return (
        <Grid container style={{padding:24}}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>
                        {rowData.survey_name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} style={{color:"grey"}}>
                        {rowData.description}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{padding:2, paddingBottom:12}}>
                    <Divider />
                </Grid>
                {
                    rowData.questions.map((question,index)=>(
                        <React.Fragment>


                            <Grid item xs={12} style={{paddingTop:36, paddingBottom:12}}>
                                <Typography>
                                    {`Q${index+1}: ${question.question_text}`}
                                </Typography>
                            </Grid>
                            {
                                question.question_type === 'Free Text' && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant={'outlined'}
                                            value={'...'}
                                            multiline
                                            rows={4}
                                            size={"small"}
                                        />
                                    </Grid>
                                )
                            }
                            {
                                question.question_type === "Numbers Radio Options" && (
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label="1"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio color="primary" />}
                                                    label="2"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="3"
                                                    control={<Radio color="primary" />}
                                                    label="3"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="4"
                                                    control={<Radio color="primary" />}
                                                    label="4"
                                                    labelPlacement="bottom"
                                                />
                                                <FormControlLabel
                                                    value="5"
                                                    control={<Radio color="primary" />}
                                                    label="5"
                                                    labelPlacement="bottom"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )
                            }
                            {
                                question.question_type === "Smiley Face Radio Options" && (
                                    <Grid item xs={12}>
                                        <ToggleButtonGroup
                                            // value={''}
                                            exclusive
                                            size="large"
                                            // onChange={handleAlignment}
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton value="left" aria-label="left aligned" style={{padding:"16px 24px"}}>
                                                <SentimentVeryDissatisfiedOutlinedIcon style={{fontSize:44}}/>
                                            </ToggleButton>
                                            <ToggleButton value="center" aria-label="centered" style={{padding:"16px 24px"}}>
                                                <MoodBadOutlinedIcon style={{fontSize:44}}/>
                                            </ToggleButton>
                                            <ToggleButton value="right" aria-label="right aligned" style={{padding:"16px 24px"}}>
                                                <SentimentSatisfiedOutlinedIcon style={{fontSize:44}} />
                                            </ToggleButton>
                                            <ToggleButton value="justify" aria-label="justified" style={{padding:"16px 24px"}}>
                                                <MoodOutlinedIcon style={{fontSize:44}}/>
                                            </ToggleButton>
                                            <ToggleButton value="justify" aria-label="justified" style={{padding:"16px 24px"}}>
                                                <SentimentVerySatisfiedOutlinedIcon style={{fontSize:44}}/>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                )
                            }
                            {
                                question.question_type === "Text Radio Options" && (
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup>
                                                {
                                                    question.opt1 !== null && (
                                                        <FormControlLabel value="female" control={<Radio color={'primary'}/>} label={question.opt1} />
                                                    )
                                                }
                                                {
                                                    question.opt2 !== null && (
                                                        <FormControlLabel value="female1" control={<Radio color={'primary'}/>} label={question.opt2} />
                                                    )
                                                }
                                                {
                                                    question.opt3 !== null && (
                                                        <FormControlLabel value="female2" control={<Radio color={'primary'}/>} label={question.opt3} />
                                                    )
                                                }
                                                {
                                                    question.opt4 !== null && (
                                                        <FormControlLabel value="female3" control={<Radio color={'primary'}/>} label={question.opt4} />
                                                    )
                                                }
                                                {
                                                    question.opt5 !== null && (
                                                        <FormControlLabel value="female4" control={<Radio color={'primary'}/>} label={question.opt5} />
                                                    )
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                )
                            }
                            {
                                question.question_type === "Text Checkbox Options" && (
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup>
                                                {
                                                    question.opt1 !== null && (
                                                        <FormControlLabel value="female" control={<Checkbox color={'primary'}/>} label={question.opt1} />
                                                    )
                                                }
                                                {
                                                    question.opt2 !== null && (
                                                        <FormControlLabel value="female1" control={<Checkbox color={'primary'}/>} label={question.opt2} />
                                                    )
                                                }
                                                {
                                                    question.opt3 !== null && (
                                                        <FormControlLabel value="female2" control={<Checkbox color={'primary'}/>} label={question.opt3} />
                                                    )
                                                }
                                                {
                                                    question.opt4 !== null && (
                                                        <FormControlLabel value="female3" control={<Checkbox color={'primary'}/>} label={question.opt4} />
                                                    )
                                                }
                                                {
                                                    question.opt5 !== null && (
                                                        <FormControlLabel value="female4" control={<Checkbox color={'primary'}/>} label={question.opt5} />
                                                    )
                                                }
                                                {
                                                    question.option_other === true && (
                                                        <Grid container alignItems={'center'} alignContent='center'>
                                                            <FormControlLabel value="female4" control={<Checkbox color={'primary'}/>} label={"Other:"} />
                                                            <TextField
                                                                size={'small'}
                                                                variant={'outlined'}
                                                                label={'Other option'}
                                                            />
                                                        </Grid>
                                                    )
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                )
                            }
                            {
                                question.question_type === "Yes / No" && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button variant='outlined' color={'secondary'}>
                                                    No
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant='outlined' color={'primary'}>
                                                    Yes
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )
                            }
                        </React.Fragment>
                    ))
                }
            </Grid>
        </Grid>
    )
}