import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {tooltips} from "../../constants/inputs/tooltips";
import {validationTypes} from "../../constants/validations/validationTypes";
import {patientRecordNames} from "../../constants/inputs/patientRecordNames";

export function CustomInputField({validationType,hardValidation,placeholder, setHardValidation, isMissing, name,label,value,isValid,onChange, onBlur,isRequired, id, checkDuplicates, duplicate, variant, size, enableNoZeroValue, disabled}) {
    const [zeroValueDetected, setZeroValueDetected] = useState(false);
    const validateNumber = (e) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            if (e.target.name === patientRecordNames.patient_height_in_inches && e.target.value > 11) {
                const obj = {
                    target: {
                        name: e.target.name,
                        value: value,
                    }
                };
                onChange(obj, false);
            } else if (e.target.name === patientRecordNames.patient_height_in_feet && e.target.value > 9) {
                const obj = {
                    target: {
                        name: e.target.name,
                        value: value,
                    }
                };
                onChange(obj, false);
            } else {
                onChange(e, true)
            }
        } else {
            onChange(e, false)
        }
    };
    const validateLetter = (e) => {
        if (/^[a-z]+$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateLetterAndNumber = (e) => {
        if (/^[a-zA-Z0-9]+$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateFloatingNumber = (e) => {
        if (/^[0-9,.]*$/.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateLetterAndSpaces = (e) => {
        if (/^[a-zA-Z\s]*$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateLetterAndSpacesNames = (e) => {
        if (/^[a-zA-Z\s-',.]*$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateLetterAndAlpha = (e) => {
        if (/^[a-zA-Z,. ]*$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const validateLetterAndSpace = (e) => {
        if (/^[a-zA-Z ]*$/i.test(e.target.value)) {
            onChange(e, true)
        } else {
            onChange(e, false)
        }
    };
    const [validateTypeMessage, setValidateTypeMessage] = React.useState();

    return (
        <React.Fragment>
            <TextField
            helperText={isMissing && tooltips.isRequired}
            error={isMissing}
            id={id ? name +  "_" + id : name}
            placeholder={placeholder}
            required={isRequired}
            name={name}
            label={label}
            fullWidth
            variant={variant ? variant : "outlined"}
            value={value}
            disabled={disabled ? disabled : false}
            size={size ? size : "medium"}
            onChange={(e) => {
                setZeroValueDetected(false);
                if(e.target.value.length === 1){
                    if(e.target.value === "0"){
                        setZeroValueDetected(true)
                    }
                }
                if (setHardValidation){
                    setHardValidation(false)
                }
                if (validationType === 'number') {
                    validateNumber(e)
                    if(e.target.name === patientRecordNames.patient_height_in_inches && e.target.value > 11) {
                        setValidateTypeMessage('values below 12');
                    } else if (e.target.name === patientRecordNames.patient_height_in_feet && e.target.value > 9){
                        setValidateTypeMessage('values below 10');
                    }
                    else {
                        setValidateTypeMessage('numbers')
                    }
                } else if (validationType === 'letter') {
                    validateLetter(e)
                    setValidateTypeMessage('letters')
                } else if (validationType === 'letterSpaces') {
                    validateLetterAndSpaces(e)
                    setValidateTypeMessage('letters')
                } else if (validationType === 'letterAlpha') {
                    validateLetterAndAlpha(e)
                    setValidateTypeMessage('letters')
                } else if (validationType === 'lettersAndNumbers') {
                    validateLetterAndNumber(e)
                    setValidateTypeMessage('letters and number')
                } else if (validationType === 'letterSpacesNames'){
                    validateLetterAndSpacesNames(e)
                    setValidateTypeMessage('letters')
                } else if (validationType === validationTypes.floatingNumbers){
                    validateFloatingNumber(e)
                    setValidateTypeMessage('numbers, periods and commas');
                }
                else {
                    onChange(e);
                }
            }}
            onBlur={(e) => {
                onChange(e, true)
                onBlur(e, true)
                checkDuplicates && checkDuplicates(e)
                if (value !== '') {
                    if (validationType === 'number') {
                        validateNumber(e)
                    } else if (validationType === 'letter') {
                        validateLetter(e)
                    } else if (validationType === 'letterSpaces') {
                        validateLetterAndSpaces(e)
                    } else if (validationType === 'letterAlpha') {
                        validateLetterAndAlpha(e)
                        setValidateTypeMessage('letters')
                    }  else if (validationType === 'letterSpacesNames') {
                        validateLetterAndSpacesNames(e)
                        setValidateTypeMessage('letters and number')
                    }else if (validationType === 'lettersAndNumbers') {
                        validateLetterAndNumber(e)
                        setValidateTypeMessage('only letters and numbers')
                    } else if (validationType === validationTypes.floatingNumbers) {
                        validateFloatingNumber(e)
                        setValidateTypeMessage('numbers, periods and commas')
                    } else {
                        onChange(e)
                    }
                }
            }}
            />
            <FormHelperText
                style={{color: 'red', textAlign: 'left'}}
            >
                {(!isMissing && !isValid && disabled !== true) && (
                    `${label} must contain ${validateTypeMessage}`)
                }
            </FormHelperText>
            <FormHelperText
                style={{color: 'red', textAlign: 'left'}}
            >
                {hardValidation && !checkDuplicates && (
                    `Patient with this ${label} exists`)
                }
            </FormHelperText>
            <FormHelperText
                style={{color: 'red', textAlign: 'left'}}
            >
                {(enableNoZeroValue === true && zeroValueDetected) && (
                    `Zero (0) is not an accepted value.`)
                }
            </FormHelperText>
            {(checkDuplicates || hardValidation) && <FormHelperText
                style={{color: 'red', textAlign: 'left'}}
            >
                {(duplicate) && (
                    `Patient with this ${label} exists`)
                }
            </FormHelperText>}
        </React.Fragment>
    )
}
