export const endpoints = {
    version: process.env.REACT_APP_DEV_API_URL + '/version',
    zipCode: process.env.REACT_APP_DEV_API_URL + `/utils/`,
    getPatientData: `${process.env.REACT_APP_DEV_API_URL}/patients/`,
    dme_organization: `${process.env.REACT_APP_DEV_API_URL}/dme/organization/`,
    dme_organizations: `${process.env.REACT_APP_DEV_API_URL}/dme/organizations/`,
    hospice_organizations: `${process.env.REACT_APP_DEV_API_URL}/hospice/organizations/`,
    edit_hospice: `${process.env.REACT_APP_DEV_API_URL}/hospice/organizations/`,
    regions: `${process.env.REACT_APP_DEV_API_URL}/regions`,
    hospice_organization: `${process.env.REACT_APP_DEV_API_URL}/hospice/organization/`,
    dme_roles: `${process.env.REACT_APP_DEV_API_URL}/dme/roles/`,
    hospice_roles: `${process.env.REACT_APP_DEV_API_URL}/hospice/roles/`,
    hospice_users: `${process.env.REACT_APP_DEV_API_URL}/hospice/users/`,
    patients_soft_check: `${process.env.REACT_APP_DEV_API_URL}/patients/soft`,
    patients_hard_check: `${process.env.REACT_APP_DEV_API_URL}/patients/hard`,
    patient_profile: `/patients/patientprofile/`,
    patients: `${process.env.REACT_APP_DEV_API_URL}/patients/`,
    patientNotes: `${process.env.REACT_APP_DEV_API_URL}/patients/notes/`,
    patientFacilities: `${process.env.REACT_APP_DEV_API_URL}/patients/facilities/`,
    patientCaregivers: `${process.env.REACT_APP_DEV_API_URL}/patients/caregivers/`,
    patientDiseases: `${process.env.REACT_APP_DEV_API_URL}/patients/diseases/`,
    patientsByLot: `${process.env.REACT_APP_DEV_API_URL}/patients/dme/lots/`,
    states: `${process.env.REACT_APP_DEV_API_URL}/states`,
    roles: `${process.env.REACT_APP_DEV_API_URL}/dme/roles`,
    searchHospiceUsers: `${process.env.REACT_APP_DEV_API_URL}/hospice/users/search`,
    searchDmeUsers: `${process.env.REACT_APP_DEV_API_URL}/dme/users`,
    getHospiceUsers: `${process.env.REACT_APP_DEV_API_URL}/hospice/users/`,
    searchHospice: `${process.env.REACT_APP_DEV_API_URL}/hospice/search`,
    searchDme: `${process.env.REACT_APP_DEV_API_URL}/dme/search`,
    dme_edit: `${process.env.REACT_APP_DEV_API_URL}/dme/`,
    dme_user: `${process.env.REACT_APP_DEV_API_URL}/dme/user/`,
    permissions: `${process.env.REACT_APP_DEV_API_URL}/permissions/`,
    dme_user_edit: `${process.env.REACT_APP_DEV_API_URL}/dme/user/edit/`,
    hospice_user: `${process.env.REACT_APP_DEV_API_URL}/hospice/user/`,
    hospice_user_edit: `${process.env.REACT_APP_DEV_API_URL}/hospice/user/edit/`,
    restrictedDomains: `${process.env.REACT_APP_DEV_API_URL}/restrictedDomains`,
    randomUsername: `${process.env.REACT_APP_DEV_API_URL}/randomUsernames`,
    addDomain: `${process.env.REACT_APP_DEV_API_URL}/restrictedDomain`,
    removeDomain: `${process.env.REACT_APP_DEV_API_URL}/restrictedDomain/`,
    updateDomain: `${process.env.REACT_APP_DEV_API_URL}/restrictedDomain/`,
    getAllMasterItems: `${process.env.REACT_APP_DEV_API_URL}/master/items`,
    getMasterItemById: `${process.env.REACT_APP_DEV_API_URL}/master/item/`,
    updateMasterItemById: `${process.env.REACT_APP_DEV_API_URL}/master/item/`,
    removeMasterItemById: `${process.env.REACT_APP_DEV_API_URL}/master/item/`,
    createMasterItem: `${process.env.REACT_APP_DEV_API_URL}/master/items`,
    login: `${process.env.REACT_APP_DEV_API_URL}/user/login`,
    renewLogin: `${process.env.REACT_APP_DEV_API_URL}/user/token`,
    changePassword: `${process.env.REACT_APP_DEV_API_URL}/user/password`,
    createOrder: `${process.env.REACT_APP_DEV_API_URL}/order/delivery`,
    getAssets: `${process.env.REACT_APP_DEV_API_URL}/assetsLotsSerials`,
    getManufacturers: `${process.env.REACT_APP_DEV_API_URL}/manufacturers`,
    createManufacturer: `${process.env.REACT_APP_DEV_API_URL}/manufacturer`,
    updateManufacturer: `${process.env.REACT_APP_DEV_API_URL}/manufacturer/`,
    deleteManufacturer: `${process.env.REACT_APP_DEV_API_URL}/manufacturer/`,
    getWarehouses: `${process.env.REACT_APP_DEV_API_URL}/warehouses`,
    addInventory: `${process.env.REACT_APP_DEV_API_URL}/inventory/items`,
    addInventoryItemsToOrder: `${process.env.REACT_APP_DEV_API_URL}/delivery/items/addasset`,
    updateItemsAssetToOrder: `${process.env.REACT_APP_DEV_API_URL}/delivery/items/updateasset`,
    updateInventoryItemsLotToOrder: `${process.env.REACT_APP_DEV_API_URL}/delivery/items/updatelot`,
    pickupItems: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/items/`,
    createPickup: `${process.env.REACT_APP_DEV_API_URL}/order/pickup`,
    getAllDmeWarehouses: `${process.env.REACT_APP_DEV_API_URL}/dme/warehouses/`,
    getAllDmeIds: `${process.env.REACT_APP_DEV_API_URL}/dme/ids`,
    getDmeIds: `${process.env.REACT_APP_DEV_API_URL}/dme/all/ids`,
    getDmeTeams: `${process.env.REACT_APP_DEV_API_URL}/dme/teams/`,
    getHospiceTeams: `${process.env.REACT_APP_DEV_API_URL}/hospice/teams/`,
    getAllHospiceTeamsByDmeId: `${process.env.REACT_APP_DEV_API_URL}/hospice/teams/dme/`,
    getHospiceFacilities: `${process.env.REACT_APP_DEV_API_URL}/hospice/facilities/`,
    getDmeRegions: `${process.env.REACT_APP_DEV_API_URL}/dme/regions/`,
    getHospiceRegions: `${process.env.REACT_APP_DEV_API_URL}/hospice/regions/`,
    getAllHospiceIds: `${process.env.REACT_APP_DEV_API_URL}/hospice/ids/`,
    getUserWarehouses: `${process.env.REACT_APP_DEV_API_URL}/dme/users/warehouses/`,
    getAuditTrail: `${process.env.REACT_APP_DEV_API_URL}/audit/trail`,
    getDeliveryOrderById: `${process.env.REACT_APP_DEV_API_URL}/order/delivery/`,
    getPickupOrderById: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/`,
    getInventoryItems: `${process.env.REACT_APP_DEV_API_URL}/inventory/getitems/`,
    getAllInventoryItems: `${process.env.REACT_APP_DEV_API_URL}/inventory/getAllItems`,
    getInventoryItemsAssets: `${process.env.REACT_APP_DEV_API_URL}/inventory/getitemassets/`,
    getInventoryItemByAsset: `${process.env.REACT_APP_DEV_API_URL}/inventory/getitembyasset/`,
    oxygenLogs: `${process.env.REACT_APP_DEV_API_URL}/inventory/oxygenLogs/`,
    checkForAlteration: `${process.env.REACT_APP_DEV_API_URL}/audit/checkAlteration`,
    getUsersOnRoute: `${process.env.REACT_APP_DEV_API_URL}/audit/getRouteUsers`,
    getUserLastLocation: `${process.env.REACT_APP_DEV_API_URL}/audit/userLastLocation`,
    nurses: `${process.env.REACT_APP_DEV_API_URL}/nurses`,
    distinctNurses: `${process.env.REACT_APP_DEV_API_URL}/nurses/distinct`,
    hospiceNurses: `${process.env.REACT_APP_DEV_API_URL}/hospice/nurses/`,
    createNurses: `${process.env.REACT_APP_DEV_API_URL}/createNurse`,
    updateNurses: `${process.env.REACT_APP_DEV_API_URL}/updateNurse/`,
    deleteNurses: `${process.env.REACT_APP_DEV_API_URL}/deleteNurse/`,
    availableAssets: `${process.env.REACT_APP_DEV_API_URL}/inventory/getItemAssetsByOrg`,
    getHospicesByDme: `${process.env.REACT_APP_DEV_API_URL}/dme/hospices/`,
    getActiveRental: `${process.env.REACT_APP_DEV_API_URL}/reports/activerental`,
    getFacilityReport: `${process.env.REACT_APP_DEV_API_URL}/reports/facilityReport`,
    getOOFReport: `${process.env.REACT_APP_DEV_API_URL}/reports/oofLfMonthly`,
    getLostItems: `${process.env.REACT_APP_DEV_API_URL}/reports/lostItems`,
    getBillingReport: `${process.env.REACT_APP_DEV_API_URL}/reports/billing`,
    supportEmail: `${process.env.REACT_APP_DEV_API_URL}/supportEmail`,
    changeTimeZone: `${process.env.REACT_APP_DEV_API_URL}/timezone/preference`,
    getAllOrders: `${process.env.REACT_APP_DEV_API_URL}/orders/all/`,
    getAllOrdersHospice: `${process.env.REACT_APP_DEV_API_URL}/orders/hospice/all`,
    getAllTechnicians: `${process.env.REACT_APP_DEV_API_URL}/technicians/`,
    getAllTechniciansAdmin: `${process.env.REACT_APP_DEV_API_URL}/technician/all`,
    getActiveItemsByPatient: `${process.env.REACT_APP_DEV_API_URL}/orders/active/`,
    getAllItemsByPatient: `${process.env.REACT_APP_DEV_API_URL}/orders/all/`,
    updateDeliveryOrderStatus: `${process.env.REACT_APP_DEV_API_URL}/delivery/status`,
    updatePickupOrderStatus: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/status`,
    updateExchangeOrderStatus: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/status`,
    updateArchivePatient: `${process.env.REACT_APP_DEV_API_URL}/patients/archive/`,
    updatePatientStatus: `${process.env.REACT_APP_DEV_API_URL}/patients/status/`,
    updateItemStatus: `${process.env.REACT_APP_DEV_API_URL}/inventory/item/status`,
    checkUserByEmailAndRole: `${process.env.REACT_APP_DEV_API_URL}/userByRoleAndEmail`,
    createContractDetails: `${process.env.REACT_APP_DEV_API_URL}/contracts/details/`,
    getHospiceContractedProviders: `${process.env.REACT_APP_DEV_API_URL}/contract/`,
    getHospiceContractedItems: `${process.env.REACT_APP_DEV_API_URL}/contract/items`,
    getCategoriesMasterItems: `${process.env.REACT_APP_DEV_API_URL}/orders/categories`,
    getNavPreference: `${process.env.REACT_APP_DEV_API_URL}/user/navpref/`,
    setNavPreference: `${process.env.REACT_APP_DEV_API_URL}/user/navpref/`,
    hospiceDays: `${process.env.REACT_APP_DEV_API_URL}/hospice/patients/days/`,
    requestUsername: `${process.env.REACT_APP_DEV_API_URL}/requestUsername`,
    requestPassword: `${process.env.REACT_APP_DEV_API_URL}/requestPassword`,
    contracts: `${process.env.REACT_APP_DEV_API_URL}/contracts/`,
    getContractDetails: `${process.env.REACT_APP_DEV_API_URL}/contractById/`,
    updateContractDetails: `${process.env.REACT_APP_DEV_API_URL}/contract/change/`,
    validateContract: `${process.env.REACT_APP_DEV_API_URL}/contract/validation`,
    checkUsernameAvailability: `${process.env.REACT_APP_DEV_API_URL}/checkUsernameAvailability`,
    checkEmailAvailability: `${process.env.REACT_APP_DEV_API_URL}/checkEmailAvailability`,
    updateContractStatus: `${process.env.REACT_APP_DEV_API_URL}/contract/status`,
    addDeliveryOrderNotes: `${process.env.REACT_APP_DEV_API_URL}/orders/add/notes`,
    addDeliveryOrderHistory: `${process.env.REACT_APP_DEV_API_URL}/orders/add/history`,
    addExchangeOrderHistory: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/history`,
    addTechnician: `${process.env.REACT_APP_DEV_API_URL}/technician`,
    updateDeliveryTechnician: `${process.env.REACT_APP_DEV_API_URL}/order/delivery/technician`,
    updateExchangeTechnician: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/technician`,
    updatePickupTechnician: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/technician`,
    deleteDeliveryItems: `${process.env.REACT_APP_DEV_API_URL}/delivery/items`,
    deleteExchangeItems: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/items`,
    addDeliveryItems: `${process.env.REACT_APP_DEV_API_URL}/delivery/items`,
    addExchangeOrderItems: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/items`,
    addPickupOrderNotes: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/add/notes`,
    addExchangeOrderNotes: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/notes`,
    addPickupOrderHistory: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/add/history`,
    deleteUser: `${process.env.REACT_APP_DEV_API_URL}/soft/delete/`,
    uploadMasterListImages: `${process.env.REACT_APP_DEV_API_URL}/master/item/upload`,
    checkMedicalRecordExists: `${process.env.REACT_APP_DEV_API_URL}/patients/medicalRecord/`,
    deletePickupItems: `${process.env.REACT_APP_DEV_API_URL}/order/pickup/items`,
    getHospiceAddress: `${process.env.REACT_APP_DEV_API_URL}/hospice/address/`,
    createExchangeOrder: `${process.env.REACT_APP_DEV_API_URL}/order/exchange`,
    getExchangeOrderById: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/`,
    getRoleId: `${process.env.REACT_APP_DEV_API_URL}/role/`,
    hospiceEditStep1: `${process.env.REACT_APP_DEV_API_URL}/hospice/step1/`,
    hospiceEditStep2: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/`,
    hospiceEditStep3: `${process.env.REACT_APP_DEV_API_URL}/hospice/step3/`,
    dmeEditStep1: `${process.env.REACT_APP_DEV_API_URL}/dme/step1/`,
    dmeEditStep2: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/`,
    dmeEditStep3: `${process.env.REACT_APP_DEV_API_URL}/dme/step3/`,
    updateOrderCompletionDate: `${process.env.REACT_APP_DEV_API_URL}/updateOrderCompletion`,
    updateOrderPreferredDate: `${process.env.REACT_APP_DEV_API_URL}/updateOrderPreferred`,
    sendApproveOrderMail: `${process.env.REACT_APP_DEV_API_URL}/orders/approval`,
    bulkUploadPatients: `${process.env.REACT_APP_DEV_API_URL}/patients/bulk`,
    all_dme_organizations: `${process.env.REACT_APP_DEV_API_URL}/dme/organizations/all`,
    all_hospice_organizations: `${process.env.REACT_APP_DEV_API_URL}/hospice/organizations/all`,
    masterItemsClinicalSettings: `${process.env.REACT_APP_DEV_API_URL}/master/items/settings`,
    masterItemsClinicalSettingsById: `${process.env.REACT_APP_DEV_API_URL}/master/items/settings/`,
    createEditTechnicians: `${process.env.REACT_APP_DEV_API_URL}/technician`,
    createPortalUser: `${process.env.REACT_APP_DEV_API_URL}/portal/createUser`,
    getPortalId: `${process.env.REACT_APP_DEV_API_URL}/portal/`,
    allOrganizationFeatures: `${process.env.REACT_APP_DEV_API_URL}/portal/all/features`,
    getOrganizationFeatures: `${process.env.REACT_APP_DEV_API_URL}/portal/feature`,
    updateCreateFeatureMapping: `${process.env.REACT_APP_DEV_API_URL}/portal/mapping`,
    dmeResearch: `${process.env.REACT_APP_DEV_API_URL}/search`,
    dmeResearchById: `${process.env.REACT_APP_DEV_API_URL}/provider/info`,
    getAllHospiceRoles: `${process.env.REACT_APP_DEV_API_URL}/hospice/roles`,
    getAllPortalRoles: `${process.env.REACT_APP_DEV_API_URL}/portal/roles`,
    getAllDmeRoles: `${process.env.REACT_APP_DEV_API_URL}/dme/roles`,
    getAllUsersBasedOnOrgAndRole: `${process.env.REACT_APP_DEV_API_URL}/users/all`,
    getUserAuditById: `${process.env.REACT_APP_DEV_API_URL}/users/audit`,
    getOrgFeaturesById: `${process.env.REACT_APP_DEV_API_URL}/portal/feature`,
    getItemAudit: `${process.env.REACT_APP_DEV_API_URL}/inventory/audit/`,
    getRegionDetails: `${process.env.REACT_APP_DEV_API_URL}/dme/region`,
    createDMEOrganizationStep1: `${process.env.REACT_APP_DEV_API_URL}/dme/organization/step1`,
    createDMEOrganizationStep2: `${process.env.REACT_APP_DEV_API_URL}/dme/organization/step2`,
    createDMEOrganizationItContact: `${process.env.REACT_APP_DEV_API_URL}/dme/organization/step3`,
    updateDMEOrganizationItContact: `${process.env.REACT_APP_DEV_API_URL}/dme/step3/`,
    updateDMEOrganizationGeneralInformation: `${process.env.REACT_APP_DEV_API_URL}/dme/step1/`,
    updateDMEOrganizationRegionPhone: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/phoneRegion`,
    addOrUpdateRegionTeam: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/addOrUpdate/team/`,
    addOrUpdateRegionWarehouse: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/addOrUpdate/warehouse/`,
    deleteRegionWarehouse: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/remove/warehouse`,
    deleteRegionTeam: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/remove/team`,
    verifyWarehouse: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/verify/warehouse`,
    addOrUpdateAddressRegion: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/addressRegion`,
    addOrUpdateBillingContactRegion: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/billingContactRegion`,
    addOrUpdateHospiceFacility: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/addOrUpdate/facility`,
    deleteHospiceFacility: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/remove/facility`,
    addOrUpdateHospiceTeam: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/addOrUpdate/team`,
    deleteHospiceTeam: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/remove/team`,
    hospiceRegionPhone: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/phoneRegion`,
    hospiceRegionAddress: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/addressRegion`,
    hospiceRegionBillingContact: `${process.env.REACT_APP_DEV_API_URL}/hospice/step2/billingContactRegion`,
    hospiceRegionData: `${process.env.REACT_APP_DEV_API_URL}/hospice/region/byId`,
    createHospiceRegion: `${process.env.REACT_APP_DEV_API_URL}/hospice/region/organization`,
    updateNotificationSettings: `${process.env.REACT_APP_DEV_API_URL}/portal`,
    getNotificationSettings: `${process.env.REACT_APP_DEV_API_URL}/portal/notifications/`,
    updatePaceSettings: `${process.env.REACT_APP_DEV_API_URL}/hospice/pace`,
    getPaceSettings: `${process.env.REACT_APP_DEV_API_URL}/hospice/pace/`,
    updatePaceNo: `${process.env.REACT_APP_DEV_API_URL}/delivery/order/authorizationpaceno`,
    updatePaceNoPickup: `${process.env.REACT_APP_DEV_API_URL}/pickup/order/authorizationpaceno`,
    updatePaceNoExchange: `${process.env.REACT_APP_DEV_API_URL}/order/exchange/authorizationpaceno`,
    getAllSurveyUserIds: `${process.env.REACT_APP_DEV_API_URL}/survey/portal/users`,
    getAllSurveyData: `${process.env.REACT_APP_DEV_API_URL}/survey/names`,
    getAllSurveyUserResponse: `${process.env.REACT_APP_DEV_API_URL}/answers/`,
    createSurvey: `${process.env.REACT_APP_DEV_API_URL}/survey`,
    getALLSurvey: `${process.env.REACT_APP_DEV_API_URL}/survey/all`,
    getAllPortalAccountsForPatientId: `${process.env.REACT_APP_DEV_API_URL}/portal/accounts/`,
    dispatchNotification: `${process.env.REACT_APP_DEV_API_URL}/portal/dispatch`,
    userProfileData: `${process.env.REACT_APP_DEV_API_URL}/users/getUserProfileData/`,
    updateUserEmailOrPhone: `${process.env.REACT_APP_DEV_API_URL}/users/updateUserEmailPhone`,
    updateOrganizationType: `${process.env.REACT_APP_DEV_API_URL}/hospice/org/type`,
    fileGetOnOrders: `${process.env.REACT_APP_DEV_API_URL}/order/get/files`,
    fileUploadOnOrders: `${process.env.REACT_APP_DEV_API_URL}/order/upload/files`,
    createFileUploadIdOnOrders: `${process.env.REACT_APP_DEV_API_URL}/orders/add/files`,
    removeFileUploadIdOnOrders: `${process.env.REACT_APP_DEV_API_URL}/orders/file/delete`,
    createOrUpdateSystemNotification: `${process.env.REACT_APP_DEV_API_URL}/system/notification`,
    getSystemNotifications: `${process.env.REACT_APP_DEV_API_URL}/system/notifications`,
    getUsersByOrganizations: `${process.env.REACT_APP_DEV_API_URL}/users/all/org`,
    sendSystemNotifications: `${process.env.REACT_APP_DEV_API_URL}/system/notification/users`,
    getUserSystemNotifications: `${process.env.REACT_APP_DEV_API_URL}/system/notification/user`,
    updateUserSystemNotification: `${process.env.REACT_APP_DEV_API_URL}/system/notification/user/action`,
    getUserKeyDetails: `${process.env.REACT_APP_DEV_API_URL}/hospice/user/verify/key`,
    getOrderStatus: `${process.env.REACT_APP_DEV_API_URL}/order/status`,
    patientOrders: `${process.env.REACT_APP_DEV_API_URL}/orders/patient/`,
    uploadHospiceLogo: `${process.env.REACT_APP_DEV_API_URL}/uploadHospiceLogo`,
    getHospiceLogo: `${process.env.REACT_APP_DEV_API_URL}/getHospiceLogo/`,
    getAllHospiceLogos: `${process.env.REACT_APP_DEV_API_URL}/getAllHospiceLogos`,
    getContractedHospices: `${process.env.REACT_APP_DEV_API_URL}/hospices/contracted/`,
    createCustomPackage: `${process.env.REACT_APP_DEV_API_URL}/hospices/custompackages/create`,
    getAllCustomPackage: `${process.env.REACT_APP_DEV_API_URL}/hospices/custompackages/all`,
    getCustomPackageById: `${process.env.REACT_APP_DEV_API_URL}/hospices/package/`,
    updateOnCallInformation: `${process.env.REACT_APP_DEV_API_URL}/dme/step2/onCall`,
    retrieveOnCallInformation: `${process.env.REACT_APP_DEV_API_URL}/dme/oncalldata`,
    getAllSurveys: `${process.env.REACT_APP_DEV_API_URL}/survey/all`,
    triggerSurveys: `${process.env.REACT_APP_DEV_API_URL}/survey/trigger/users`,
    getAllActivePatientsHospiceView : `${process.env.REACT_APP_DEV_API_URL}/patients/hospice/active/`,
    medicalRecordBulk : `${process.env.REACT_APP_DEV_API_URL}/medicalRecord/bulk`,
    sendLostEmailNotifications: `${process.env.REACT_APP_DEV_API_URL}/inventory/item/lost/notifications`,
    businessReviewReport: `${process.env.REACT_APP_DEV_API_URL}/reports/businessReview`,
    createItemResource: `${process.env.REACT_APP_DEV_API_URL}/item/resources/create`,
    uploadItemResource: `${process.env.REACT_APP_DEV_API_URL}/item/resources/upload`,
    getUploadItemResource: `${process.env.REACT_APP_DEV_API_URL}/item/resources/get`,
    getAllItemResources: `${process.env.REACT_APP_DEV_API_URL}/item/resources`,
    createInventoryLog:  `${process.env.REACT_APP_DEV_API_URL}/inventory/clean/maintenance`,
    getInventoryInfo:  `${process.env.REACT_APP_DEV_API_URL}/inventory/`,
    addUnauthorizedLog: `${process.env.REACT_APP_DEV_API_URL}/audit/unauthorize`,
    getUnauthorizedLogs: `${process.env.REACT_APP_DEV_API_URL}/audit/unauthorize/logs`,
    getPendingOrders: `${process.env.REACT_APP_DEV_API_URL}/orders/pending/notifications/all`,
    getContractedHospicesByDmeIds: `${process.env.REACT_APP_DEV_API_URL}/contractByDmeIds`,
    getUserImpersonations: `${process.env.REACT_APP_DEV_API_URL}/dme/user/impersonations/`,
    setDmeImpersonation: `${process.env.REACT_APP_DEV_API_URL}/user/token/impersonation`,
    getDMEUserDataById: `${process.env.REACT_APP_DEV_API_URL}/dme/user/data/`,
    permissionPackage: `${process.env.REACT_APP_DEV_API_URL}/permissionPackage`,
    getPermissionPackageById: `${process.env.REACT_APP_DEV_API_URL}/permissionPackage/`,
    checkExistingPermissionPackage: `${process.env.REACT_APP_DEV_API_URL}/permissionPackage/validate/`,
    getUserNotification: `${process.env.REACT_APP_DEV_API_URL}/user/action/notification/`,
    readAllNotification: `${process.env.REACT_APP_DEV_API_URL}/user/action/notification/mark/read`,
    deleteAllNotification: `${process.env.REACT_APP_DEV_API_URL}/user/action/notification/delete`,
    bulkUserUpload: `${process.env.REACT_APP_DEV_API_URL}/users/bulk`
};
