const username = (state = '', action) => {
    switch (action.type) {
        case 'login':
            return state = action.name;
        case 'logout':
            return state = '';
        default:
            return state;
    }
};

export default username;