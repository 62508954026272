import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import Button from "@material-ui/core/Button";
import {Tooltip} from "@material-ui/core";
import {placeholders} from "../../../../../constants/inputs/paceholders";

export function Table({items, columns, order, setOrder, handleClearAll}) {
    const [data, setData] = React.useState([]);

    useEffect(() => {
        setData(items);
    }, [items]);

    return (
        <div className={'generaltable'} style={{paddingBottom: '20px'}}>
            <MaterialTable
                title={'Exchange Items:'}
                isLoading={false}
                localization={{
                    body: {
                        emptyDataSourceMessage: placeholders.empty_cart
                    }
                }}
                options={{
                    paging: false,
                    search: false,
                    headerStyle: {
                        backgroundColor: '#F2F6F7',
                        color: '#2E3236',
                        height: 20,
                        '&:hover': {color: '#2E3236'}
                    }
                }}
                columns={columns}
                data={data}
            />
            <div style={{
                alignContent: 'right',
                justifyContent: 'flex-end',
                display: 'flex',
                paddingTop: '10px',
                width: '100%'
            }}>
                <Tooltip title={'Remove all items'}>
                    <Button
                        variant={"outlined"}
                        color={"default"}
                        onClick={handleClearAll}
                    >Clear items</Button>

                </Tooltip>
            </div>
        </div>
    );
}