import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export default function TicTacToe(){
    const [boxes, setBoxes] = useState([null,null,null,null,null,null,null,null,null]);
    const [winner, setWinner] = useState("");
    const [state, setState] = useState([null,null,null,null,null,null,null,null,null]);
    const [loading, setLoading] = useState(false);

    function newGame() {
        setBoxes([null,null,null,null,null,null,null,null,null]);
        setState([null,null,null,null,null,null,null,null,null]);
        setWinner("");
    }

    function nextMove (arr, clone){
        setLoading(true);
        fetch(`https://stujo-tic-tac-toe-stujo-v1.p.rapidapi.com/${arr}/O`, {
            "method": "GET",
            "headers": {
                "x-rapidapi-key": "50eab4f08dmshf4e944680cb2efcp14ebecjsne0a52c887c60",
                "x-rapidapi-host": "stujo-tic-tac-toe-stujo-v1.p.rapidapi.com"
            }
        })
            .then(response => {
                return response.json()
            })
            .then(res => {
                clone[res.recommendation] = "O";
                setState(clone);
            })
            .catch(err => {
            });
    }
    useEffect(()=> {
        setBoxes(state);
        setLoading(false);
    }, [state]);

    useEffect(()=> {
        if ((boxes[0] ==="X" && boxes[1] ==="X" && boxes[2] ==="X") ||
            (boxes[3] ==="X" && boxes[4] ==="X" && boxes[5] ==="X") ||
            (boxes[6] ==="X" && boxes[7] ==="X" && boxes[8] ==="X") ||
            (boxes[0] ==="X" && boxes[3] ==="X" && boxes[6] ==="X") ||
            (boxes[1] ==="X" && boxes[4] ==="X" && boxes[7] ==="X") ||
            (boxes[6] ==="X" && boxes[7] ==="X" && boxes[8] ==="X") ||
            (boxes[6] ==="X" && boxes[7] ==="X" && boxes[8] ==="X") ||
            (boxes[6] ==="X" && boxes[7] ==="X" && boxes[8] ==="X") ||
            (boxes[0] ==="X" && boxes[4] ==="X" && boxes[8] ==="X") ||
            (boxes[2] ==="X" && boxes[4] ==="X" && boxes[6] ==="X")
        ) {
            setWinner("X")
        } else if (
            (boxes[0] ==="O" && boxes[1] ==="O" && boxes[2] ==="O") ||
            (boxes[3] ==="O" && boxes[4] ==="O" && boxes[5] ==="O") ||
            (boxes[6] ==="O" && boxes[7] ==="O" && boxes[8] ==="O") ||
            (boxes[0] ==="O" && boxes[3] ==="O" && boxes[6] ==="O") ||
            (boxes[1] ==="O" && boxes[4] ==="O" && boxes[7] ==="O") ||
            (boxes[6] ==="O" && boxes[7] ==="O" && boxes[8] ==="O") ||
            (boxes[6] ==="O" && boxes[7] ==="O" && boxes[8] ==="O") ||
            (boxes[6] ==="O" && boxes[7] ==="O" && boxes[8] ==="O") ||
            (boxes[0] ==="O" && boxes[4] ==="O" && boxes[8] ==="O") ||
            (boxes[2] ==="O" && boxes[4] ==="O" && boxes[6] ==="O")
        ){
            setWinner("O")
        } else if (
            boxes[0] !== null &&
            boxes[1] !== null &&
            boxes[2] !== null &&
            boxes[3] !== null &&
            boxes[4] !== null &&
            boxes[5] !== null &&
            boxes[6] !== null &&
            boxes[7] !== null &&
            boxes[8] !== null
        ) {
            setWinner("T")
        }
    }, [boxes, state]);

    return (
        <React.Fragment>
            <Grid container justify={'center'}>
                <Grid item xs={12} style={{padding:24,paddingBottom:36, textAlign:'center', height:16, fontSize:24, fontWeight:'bold'}}>
                    {winner !== "" && `${winner === "X" ? "You win! Congratulations!" : winner === "O" ? "You Lose! Good luck next time!" : "It's a Tie"}`}
                </Grid>

            <Grid container style={{maxWidth:460}}>

            {boxes.map((box, index) => (
                <Grid item xs={4} style={{height:154, padding:8}}>
                    <Button style={{height:'100%', width:'100%', margin:0, border:'1px solid grey',

                    }}
                            disabled={loading || box === "X" || box === "O" || winner !== ""}
                    onClick={()=> {
                        let clone = [...boxes];
                        clone[index] = "X";
                        let arr = '';
                        clone.map(item => {
                            if (item === "X"){
                                arr = arr + "X"
                            } else if (item === "O"){
                                arr = arr + "O"
                            } else {
                                arr = arr + "-"
                            }
                        });
                        setBoxes(clone);
                        nextMove(arr, clone);
                    }}
                    >
                        <Typography style={{fontSize:60, fontWeight:'bold'}}>{box}</Typography>
                        {/*{index === 4 && <div style={{width:460, position:'absolute', height:3,borderRadius:4, backgroundColor:'black'}}></div>}*/}
                    </Button>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Grid container style={{padding:8}}>
                    <Button variant={'outlined'} color={'primary'} onClick={newGame}>New Game</Button>
                </Grid>
            </Grid>
        </Grid>
            </Grid>
        </React.Fragment>
    )
}