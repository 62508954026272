import makeStyles from "@material-ui/core/styles/makeStyles";

export const DmeProviderRegionsStyle = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: 'center'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: 200,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});