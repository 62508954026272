import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 1428,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    header: {
        backgroundColor:'rgb(230,240,243)',

    },
    status: {
        padding: 8,
        display:'flex',
        fontSize:15
    },
    bold: {
        fontWeight: "bold",
    },
}));

const AccordionCSS = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

export const CustomAccordion = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AccordionCSS>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.header}
                >
                    <Typography className={classes.heading}>Order Status Updates</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {props.history?.orderHistory && props.history.orderHistory.map((action, index)=> {
                            let description = action.description;
                            let row = description.split(":");
                            let message = "";
                            for (let i = 1; i< row.length; i++){
                                message = message + ":" + row[i];
                            }
                            return (
                                <div key={index} className={classes.status}>
                                    <span className={classes.bold}>{`${row[0]} `}</span>{message}
                                </div>
                            )
                        })}
                    </Typography>
                </AccordionDetails>
            </AccordionCSS>
            <AccordionCSS>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.header}
                >
                    <Typography className={classes.heading}>Notes History</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {props.history?.noteHistory && props.history.noteHistory.map((action, index)=> {
                            let description = action.description;
                            let row = description.split(":");
                            return (
                                <div key={index} className={classes.status}>
                                    <span className={classes.bold}>{row[0]}</span>{ row[2] ? `: ${row[1]}:${row[2]}` :`: ${row[1]}`}
                                </div>
                            )
                        })}
                    </Typography>
                </AccordionDetails>
            </AccordionCSS>
            <AccordionCSS>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                    className={classes.header}
                >
                    <Typography className={classes.heading}>Order Modification History</Typography>
                </AccordionSummary>
                <AccordionDetails style={{boxShadow:'none'}}>
                    <Typography>
                        {props.history?.itemHistory && props.history.itemHistory.map((action, index)=> {
                            let description = action.description;
                            let row = description.split(":");
                            return (
                                <div key={index} className={classes.status}>
                                    <span className={classes.bold}>{row[0]}</span>{ row[2] ? `: ${row[1]}:${row[2]}` :`: ${row[1]}`}
                                </div>
                            )
                        })}
                    </Typography>
                </AccordionDetails>
            </AccordionCSS>
        </div>
    );
};