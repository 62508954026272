import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {componentTitles, sectionTitles} from "../../../constants/names/titles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../constants/inputs/tooltips";
import CancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import {HospiceProviderStyle} from "./HospiceProviderIndex.style";
import {HospiceGeneralInformations} from "./components/HospiceGeneralInformations";
import {HospiceProviderResources} from "./components/HospiceProviderResources";
import {HospiceProviderFinal} from "./components/HospiceProviderFinal";
import {userNavigation} from "../../../constants/types/actionTypes";
import {inputType} from "../../../constants/types/inputType";
import moment from "moment";
import _ from "lodash";
import {useSnackbar} from "notistack";
import {endpoints} from "../../../constants/endpoints";
import {
    getOrgData,
    getRegionData,
    getRegions,
    processHospice,
    updateThirdStep,
    saveRegion
} from "./HospiceProviderHelper";
import {regionsSet} from "../../../common/regions/RegionHelper";

export const HospiceProviderIndex = ({edit = false}) => {
    const [regionList, setRegionList] = useState([]);
    const isLoggedIn = useSelector(state => state.isLogged);
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(edit === true);
    const [updatingField, setUpdatingField] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [hospiceId, setHospiceId] = useState("");
    const [steps, setSteps] = useState([sectionTitles.generalInformation, sectionTitles.regions, sectionTitles.contacts + " & " + sectionTitles.links]);
    const token = JSON.parse(localStorage.getItem('key'));
    const [hospice, setHospice] = useState({
        hospice_name: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        state: "",
        status: true,
        profit: true,
        phone: "",
        second_phone: "",
        fiscal_year: "",
        pbm_link: "",
        medical_supplies_vendor_link: ""
    });
    const [facilities, setFacilities] = useState([]);
    const [contacts, setContacts] = useState({
        it_contact: [{
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
        }]
    });
    const [selectedRegionIndex, setSelectedRegionIndex] = useState(0);
    const [regions, setRegions] = useState([]);
    const [newRegionTeam, setNewRegionTeam] = useState("");
    const [newFacility, setNewFacility] = useState("");
    const [firstStep, setFirstStep] = useState(false);
    const [secondStep, setSecondStep] = useState(false);
    const [selectedRegionData, setSelectedRegionData] = useState({
        name: "",
        region_phone: "",
        billing_contact_region: [{
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
        }],
        address_region: [{
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
        }],
        teams: [],
    });
    const [missingFields, setMissingFields] = useState({
        hospice_name: false,
        address1: false,
        zip: false,
        city: false,
        state: false,
        phone: false,
        fiscal_year: false,
    });
    const [validFields, setValidFields] = useState({
        hospice_name: true,
        address1: true,
        zip: true,
        city: true,
        state: true,
        phone: true,
        fiscal_year: true,
    });

    const getZipRelatedData = async (payload, setterType, position) => {
        if (setterType === "hospice") {
            if (payload.length === 5 && hospice.city?.length < 1) {
                setHospice({...hospice, zip: payload});
                const target = endpoints.zipCode + payload;
                const token = JSON.parse(localStorage.getItem('key'));
                const getZipPromise = await fetch(target, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const getZipResponse = await getZipPromise.json();

                setHospice({...hospice, city: getZipResponse.city, state: getZipResponse.state, zip: payload});
                setMissingFields({...missingFields, city: false, state: false, zip: false})

            } else if (payload.length < 6) {
                setHospice({...hospice, zip: payload})
                setMissingFields({...missingFields, zip: payload.length === 0})
            }
        }
        if (setterType === "regionAddress") {
            if (payload.length === 5 && selectedRegionData.address_region[position].city.length < 1 && selectedRegionData.address_region[position].state.length < 1) {
                const target = endpoints.zipCode + payload;
                const token = JSON.parse(localStorage.getItem('key'));
                const getZipPromise = await fetch(target, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const getZipResponse = await getZipPromise.json();
                let clone = _.cloneDeep(selectedRegionData);
                clone.address_region[position].city = getZipResponse.city;
                clone.address_region[position].state = getZipResponse.state;
                clone.address_region[position].zip = payload;
                setSelectedRegionData(clone);
            }
        }
    };

    const handleInput = async (e) => {
        let clone;
        const {name} = e.target;
        switch (e.inputEventType) {
            case inputType.required:
                switch (name) {
                    case "hospice_name":
                    case "address1":
                    case "city":
                    case "state":
                    case "phone":
                    case "fiscal_year":
                        setHospice({...hospice, [name]: e.target.value});
                        setMissingFields({...missingFields, [name]: e.target.length === 0});
                        setIsModified(true);
                        break;
                    case "zip":
                        getZipRelatedData(e.target.value, "hospice");
                        setIsModified(true);
                        break;
                    // default:
                    //     console.log("Case NOT treated: [", name, "]")
                    //     break;
                }
                break;

            case inputType.standard:
                if (activeStep === 0 || (activeStep === 1 && selectedRegionIndex === regions.length)) {
                    setIsModified(true);
                }
                switch (name) {
                    case "address2":
                    case "second_phone":
                    case "start_hour":
                    case "end_hour":
                    case "timezone":
                        setHospice({...hospice, [name]: e.target.value});
                        setIsModified(true);
                        break;
                    case "profit":
                    case "status":
                        setHospice({...hospice, [name]: e.target.checked});
                        setIsModified(true);
                        break;
                    case "medical_supplies_vendor_link":
                    case "pbm_link":
                        setHospice({...hospice, [name]: e.target.value});
                        break;
                    case "providerStatus":
                        // handleStatus();
                        break;
                    case "it_contact_first_name":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].first_name = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_last_name":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].last_name = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_email":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].email = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_phone_number":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].phone = e.target.value;
                        setContacts(clone);
                        break;
                    case "tableMovement":
                        setLoading(true);
                        if (e.target.value < regions.length) {
                            setSelectedRegionIndex(regions[e.target.value].region_id);
                            await getRegionData(setSelectedRegionData, regions[e.target.value].region_id, hospiceId);
                            setLoading(false);
                        } else {
                            setSelectedRegionData({
                                name: "",
                                region_phone: "",
                                billing_contact_region: [{
                                    first_name: "",
                                    last_name: "",
                                    email: "",
                                    phone: "",
                                }],
                                address_region: [{
                                    address1: "",
                                    address2: "",
                                    city: "",
                                    state: "",
                                    zip: "",
                                }],
                                teams: [],
                            });
                            setLoading(false);
                            setSelectedRegionIndex(regions.length);
                        }
                        break;
                    case "regionName":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.region_id = e.value;
                            clone.name = e.label;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "regionPhoneNumber":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.region_phone = e.target.value;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "team":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.teams[e.position] = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "newTeam":
                        setNewRegionTeam(e.target.value);
                        break;
                    case "newFacility":
                        setNewFacility(e.target.value);
                        break;
                    case "region_address1":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].address1 = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_address2":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].address2 = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_city":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].city = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_state":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].state = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_zip":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].zip = e.target.value;
                        setSelectedRegionData(clone);
                        getZipRelatedData(e.target.value, "regionAddress", e.position);
                        break;
                    case "billingFirstName":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].first_name = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingLastName":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].last_name = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingEmail":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].email = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingRegionPhone":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].phone = e.target.value;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "addNewTeam":
                        if (!selectedRegionData.teams.includes(newRegionTeam)) {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.teams = [...clone.teams, newRegionTeam];
                            setSelectedRegionData(clone);
                            setNewRegionTeam("");
                        } else {
                            enqueueSnackbar("Team already exists.", {variant: "info"});
                        }
                        break;
                    case "clearNewTeam":
                        setNewRegionTeam("");
                        break;
                    case "clearNewFacility":
                        setNewFacility("");
                        break;
                    case "deleteTeam":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.teams.splice(e.position, 1);
                        setSelectedRegionData(clone);
                        break;
                    case "deleteFacility":
                        clone = _.cloneDeep(facilities);
                        clone.splice(e.position, 1);
                        setFacilities(clone);
                        break;
                    // default:
                    //     console.log("Case NOT treated: [", name, "]");
                    //     break;
                }
                break;
            case inputType.editOnBlur:
                if (e.type === "change") {
                    switch (name) {
                        case "team":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.teams[e.position].team_name = e.target.value;
                            setSelectedRegionData(clone);
                            break;
                        case "facility":
                            clone = _.cloneDeep(facilities);
                            clone[e.position].name = e.target.value;
                            setFacilities(clone);
                            break;
                        case "region_zip":
                            await getZipRelatedData(e.target.value, "regionAddress", e.position);
                            break;
                        case "billingFirstName":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].first_name = e.target.value;
                            setSelectedRegionData(clone);
                            break;
                        case "billingLastName":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].last_name = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        case "billingRegionPhone":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].phone = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        case "billingEmail":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].email = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        // default:
                        //     console.log("Case NOT treated: [", name, "]");
                        //     break;
                    }
                }
                if (e.type === "blur") {
                    switch (name) {
                        case "team":
                            const editTeam = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateHospiceTeam, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        teamName: selectedRegionData.teams[e.position].team_name,
                                        teamId: selectedRegionData.teams[e.position].team_id,
                                        regionId: selectedRegionData.region_id,
                                        hospiceId: hospiceId,
                                    })
                                })
                                let response = await promise.json()
                                if (response.status === "success") {
                                    enqueueSnackbar("Updated region team successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update team", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                            }
                            if(_.findIndex(selectedRegionData.teams, {"team_name":e.target.value}) === -1) {
                                editTeam();
                            } else {
                                setUpdatingField(true);
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                                enqueueSnackbar(`Team already exists`, {variant:"info"})
                            }
                            break;
                        case "facility":
                            const editFacility = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateHospiceFacility, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        facilityName: facilities[e.position].name,
                                        facilityId: facilities[e.position].facility_id,
                                        hospiceId: hospiceId
                                    })
                                })
                                let response = await promise.json()
                                if (response.status === "success") {
                                    enqueueSnackbar("Updated facility successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update warehouse", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                            }
                            if(_.findIndex(facilities, {"name":e.target.value}) === -1) {
                                editFacility();
                            } else {
                                setUpdatingField(true);
                                await getOrgData(setHospice, hospiceId, setContacts, contacts, setLoading, setFacilities);
                                setUpdatingField(false);
                                enqueueSnackbar(`Facility already exists`, {variant:"info"})
                            }
                            break;
                        case "medical_supplies_vendor_link":
                        case "pbm_link":
                        case "it_contact_first_name":
                        case "it_contact_last_name":
                        case "it_contact_email":
                        case "it_contact_phone_number":
                            const editItContact = async () => {
                                setUpdatingField(true);
                                await updateThirdStep(contacts, hospice, hospiceId, enqueueSnackbar);
                                await getOrgData(setHospice, hospiceId, setContacts, contacts, setLoading, setFacilities);
                                setUpdatingField(false);
                            }
                            if (name === 'it_contact_phone_number' && e.target.value.target.value.length !== 14){
                                //none
                            } else {
                                editItContact();
                            }
                            break
                        case "regionPhoneNumber":
                            const editRegionPhone = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.hospiceRegionPhone, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        hospiceId: hospiceId,
                                        phone: selectedRegionData.region_phone,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region phone successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update region", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                            }
                            editRegionPhone();
                            break;
                        case "region_address1":
                        case "region_address2":
                        case "region_city":
                        case "region_state":
                        case "region_zip":
                            const editRegionAddress = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.hospiceRegionAddress, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        hospiceId: hospiceId,
                                        region: selectedRegionData,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region address successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update region address", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                            }
                            editRegionAddress();
                            break;
                        case "billingFirstName":
                        case "billingLastName":
                        case "billingRegionPhone":
                        case "billingEmail":
                            const editBillingContact = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.hospiceRegionBillingContact, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        hospiceId: hospiceId,
                                        region: selectedRegionData,
                                        hospice_name: hospice.hospice_name,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region billing contact successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update billing contact", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                setUpdatingField(false);
                            }
                            editBillingContact();
                            break;
                        default:
                            break;
                    }
                }
                break;

            case inputType.buttonAction:
                switch (name) {
                    case "addNewTeam":
                        const addRegionTeam = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.addOrUpdateHospiceTeam, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({
                                    teamName: newRegionTeam,
                                    regionId: selectedRegionData.region_id,
                                    hospiceId: hospiceId
                                })
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                enqueueSnackbar("Added team successfully.", {variant: "success"});
                                setNewRegionTeam("");
                            } else {
                                enqueueSnackbar("Could not team", {variant: "warning"});
                            }
                            await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                            setUpdatingField(false);
                        }
                        if(_.findIndex(selectedRegionData.teams, {"team_name":newRegionTeam}) === -1)
                            addRegionTeam();
                        else {
                            enqueueSnackbar("Team already exists", {variant: "info"});
                        }
                        break;
                    case "deleteTeam":
                        const removeRegionTeam = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.deleteHospiceTeam, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({
                                    teamId: selectedRegionData.teams[e.position].team_id,
                                    regionId: selectedRegionData.region_id
                                })
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                                enqueueSnackbar("Removed team successfully.", {variant: "success"});
                                setNewRegionTeam("");
                            } else {
                                enqueueSnackbar("Could not remove team", {variant: "warning"});
                            }
                            await getRegionData(setSelectedRegionData, selectedRegionData.region_id, hospiceId);
                            setUpdatingField(false);
                        }
                        removeRegionTeam()
                        break;
                    case "addNewFacility":
                        const addFacility = async () => {
                            setUpdatingField(true);
                            let checkExiting = [];
                            facilities.map(item => {
                                checkExiting.push(item.name)
                            });
                            if (!checkExiting.includes(newFacility)) {
                                let promise = await fetch(endpoints.addOrUpdateHospiceFacility, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({facilityName: newFacility, hospiceId: hospiceId})
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    await getOrgData(setHospice, hospiceId, setContacts, contacts, setLoading, setFacilities)
                                    enqueueSnackbar("Added facility successfully.", {variant: "success"});
                                    setNewFacility("");
                                } else {
                                    enqueueSnackbar("Could not facility", {variant: "warning"});
                                }
                            }
                            setUpdatingField(false);
                        }
                        if(_.findIndex(facilities, {"name":newFacility}) === -1)
                            addFacility();
                        else {
                            enqueueSnackbar("Facility already exists", {variant: "info"});
                        }
                        break;
                    case "deleteFacility":
                        const removeFacility = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.deleteHospiceFacility, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({
                                    facilityId: facilities[e.position].facility_id,
                                    hospiceId: hospiceId
                                })
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getOrgData(setHospice, hospiceId, setContacts, contacts, setLoading, setFacilities)
                                enqueueSnackbar("Removed facility successfully.", {variant: "success"});
                                setNewFacility("");
                            } else {
                                enqueueSnackbar("Could not remove facility", {variant: "warning"});
                            }
                            setUpdatingField(false);
                        }
                        removeFacility()
                        break;
                    case "saveRegion":
                        const saveRegionFunction = async () => {
                            setLoading(true);
                            await saveRegion(setSuccessSave, hospice, hospiceId, selectedRegionData, enqueueSnackbar);
                            await getAllRegionsList();
                            await getRegions(setRegions, hospiceId);
                            setLoading(false);
                        };
                        saveRegionFunction();
                        break;
                }
                break;
            // default:
            //     console.log("Case NOT treated: [", name, "]");
            //     break;
        }

    };
    useEffect(()=> {
        if (regions.length > 0){
            getRegionData(setSelectedRegionData, regions[regions.length-1].region_id, hospiceId);
        }
    },[regions]);

    const renderCancelButton = () => {
        if (isModified && activeStep < steps.length - 1) {
            return (
                <Grid item>
                    <CancelActionAlert
                        flag={cancellingAlertFlags.newPatient}
                        CSS={classes.button}
                        alertLevel={alertLevel.warn}
                        target={"/admin"}
                    />
                </Grid>
            )
        }
        if (!isModified && activeStep < steps.length - 1) {
            return (
                <Grid item>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => {
                            history.push('/admin')
                        }} className={classes.button}>
                        Cancel
                    </Button>
                </Grid>
            )
        } else {
            return null;
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <HospiceGeneralInformations
                    handleInput={handleInput}
                    validType={validFields}
                    missingValues={missingFields}
                    hospice={hospice}
                    setMissingValues={setMissingFields}
                    hospiceId={hospiceId}
                    loading={loading}
                />
            case 1:
                return <HospiceProviderResources
                    selectedRegionData={selectedRegionData}
                    regionIdentifiers={regions}
                    newRegionTeam={newRegionTeam}
                    newFacility={newFacility}
                    facilities={facilities}
                    handleInput={handleInput}
                    loading={loading}
                    updatingField={updatingField}
                    regionList={regionList}
                />
            case 2:
                return <HospiceProviderFinal
                    handleInput={handleInput}
                    hospice={hospice}
                    contacts={contacts}
                    loading={loading}
                    updatingField={updatingField}
                />
        }
    }

    const handleStepChange = (movement) => {
        if (movement === userNavigation.next) {
            switch (activeStep) {
                case 0:
                    setMissingFields({
                        hospice_name: hospice.hospice_name.length === 0,
                        address1: hospice.address1.length === 0,
                        city: hospice.city.length === 0,
                        state: hospice.state.length === 0,
                        zip: hospice.zip.length === 0,
                        phone: hospice.phone.length < 10,
                        fiscal_year: hospice.fiscal_year.length === 0,

                    });
                    if (hospice.hospice_name.length > 0 &&
                        hospice.address1.length > 0 &&
                        hospice.city.length > 0 &&
                        hospice.state.length > 0 &&
                        hospice.zip.length > 0 &&
                        hospice.phone.length === 10 &&
                        hospice.fiscal_year.length > 0
                    ) {
                        const create = async () => {
                            await processHospice(setHospiceId, hospice, "create", hospiceId, enqueueSnackbar, setIsModified, history);
                            setActiveStep(activeStep + 1);
                        };
                        const editHospice = async () => {
                            await processHospice(setHospiceId, hospice, "edit", hospiceId, enqueueSnackbar, setIsModified, history);
                            setActiveStep(activeStep + 1);
                        }
                        if (edit === false) {
                            create();
                        } else if (edit === true) {
                            if (isModified === true) {
                                editHospice();
                            } else {
                                setActiveStep(activeStep + 1);
                            }
                        }
                    }
                    break;
                case 1:
                    setActiveStep(activeStep + 1);
                    break;
                case 2:
                    setActiveStep(activeStep + 1);
                    break;
            }
        } else {
            setActiveStep(activeStep - 1);
        }
    }

    useEffect(() => {
        if (activeStep === 1) {
            getRegions(setRegions, hospiceId);
        }
    }, [activeStep, hospiceId]);

    async function getAllRegionData() {
        if (selectedRegionIndex === 0 && regions.length > 0) {
            await getRegionData(setSelectedRegionData, regions[0].region_id, hospiceId);
            setLoading(false)
        } else if (selectedRegionIndex === 0 && edit !== true) {
            setLoading(false)
        }
    }

    useEffect(() => {

        getAllRegionData();
    }, [regions]);

    const getAllRegionsList = async () => {
         const token = JSON.parse(localStorage.getItem('key'));
         const response = await fetch(endpoints.regions, {
             method: 'get',
             headers: {
               'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            }
         });
        const {status, data} = await response.json();
        if (status === "success") {
         setRegionList(data.map(item => {
              return {value: item.region_id, label: item.name}
                  }))
            }
        };
    useEffect(() => {
        getAllRegionsList();
     }, []);

    useEffect(() => {
        if (hospice.hospice_name.length > 0 &&
            hospice.address1.length > 0 &&
            hospice.city?.length > 0 &&
            hospice.state.length > 0 &&
            hospice.zip.length > 0 &&
            hospice.phone.length === 10) {
            setFirstStep(true)
        } else {
            setFirstStep(false)
        }
    }, [hospice]);

    useEffect(() => {
        if (edit === true) {
            let urlParameters = window.location.href.split('/');
            setHospiceId(urlParameters[urlParameters.length - 1]);
            getOrgData(setHospice, urlParameters[urlParameters.length - 1], setContacts, contacts, setLoading, setFacilities)

        }
    }, []);

    return (
        <React.Fragment>
            {isLoggedIn ?
                <React.Fragment>
                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                                {edit ? componentTitles.editHospice : componentTitles.newHospice}
                            </Typography>
                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <React.Fragment>
                                {getStepContent(activeStep)}

                                <div className={classes.buttons}>
                                    {
                                        renderCancelButton()
                                    }
                                    {(activeStep !== 0 && activeStep < steps.length) && (
                                        <Button onClick={() => handleStepChange(userNavigation.back)}
                                                className={classes.button}>
                                            Back
                                        </Button>
                                    )}
                                    {activeStep === 0 && (
                                        <Tooltip
                                            title={firstStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                            arrow placement="bottom-end">
                                            <Button
                                                id={'next'}
                                                variant={firstStep === true || false ? "contained" : 'outlined'}
                                                color="primary"
                                                onClick={() => handleStepChange(userNavigation.next)}
                                                className={classes.button}
                                            >
                                                Next
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {activeStep === 1 && (
                                        <Tooltip
                                            title={secondStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                            arrow placement="bottom-end">
                                            <Button
                                                id={'next'}
                                                variant={"contained"}
                                                color="primary"
                                                onClick={() => handleStepChange(userNavigation.next)}
                                                className={classes.button}
                                            >
                                                {(activeStep === steps.length - 1 && edit) && buttonText.editHospice}
                                                {(activeStep === steps.length - 1 && !edit) && buttonText.createHospice}
                                                {activeStep !== steps.length - 1 && buttonText.next}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </div>
                            </React.Fragment>
                        </Paper>
                    </main>
                </React.Fragment>
                :
                <React.Fragment>
                    <h1>Please Log In</h1>
                </React.Fragment>

            }
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => (HospiceProviderStyle(theme)));
