import React from "react";
import Grid from "@material-ui/core/Grid";
import { OrderItem } from './orderItem/orderItem';
import {makeStyles} from "@material-ui/core/styles";

export const SelectItems = ({user, items, order, setOrder, isPace}) => {
    const classes = useStyles();
    return (
            <div className={classes.container}>
                <div className={classes.paper}>
                    <Grid container justify={'flex-start'} spacing={1}>
                        {items.map(item =>
                            <OrderItem
                                isPace={isPace}
                                user={user}
                                item={item}
                                order={order}
                                setOrder={setOrder}
                            />
                        )}
                    </Grid>
                </div>
            </div>
    )
};

const useStyles = makeStyles((theme) => ({
    container: {
        height:'50vh',
        overflowY:'scroll',
    },
    paper: {
        paddingTop:'12px',
        display:'flex',
    },
}));
