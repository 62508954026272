import React, {useEffect, useState} from "react";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../constants/names/titles";
import MaterialTable from "material-table";
import {getAllManufacturers} from "../helpers/dataGetter";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

export const EditManufacturers = (props) => {

    const columns = [
        {title: 'ID', field: 'manufacturer_id', width: 20, editable:'never'},
        {title: tableColumnTitles.manufacturer, field: 'name', width: 800},
    ];

    const [manufacturers, setManufacturers] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllManufacturers(setManufacturers);
    }, []);

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.manufacturers}
                    </Typography>
                </Grid>
                <Grid item>
                    <MaterialTable
                        title={componentTitles.manufacturers}
                        columns={columns}
                        data={manufacturers}
                        options={{
                            pageSize: 10,
                        }}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.createManufacturer, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({manufacturer_name: newData.name})
                                    }).then(res => {
                                        if (res.status === status.created) {
                                            resolve();
                                            setManufacturers((prevState) => {
                                                const data = [...prevState];
                                                data.push(newData);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.updateManufacturer + oldData.name, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({manufacturer_name: newData.name})
                                    }).then(res => {
                                        if (res.status === status.success) {
                                            resolve();
                                            if (oldData) {
                                                setManufacturers((prevState) => {
                                                    const data = [...prevState];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return data;
                                                });
                                            }
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.deleteManufacturer + oldData.manufacturer_id, {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        }
                                    }).then(res => {
                                        if (res.status === status.nocontent) {
                                            resolve();
                                            setManufacturers((prevState) => {
                                                const data = [...prevState];
                                                data.splice(data.indexOf(oldData), 1);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                        }}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};