import React, {useEffect, useState} from 'react';
import './orders.css';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {componentTitles} from "../../constants/names/titles";
import {TabSearchBar} from "../../common/search/tabSearch/TabSearch";
import {Table} from "../../common/table/table";
import {useHistory} from "react-router-dom";
import {
    searchTechnicians,
    setDmeUserOrganizations,
} from "../../common/search/tabSearch/TabSearchHelper";
import {logout, setCriteriaOrders} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import {tableColumnTitles} from "../../constants/names/columnTitles";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../constants/inputs/tooltips";
import jwt from "jwt-decode";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import {alertTitles} from "../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {alertMessages} from "../../constants/alert/alertMessages";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {deliveryOrderStyle} from "./deliveryOrder/deliveryOrderStyle";
import _ from "lodash";
import {checkTokenAvailability} from "../../common/utils/utils";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {useSnackbar} from "notistack";

export default function Orders() {
    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {accessToken} = JSON.parse(localStorage.getItem("key"));
    const userData = jwt(accessToken);
    const isLogged = useSelector(state => state.isLogged);
    const [show, setShow] = useState(true);
    const [showActionModal, setShowActionModal] = useState(false);
    const [loading, setLoading] = useState(true);
    let searchValues = {
        status:undefined,
        type: undefined,
        criteria: undefined,
        technician: undefined,
        priority: undefined,
        role: undefined,
        organization: undefined,
        isActiveUser: undefined,
    }
    let rawFilters = localStorage.getItem("order-filters");
    if (rawFilters){
        let values = JSON.parse(rawFilters);
        searchValues.status = values.status ? values.status : undefined;
        searchValues.type = values.type ? values.type : undefined;
        searchValues.criteria = values.criteria ? values.criteria : undefined;
        searchValues.technician = values.technician ? values.technician : undefined;
        searchValues.priority = values.priority ? values.priority : undefined;
        searchValues.role = values.role ? values.role : undefined;
        searchValues.organization = values.organization ? values.organization : undefined;
    }
    const [dmeUsersFiltersValue, setDmeUsersFiltersValue] = useState(searchValues);
    const [orders, setOrders] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [orderStatus, setOrderStatus] = useState('');
    const [allOrders, setAllOrders] = useState([]);
    const priorityOptions = [{label:'All Priorities', value:"All Priorities"},{label:'Priority', value:"Priority"},{label:'Routine', value:"Routine"}];
    const typeOptions = [{label:'All Types', value:"All Types"},{label:'Delivery', value:"Delivery"},{label:'Pickup', value:"Pickup"},{label:'Exchange', value:"Exchange"}];
    const statusOptions = [{label:'All Statuses', value:"All Statuses"},{label:'Open', value:"Open"},{label:'Processing', value:"Processing"},{label:'Completed', value:"Completed"},{label:'Canceled', value:"Canceled"},{label:'Pending', value:"Pending"}, {label:'Rejected', value:"Rejected"}];
    const [technicianOptions, setTechnicianOptions] = useState([]);
    let filterOptions = [
        { name: "status", placeholder: "Status", options: statusOptions, value: dmeUsersFiltersValue.status, isSelect: true, isClearable: true},
        { name: "type", placeholder: "Type", options: typeOptions, value: dmeUsersFiltersValue.type, isSelect: true},
        { name: "priority", placeholder: "Priority", options: priorityOptions, value: dmeUsersFiltersValue.priority, isSelect: true},
        { name: "technician", placeholder: "DME Technicians", options: technicianOptions, value: dmeUsersFiltersValue.technician, isSelect: true},
        { name: "role", placeholder: "Facility", options: roleOptions, value: dmeUsersFiltersValue.role, isSelect: true},
        // { name: "hospice", placeholder: "Hospice", options: roleOptions, value: dmeUsersFiltersValue.role, isSelect: true},
    ];
    if (userData.payload.type === "Hospice"){
       filterOptions.splice(3,1)
    }

    const handleDeleteCriteria = (chip) => {
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, criteria: dmeUsersFiltersValue.criteria.filter(c => chip !== c)});
    };

    function handleFilterUpdate(event){
        localStorage.setItem('order-filters', JSON.stringify({...dmeUsersFiltersValue, [event.target.name]: event.target.value}));
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        // event.persist();
        // if (event.keyCode === 13 && dmeUsersFiltersValue.input) {
        //     if (!dmeUsersFiltersValue.criteria.includes(dmeUsersFiltersValue.input)) {
        //         if (dmeUsersFiltersValue.criteria[0]===('All')){
        //             setDmeUsersFiltersValue({...dmeUsersFiltersValue,criteria:[dmeUsersFiltersValue.input], input:''})
        //             // searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
        //             setShow(false)
        //         } else {
        //             setDmeUsersFiltersValue({...dmeUsersFiltersValue, criteria:[...dmeUsersFiltersValue.criteria, dmeUsersFiltersValue.input], input: ''})
        //             // searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
        //             setShow(false)
        //         }
        //     }
        // }
    };
    const handleInputChange = (value) => {
        if (value && value.length < 2 && dmeUsersFiltersValue.input === ""){
            setDmeUsersFiltersValue( {
                input:value,
                criteria:['All'],
                role: "All",
                organization: "All",
                isActiveUser: true,
            })
        } else {
            setDmeUsersFiltersValue({...dmeUsersFiltersValue, input:value})
        }
    };

    const clearFilters=()=>{
        setDmeUsersFiltersValue({
            input:'',
            criteria:['All'],
            role: "All",
            organization: "All",
            isActiveUser: true,
        });
        dispatch(setCriteriaOrders(dmeUsersFiltersValue));
        localStorage.setItem('order-filters', JSON.stringify({
            input:'',
            criteria:['All'],
            role: "All",
            organization: "All",
            isActiveUser: true,
        }));
        setShow(false)

    };
    useEffect(() => {
        //Call query function
        dispatch(setCriteriaOrders(dmeUsersFiltersValue));
        if (dmeUsersFiltersValue.status || dmeUsersFiltersValue.input){
            setAllOrders(orders);
        }
    }, [dmeUsersFiltersValue, orders]);
    useEffect(() => {
        setShow(true)
    }, [show]);


    const getOrders = (params) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllOrders, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body:JSON.stringify({dme_organization_ids: params})
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                response.data.map(order => {
                    if (order.delivery_order_id){
                        order.order_id = "D100" + order.delivery_order_id
                    } else if (order.pickup_order_id){
                        order.order_id = "P200" + order.pickup_order_id
                    }else if (order.exchange_order_id){
                        order.order_id = "S300" + order.exchange_order_id
                    }
                });
                setOrders(response.data);
                setLoading(false)
            })
            .catch(err => {
                setLoading(false);
                console.log('error', err)
            })
    };

    const getContractedProviders = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${userData.payload.hospice_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length === 1){
                    getOrders([response.data[0].dme_id]);
                } else {
                    let arr = [];
                    response.data.map(item => {
                        arr.push(item.dme_id);
                    });
                    getOrders([arr]);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    useEffect(() => {
        setDmeUserOrganizations(setOrgOptions);
        if (userData.payload.type !== "Hospice"){
            searchTechnicians(setTechnicianOptions);
        }

        if(userData.payload.hospice_organization_id !== null) {
            getContractedProviders();
        } else {
            if (orders.length===0){
                getOrders([userData.payload.dme_organization_id]);
            }
        }
    }, []);

    const actionModalCloseHandler = () => {
        setShowActionModal(false)
    };
    const addDeliveryHistory = (order_id, description ) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addDeliveryOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history:[{
                    description:description,
                }],
                deliveryOrderId:order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const addPickupHistory = (order_id, description ) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addPickupOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history:[{
                    description:description,
                }],
                pickupOrderId:order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const addExchangeHistory = (order_id, description ) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addExchangeOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history:[{
                    description:description,
                }],
                exchangeOrderId:order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const changeOrderStatus = () => {
        let userType = localStorage.getItem('key');
        userType= JSON.parse(userType);
        let user = jwt(userType.accessToken);
        if (orderStatus.type === "Delivery") {
            const status = {
                delivery_order_id: orderStatus.delivery_order_id,
                status: orderStatus.status
            };
            const token = JSON.parse(localStorage.getItem('key'));
            (async () => {
                const availableToken = await checkTokenAvailability();
                if(!availableToken){
                    dispatch(logout());
                    history.push('/login');
                }
            })();
                fetch(endpoints.updateDeliveryOrderStatus, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify(status)
                })
                    .then(response => {
                        if (response.status ===200) {
                            setShowActionModal(false);
                            let orderClone = _.cloneDeep(orders);
                            let index = _.findIndex(orderClone, {delivery_order_id:orderStatus.delivery_order_id });
                            orderClone[index].status=orderStatus.status;
                            setOrders(orderClone);
                            addDeliveryHistory(orderStatus.delivery_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                            enqueueSnackbar("Status updated with success.", {
                                persist: false,
                                variant: "success",
                            });
                        }
                    })
                    .catch(err => console.log('error', err))
        } else if (orderStatus.type === "Pickup"){
            const status = {
                pickup_order_id: orderStatus.pickup_order_id,
                status: orderStatus.status
            };
            const token = JSON.parse(localStorage.getItem('key'));
            (async () => {
                const availableToken = await checkTokenAvailability();
                if(!availableToken){
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            fetch(endpoints.updatePickupOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(status)
            })
                .then(response => {
                    if (response.status ===200) {
                        setShowActionModal(false);
                        let orderClone = _.cloneDeep(orders);
                        let index = _.findIndex(orderClone, {pickup_order_id:orderStatus.pickup_order_id });
                        orderClone[index].status=orderStatus.status;
                        setOrders(orderClone)
                        addPickupHistory(orderStatus.pickup_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                        enqueueSnackbar("Status updated with success.", {
                            persist: false,
                            variant: "success",
                        });
                    }
                })
                .catch(err => console.log('error', err))
        } else if (orderStatus.type === "Exchange"){
        const status = {
            exchange_order_id: orderStatus.exchange_order_id,
            status: orderStatus.status
        };
        const token = JSON.parse(localStorage.getItem('key'));
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.updateExchangeOrderStatus, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(status)
        })
            .then(response => {
                if (response.status ===200) {
                    setShowActionModal(false);
                    let orderClone = _.cloneDeep(orders);
                    let index = _.findIndex(orderClone, {exchange_order_id:orderStatus.exchange_order_id });
                    orderClone[index].status=orderStatus.status;
                    setOrders(orderClone)
                    addExchangeHistory(orderStatus.exchange_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                    enqueueSnackbar("Status updated with success.", {
                        persist: false,
                        variant: "success",
                    });
                }
            })
            .catch(err => console.log('error', err))
    }
        setShow(false)
    };

    const columns = [
        {title: tableColumnTitles.orderType, field: 'type', width: 100, sorting: false, defaultFilter:dmeUsersFiltersValue.type === "All Types" ? undefined : dmeUsersFiltersValue.type,
            headerStyle:{
                textAlign: 'center',
                fontWeight:'bold',
            },},
        {title: tableColumnTitles.orderId, field: 'delivery_order_id', grouping:false, width: 160, sorting: false, disableClick:true,
            headerStyle:{
                textAlign: 'center'
            },
            render:rowData =>  <div>
                { rowData.delivery_order_id && <div style={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
                    <span
                        style={{cursor:'pointer'}}
                        onClick={()=> history.push(`order/delivery/${rowData.delivery_order_id}`)}
                    >
                        {rowData.delivery_order_id && "D100"+ rowData.delivery_order_id}
                    </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/delivery/${rowData.delivery_order_id}`} target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize:18}}/>
                        </a>
                    </Tooltip>
                </div>}

                { rowData.pickup_order_id && <div style={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
                <span
                    style={{cursor:'pointer'}}
                    onClick={()=> history.push(`order/pickup/${rowData.pickup_order_id}`)}
                >
                    P200{rowData.pickup_order_id}
                </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/pickup/${rowData.delivery_order_id}`} target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize:18}}/>
                        </a>
                    </Tooltip>
                </div>}

                { rowData.exchange_order_id && <div style={{display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
                <span
                    style={{cursor:'pointer'}}
                    onClick={()=> history.push(`order/exchange/${rowData.exchange_order_id}`)}
                >
                    S300{rowData.exchange_order_id}
                </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/exchange/${rowData.exchange_order_id}`} target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize:18}}/>
                        </a>
                    </Tooltip>
                </div>}

            </div>
        },
        {title: tableColumnTitles.priority, field: 'priority_code', width: 100,defaultFilter:dmeUsersFiltersValue.priority === "All Priorities" ? undefined : dmeUsersFiltersValue.priority,
            headerStyle:{
                textAlign: 'center',
                fontWeight:'bold'
            },
        },
        {title: tableColumnTitles.patient, field: 'last_name', width: 300, grouping:false,
            headerStyle:{
                textAlign: 'center'
            },
            render:rowData =>                  <div>
                <span>{`${rowData.first_name} ${rowData.last_name}`}</span><br/>
                <span style={{fontSize: 12, color:'rgba(117, 117, 117, 1)'}}>{`${rowData.address1} ${rowData.address2}, ${rowData.city}, ${rowData.state}, ${rowData.zip}`}</span>
            </div>
        },
        {
            title: tableColumnTitles.orderStatus, field: 'status',
            width: 100,defaultFilter:dmeUsersFiltersValue.status === "All Statuses" ? undefined : dmeUsersFiltersValue.status,
            headerStyle: {
                textAlign: 'left',
                fontWeight:'bold',
                padding: 0,
                margin: 0,
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {title: tableColumnTitles.requestedDate, field: `requested_date`, grouping:false, width: 150,defaultSort:'desc',
            headerStyle:{
                textAlign: 'center'
            },
            render:rowData => <div style={{display: "flex", justifyContent: "center", grouping:false,}}> {moment(rowData.requested_date).format(" MMM DD, YYYY")}  </div>},
        {title: tableColumnTitles.organization, field: 'hospice_name',grouping:false, width: 100,
            headerStyle:{
                textAlign: 'center'
            },
            render:rowData => <div style={{display: "flex", justifyContent: "center"}}> {rowData.hospice_name}  </div>
        },
        {title: tableColumnTitles.organization, field: 'order_id', hidden:true, searchable:true, defaultFilter:dmeUsersFiltersValue.input},
        {title: tableColumnTitles.organization, field: 'last_name', hidden:true, searchable:true},
        {title: tableColumnTitles.organization, field: 'zip', hidden:true, searchable:true},
        {title: tableColumnTitles.action, field: 'technician_name',  grouping:false, width: 50 ,defaultFilter:dmeUsersFiltersValue.technician === "All Technicians" ? undefined : dmeUsersFiltersValue.technician, disableClick:true, sorting:false,
            headerStyle:{
                textAlign: 'center'
            },
            render:rowData => <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                {
                    (rowData.status === 'Open') && (
                        <React.Fragment>
                            {
                                (checkPermissions([permissions.update_orders], "DME")) ? (
                                    <Tooltip title={tooltips.processOrder}>
                                        <Button style={{background:"rgba(123, 153, 181, 1)", width: 150, color : "white", border:'none'}} variant='outlined'  size='small' onClick={(e)=>{
                                            e.preventDefault();
                                            if (checkPermissions([permissions.update_orders], "DME")) {
                                                setOrderStatus({
                                                    status: 'Processing',
                                                    type: rowData.type,
                                                    delivery_order_id: rowData.delivery_order_id,
                                                    pickup_order_id: rowData.pickup_order_id,
                                                    exchange_order_id: rowData.exchange_order_id,
                                                });
                                                setShowActionModal(true)
                                            }
                                        }}>
                                            OPEN
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button disabled style={{background:"rgba(123, 153, 181, 1)", width: 150, color : "white", cursor:'initial',border:'none'}} variant='outlined'  size='small'>
                                        OPEN
                                    </Button>
                                )
                            }

                        </React.Fragment>
                    )}
                {
                    (rowData.status === 'Processing') &&  (
                        <React.Fragment>
                            {
                               checkPermissions([permissions.update_orders], "DME") ? (
                                   <Tooltip title={tooltips.completeOrder}>
                                       <Button variant='outlined' style={{background:"rgba(206, 193, 41, 1)", width: 150, color : "white", border:'none'}}   size='small' onClick={(e)=>{
                                           e.preventDefault();
                                           setOrderStatus({
                                               status:'Completed',
                                               type:rowData.type,
                                               delivery_order_id: rowData.delivery_order_id,
                                               pickup_order_id: rowData.pickup_order_id,
                                               exchange_order_id: rowData.exchange_order_id,
                                           });
                                           setShowActionModal(true)
                                           }}
                                       >
                                           PROCESSING
                                       </Button>
                                   </Tooltip>
                               ) : (
                                   <Button disabled  variant='outlined' style={{background:"rgba(206, 193, 41, 1)", width: 150, color : "white",  cursor:'initial', border:'none'}}  size='small'
                                   >
                                       PROCESSING
                                   </Button>
                               )
                            }

                        </React.Fragment>

                    )
                }
                {
                    (rowData.status === 'Completed') &&  (
                        <Tooltip title={tooltips.completed}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',background:"rgba(17, 124, 112, 1)", width: 150, height: 30, borderRadius: 4, color : "white", border:'none'}}>
                        <span>COMPLETED</span>
                    </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Pending') &&  (
                        <Tooltip title={tooltips.pending}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',background:"rgba(206, 193, 41, 1)", width: 150, height: 30, borderRadius: 4, color : "white", border:'none'}}>
                        <span>PENDING</span>
                    </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Canceled') &&  (
                        <Tooltip title={tooltips.completed}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center',background:"rgba(205, 29, 50, 1)", width: 150, height: 30, borderRadius: 4, color : "white", border:'none'}}>
                                <span>CANCELED</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Rejected') &&  (
                        <Tooltip title={tooltips.completed}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center',background:"rgba(205, 29, 50, 1)", width: 150, height: 30, borderRadius: 4, color : "white", border:'none'}}>
                                <span>REJECTED</span>
                            </div>
                        </Tooltip>
                    )
                }
            </div>
        },
    ];
    return (
        <React.Fragment>
            { (isLogged) ?
                <React.Fragment>
                    <Grid container className={classes.root} style={{maxWidth: "80%"}}>
                        <Grid item xs={12}>
                            <Typography className={classes.h1}>
                                {componentTitles.orders}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TabSearchBar
                                filtersValue={dmeUsersFiltersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputKeyup={handleInputKeyup}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                handleFilterUpdate={handleFilterUpdate}
                                searchPatients={()=>{
                                            setDmeUsersFiltersValue({
                                                input:dmeUsersFiltersValue.input,
                                                criteria:['All'],
                                                role: "All",
                                                organization: "All",
                                                isActiveUser: true,
                                            });

                                    setShow(false)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start", paddingBottom: 16}}>
                            {(checkPermissions([permissions.create_orders])) && (
                                <Button
                                    className={classes.button}
                                    //fullWidth
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={()=>{ history.push(`/patients/all`)}}
                                >
                                    Add New {buttonText.order}
                                </Button>)
                            }
                        </Grid>
                        {show && (
                            <Grid item xs={12} style={{paddingTop:12,width:'50vw'}}>
                                <Table
                                    isLoading={loading && dmeUsersFiltersValue.status}
                                    search={true}
                                    filtering={true}
                                    columns={columns}
                                    items={allOrders}
                                    searchText={dmeUsersFiltersValue.input}
                                    rowClick={true}
                                    customText={"Choose a status to retrieve orders"}
                                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                    textColor={"rgba(46, 50, 54, 1)"}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Dialog
                        open={showActionModal}
                        onClose={actionModalCloseHandler}
                    >
                        <DialogTitle>{alertTitles.changeStatus}</DialogTitle>
                        <DialogContent className={classes.dialog}>
                            {
                                (orderStatus.status === 'Processing') ? (
                                    <div className={classes.messages}>{alertMessages.changeNewOrderStatus}</div>
                                ) : (
                                    <div className={classes.messages}>{alertMessages.changeProcessingOrderStatus}</div>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={actionModalCloseHandler} variant="outlined" color="secondary">
                                {buttonText.cancel}
                            </Button>
                            <Button
                                onClick={changeOrderStatus}
                                variant="contained" color="primary" >
                                {buttonText.yes}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>: ''}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => (deliveryOrderStyle(theme)));
