import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHistory} from "react-router-dom";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import IconButton from "@material-ui/core/IconButton";
import {buttonTypes} from "../../../constants/alert/buttonTypes";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

// props
// confirmAction = func to be executed on confirm
// denyAction = func to be executed on deny
// type = type of button to display (options in getButtonType func)
// title = title of alert
// content = alert message, can display a simple string or an array of 1 to many strings.
// denyActionText = text to be displayed on deny button
// confirmActionText = text to be displayed on confirm button
// disableButton = boolean, disable or not the confirmation button
// buttonText the name of the button

export const AlertDialog = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    let history = useHistory();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        setOpen(false);
        if (reason !== 'backdropClick') {
            props.denyAction && props.denyAction();
        }

    };

    const handleConfirm = () => {
        setOpen(false);
        props.confirmAction && props.confirmAction();
    };

    const getButtonType = (type) => {
        switch (type) {
            case buttonTypes.standard:
                return (
                    <Button
                        variant="outlined"
                        color="default"
                        className={props.buttonCSS ? props.buttonCSS : classes.button}
                        onClick={handleOpen}
                    >
                        {props.buttonText}
                    </Button>
                );
            case buttonTypes.standardOutlinedPrimary:
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        className={props.buttonCSS ? props.buttonCSS : classes.button}
                        onClick={handleOpen}
                    >
                        {props.buttonText}
                    </Button>
                );
            case buttonTypes.standardContainedPrimary:
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        className={props.buttonCSS ? props.buttonCSS : classes.button}
                        onClick={handleOpen}
                        disabled={props.isLoading ? props.isLoading : false}
                    >
                        {props.buttonText}
                    </Button>
                );
            case buttonTypes.deleteIcon:
                return (
                    <Button
                        variant="outlined"
                        color="default"
                        className={props.buttonCSS ? props.buttonCSS : classes.button}
                        startIcon={<DeleteIcon/>}
                        onClick={handleOpen}
                    >
                        {props.buttonText}
                    </Button>
                );
            case buttonTypes.uploadIcon:
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        className={props.buttonCSS ? props.buttonCSS : classes.button}
                        startIcon={<CloudUploadIcon/>}
                        onClick={handleOpen}
                    >
                        {props.buttonText}
                    </Button>
                );
            case buttonTypes.removeIcon:
                return (
                    <IconButton onClick={props.confirmAction}>
                        <RemoveCircleIcon color="secondary" aria-label="Remove this item"/>
                    </IconButton>
                )
        }
    };

    return (
        <div>
            {getButtonType(props.type)}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                    <Grid container justify={"center"} alignItems={"flex-start"} alignContent={'flex-start'} direction={"column"} spacing={2}>
                        {
                            Array.isArray(props.content) ? (props.content.length > 1 ?
                                props.content.map((item, index) =>
                                    <Typography variant="subtitle1" key={index}>
                                        {item}
                                    </Typography>) :
                                <Typography variant="subtitle1">
                                    {props.content[0]}
                                </Typography>) : props.content
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            variant="contained"
                            color="secondary"
                            autoFocus
                            style={{textTransform: 'none'}}>
                        {props.denyActionText ? props.denyActionText : buttonText.negative}
                    </Button>
                    {props.confirmAction && props.confirmActionText && <Button onClick={handleConfirm}
                             variant="contained"
                             color="primary"
                             style={{textTransform: 'none'}}
                             disabled={props.disabledButton}>
                        {props.confirmActionText}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
};
