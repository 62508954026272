import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
import {labels} from "../../../../constants/inputs/labels";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {inputType} from "../../../../constants/types/inputType";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export const DmeProviderContacts= ({contacts, handleInput, loading, updatingField}) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Contacts
            </Typography>
            <div
                style={{justifyContent: "left", paddingLeft: '198px'}}
            >
                <div style={{width: '320px'}}>
                    <Typography style={{paddingTop: '30px', paddingBottom: '5px'}}
                                gutterBottom>
                        IT Contact
                    </Typography>
                    <div style={{display: 'flex'}}>
                        <TextField
                            label={labels.first_name}
                            id={`it_contact_first_name`}
                            name="it_contact_first_name"
                            fullWidth
                            disabled={updatingField}
                            placeholder={placeholders.addFirstName}
                            value={contacts.it_contact[0].first_name}
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                let event = {
                                    ...e,
                                    position: 0,
                                    inputEventType: inputType.standard
                                }
                                handleInput(event)
                            }}
                            onBlur={e => {
                                let event = {
                                    ...e,
                                    position: 0,
                                    inputEventType: inputType.editOnBlur
                                }
                                handleInput(event)
                            }}
                        />
                        <div style={{width: '40px'}}></div>
                        <TextField
                            label={labels.last_name}
                            id={`it_contact_last_name`}
                            name="it_contact_last_name"
                            fullWidth
                            disabled={updatingField}
                            placeholder={placeholders.addLastName}
                            value={contacts.it_contact[0].last_name}
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                let event = {
                                    ...e,
                                    position: 0,
                                    inputEventType: inputType.standard
                                }
                                handleInput(event)
                            }}
                            onBlur={e => {
                                let event = {
                                    ...e,
                                    position: 0,
                                    inputEventType: inputType.editOnBlur
                                }
                                handleInput(event)
                            }}
                        />
                    </div>
                </div>
                <div style={{width: '720px'}}>
                    <div style={{display: 'flex', paddingTop: '14px'}}>
                        <div style={{paddingTop: '20px', width: '260px'}}>

                            <TextField
                                label={labels.email}
                                id={`it_contact_email`}
                                name="it_contact_email"
                                fullWidth
                                disabled={updatingField}
                                placeholder={placeholders.email}
                                value={contacts.it_contact[0].email}
                                variant="outlined"
                                size="small"
                                onChange={e => {
                                    let event = {
                                        ...e,
                                        position: 0,
                                        inputEventType: inputType.standard

                                    }
                                    handleInput(event)
                                }}
                                onBlur={e => {
                                    let event = {
                                        ...e,
                                        position: 0,
                                        inputEventType: inputType.editOnBlur

                                    }
                                    handleInput(event)
                                }}
                            />
                        </div>
                        <div style={{width: '20px'}}></div>

                        <div style={{width: '140px'}}>
                            <InputLabel style={{
                                fontSize: '0.8em',
                                paddingBottom: '10px',
                                textAlign: 'left'
                            }}>Phone
                                number</InputLabel>
                            <PhoneInput
                                name="it_contact_phone_number"
                                country={'us'}
                                disableCountryCode={true}
                                onlyCountries={['us']}
                                placeholder={placeholders.phone}
                                disabled={updatingField}
                                value={contacts.it_contact[0].phone !== null ? contacts.it_contact[0].phone : ""}
                                onChange={(e) => {
                                    let event = {
                                        target: {
                                            name: "it_contact_phone_number",
                                            value: e,
                                        },
                                        position: 0,
                                        inputEventType: inputType.standard
                                    };
                                    handleInput(event)
                                }}
                                onBlur={(e) => {
                                    let event = {
                                        target: {
                                            name: "it_contact_phone_number",
                                            value: e,
                                        },
                                        type: "blur",
                                        position: 0,
                                        inputEventType: inputType.editOnBlur
                                    };
                                    handleInput(event)
                                }}
                                inputProps={{
                                    id:"it_contact_phone_number"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}