import React from 'react';
import PeopleIcon from '@material-ui/icons/People';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useHistory} from "react-router-dom";
import {sectionTitles} from "../../../constants/names/titles";
import {StyledMenu, StyledMenuItem, useStyles} from "./menu.style";
import {options} from "./menu.data";
import {checkPermissions} from "../../permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";

export const NavMenu = props => {
    let history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    return (
        <div>
            <div className={classes.button}
                 onClick={handleClick}
                 id='userMenu'
            >
                {props.type === 'patients' && (
                    <React.Fragment>
                        <PeopleIcon fontSize="inherit"/>
                        {sectionTitles.patients}
                    </React.Fragment>
                )}
                {props.type === 'user' && (
                    <React.Fragment>
                        {props.name}
                    </React.Fragment>
                )}
                <ExpandMoreIcon/>
            </div>
            {props.type === 'patients' && (<StyledMenu
                id="patients_menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {checkPermissions([permissions.create_patients]) && (
                    <StyledMenuItem
                        onClick={() => history.push(options.patients.add.path)}>
                        {options.patients.add.label}
                    </StyledMenuItem>
                )}
                {checkPermissions([permissions.read_patients]) && (
                    <StyledMenuItem
                        onClick={() => history.push(options.patients.view.path)}>
                        {options.patients.view.label}
                    </StyledMenuItem>
                )}
            </StyledMenu>)}
            {props.type === 'user' && (<StyledMenu
                id="user_menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props?.impersonateDmeIds?.length > 0 && <StyledMenuItem onClick={() => {
                    props.setImpersonateDialogOpen(true)
                }}>
                    Impersonate DME
                </StyledMenuItem>}
                {options.user.map((item, index) => {
                    return (
                        <StyledMenuItem key={index}
                                        id={item.label === sectionTitles.sign_out ? 'signOut' : ''}
                                        onClick={() => {
                                            history.push(item.path);
                                            if (item.label === sectionTitles.sign_out) {
                                                props.onSignOut()
                                            }
                                        }}
                        >
                            {item.label}
                        </StyledMenuItem>)
                })}
            </StyledMenu>)}
        </div>
    );
};




