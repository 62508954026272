export const errorMessages = {
    required_field:`This field is required.`,
    zip_validation:`Zip code must contain numbers`,
    invalid_phone: `Invalid phone number`,
    zero_results: `No results found`,
    error_occurred: `Error occurred try again or enter manually`,
    invalidCredentials: "There was no account found for this credentials.",
    mailingError: "There seems to be a problem. Please try again.",
};

export const successMessages = {
    mailingSuccess: "An email was sent successfully."
};