import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";
import './slider.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300 + theme.spacing(3) * 2,
    },
    margin: {
        height: theme.spacing(3),
    },
}));

function ValueLabelComponent(props) {
    const {children, open, value} = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);


export default function CustomizedSlider({min_range, max_range, itemValues, setItemValues, noCss}) {
    const [minVal, setMinVal] = useState(min_range);
    const [maxVal, setMaxVal] = useState(max_range);
    const classes = useStyles();
    const marks = [
        {
            value: minVal,
        },
        {
            value: maxVal,
        },
    ];


    return (
        <div className={classes.root}
             style={{width: '480px', minWidth: '360px', display: 'flex', justifyContent: noCss === true ? 'flex-start' : 'center'}}>
            <div style={{width: 80, marginRight: 20, marginTop: -6}}>
                <TextField
                    id="outlined-number"
                    label="Min."
                    value={minVal}
                    //type="number"
                    inputProps={{min: 0, style: {textAlign: 'center'}}}
                    variant="outlined"
                    size={'small'}
                    onChange={(e) => {
                        if (e.target.value >= min_range && e.target.value < max_range) {
                            setMinVal(e.target.value);
                            let obj = {...itemValues.special_item_info};
                            obj = {...obj, min_range: e.target.value};
                            setItemValues({...itemValues, special_item_info: obj})
                            if(e.target.value === ""){
                                setMinVal(min_range);
                                let obj = {...itemValues.special_item_info};
                                obj = {...obj, min_range: min_range};
                                setItemValues({...itemValues, special_item_info: obj})
                            }
                        }
                    }}
                />
            </div>
            <IOSSlider
                onChange={(e, newValue) => {
                    setMinVal(newValue[0]);
                    setMaxVal(newValue[1]);
                    let obj = {...itemValues.special_item_info};
                    obj = {...obj, min_range: newValue[0], max_range: newValue[1]};
                    setItemValues({...itemValues, special_item_info: obj})
                }}
                aria-label="ios slider"
                min={min_range}
                max={max_range}
                step={0.5} value={[minVal, maxVal]} marks={marks} valueLabelDisplay="on"/>
            <div style={{width: 80, marginLeft: 20, marginTop: -6}}>
                <TextField
                    id="outlined-number"
                    label="Max."
                    value={maxVal}
                    fullWidth
                    //type="number"
                    inputProps={{min: 0, style: {textAlign: 'center'}}}
                    variant="outlined"
                    size={'small'}
                    onChange={(e) => {
                        if (e.target.value <= max_range && e.target.value > min_range) {
                            setMaxVal(e.target.value);
                            let obj = {...itemValues.special_item_info};
                            obj = {...obj, max_range: e.target.value};
                            setItemValues({...itemValues, special_item_info: obj})
                        }
                        if(e.target.value  === ''){
                            setMaxVal(max_range);
                            let obj = {...itemValues.special_item_info};
                            obj = {...obj, max_range: max_range};
                            setItemValues({...itemValues, special_item_info: obj})
                        }
                    }}
                />
            </div>
        </div>
    );
}
