import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DownloadIcon from "../../assets/download.svg";
import PrinterIcon from "../../assets/printer.svg";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let _ = require('lodash');


const ExportForm = props => {
    const exportFile = () => {
        let x;
        if (props.getExportValues){
            x = props.getExportValues();
        } else {
            x = _.cloneDeep(props.exportValues);
        }
        if (props.excelHeaders) {
            x.unshift(props.excelHeaders)
        }

        const ws = XLSX.utils.aoa_to_sheet(x);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, props.fileName);
        XLSX.writeFile(wb, `${props.fileName}.xlsx`)
    };

    const exportText = () => {

        let x;
        if (props.getExportValues){
            x = props.getExportValues();
        } else {
            x = _.cloneDeep(props.exportValues);
        }
        if (props.excelHeaders) {
            x.unshift(props.excelHeaders);
        }


        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(x);
        XLSX.utils.book_append_sheet(wb, ws, props.fileName);
        XLSX.utils.sheet_to_txt(wb, 'test');
        XLSX.writeFile(wb, `${props.fileName}.txt`)
    };

    const exportPDF = (name) => {
        let x;
        if (props.getExportValues){
            x = props.getExportValues();
        } else {
            x = _.cloneDeep(props.exportValues);
        }
        if (!props.headers) {
            x.unshift([
                {text: "Patient ID", style: 'tableHeader'},
                {text: "Name", style: 'tableHeader'},
                {text: "Care Setting", style: 'tableHeader'},
                {text: "Phone number", style: 'tableHeader'}
            ])
        } else {
            x.unshift(props.headers)
        }

        let dd = {
                // by default we use portrait, you can change it to landscape if you wish
            pageSize: props.landscape === true ? 'A3' : 'A4',
            pageOrientation: props.landscape === true ? 'landscape' : 'portrait',
            content: [
                {text: `${props.fileName}`, style: 'header'},
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: x
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 2 === 0) ? '#E0E0E0' : null;
                        }
                    }
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
        };
        if (name === 'download') {
            pdfMake.createPdf(dd).download(`${props.fileName}.pdf`);
        } else if (name === 'print') {
            pdfMake.createPdf(dd).print();
        }
    };

    const exportCSV = () => {
        let x;
        if (props.getExportValues){
            x = props.getExportValues();
        } else {
            x = _.cloneDeep(props.exportValues);
        }
        if (props.excelHeaders) {
            x.unshift(props.excelHeaders)
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(x);
        XLSX.utils.book_append_sheet(wb, ws, props.fileName);
        XLSX.writeFile(wb, `${props.fileName}.csv`)
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick}
                style={{
                    marginRight: props.name ? 0 : 12,
                    paddingLeft: 16,
                    paddingRight: 8,
                    paddingTop: 8,
                    paddingBottom: 8,
                    backgroundColor: 'white',
                    border: "1px solid #00223D",
                    fontSize: 12,
                    color: '#00223D',
                    fontWeight: 'bold'
                }}
            >
                <img
                    src={DownloadIcon}
                    alt="logo"
                    width='16'
                    // className={classes.cursor}
                    style={{marginRight: 8}}
                />
                {/*<GetAppIcon style={{paddingRight:6}}/>*/}
                { props.name ? 'EXPORT' : 'DOWNLOAD'}
                <ArrowDropDownIcon/>
            </Button>
            {props.showPrint === true && (
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    onClick={() => exportPDF('print')}
                    style={{
                        marginRight: 12,
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: 'white',
                        lineHeight: '20px',
                        border: "1px solid #00223D",
                        fontSize: 12,
                        color: '#00223D',
                        fontWeight: 'bold'
                    }}
                >
                    <img
                        src={PrinterIcon}
                        alt="logo"
                        width='18'
                        // className={classes.cursor}
                        style={{marginRight: 8}}
                    />
                    PRINT
                </Button>

            )}

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem
                    onClick={exportFile}
                >
                    <i className="fa fa-file-excel-o" style={{fontSize: 20}}/>
                    <ListItemText style={{paddingLeft: 10}} primary={`${props.name ? 'Export as' : 'Download'} xlxs`}/>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={exportCSV}
                >
                    <i className="fa fa-file-text-o" style={{fontSize: 20}}/>
                    <ListItemText style={{paddingLeft: 10}} primary={`${props.name ? 'Export as' : 'Download'} CSV`}/>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={exportText}
                >
                    <i className="fa fa-file-text-o" style={{fontSize: 20}}/>
                    <ListItemText style={{paddingLeft: 10}} primary={`${props.name ? 'Export as' : 'Download'} text`}/>
                </StyledMenuItem>
                {props.showPrint === true && (
                    <StyledMenuItem
                        onClick={() => exportPDF('download')}
                    >
                        <i className="fa fa-file-pdf-o" style={{fontSize: 20}}/>
                        <ListItemText style={{paddingLeft: 10}} primary="Download PDF"/>
                    </StyledMenuItem>

                )}

            </StyledMenu>
        </div>
    );
};

export default ExportForm;

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);