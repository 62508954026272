import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PatientsHeader from "../../../patients/view-all-patients/patientsHeader/patientsHeader";
import {endpoints} from "../../../../constants/endpoints";
import {routes} from "../../../../constants/routes/routes";
import {logout, setCriteriaDmeUsers} from "../../../../redux/actions";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {Table} from '../../../../common/table/table';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import {searchDmeUsers} from "../../../../common/search/tabSearch/TabSearchHelper";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {componentTitles} from "../../../../constants/names/titles";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import moment from "moment";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {useSnackbar} from "notistack";
import {ContractPrintModal} from "./components/contractPrintModal/ContractPrintModal";

export default function ViewContracts() {
    let history = useHistory();
    const dispatch = useDispatch();
    const [dmeUsers, setDmeUsers] = useState([]);
    const [stringStatus, setStringStatus] = useState(true);
    const [dmeUsersFiltersValue, setDmeUsersFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        role: "All",
        organization: "All",
        isActiveUser: true,
    });
    const [contracts, setContracts] = useState([]);
    const [activeContracts, setActiveContracts] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const filterOptions = [
        {
            name: "isActiveUser",
            value: dmeUsersFiltersValue.isActiveUser,
            positive: "Active Contracts",
            negative: "Inactive Contracts"
        },
    ];

    const handleDeleteCriteria = (chip) => {
        setDmeUsersFiltersValue({
            ...dmeUsersFiltersValue,
            criteria: dmeUsersFiltersValue.criteria.filter(c => chip !== c)
        });
    };

    function handleFilterUpdate(event) {
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && dmeUsersFiltersValue.input) {
            if (!dmeUsersFiltersValue.criteria.includes(dmeUsersFiltersValue.input)) {
                if (dmeUsersFiltersValue.criteria[0] === ('All')) {
                    setDmeUsersFiltersValue({
                        ...dmeUsersFiltersValue,
                        criteria: [dmeUsersFiltersValue.input],
                        input: ''
                    });
                    searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
                } else {
                    setDmeUsersFiltersValue({
                        ...dmeUsersFiltersValue,
                        criteria: [...dmeUsersFiltersValue.criteria, dmeUsersFiltersValue.input],
                        input: ''
                    });
                    searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
                }
            }
        }
    };
    const handleInputChange = (value) => {
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, input: value})
    };

    const clearFilters = () => {
        setDmeUsersFiltersValue({
            input: '',
            criteria: ['All'],
            role: "All",
            organization: "All",
            isActiveUser: true,
        });
        // dispatch(setCriteriaDmeUsers(dmeUsersFiltersValue));
    };
    useEffect(() => {
        //Call query function
        // dispatch(setCriteriaDmeUsers(dmeUsersFiltersValue));
        searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);

        let newArr = [];

        if (dmeUsersFiltersValue.isActiveUser) {
            activeContracts.map(contract => {
                let dme_name = contract.dme_name.toLowerCase();
                let hospice_name = contract.hospice_name.toLowerCase();
                if (dme_name.includes(dmeUsersFiltersValue.input.toLowerCase())) {
                    newArr.push(contract)
                } else if (hospice_name.includes(dmeUsersFiltersValue.input.toLowerCase())) {
                    newArr.push(contract)
                }
            })
        } else {
            contracts.map(contract => {
                let dme_name = contract.dme_name.toLowerCase();
                let hospice_name = contract.hospice_name.toLowerCase();
                if (dme_name.includes(dmeUsersFiltersValue.input.toLowerCase())) {
                    newArr.push(contract)
                } else if (hospice_name.includes(dmeUsersFiltersValue.input.toLowerCase())) {
                    newArr.push(contract)
                }
            })
        }
        setFilteredContracts(newArr);
    }, [dmeUsersFiltersValue]);

    useEffect(() => {
        if (dmeUsersFiltersValue.isActiveUser === true) {
            setStringStatus('true')
        } else if (dmeUsersFiltersValue.isActiveUser  === false) {
            setStringStatus('false')
        }
    }, [dmeUsersFiltersValue.isActiveUser ]);

    const getContracts = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.contracts, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data !== undefined) {
                    let arr = [];
                    response.data.map(item => {
                        let index = arr.findIndex(i => i.contract_id === Number(item.contract_id));
                        if (index !== -1) {
                            arr[index].warehouses.push(item.warehouse_name)
                        } else {
                            let finalItem = item;
                            finalItem.warehouses = [item.warehouse_name];
                            delete finalItem.warehouse_name;
                            arr.push(finalItem);
                        }
                    });
                    let contracts = [];
                    arr.map(contract => {
                        if (contract.active === true){
                            contract.stringStatus = 'true'
                        } else if(contract.active === false) {
                            contract.stringStatus = 'false'
                        }
                        contracts.push(contract);

                    });
                    setActiveContracts(contracts);
                    setContracts(arr);
                    setFilteredContracts(arr);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    };

    useEffect(() => {
        getContracts();
    }, []);

    const columns = [
        {
            title: tableColumnTitles.edit, field: 'contract_id', width: 80, sorting: false,
            render: rowData => <div>
                <Tooltip placement="top" title={tooltips.editContract}>
                    <IconButton
                        onClick={() => {
                            history.push(routes.editContract + rowData.contract_id)
                        }
                        }
                    >
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
        {title: tableColumnTitles.hospiceOrganization, field: 'hospice_name', width: 400},
        {title: tableColumnTitles.hospiceRegion, field: 'region_name', width: 300},
        {title: tableColumnTitles.dmeOrganization, field: 'dme_name', width: 300},
        {
            title: tableColumnTitles.dmeWarehouses, field: 'dme_warehouses', width: 300,
            render: rowData => <div>{rowData.warehouses && rowData.warehouses.map((org, index) => (
                <div className={'organization_row'} key={index}>
                    {org}
                </div>
            ))}
            </div>
        },
        {
            title: 'Status', field: 'stringStatus', width: 100,defaultFilter:stringStatus,
            render: rowData => <div>
                {rowData.active === true ? 'Active' : 'Inactive'}
            </div>
        },
        {
            title: tableColumnTitles.effectiveDate, field: 'created_date', width: 300,
            render: rowData => <div>
                {rowData.created_date && moment(rowData.created_date).format('LL')}
            </div>
        },
        {
            title: tableColumnTitles.renewalDate, field: 'contract_renewal_date', width: 300,
            render: rowData => <div>
                {rowData.contract_renewal_date && moment(rowData.contract_renewal_date).format('LL')}
            </div>
        },
        {
            title: tableColumnTitles.terminationDate, field: 'name', width: 300,
            render: rowData => <div>
                {rowData.termination_date && moment(rowData.termination_date).format('LL')}
            </div>
        },{
            title: tableColumnTitles.download, field: 'name', width: 300,
            render: rowData => <div>
                <ContractPrintModal contractId={rowData.contract_id} dmeId={rowData.dme_organization_id} hospiceId={rowData.hospice_organization_id}/>
            </div>
        },
    ];

    return (
        <React.Fragment>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center">
                <Grid item xs={10}>
                    <PatientsHeader buttonText={buttonText.contract}
                                    path={'/admin/contract/create'}
                                    title={componentTitles.contracts}
                    />
                </Grid>
                <Grid item>
                    <TabSearchBar
                        filtersValue={dmeUsersFiltersValue}
                        handleDeleteCriteria={handleDeleteCriteria}
                        handleInputKeyup={handleInputKeyup}
                        handleInputChange={handleInputChange}
                        clearFilters={clearFilters}
                        filterOptions={filterOptions}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Grid>
                <Table columns={columns} items={filteredContracts}/>
            </Grid>
        </React.Fragment>
    );
}