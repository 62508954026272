import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../constants/names/titles";
import {exchageStyles} from "./requestExchangeStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PickupComponent from "./components/pickupComponent";
import {endpoints} from "../../constants/endpoints";
import _ from "lodash";
import {titles} from "../../constants/inputs/tableColumnTitles";
import {exchangeActions} from "../../constants/types/actionTypes";
import DeliveryComponent from "./components/deliveryComponent";
import {OrderReview} from "./components/orderExchangeReview/orderReview";
import moment from "moment";
import jwt from "jwt-decode";
import {useSnackbar} from "notistack";
import ToolIcon from "../../assets/tool.svg";
import {checkPaceHospice} from "../../common/utils/utils";
import {pickupTypes} from "../../constants/types/pickupTypes";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function RequestExchange(props) {
    let history = useHistory();
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAuthorization, setShowAuthorization] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const classes = exchageStyles();
    const vertical = 'top';
    const horizontal = 'right';
    const [open, setOpen] = useState(props.openOrder ? props.openOrder : false);
    const [value, setValue] = useState('');
    const [patientInventory, setPatientInventory] = useState([]);
    const [exchangeCart, setExchangeCart] = useState([]);
    const [showNotification, setShowNotification] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Select items to pick up', 'Select items to deliver', 'Finalize'];
    const [deliveryItems, setDeliveryItems] = useState([]);
    const [notes, setNotes] = useState([]);
    const [preferredDate, setPreferredDate] = useState(moment().format("L"));
    const [priority, setPriority] = useState("Routine");
    const [suggestions, setSuggestions] = useState([]);
    const [notifyHospice, setNotifyHospice] = useState(false);
    const [contractedProviders, setContractedProviders] = useState([]);
    const [dmeId, setDmeId] = useState("");
    const [hospiceId, setHospiceId] = useState("");
    const [regionId, setRegionId] = useState("");
    const [category, setCategory] = useState("");
    const [search, setSearch] = useState("");
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [filteredItemsList, setFilteredItemsList] = useState([]);
    const [hasItems, setHasItems] = useState(true);
    const [hasData, setHasData] = useState(false);
    const [isPace, setIsPace] = useState(false);
    const [authorization_pace_no, setAuthorization_pace_no] = useState('');
    const [categories, setCategories] = useState([
        {label: "Bedroom", value: "Bedroom"},
        {label: "Mobility/Positioning", value: "Mobility/Positioning"},
        {label: "Respiratory", value: "Respiratory"},
        {label: "Other", value: "Other"},
    ]);
    // const [dmeProvides, ]
    let user = JSON.parse(localStorage.getItem('key'));
    let decoded = jwt(user.accessToken);

    const orderHistory = [{
        description: `Order created: ${decoded.payload.first_name} ${decoded.payload.last_name} created order on ${moment().format('LLL')}`,
        created_date: moment()
    }];

    const getContractedProviders = (id) => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                }
                setContractedProviders(response.data);
                setContractedProvidersList(object);
                if (response.data.length === 1) {
                    setDmeId(response.data[0].dme_id);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    const getCategoriesList = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCategoriesMasterItems}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length > 0) {
                    let object = [{value: "All", label: "All"}];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].category, label: response.data[i].category})
                    }
                    setCategoriesList(object);
                } else {
                    setCategoriesList([]);
                }
            })
            .catch(err => console.log('error', err))
    };

    useEffect(() => {
        if (decoded.payload.hospice_organization_id !== null) {
            getContractedProviders(decoded.payload.hospice_organization_id);
            setHospiceId(decoded.payload.hospice_organization_id);
            setRegionId(decoded.payload.region_id);
        } else {

            setHospiceId(props.hospice_id);
            setRegionId(props.region_id);
            if (!dmeId){
                setDmeId(decoded.payload.dme_organization_id);
            }

        }
    }, [dmeId]);
    useEffect(() => {

        if (contractedProvidersList.length === 1 && dmeId === '') {
            setDmeId(contractedProvidersList[0].dme_id);
        }
    }, [contractedProvidersList]);
    useEffect(() => {
        if (itemsList.length === 0 && dmeId !== '' && open) {
            const data = JSON.parse(localStorage.getItem('key'));
            let body;
            if (decoded.payload.hospice_organization_id !== null) {
                body = {
                    dme_id: dmeId,
                    hospice_id: hospiceId,
                    hospice_region_id: regionId,
                };
            } else {
                body = {
                    dme_id: dmeId,
                    hospice_id: hospiceId,
                    hospice_region_id: regionId,
                }
            }
            fetch(`${endpoints.getHospiceContractedItems}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(response => {
                    return response.json();
                }).then(response => {
                if (response.data.length > 0) {
                    let clonedItems = _.cloneDeep(response.data);
                    clonedItems.map(item => {
                        if (!item.max_quantity_per_item) {
                            item.max_quantity_per_item = 10;
                        }
                    });
                    setItemsList(clonedItems);
                    setFilteredItemsList(clonedItems);
                } else {
                    setItemsList([]);
                }
            })
                .catch(err => console.log('error', err));
        }
    }, [dmeId, activeStep]);

    useEffect(() => {
        let cloneItemList = _.cloneDeep(itemsList);
        let result = [];
        if (category !== '' && category !== "All" && category !== null) {
            cloneItemList.map(item => {
                if (item.category && _.includes(item.category.toLowerCase(), category.toLowerCase())) {
                    result.push(item)
                }
            })
        } else {
            result = cloneItemList;
        }
        setFilteredItemsList(result)
    }, [category]);
    useEffect(() => {
        if (search !== '') {
            let lorin = _.cloneDeep(itemsList);
            let result = [];
            lorin.map(item => {
                if (_.includes(item.name.toLowerCase(), search.toLowerCase())) {
                    result.push(item)
                } else if (_.includes(item.tags, search.toLowerCase())) {
                    result.push(item)
                }
            });
            setFilteredItemsList(result);
        } else {
            setFilteredItemsList(itemsList);
        }
    }, [search]);

    useEffect(() => {
        if (categoriesList.length === 0 && open) {
            getCategoriesList()
        }
    }, [open]);

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setIsLoading(true);
            const data = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.createExchangeOrder, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                },
                body: JSON.stringify({
                    notes,
                    preferredDate,
                    priority,
                    notifyHospice,
                    exchangeCart,
                    patient_id: props.patientId,
                    dme_organization_id: dmeId,
                    user_id: decoded.payload.user_id,
                    history: orderHistory,
                    authorization_pace_no:authorization_pace_no
                })
            }).then(res => {
                return res.json();
            }).then(res => {
                props.getPatientOrders && props.getPatientOrders();
                uploadFiles(res.data)

            });
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        setOpen(false);
        if (reason === 'clickaway') {
            return;
        }
    };
    const createOrder = () => {
        setShowNotification(true);
        setOpen(false);
    };
    const hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowNotification(false);
    };
    const viewOrder = () => {
        history.push(`/order/exchange/${value}`);
    };
    const getData = async (totalParam) => {
        const promise = await fetch(endpoints.pickupItems + totalParam, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            },
        });
        const response = await promise.json();
        console.log(response.data, 'data')
        setPatientInventory(response.data);
    };
    const exchangeCartTableColumns = [
        {
            title: titles.item, field: "name", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.name}
                </Typography>
            </Grid>
        },
        {
            title: titles.action, field: "Action", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.action}
                </Typography>
            </Grid>
        },
    ];
    const handleInput = (event) => {
        const {name} = event.target;
        let values;
        switch (name) {
            case "pickup":
                const {findItem, value} = event.target;
                console.log(findItem, value)
                const pickupIndex = findItem.asset ? _.findIndex(patientInventory, {
                        'special_item_id': findItem.special_item_id,
                    })
                    : _.findIndex(patientInventory, {
                        'special_item_id': findItem.special_item_id,
                    });
                values = _.cloneDeep(patientInventory);
                values[pickupIndex].pickup = value;
                setDmeId(findItem.dme_id);
                setPatientInventory(values);

                let exchangeIndex;
                values = _.cloneDeep(exchangeCart);
                exchangeIndex = _.findIndex(exchangeCart, {
                    'special_item_id': findItem.special_item_id,
                });
                if (exchangeIndex === -1) {
                    console.log(findItem, 'find')
                    values.push({...findItem, "action": exchangeActions.pickup});
                } else {
                    values.splice(exchangeIndex, 1);
                }
                setExchangeCart(values);

                let suggestionIndex;
                values = _.cloneDeep(suggestions);
                suggestionIndex = _.findIndex(suggestions, {"asset": findItem.asset});
                if (suggestionIndex === -1) {
                    values.push({...findItem, checked: false, special_item_info:{} });
                }
                setSuggestions(values);

                break;
            case "addItem":
                values = [...exchangeCart];
                let item = {...event.target.itemValues};
                item = {
                    ...item,
                    hasAsset: event.target.item.hasAsset,
                    in_formulary: event.target.item.in_formulary,
                    action: exchangeActions.deliver
                };
                values.push(item);
                setExchangeCart(values);
                break;
            case "search":
                setSearch(event.target.value);
                break;
            case "category":
                setCategory(event.target.value);
                break;
            case "suggestion":
                const {checked, index} = event.target;
                values = _.cloneDeep(suggestions);
                values[index].checked = checked;
                setSuggestions(values);
                let exchangeClone = _.cloneDeep(exchangeCart);
                if (checked === true) {
                    exchangeClone.push({...values[index], action: exchangeActions.deliver})
                } else {
                    let exchangeIndex = _.findIndex(exchangeCart, {
                        'inventory_item_id': values[index].inventory_item_id,
                        "item_name": values[index].item_name,
                        "checked": true, // maybe this from backend too
                    });
                    if (exchangeIndex > -1) {
                        exchangeClone.splice(exchangeIndex, 1);
                    }
                }
                setExchangeCart(exchangeClone);
                break;
            case "notifyHospice":
                setNotifyHospice(event.target.checked);
                break;
            case "preferredDate":
                setPreferredDate(moment(event.target.value).format("L"));
                break;
            case "priority_code":
                setPriority(event.target.value);
                break;
            default:
        }
    };


    async function uploadFiles (orderId) {
        for (let i=0; i<files.length; i++){
            await createOrderFilesIds(orderId, files[i])
        }
        setSuggestions([]);
        setPatientInventory([]);
        setActiveStep(0);
        setNotes([]);
        setExchangeCart([]);
        setValue(orderId);
        setShowNotification(true);
        setActiveStep(0);
        handleClose();
        setIsLoading(false);
    }
    async function createOrderFilesIds(orderId, file){
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'exchange',
            })
        });
        const response = await promise1.json();
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length-1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile (file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  fileName, type:'exchange'})
        });
        const result1 = await promise1.json();
        try{
            const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }


    const handleClearAll = () => {

        let values = _.cloneDeep(patientInventory);
        for (const item of values) {
            item.pickup = false;
        }
        setPatientInventory(values);
        setExchangeCart([]);
        setSuggestions([]);
    };
    const handleRemove = (event) => {
        const {value} = event.target;
        let exchangeIndex;
        let inventoryClone = _.cloneDeep(patientInventory);
        let exchangeClone = _.cloneDeep(exchangeCart);
        if (value.action === exchangeActions.pickup) {

            let inventoryIndex = _.findIndex(inventoryClone, {
                "special_item_id": value.special_item_id
            });
            inventoryClone[inventoryIndex].pickup = !inventoryClone[inventoryIndex].pickup;
            exchangeIndex = _.findIndex(exchangeClone, {
                "special_item_id": value.special_item_id
            });
            exchangeClone.splice(exchangeIndex, 1);
            setPatientInventory(inventoryClone);
            setExchangeCart(exchangeClone);
        } else if (value.action === exchangeActions.deliver) {
            let exchangeIndex = _.findIndex(exchangeClone, function (item) {
                return item.tableData.id === value.tableData.id;
            });
            if (exchangeIndex > -1) {
                exchangeClone.splice(exchangeIndex, 1);
            }
            setExchangeCart(exchangeClone);

        } else {
            if (value.special_item_info && Object.keys(value.special_item_info).length > 0) {
                exchangeIndex = _.findIndex(exchangeClone, {
                    "inventory_item_id": value.inventory_item_id,
                    "special_item_info": value.special_item_info
                });
                if (exchangeIndex > -1) {
                    exchangeClone.splice(exchangeIndex, 1);
                }
                setExchangeCart(exchangeClone);

            } else {
                exchangeIndex = _.findIndex(exchangeClone, {
                    "inventory_item_id": value.inventory_item_id,
                });
                if (exchangeIndex > -1) {
                    exchangeClone.splice(exchangeIndex, 1);
                }
                setExchangeCart(exchangeClone);
            }
            let suggestionClone = _.cloneDeep(suggestions);
            let suggestionIndex = _.findIndex(suggestions, {
                "inventory_item_id": value.inventory_item_id,
                "asset": value.asset
            });
            suggestionClone[suggestionIndex].checked = false;
            setSuggestions(suggestionClone);
        }
    };
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <PickupComponent
                    patientInventory={patientInventory}
                    handleInput={handleInput}
                    exchangeCartTableColumns={exchangeCartTableColumns}
                    exchangeCart={exchangeCart}
                    dmeId={dmeId}
                />;
            case 1:
                return <DeliveryComponent
                    patientInventory={patientInventory}
                    handleInput={handleInput}
                    exchangeCartTableColumns={exchangeCartTableColumns}
                    exchangeCart={exchangeCart}
                    masterList={filteredItemsList}
                    deliveryItems={deliveryItems}
                    setDeliveryItems={setDeliveryItems}
                    suggestions={suggestions}
                    categories={categoriesList}
                    category={category}
                    contractedProvidersList={contractedProvidersList}
                    setDmeId={setDmeId}
                    dmeId={dmeId}
                    isPace={isPace}
                />;
            case 2:
                return <OrderReview
                    exchangeItems={exchangeCart}
                    setExchangeCart={setExchangeCart}
                    notes={notes}
                    setNotes={setNotes}
                    patientInventory={patientInventory}
                    handleRemove={handleRemove}
                    handleInput={handleInput}
                    handleClearAll={handleClearAll}
                    preferredDate={preferredDate}
                    priority={priority}
                    notifyHospice={notifyHospice}
                    authorization_pace_no={authorization_pace_no}
                    setAuthorization_pace_no={setAuthorization_pace_no}
                    showAuthorization={showAuthorization}
                    files={files}
                    setFiles={setFiles}
                />;
            default:
                return 'Unknown stepIndex';
        }
    };

    useEffect(() => {
        open === true && getData(false + `/${props.patientId}`);
    }, [open]);

    useEffect(()=> {
        async function check() {
            let res = await checkPaceHospice(props.hospice_id);
            setShowAuthorization(res);
            setIsPace(res);
        }
        check();
    }, []);


    useEffect(() => {
        const values = _.cloneDeep(exchangeCart);
        for (let index = 0; index < deliveryItems.length; index++) {
            if (_.findIndex(values, {
                "inventory_item_id": deliveryItems[index].inventory_item_id,
                "item_name": deliveryItems[index].item_name,
            }) === -1) {
                values.push({...deliveryItems[index], "action": exchangeActions.deliver});
            }
        }
        setExchangeCart(values);
    }, [deliveryItems]);
    // const checkPatientInventory = async (patient_id) => {
    //     let state;
    //     const token = JSON.parse(localStorage.getItem('key'));
    //     const promise = await fetch(endpoints.pickupItems + "true" + '/' + patient_id, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token.accessToken
    //         },
    //     });
    //     let response = await promise.json();
    //     let {data} = response;
    //     if (data && data.length === 0) {
    //         setHasItems(false);
    //     } else {
    //         setHasItems(true);
    //     }
    // };
    // useEffect(() => {
    //     if (props.patientId) {
    //         checkPatientInventory(props.patientId);
    //     }
    // }, [props.patientId]);

    return (
        <React.Fragment>
            {hasItems ? (
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    startIcon={<img
                        src={ToolIcon}
                        alt="logo"
                        width='16'
                        height='15'
                        // className={classes.simpleLogo}
                        style={{cursor: 'pointer',}}
                    />}
                    style={{width: '160px', fontWeight: 'bold'}}
                    onClick={handleClickOpen}
                >
                    NEW EXCHANGE
                </Button>
            ) : (<div style={{width: 160}}></div>)
            }
            <Dialog
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container justify={"flex-start"} alignItems={"flex-start"} direction={"row"}>
                        <Grid item xs={2}>
                            <Typography variant={"subtitle1"}>
                                {componentTitles.exchange}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant={"subtitle1"}>
                                {props.name}
                            </Typography>
                            <Typography variant={"subtitle2"}>
                                {props.address}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                "done"
                            ) : (
                                <div>
                                    {getStepContent(activeStep)}
                                </div>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>

                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            Back
                        </Button>
                        <Button
                            onClick={handleNext}
                            color="primary"
                            variant="contained"
                            autoFocus
                            id={"next"}
                            disabled={isLoading}
                            style={{ fontWeight:'bold', fontSize:12,
                                border:(isLoading) ? 'none' : '1px solid #00223D',
                                height:40}}
                        >
                            {isLoading ? (
                                <CircularProgress style={{color:'white'}} size={24}/>
                            ): (
                                <React.Fragment>
                                    {(activeStep !== steps.length - 1) ? 'NEXT' : 'CREATE ORDER'}
                                </React.Fragment>
                            )}

                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                ContentProps={{classes: {root: classes.snackbar}}}
                style={{padding: '10px'}}
                key={`${vertical},${horizontal}`} open={showNotification}
                autoHideDuration={10000}
                onClose={hideNotification}
                message={`Exchange Order #S300${value} was successfully created!`}
                action={
                    <React.Fragment>
                        <Button style={{color: 'black', marginRight: '6px'}} size="small" onClick={viewOrder}>
                            View Exchange Order
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={hideNotification}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            >
            </Snackbar>
        </React.Fragment>
    );
}
