import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import ListItem from "@material-ui/core/ListItem";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash";
import {endpoints} from "../../../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";

export default function ClinicalSettings(props) {
    const {enqueueSnackbar} = useSnackbar();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState([]);

    const handleClose = () => {
        setShowModal(false)
    };

    const handleResponse = async (val) => {
        //fetch the existing settings
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.masterItemsClinicalSettingsById}${props.item_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        let arr = _.cloneDeep(response.data);
        if ((!arr.some(e => e.main_title === 'Select Air PSI Range')) || arr.length === 0) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: null,
                max_range: null,
                type: 'slider',
                main_title: 'Select Air PSI Range',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                air_psi: false
            },)
        }
        if ((!arr.some(e => e.main_title === 'Select Liters Per Minute Range'))) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: null,
                max_range: null,
                type: 'slider',
                main_title: 'Select Liters Per Minute Range',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                air_psi: false
            },)
        }
        if (!arr.some(e => e.main_title === 'Select Delivery Method',)) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'radio',
                main_title: 'Select Delivery Method',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false
            })
        }
        if (!arr.some(e => e.main_title === 'Select Weight',)) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'input',
                main_title: 'Select Weight',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false
            })
        }
        if (!arr.some(e =>  e.main_title === 'Select Sling Size',)) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'radio',
                main_title: 'Select Sling Size',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false
            })
        }
        if (!arr.some(e => e.main_title === 'Select Oxygen Therapy')) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'checkbox',
                main_title: 'Select Oxygen Therapy',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false
            })
        }
        if (!arr.some(e => e.main_title === 'Select Humidifier Bottle')) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'input',
                main_title: 'Select Humidifier Bottle',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false
            })
        }
        if (!arr.some(e => e.main_title === 'Single Item')) {
            arr.push({
                clinical_setting_id: null,
                item_id: props.item_id,
                min_range: '',
                max_range: '',
                type: 'input',
                main_title: 'Single Item',
                weight: false,
                size_lift: false,
                iscontinuous: false,
                humidifier_for_compressor: false,
                delivery_method: false,
                air_psi: false,
                single_item:true
            })
        }
        setValues(arr);
        console.log(arr,'ar')
    };
    useEffect(() => {
        if (values.length === 0 && showModal) {
            handleResponse(values);
        }
    }, [showModal]);

    useEffect(() => {
        if (values.length > 0 && showModal) {
            setLoading(false);
        }
    }, [values]);

    const handleChange = async (name, value, field, clinical_id, min_range, max_range, type) => {
        if (clinical_id) {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.masterItemsClinicalSettings, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    clinical_setting_id: clinical_id,
                    item_id: props.item_id,
                    min_range: (min_range !== null && min_range !== undefined && min_range !== "") ? min_range : null,
                    max_range: (max_range !== null && max_range !== undefined && max_range !== "") ? max_range : null,
                    type: type,
                    main_title: name,
                    weight: field === 'weight' ? value : false,
                    size_lift: field === 'size_lift' ? value : false,
                    iscontinuous: field === 'iscontinuous' ? value : false,
                    humidifier_for_compressor: field === 'humidifier_for_compressor' ? value : false,
                    delivery_method: field === 'delivery_method' ? value : false,
                    air_psi: field === 'air_psi' ? (
                        (
                            (min_range === null || min_range === undefined || min_range === "") &&
                            (max_range === null || max_range === undefined || max_range === "")
                        ) ? false : value) : false,
                    liters_per_minute: field === 'liters_per_minute' ? (
                        (
                            (min_range === null || min_range === undefined || min_range === "") &&
                            (max_range === null || max_range === undefined || max_range === "")
                        ) ? false : value) : false,
                    single_item:field === 'single_item' ? value : false,
                })
            });
            const response = await promise.json();
            if (response.data?.status === 'success') {
                enqueueSnackbar("Clinical settings updated successfully", {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"}
                });
            }
            let clone = _.cloneDeep(values);
            let index = clone.findIndex(e => e.main_title === name);
            clone[index].min_range = min_range;
            clone[index].max_range = max_range;
            clone[index].size_lift = field === 'size_lift' ? value : false;
            clone[index].weight = field === 'weight' ? value : false;
            clone[index].iscontinuous = field === 'iscontinuous' ? value : false;
            clone[index].humidifier_for_compressor = field === 'humidifier_for_compressor' ? value : false;
            clone[index].delivery_method = field === 'delivery_method' ? value : false;
            clone[index].single_item = field === 'single_item' ? value : false;
            clone[index].air_psi = field === 'air_psi' ? (
                (
                    (min_range === null || min_range === undefined || min_range === "") &&
                    (max_range === null || max_range === undefined || max_range === "")
                ) ? false : value) : false;
            clone[index].liters_per_minute = field === 'liters_per_minute' ? (
                (
                    (min_range === null || min_range === undefined || min_range === "") &&
                    (max_range === null || max_range === undefined || max_range === "")
                ) ? false : value) : false;
            setValues(clone);
        } else {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.masterItemsClinicalSettings, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    clinical_setting_id: null,
                    item_id: props.item_id,
                    min_range: (min_range !== null && min_range !== undefined) ? min_range : null,
                    max_range: (max_range !== null && max_range !== undefined) ? max_range : null,
                    type: type,
                    main_title: name,
                    weight: field === 'weight' ? value : false,
                    size_lift: field === 'size_lift' ? value : false,
                    iscontinuous: field === 'iscontinuous' ? value : false,
                    humidifier_for_compressor: field === 'humidifier_for_compressor' ? value : false,
                    delivery_method: field === 'delivery_method' ? value : false,
                    air_psi: field === 'air_psi' ? (
                        (
                            (min_range === null || min_range === undefined || min_range === "") &&
                            (max_range === null || max_range === undefined || max_range === "")
                        ) ? false : value) : false,
                    liters_per_minute: field === 'liters_per_minute' ? (
                        (
                            (min_range === null || min_range === undefined || min_range === "") &&
                            (max_range === null || max_range === undefined || max_range === "")
                        ) ? false : value) : false,
                    single_item:field === 'single_item' ? value : false,
                })
            });
            const response = await promise.json();
            if (response.data?.status === 'success') {
                enqueueSnackbar("Clinical settings updated successfully", {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"}
                });
            }
            let clone = _.cloneDeep(values);
            let index = clone.findIndex(e => e.main_title === name);
            clone[index].min_range = min_range;
            clone[index].max_range = max_range;
            clone[index].size_lift = field === 'size_lift' ? value : false;
            clone[index].weight = field === 'weight' ? value : false;
            clone[index].iscontinuous = field === 'iscontinuous' ? value : false;
            clone[index].humidifier_for_compressor = field === 'humidifier_for_compressor' ? value : false;
            clone[index].delivery_method = field === 'delivery_method' ? value : false;
            clone[index].air_psi = field === 'air_psi' ? (
                (
                    (min_range === null || min_range === undefined || min_range === "") &&
                    (max_range === null || max_range === undefined || max_range === "")
                ) ? false : value) : false;
            clone[index].liters_per_minute = field === 'liters_per_minute' ? (
                (
                    (min_range === null || min_range === undefined || min_range === "") &&
                    (max_range === null || max_range === undefined || max_range === "")
                ) ? false : value) : false;
            clone[index].clinical_setting_id = response.id.clinical_setting_id;
            clone[index].single_item = field === 'single_item' ? value : false;
            setValues(clone);
        }
    };

    return (
        <React.Fragment>
            <Button variant='outlined' color='primary'
                    onClick={() => setShowModal(true)}
            >
                Settings
            </Button>
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {!loading ? (
                            <React.Fragment>
                                <Grid container justify={'space-between'} alignContent={'center'} alignItems={'center'}>
                                    <div style={{fontSize:24, fontWeight:'bold', paddingBottom:8, paddingTop:12}}>
                                        {props.name}
                                    </div>
                                    <IconButton aria-label="close"
                                        // className={classes.closeButton}
                                        onClick={() => setShowModal(false)}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </Grid>

                                <div  style={{fontSize: 18, fontWeight: 600,paddingTop:8}}>
                                    Select desired clinical settings:
                                </div>
                                {values.map((value, i) => {
                                    return (
                                        <React.Fragment>
                                            {value.main_title === 'Select Air PSI Range' && (
                                                <div style={{border: '1px solid #F5F5F5', borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16,}}>
                                                    <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                              // disabled={value.clinical_setting_id === null}
                                                              onClick={() => {
                                                                  handleChange('Select Air PSI Range', !values[i].air_psi === true, 'air_psi', value.clinical_setting_id, value.min_range, value.max_range, 'radio')
                                                              }}
                                                    >
                                                        <Checkbox
                                                            color='primary'
                                                            checked={values[i].air_psi === true}
                                                        />
                                                        <div style={{fontSize: 16, fontWeight: 600}}>
                                                            Select Air PSI
                                                        </div>
                                                    </ListItem>
                                                    <div style={{display: 'flex', paddingLeft: 40, paddingTop: 8}}>
                                                        <div style={{width: 100, padding: 8, paddingTop: 0}}>
                                                            <TextField variant="outlined" size='small' label='Min val'
                                                                       placeholder='0'
                                                                       defaultValue={values[i]?.min_range}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value !== values[i].min_range) {
                                                                               handleChange('Select Air PSI Range', true, 'air_psi', value.clinical_setting_id, e.target.value, value.max_range, 'input')
                                                                           }
                                                                       }}
                                                            />
                                                        </div>
                                                        <div style={{width: 100, padding: 8, paddingTop: 0}}>
                                                            <TextField variant="outlined" size='small' label='Max val'
                                                                       placeholder='0'
                                                                       defaultValue={values[i]?.max_range}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value !== values[i].max_range) {
                                                                               handleChange('Select Air PSI Range', true, 'air_psi', value.clinical_setting_id, value.min_range, e.target.value, 'input')
                                                                           }
                                                                       }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                            {value.main_title === 'Select Liters Per Minute Range' && (
                                                <div style={{border: '1px solid #F5F5F5', borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16,}}>
                                                    <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                              onClick={() => {
                                                                  handleChange('Select Liters Per Minute Range', !values[i].air_psi === true, 'liters_per_minute', value.clinical_setting_id, value.min_range, value.max_range, 'radio')
                                                              }}
                                                    >
                                                        <Checkbox
                                                            color='primary'
                                                            checked={values[i].liters_per_minute === true}
                                                        />
                                                        <div style={{fontSize: 16, fontWeight: 600}}>
                                                            Select Liters Per Minute Range
                                                        </div>
                                                    </ListItem>
                                                    <div style={{display: 'flex', paddingLeft: 40, paddingTop: 8}}>
                                                        <div style={{width: 100, padding: 8, paddingTop: 0}}>
                                                            <TextField variant="outlined" size='small' label='Min val'
                                                                       placeholder='0'
                                                                       defaultValue={values[i]?.min_range}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value !== values[i].min_range) {
                                                                               handleChange('Select Liters Per Minute Range', true, 'liters_per_minute', value.clinical_setting_id, e.target.value, value.max_range, 'input')
                                                                           }
                                                                       }}
                                                            />
                                                        </div>
                                                        <div style={{width: 100, padding: 8, paddingTop: 0}}>
                                                            <TextField variant="outlined" size='small' label='Max val'
                                                                       placeholder='0'
                                                                       defaultValue={values[i]?.max_range}
                                                                       onBlur={(e) => {
                                                                           if (e.target.value !== values[i].max_range) {
                                                                               handleChange('Select Liters Per Minute Range', true, 'liters_per_minute', value.clinical_setting_id, value.min_range, e.target.value, 'input')
                                                                           }
                                                                       }}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                            {
                                                value.main_title === "Select Delivery Method" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Select Delivery Method', !values[i].delivery_method === true, 'delivery_method', value.clinical_setting_id, null, null, 'radio')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={values[i].delivery_method === true}
                                                            />
                                                            <div style={{
                                                                fontSize: 16,
                                                                fontWeight: 600,
                                                                paddingRight: 12
                                                            }}> Select Delivery Method ( Nasal Cannula / Mask )
                                                            </div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                value.main_title === "Select Oxygen Therapy" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16,}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Select Oxygen Therapy', !values[i].iscontinuous === true, 'iscontinuous', value.clinical_setting_id, null, null, 'radio')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={values[i].iscontinuous === true}
                                                            />
                                                            <div style={{fontSize: 16, fontWeight: 600}}> Select Oxigen Therapy (
                                                                Continous / PRN)
                                                            </div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                value.main_title === "Select Weight" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Select Weight', !values[i].weight === true, 'weight', value.clinical_setting_id, null, null, 'input')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={values[i].weight === true}
                                                            />
                                                            <div style={{fontSize: 16, fontWeight: 600}}>Select Weight</div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                value.main_title === "Select Humidifier Bottle" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Select Humidifier Bottle', !values[i].humidifier_for_compressor === true, 'humidifier_for_compressor', value.clinical_setting_id, null, null, 'input')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={values[i].humidifier_for_compressor === true}
                                                            />
                                                            <div style={{fontSize: 16, fontWeight: 600}}>Select Humidifier Bottle</div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                value.main_title === "Select Sling Size" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16,}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Select Sling Size', !values[i].size_lift === true, 'size_lift', value.clinical_setting_id, null, null, 'radio')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={values[i].size_lift === true}
                                                            />
                                                            <div style={{fontSize: 16, fontWeight: 600}}>Select Sling Size
                                                                (Small / X-large)
                                                            </div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                            {
                                                value.main_title === "Single Item" && (
                                                    <div style={{borderRadius: 4, backgroundColor: '#F9FAFC', margin: 16,}}>
                                                        <ListItem button style={{display: 'flex', alignContent: 'center', alignItems: 'center', cursor: 'pointer', padding: 4}}
                                                                  onClick={() => handleChange('Single Item', !values[i].single_item === true, 'single_item', value.clinical_setting_id, null, null, 'checkbox')}
                                                        >
                                                            <Checkbox
                                                                color='primary'
                                                                checked={(values[i].single_item === true || values[i].single_item === undefined) }
                                                            />
                                                            <div style={{fontSize: 16, fontWeight: 600}}>
                                                                Single Item
                                                            </div>
                                                        </ListItem>
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>)
                                })}
                            </React.Fragment>
                        ) :
                        <div style={{
                            height: 430,
                            width: '100vw',
                            maxWidth: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}>
                            <CircularProgress/>
                        </div>}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    closeButton: {
        position: 'absolute',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
