export const placeholders = {
    add_facility: "Add Facility ...",
    add_team: "Add Team ...",
    add_region: "Add Region ...",
    add_hospice: "Add Hospice ...",
    add_warehouse: "Add Warehouse ...",
    first_name: "eg. John",
    last_name: "eg. Doe",
    patient_address1: "eg. Ocean View no.39",
    patient_address2: "eg. Ap 1233, Fl 34",
    patient_weight: "eg. 56",
    patient_height_in_feet: "eg. 5",
    patient_height_in_inches: "eg. 6",
    priority: "Priority",
    date_MM_DD_YYYY: "MM/DD/YYYY",
    reason: "Reason",
    username: "eg. firstname.lastname",
    empty_cart: "Cart is empty",
    add_note: "Add additional note...",
    loading_order: "Order is loading",
    selectWarehouse: "Select Warehouse",
    categories: "Category ...",
    phone: "(702) 123-4567",
    otherDiseaseProtocol: "eg. Diabet...",
    addAdditionalNote: "Add additional note...",
    organizationName: "Organization Name",
    selectHospice: "Select Hospice / Organization",
    selectTeam: "Select Team",
    selectTitle: "Select Title",
    selectRole: "Select Role",
    selectRegion: "Select Region",
    selectProvider: "Select Provider",
    addFirstName: "Add first name ...",
    addLastName: "Add last name ...",
    email: "user@company.com",
    quickSearch: "Quick Items Search",
    selectCreateRegion: "Select or create region ...",
    addRegionAddress: "Add region address...",
    addRegionState: "Add region state...",
    addRegionZip: "Add region zip...",
    addRegionCity: "Add region city...",
}