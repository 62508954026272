export const exchangeOrderStyle =(theme) => {
    return {
        h1: {
            textAlign: 'left',
                color: '#00223D',
                fontSize: 26,
                fontWeight: "bold",
                lineHeight: '38px',
                borderBottom: '1px solid #B2D1D9',
                marginBottom: 16,
                textTransform: "uppercase",
                paddingTop: 40
        },
        loading: {
            display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 30,
        },
        parent: {
            display: "flex",
                justifyContent: 'center',
        },
        root: {
            flexGrow: 1,
                maxWidth: 1428,
                height: '90vh',
                justifyContent: 'flex-start',
                alignContent: 'flex-start',
                [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                    paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                    paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                    paddingRight: 16,
            },

        },
        paper: {
            flex: 1,
                border: '1px solid #F0F0F0',
                backgroundColor: '#fff',
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 24
        },
        buttons: {
            display: 'flex',
                justifyContent: "flex-start",
                paddingTop: 20
        },
        button: {
            marginRight: 16,
        },
        divider: {
            marginLeft: 20,
                marginRight: 20,

        },
        buttonGrey: {
            color: "#00223D",
                fontSize: 12,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                backgroundColor: '#FFF',
                "&:hover": {
                background: "#EBEBEB"
            },
            border: '1px solid #00223D'
        },
        title: {
            fontWeight: 600,
                fontSize: 24,
                display: 'flex',
                alignItems: 'center'
        },
        columns: {
            display: 'flex',
                padding: '24px 32px',
                backgroundColor: '#F8F8F9',
                borderRadius: '8px',
                marginTop: 12
        },
        column: {
            // paddingLeft: 20,
            // paddingRight: 20,
            display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
            // paddingBottom: 20,
        },
        span: {
            color: '#707070',
        },
        h2: {
            fontSize: 18,
                fontWeight: 'bold',
                color: '#1A1818',

        },
        h3: {
            color: "#00223D",
                fontWeight: 600,
                paddingTop: 18
        },
        details: {
            color: "#4E6071",
                fontSize: 14,
                paddingTop: 18
        },
        patient: {
            textTransform: 'uppercase',
                fontSize: 18,
                fontWeight: 'bold',
                color: '#1A1818',
        },
        border: {
            borderBottom: '1px solid #E0E0E0',
                height: '1px',
                width: '100%',
                marginTop: 10,
                marginBottom: 10,
        },
        popover: {
            padding: 12,
                maxWidth: 400,
        },
        popoverBottom: {
            paddingBottom: 20,
        },
        subtitle: {
            padding: 8,
                margin: 0,
                textDecoration: "underline",
        },
        bold: {
            fontWeight: "bold",
        },
        status: {
            padding: 8,
        },
        table: {
            // padding: 20,
        },
        detailsTitle: {
            fontSize: 24,
                fontWeight: 600,
                color: '#00223D',
                paddingLeft: 24
        },
        tableHeader: {
            paddingBottom: 10,
                marginTop: -10,
                height: 50,
        },
        tableFooter: {
            paddingTop: 10
        },
        middle: {
            marginRight: 10,
        },
        noteInput: {
            // padding: '6px',
            // borderRadius: '5px',
            width: '100%',
        },
        div: {
            alignContent: 'right',
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                paddingBottom: 20,
        },
        notesSection: {
            display: 'flex',
                backgroundColor: '#F9F9F9',
                borderRadius: 2,
                padding: 16,
                marginBottom: 12,
                justifyContent: 'space-between',
        },
        dialog: {
            minWidth: 350,
            minHeight: 300
        },
        messages: {
            paddingTop: 8,
                paddingBottom: 16
        },
        warehouseSelect: {
            height: 100
        },
        subtitleDialog: {
            paddingBottom: 8
        },
        technician: {
            width: 400,
                paddingRight: 30,
                display: 'flex',
                alignItems: 'center',
        },
        technicianLabel: {
            paddingRight: 12
        },
        canceled: {
            marginLeft: 12,
                display: 'inline-block',
                justifyContent: 'center',
                alignContent: 'center',
                width: 80,
                height: 22,
                backgroundColor: "#CD1D32",
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 4,
                lineHeight: '22px'
        },
        open: {
            marginLeft: 12,
                display: 'inline-block',
                justifyContent: 'center',
                alignContent: 'center',
                width: 80,
                height: 22,
                backgroundColor: "#7B99B5",
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 4,
                lineHeight: '22px'
        },
        processing: {
            marginLeft: 12,
                display: 'inline-block',
                justifyContent: 'center',
                alignContent: 'center',
                width: 80,
                height: 22,
                backgroundColor: "#CEC129",
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 4,
                lineHeight: '22px'
        },
        completed: {
            marginLeft: 12,
                display: 'inline-block',
                justifyContent: 'center',
                alignContent: 'center',
                width: 80,
                height: 22,
                backgroundColor: "#117C70",
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 600,
                borderRadius: 4,
                lineHeight: '22px'
        },
    }
};