import React from "react";
import Grid from "@material-ui/core/Grid";
import {OrderItem} from '../../../../createOrder/orderItems/selectItems/orderItem/orderItem';
import {makeStyles} from "@material-ui/core/styles";


export const SelectItems = ({user, items, order, setOrder, handleInput, isPace}) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.paper}>
                <Grid container justify={'flex-start'} spacing={1}>
                {items.map(item =>
                        <OrderItem
                            user={user}
                            item={item}
                            order={order}
                            setOrder={setOrder}
                            handleInput={handleInput}
                            isPace={isPace}
                        />
                    )}
                </Grid>
            </div>
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop:12,
        border: '1px solid rgb(204, 204, 204)',
        borderRadius: '4px',
        backgroundColor: '#f5f5f5',
        height:'50vh',
        overflowY:'scroll',
        minWidth:320
    },
    paper: {
        padding:'12px',
        display:'flex',
    },
}));
