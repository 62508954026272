export const DmeProviderStyle = (theme) => {
    return {
        appBar: {
            position: 'relative',
        },
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
                maxWidth: 1200,
                marginLeft: 'auto',
                marginRight: 'auto'
            },
        },
        paper: {
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        stepper: {
            display: "flex",
            width: '100%',
            padding: theme.spacing(2, 0, 3),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            width: '100%',
            marginTop: theme.spacing(2),
            fontWeight: "bold",
        },
    }
};