import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import GeneralInformations from "./generalInformations";
import AccessRights from './accessRights';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import CancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import {endpoints} from "../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getRestrictedDomains} from "../createUserHelper";
import {emailValidation, usernameValidation} from "../../../constants/validations/validationTypes";
import {getAllWarehouses} from "../../utils/helpers/dataGetter";
import _ from "lodash";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        padding:12
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function CreateDmeUser(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const tokenData = jwt(JSON.parse(localStorage.getItem('key'))?.accessToken)?.payload;
    const [wasSuccessful, setWasSuccessful] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [user_id, setUser_id] = useState();
    const [first_role, setFirst_Role] = useState(0);
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstStep, setFirstStep] = useState(false);
    const [secondStep, setSecondStep] = useState(true);
    const [entered, setEntered] = useState(false);
    const [dmeList, setDmeList] = useState([]);
    const [initialEmail, setInitialEmail] = useState("");
    const [regionList, setRegionList] = useState({});
    const [rolesList, setRolesList] = useState([{value: '', label: ''}]);
    const [roleCategories, setRolesCategories] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [restrictedDomains, setRestrictedDomain] = useState([]);
    const [possibleEmail, setPossibleEmail] = useState(props.edit ? ["You cannot edit usernames!"] : ["Add First and Last name for suggestions"]);
    const [validEmail, setValidEmail] = useState(emailValidation.empty);
    const [validUsername, setValidUsername] = useState(usernameValidation.empty);
    const [warehouseData, setWarehouseData] = useState([]);
    const [warehouseIds, setWarehouseIds] = useState([]);
    const [impersonateDmeIds, setImpersonateDmeIds] = useState([]);
    const [teamData, setTeamData] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [dmeData, setDmeData] = useState([]);
    const [contractedHospiceData, setContractedHospiceData] = useState([]);
    //const [approveDelete, setApproveDelete] = useState(false);
    const [regionData, setRegionData] = useState([]);
    const [selOrgId, setSelOrgId] = useState();
    const [user, setUser] = useState({
        status: true,
        deleted: false,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        organization_id: '',
        region: null,
        team: null,
        title: '',
        role_id: '',
        permissions: '',
        username: '',
    });
    const getRolePermissions = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.permissions}${user.role_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setPermissionList(response.data);
            })
            .catch(err => console.log('error', err))
    };
    useEffect(() => {
        if (user.role_id !== '') {
            getRolePermissions();
        }
    }, [user.role_id]);

    function getCategories() {
        let categories = [];
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        //TODO when next at DME user creation
        permissionList.map(permission => categories.push(permission.category));
        let uniqueNames = categories.filter(onlyUnique);
        setRolesCategories(uniqueNames);
    }

    function getDmes() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getDmeIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_name, label: response.data[i].dme_name})
                }
                setDmeList(object);
                setDmeData(response.data);
                if(response.data) {
                    let dmeIds = [];
                    for (const item of response.data) {
                        dmeIds.push(item.dme_organization_id);
                    }
                    (async () => {
                        let promise = await fetch(endpoints.getContractedHospicesByDmeIds, {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token.accessToken
                            },
                            body: JSON.stringify({dmeIds:dmeIds})
                        })
                        let res = await promise.json();
                        if(res.data) {
                            setContractedHospiceData(res.data);
                        }
                    })();
                }
            })
            .catch(err => console.log('error', err))
    }

    function getRegions() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        if (selOrgId !== '') {
            fetch(`${endpoints.getDmeRegions}${selOrgId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].name, label: response.data[i].name})
                    }
                    setRegionList(object);
                    setRegionData(response.data);
                })
                .catch(err => console.log('error', err))
        }
    }

    function getRoles() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.dme_roles, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].role_id, label: response.data[i].name})
                }
                setRolesList(object);
            })
            .catch(err => console.log('error', err))
    }

    const updatePermissionIDs = () => {
        let list = [];
        permissionList.map(permission => {
            list.push(permission.permission_id);

        });
        setUser({...user, permissions: list})
    };

    const handleWarehouseInput = (event) => {
        const {name} = event.target;
        const warehouseIndex = _.findIndex(warehouseData, {"name": name});
        let values = [...warehouseIds];

        if (!_.includes(warehouseIds, warehouseData[warehouseIndex].warehouse_id)) {
            values.push(warehouseData[warehouseIndex].warehouse_id);
        } else {
            const index = warehouseIds.indexOf(warehouseData[warehouseIndex].warehouse_id);
            if (index > -1)
                values.splice(index, 1);
        }
        setWarehouseIds(values);
    };
    const handleDmeImpersonate = (event) => {
        const {name} = event.target;
        const impersonateIndex = _.findIndex(dmeData, {"dme_name": name});
        let values = [...impersonateDmeIds];
        if (!impersonateDmeIds.some( dme => dme.dme_organization_id === dmeData[impersonateIndex].dme_organization_id)) {
            values.push(dmeData[impersonateIndex]);
        } else {
            const index = impersonateDmeIds.indexOf(dmeData[impersonateIndex]);
            if (index > -1)
                values.splice(index, 1);
        }
        setImpersonateDmeIds(values);
    };


    useEffect(() => {
        getDmes();
        getRestrictedDomains(setRestrictedDomain);
        let urlParameters = window.location.href.split('/');
        setUser_id(Number(urlParameters[urlParameters.length - 1]))
    }, []);

    useEffect(() => {
        if (user.organization_id !== '') {
            getAllWarehouses(setWarehouseData, user.organization_id, setWarehouseIds);
            getRegions();
            getRoles();
            getTeams();
        }
    }, [user.organization_id]);

    useEffect(() => {
        getCategories();
        if (user.role_id && parseInt(user.role_id) !== first_role) {
            updatePermissionIDs();
        }
    }, [permissionList]);

    const getTeams = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getDmeTeams}${selOrgId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].team_name, label: response.data[i].team_name})
                }
                setTeamList(object);
                setTeamData(response.data)
            })
            .catch(err => console.log('error', err))
    };

    const deleteUser = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.deleteUser}${user_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                if (response.status === 204) {
                    setUser({...user, deleted: true});
                    setTimeout(() => history.push("/admin/dmeusers/all"), 1000);
                }
            })
            .catch(err => console.log('error', err))
    };

    async function getData() {
        getDmes();
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));

        const promise = await fetch(endpoints.getUserWarehouses + user_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if (response.status === 'success') {
            const {data} = response;
            let values = [];
            if (data) {
                for (let index = 0; index < data.length; index++) {
                    values.push(data[index].warehouse_id);
                }
            }
            setWarehouseIds(values);
        }
        fetch(`${endpoints.dme_user}${user_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setFirst_Role(Number(response.data[0].role_id));
                let arr = [];
                if (response.data[0].permissions[0] && response.data[0].permissions[0].permission_id){
                    response.data[0].permissions.map(permission => arr.push(Number(permission.permission_id)));
                } else {
                    response.data[0].permissions.map(permission => arr.push(Number(permission)));
                }
                setUser(prevState => ({
                    ...prevState,
                    first_name: response.data[0].first_name,
                    last_name: response.data[0].last_name,
                    organization_id: parseInt(response.data[0].dme_organization_id),
                    email: response.data[0].email,
                    team: response.data[0].team_id ? parseInt(response.data[0].team_id) : null,
                    title: response.data[0].title,
                    role_id: parseInt(response.data[0].role_id),
                    phone: response.data[0].phone,
                    status: response.data[0].status,
                    region: response.data[0].region_id ? parseInt(response.data[0].region_id) : null,
                    permissions: arr,
                    username: response.data[0].username,
                    deleted: response.data[0].deleted,
                }));
                setInitialEmail(response.data[0].email ? response.data[0].email : "");
                setValidEmail(emailValidation.success);
                setValidUsername(usernameValidation.success);
            }).catch(err => console.log('error', err))
        const impersonationPromise = await fetch(endpoints.getUserImpersonations + user_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const impersonationResponse = await impersonationPromise.json();
        if (impersonationResponse.status === 'success') {
            const {data} = impersonationResponse;
            let values = [];
            if (data) {
                for (let index = 0; index < data.length; index++) {
                    values.push({dme_name: data[index].dme_organization_name, dme_organization_id: Number(data[index].dme_organization_id)});
                }
            }
            setImpersonateDmeIds(values);
        }
    }

    useEffect(() => {
        if (props.edit && user_id !== undefined) {
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            getData();
            getDmes();
            getRegions();
            getRoles();
        }
    }, [user_id]);

    const checkAvailability = async (target, value) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        let endpoint;
        if (target === "email") {
            endpoint = endpoints.checkEmailAvailability;
        } else {
            endpoint = endpoints.checkUsernameAvailability
        }
        const promise = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({value: value})
        });
        const response = await promise.json();
        if (target === "email" && value !== initialEmail) {
            if (response.status === "success") {
                validEmail === true && setValidEmail(emailValidation.success)
            } else {
                setValidEmail(emailValidation.emailAlreadyExists)
            }
        } else if(target === "username" && !value.includes("@")){
            response.status === "success" ? setValidUsername(usernameValidation.success) : setValidUsername(usernameValidation.usernameAlreadyExists)
        }

    };

    useEffect(() => {
        if (user.role_id !== '') {
            if (props.edit) {
                setFirstStep(true);
            } else if (validUsername === usernameValidation.success && validEmail === emailValidation.success) {
                setFirstStep(true);
            } else {
                setFirstStep(false);
            }
        } else {
            setFirstStep(false);
        }
    }, [user, validUsername, validEmail]);

    useEffect(() => {
        if (
            user.organization_id &&
            user.first_name.length &&
            user.last_name.length &&
            user.phone.length === 10 &&
            user.email.length &&
            user.role_id &&
            validEmail === emailValidation.success &&
            validUsername === usernameValidation.success
        ) {
            setFirstStep(true);
        } else {
            setFirstStep(false);
        }
        setSecondStep(true);
        setEntered(true);
    }, [user]);

    function handleRequiredInput(e, type) {
        setUser({...user, [e.target.name]: e.target.value})
    }

    const handleStatus = () => {
        const values = {...user};
        values.status = !user.status;
        setUser(values);
    };

    const handleOrganizationSelect = (event) => {
        const values = {...user};
        values.organization_id = dmeData[_.findIndex(dmeData, {"dme_name": event.value})].dme_organization_id;
        values.team = null;
        values.region = null;
        values.role_id = "";
        setWarehouseIds([]);
        setUser(values);
        setSelOrgId(values.organization_id);
    };

    const handleRegionSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.region = regionData[_.findIndex(regionData, {"name": event.value})].region_id;
        setUser(updateHospice);
    };
    const handleTeamSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.team = teamData[_.findIndex(teamData, {"team_name": event.value})].team_id;
        setUser(updateHospice);
    };
    const handleRoleSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.role_id = event.value;
        setUser(updateHospice);
    };
    const handleTitleSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.title = event.value;
        setUser(updateHospice);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const values = {...user};
            if (name === "first_name") {
                values.first_name = value;
            } else if (name === "last_name") {
                values.last_name = value;
            } else if (name === "phone") {
                values.phone = value;
            } else if (name === "email" && !value.includes(" ")) {
                values.email = value;
            } else if (name === "username" && !value.includes(" ")) {
                values.username = value
            }
            setUser(values)
    };

    const steps = ['General Informations', 'Access rights'];

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <GeneralInformations
                    regionList={regionList}
                    dmeList={dmeList}
                    rolesList={rolesList}
                    handleOrganizationSelect={handleOrganizationSelect}
                    handleStatus={handleStatus}
                    handleRequiredInput={handleRequiredInput}
                    user={user}
                    handleInputChange={handleInputChange}
                    handleRegionSelect={handleRegionSelect}
                    handleTeamSelect={handleTeamSelect}
                    handleRoleSelect={handleRoleSelect}
                    handleTitleSelect={handleTitleSelect}
                    possibleEmail={possibleEmail}
                    validEmail={validEmail}
                    setValidEmail={setValidEmail}
                    restrictedDomains={restrictedDomains}
                    setPossibleEmail={setPossibleEmail}
                    setValidUsername={setValidUsername}
                    validUsername={validUsername}
                    isEdit={!!props.edit}
                    warehouses={warehouseData}
                    warehouseIds={warehouseIds}
                    handleWarehouseInput={handleWarehouseInput}
                    handleDmeImpersonate={handleDmeImpersonate}
                    impersonateDmeIds={impersonateDmeIds}
                    dmeData={dmeData}
                    regionData={regionData}
                    teamOptions={teamList}
                    teamData={teamData}
                    checkAvailability={checkAvailability}
                    deleteUser={deleteUser}
                    tokenData={tokenData}
                    contractedHospiceData={contractedHospiceData}
                />;
            case 1:
                return <AccessRights
                    roleCategories={roleCategories}
                    rolesList={rolesList}
                    user={user}
                    permissionList={permissionList}
                    setUser={setUser}
                />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        if (firstStep && activeStep === 0) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {
            setActiveStep(activeStep + 1);
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            if (props.edit) {
                const token = JSON.parse(localStorage.getItem('key'));
                fetch(`${endpoints.dme_user_edit}${user_id}`, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        ...user,
                        warehouseIds: warehouseIds,
                        impersonateDmes: impersonateDmeIds,
                    })
                })
                    .then(response => {
                        if (response.status === 200) {
                            setWasSuccessful(true);
                            setIsLoading(false)
                            //setDme(dme.id);
                        } else {
                            setWasSuccessful(false);
                            setIsLoading(false)
                        }
                    })
                    .catch(err => console.log('error', err));
            } else {
                const token = JSON.parse(localStorage.getItem('key'));

                fetch(endpoints.dme_user, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        ...user,
                        warehouseIds: warehouseIds,
                        impersonateDmes: impersonateDmeIds,
                    })
                })
                    .then(response => {
                        if (response.status === 201) {
                            setWasSuccessful(true);
                            setIsLoading(false)
                            //setDme(dme.id);
                        } else {
                            setWasSuccessful(false);
                            setIsLoading(false)
                        }
                    })
                    .catch(err => console.log('error', err));
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const redirectToDmeUser = () => {
        history.push("/admin/dmeusers/all");
    }

    return (
        <React.Fragment>
            {(isLogged) ? <React.Fragment>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        {(dmeData.length > 0) ? (
                            <React.Fragment>
                                <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                                    {props.edit ? 'Edit DME User' : 'Add new DME User'}
                                </Typography>
                                <Stepper activeStep={activeStep} className={classes.stepper}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <React.Fragment>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            {isLoading ? (
                                                <div style={{display: "flex", justifyContent: 'center'}}>
                                                    <CircularProgress/>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <Typography variant="h5" gutterBottom>
                                                        {(wasSuccessful && props.edit) && 'Success'}
                                                        {(wasSuccessful && !props.edit) && 'Success'}
                                                        {!wasSuccessful && props.edit && 'Unable to edit'}
                                                        {!wasSuccessful && !props.edit && 'Unable to register'}
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {(wasSuccessful && props.edit) && 'User has been updated.'}
                                                        {wasSuccessful && !props.edit && 'User has been created.'}
                                                        {!wasSuccessful && !props.edit && 'Please try again'}
                                                    </Typography>
                                                    <Button variant="contained" color="primary" onClick={redirectToDmeUser}>
                                                        View DME Users
                                                    </Button>
                                                    {!props.edit && (
                                                        <Grid container style={{paddingTop:24}}>
                                                            <Grid item xs={12}>
                                                                <Button variant={"contained"} color={'primary'}
                                                                        onClick={()=>{
                                                                            setUser({
                                                                                status: true,
                                                                                deleted: false,
                                                                                first_name: '',
                                                                                last_name: '',
                                                                                email: '',
                                                                                phone: '',
                                                                                organization_id: user.organization_id,
                                                                                region: user.region,
                                                                                team: user.team,
                                                                                title: '',
                                                                                role_id: '',
                                                                                permissions: '',
                                                                                username: '',
                                                                            });
                                                                            setActiveStep(0);
                                                                        }}>
                                                                    Create another user for this Organization
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {getStepContent(activeStep)}
                                            <div className={classes.buttons}>
                                                {entered ? <CancelActionAlert
                                                    flag={cancellingAlertFlags.newPatient}
                                                    CSS={classes.button}
                                                    alertLevel={alertLevel.warn}
                                                    target={"/admin"}
                                                /> : <Button
                                                    variant={'outlined'}
                                                    color="primary"
                                                    onClick={() => {
                                                        history.push('/admin')
                                                    }} className={classes.button}>
                                                    Cancel
                                                </Button>
                                                }
                                                {activeStep !== 0 && (
                                                    <Button onClick={handleBack} className={classes.button}>
                                                        Back
                                                    </Button>
                                                )}
                                                {activeStep === 0 && (
                                                    <Tooltip
                                                        title={firstStep === true || false ? '' : "Please fill all required fields"}
                                                        arrow placement="bottom-end">
                                                        <Button
                                                            variant={firstStep === true || false ? "contained" : 'outlined'}
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={classes.button}
                                                        >
                                                            {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                            {(activeStep === steps.length - 1 && !props.edit) && 'Create User'}
                                                            {activeStep !== steps.length - 1 && 'Next'}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {activeStep === 1 && (
                                                    <Tooltip
                                                        title={secondStep === true || false ? '' : "Please fill all required fields"}
                                                        arrow placement="bottom-end">
                                                        <Button
                                                            variant={secondStep === true || false ? "contained" : 'outlined'}
                                                            color="primary"
                                                            onClick={handleNext}
                                                            className={classes.button}
                                                        >
                                                            {(activeStep === steps.length - 1 && props.edit) && 'Save Changes'}
                                                            {(activeStep === steps.length - 1 && !props.edit) && 'Create User'}
                                                            {activeStep !== steps.length - 1 && 'Next'}
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </React.Fragment>
                        ) : (
                            <CircularProgress/>
                        )}
                    </Paper>
                </main>
            </React.Fragment> : ''}
        </React.Fragment>
    );
}
