import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PhoneInput from "react-phone-input-2";
import {placeholders} from "../../constants/inputs/paceholders";
import FormHelperText from "@material-ui/core/FormHelperText";
import {errorMessages} from "../../constants/messages/responseMessages";
import {checkTokenAvailability, isValidEmail} from "../utils/utils";
import {logout} from "../../redux/actions";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

export default function NotificationSettings(props) {

    const userId = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload.user_id;
    const history = useHistory();
    const dispatch = useDispatch();
    const token = JSON.parse(localStorage.getItem('key'));
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [phone, setPhone] = useState(false);
    const [notifications, setNotifications] = useState({
        email: false,
        phone: false,
        all: false
    })
    const [userData, setUserData] = useState({
        phone: "",
        email: "",
    });
    const [newContactInfo, setNewContactInfo] = useState({
        phone: "",
        email: "",
    });
    const [open, setOpen] = useState(false);
    const handleChange = (type, event) => {
        let all = false;
        switch (type) {
            case 'newEmail':
            case 'newPhone':
                setNewContactInfo({...newContactInfo, [event.target.name]: event.target.value})
                break;
            case 'phone':
                if (newContactInfo.phone?.length !== 10) {
                    setOpen(true);
                } else {
                    if (notifications.email === false && event.target.checked === false) {
                        all = true
                    }
                    setNotifications({...notifications, phone: event.target.checked, all: all});
                    saveSetting({email: notifications.email, phone: event.target.checked});
                }
                break;
            case 'email':
                if (!isValidEmail(newContactInfo.email)) {
                    setOpen(true)
                } else {
                    if (notifications.phone === false && event.target.checked === false) {
                        all = true
                    }
                    setNotifications({...notifications, email: event.target.checked, all: all});
                    saveSetting({email: event.target.checked, phone: notifications.phone});
                }
                break;
            case 'all':
                if (event.target.checked === false && (newContactInfo.phone?.length !== 10 || !isValidEmail(newContactInfo.email) )) {
                    setOpen(true);
                } else {
                    if (event.target.checked) {
                        setNotifications({...notifications, all: event.target.checked, email: false, phone: false});
                        saveSetting({email: false, phone: false});
                    } else {
                        setNotifications({...notifications, all: event.target.checked, email: true, phone: true});
                        saveSetting({email: true, phone: true});
                    }
                }
                break;
            default:
                break;
        }
    };
    const handleSubmit = async () => {
        setLoading(true);
        const promise = await fetch(endpoints.updateUserEmailOrPhone, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                id: userId,
                email: newContactInfo.email,
                phone: newContactInfo.phone
            })
        })
        const response = await promise.json();
        if (response.status === "success") {
            enqueueSnackbar("Updated information successfully.", {variant: "success"});
        } else {
            enqueueSnackbar("Something happened while updating information", {variant: "warning"});
        }
        await getUserData();
        setOpen(false);
        setLoading(false);
    }
    const getUserData = async () => {
        const promise = await fetch(endpoints.userProfileData + userId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const response = await promise.json();
        if (response.status === "success") {
            setUserData(response.data[0]);
            setNewContactInfo(response.data[0]);
        }
    }

    const saveSetting = async (obj) => {
        setLoading(true);
        const promise = await fetch(endpoints.updateNotificationSettings, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                email: obj.email,
                phone: obj.phone,
                id: userId
            })
        });
        let res = await promise.json();
        if (res.status === 'success') {
            enqueueSnackbar(`Notifications settings was updated with success`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {vertical: "top", horizontal: "right"},
            });
            setLoading(false);
        }
    };

    const getData = async () => {
        await getUserData();
        const promise = await fetch(`${endpoints.getNotificationSettings}${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        let res = await promise.json();

        if (res.data.notifications === 'phone') {
            setNotifications({
                phone: true,
                email: false,
                all: false
            })
        } else if (res.data.notifications === 'email') {
            setNotifications({
                phone: false,
                email: true,
                all: false
            })
        } else if (res.data.notifications === 'all') {
            setNotifications({
                phone: true,
                email: true,
                all: false
            })
        } else if (res.data.notifications === 'none') {
            setNotifications({
                phone: false,
                email: false,
                all: true
            })
        }
        setLoading(false);
    };
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getData()
    }, []);
    return (
        <Grid container style={{paddingLeft: 52}}>
            <Grid item xs={12}>
                <Typography style={{fontSize: 16, fontWeight: "bold"}}>
                    Select Notification Settings:
                </Typography>

            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                <Checkbox
                    checked={notifications.email}
                    onChange={(e) => handleChange('email', e)}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                />
                <Typography>Email notifications</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                <Checkbox
                    checked={notifications.phone}
                    onChange={(e) => handleChange('phone', e)}
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                />
                <Typography>SMS notifications</Typography>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                <Checkbox
                    checked={notifications.all}
                    color="primary"
                    onChange={(e) => handleChange('all', e)}
                    inputProps={{'aria-label': 'secondary checkbox'}}
                />
                <Typography>No notifications</Typography>
            </Grid>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Dialog open={open}
                    onClose={() => {
                        setOpen(false)
                        setNewContactInfo(userData);
                    }}
                    aria-labelledby="form-dialog-title"
                    maxWidth={"sm"}>
                <DialogTitle id="form-dialog-title">Something seems to be missing...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Grid container direction={"column"} justify={"flex-start"} spacing={1}>
                            <Grid item style={{color: isValidEmail(newContactInfo.email) === false ? "red" : "green"}}>
                                {isValidEmail(newContactInfo.email) === false ? "It seems you are missing a email address to enable this option." : "Email is valid."}
                            </Grid>
                            <Grid item style={{color: newContactInfo.phone?.length !== 10 ? "red" : "green"}}>
                                {newContactInfo.phone?.length !== 10 ? "It seems you are missing a phone number to enable this option." : "Phone number is correct."}
                            </Grid>
                            <Grid item style={{fontWeight: 800}}>
                                By clicking submit you confirm that the introduced data is valid and notifications will
                                reach you at the provided information. Please enable your choice after this dialog
                                closes.
                            </Grid>
                            <Grid item style={{fontWeight: 1000}}>
                                By clicking submit you acknowledge the fact that this operation can be performed only
                                ONE time.
                            </Grid>
                        </Grid>
                    </DialogContentText>
                    {isValidEmail(userData.email) === false && <TextField
                        autoFocus
                        margin="dense"
                        label="Email Address"
                        type="email"
                        name="email"
                        size={"small"}
                        variant={"outlined"}
                        value={newContactInfo.email}
                        onChange={(e) => handleChange("newEmail", e)}
                        fullWidth
                    />}
                    {userData.phone?.length !== 10 && <div><PhoneInput
                        country={'us'}
                        placeholder={placeholders.phone}
                        disableCountryCode={true}
                        containerStyle={{width: '100%'}}
                        inputStyle={{width: '100%'}}
                        value={newContactInfo.phone ? newContactInfo.phone : ""}
                        onlyCountries={['us']}
                        onChange={(e) => {
                            if (e.length === 10) {
                                setPhone(true)
                            }
                            let obj = {
                                target: {
                                    name: "phone",
                                    value: e,
                                }
                            }
                            handleChange("newPhone", obj);
                        }}
                        inputProps={{
                            label: 'test',
                            required: true,
                            id: 'patient_phone'
                        }}
                        onBlur={(e) => {
                            if (e.target.value.length === 14) {
                                setPhone(true)
                            } else {
                                setPhone(false)
                            }
                        }}
                        isValid={phone}
                    />
                        <FormHelperText style={{
                            color: 'red',
                            textAlign: 'left'
                        }}>{!phone && errorMessages.invalid_phone}</FormHelperText></div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                        setNewContactInfo(userData);
                    }} color="secondary"
                            variant={"contained"}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmit()} color="primary"
                            disabled={isValidEmail(newContactInfo.email) === false || newContactInfo.phone?.length !== 10}
                            variant={"contained"}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));