import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";

export const ProtectedRoute = ({ path, permission, component, render }) => {
    const [correctRoute, setCorrectRoute] = useState(false);

    function checkRoute(){
        let hasId = path.includes(":");
        if (hasId === true) {
            const i1 = window.location.pathname.split("")
                .map((elem, i) => elem === "/" ? i : null)
                .filter(i => i !== null)
            const i2 = path.split("")
                .map((elem, i) => elem === "/" ? i : null)
                .filter(i => i !== null)
            if(window.location.pathname.substring(0, i1[i1.length-1]) === path.substring(0, i2[i2.length-1])){
                setCorrectRoute(true)
            }
        } else {
            if (path === window.location.pathname){
                setCorrectRoute(true)
            }
        }
    }

    useEffect(()=> {
        checkRoute()
    }, [window.location.pathname])

    return (
        <React.Fragment>
            { correctRoute === false ? null : (
                permission ? (
                    <Route
                        exact path={path}
                        component={component}
                        render={render}
                    />
                ) : (
                    <Route
                        path={path}
                        component={({ history, location, match }) => {
                            history.replace({
                                ...location,
                                pathname: '/unauthorized',
                                state: { detail: path }
                            });
                            return null;
                        }}
                    />
                )
            )}
        </React.Fragment>
    )
};
