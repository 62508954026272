import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {endpoints} from "../../../constants/endpoints";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import ExportForm from "../../../common/exportForm/exportForm";
import MaterialTable from "material-table";
import Select from 'react-select';
import moment from "moment";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import jwt from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const selectStyles = {
    menu: base => ({
        ...base,
        textAlign: 'left'
    })
};

export const BillingReport = props => {
    const isLogged = useSelector(state => state.isLogged);
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [contractedProviders, setContractedProviders] = useState([]);
    const classes = useStyles();
    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
    const [isLoading, setIsLoading] = useState(true);
    const [years, setYears] = useState([]);
    const [values, setValues] = useState({
        dme_id: userData.dme_organization_id,
        hospices_ids: []
    });
    const [orgData, setOrgData] = useState([]);
    const [results, setResults] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    let token = localStorage.getItem('key');
    let currentUser = JSON.parse(token);
    let user = jwt(currentUser.accessToken);

    const getContractedProviders = () => {
        if (userData.hospice_organization_id !== null) {
            const data = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.getHospiceContractedProviders}${userData.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                }
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                    }
                    setContractedProviders(response.data);
                    setContractedProvidersList(object);
                    if (response.data.length === 1) {
                        setValues(prevState => {
                            return {...prevState, dme_id:response.data[0].dme_id};
                        });
                    }
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        }
    };

    const getData = () => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const userData = jwt(token.accessToken);
        if (userData.payload.hospice_organization_id === null) {
            fetch(`${endpoints.getHospicesByDme}${userData.payload.dme_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    setOrgData(response.data);
                    setIsLoading(false);
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        } else {
            fetch(`${endpoints.getHospiceRegions}${userData.payload.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let data = _.cloneDeep(response.data);
                    let newArr = [];
                    data.map(region => {
                        newArr.push({
                            region_name:region.name,
                            region_id: region.region_id
                        })
                    });
                    setOrgData([{
                        hospiceName: userData.payload.hospice_name,
                        hospice_id: userData.payload.hospice_organization_id,
                        regions: newArr
                    }]);
                    setValues({
                        ...values, hospices_ids: [{
                            hospiceName: userData.payload.hospice_name,
                            hospice_id: userData.payload.hospice_organization_id,
                            regions: newArr
                        }]
                    });
                    setIsLoading(false);

                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        }
    };

    useEffect(() => {
        if (orgData !== undefined && orgData.length === 0) {
            getData();
            getContractedProviders()
        }
    },[values.dme_id]);

    function getYears() {
        let arr = [];
        let date = new Date();
        let n = date.getFullYear();
        for(let i = 2020; i <= n; i++){
            arr.push({
                value:i,
                label:i
            })
        }
        setYears(arr);

    }
    useEffect(()=> {
        getYears()
    },[]);

    const generateReport = () => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getBillingReport, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    };
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.team_name);
                newArr.push(value.facility_name);
                newArr.push(value.patient_id);
                newArr.push(value.first_name + " " + value.last_name);
                newArr.push(value.medical_record);
                newArr.push(value.name);
                newArr.push(moment(value.delivery_date).format('YYYY-MM-DD'));
                newArr.push(value.pickup_completed ? moment(value.pickup_completed).format('YYYY-MM-DD') : "");
                newArr.push(value.dos);
                newArr.push(value.quantity);
                newArr.push(value.extendedQty);
                newArr.push(value.rf);
                newArr.push(value.pf);
                newArr.push(value.lineSTotal);
                newArr.push(value.salesTax);
                newArr.push(value.lineTotal);
                newArr.push(value.delivery_order_id !== null ? "D100" + value.delivery_order_id : null);
                newArr.push(value.pickup_order_id !== null ? "P200" + value.pickup_order_id : null);
                newArr.push(value.exchange_order_id !== null ? "S300" + value.exchange_order_id : null);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Organization_Name", style: 'tableHeader'},
        {text: "Team", style: 'tableHeader'},
        {text: "Facility", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient Name", style: 'tableHeader'},
        {text: "Medical Record Number", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Delivery Date", style: 'tableHeader'},
        {text: "Pickup Date", style: 'tableHeader'},

    ];
    const excelHeaders = [
        "Organization_Name",
        "Team",
        "Facility",
        "Patient_ID",
        "Patient_Name",
        "Medical_Record_Number",
        "Item_Name",
        "Delivery_Date",
        "Pickup_Date",
        "DOS",
        "QTY",
        "Extended_Qty",
        "Rental_Fee",
        "Purchase_Fee",
        "Line_STotal",
        "Sales_Tax",
        "Line_Total",
        "Delivery_Order_Id",
        "Pickup_Order_Id",
        "Exchange_Order_Id",
    ];
    const columns = [{title: 'Organization', field: 'hospice_name', align: "center",
    },
        {title: 'Team', field: 'team_name', align: "center",
            headerStyle: {
                textAlign: 'center'
            }},
        {title: 'Facility', field: 'facility_name', align: "center",
            headerStyle: {
                textAlign: 'center'
            }},
        {title: 'Patient ID', field: 'patient_id', align: "center",
            headerStyle: {
                textAlign: 'center'
            }},
        {
            title: 'Patient Name', field: 'first_name', align: "center",
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div>
                {rowData.first_name + " " + rowData.last_name}
            </div>
        },
        {title: 'Medical Record Number', field: 'medical_record',
            headerStyle: {
                textAlign: 'center'
            }},
        {title: 'Item Name', field: 'name',
            headerStyle: {
                textAlign: 'center'
            }},
        {
            title: 'Delivery Date', field: 'delivery_completed', align: "center",
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div>
                {moment(rowData.delivery_date).format('YYYY-MM-DD')}
            </div>
        },
        {
            title: 'Pickup Date', field: 'pickup_completed', align: "center",
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div>
                {rowData.pickup_completed && moment(rowData.pickup_completed).format('YYYY-MM-DD')}
            </div>
        },
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
            <Grid container className={classes.container}>
                <Typography className={classes.maintitle}>Billing Summary Report</Typography>
                <Grid container className={classes.select}>
                    <div style={{display: "flex"}}>
                        {user.payload.type === 'DME' && (
                            <Typography className={classes.title}>Select organization</Typography>
                        )}

                        {values.hospices_ids.length > 0 && (
                            <Typography className={classes.title}>Select region</Typography>)}
                    </div>
                    <Grid container>
                        <div style={{width: user.payload.type === 'DME' ? 400 : 200}}>
                            {orgData !== undefined && orgData.map((org, i) => {
                                return (
                                    <React.Fragment>
                                        {user.payload.type === 'DME' ? (
                                        <Grid item xs={12} style={{display: "flex", padding: 8}}>

                                            <Grid item xs={6}>
                                                <div className={classes.org}>
                                            <span style={{paddingLeft: 12}}>
                                                {org.hospiceName}
                                            </span>
                                                    <Checkbox
                                                        checked={_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) > -1}
                                                        style={{height: 20}}
                                                        color="primary"
                                                        onChange={() => {
                                                            let a = _.cloneDeep(values)
                                                            let index = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName})
                                                            if (index > -1) {
                                                                a.hospices_ids[index] = {};
                                                            } else {
                                                                a.hospices_ids[i] = orgData[i]
                                                            }
                                                            setValues(a)
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {orgData[i].regions.map((reg, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                                <div className={classes.region}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.region_name}</span>
                                                                    <Checkbox
                                                                        checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name}) > -1}
                                                                        style={{height: 20}}
                                                                        color="primary"
                                                                        onChange={() => {
                                                                            let a = _.cloneDeep(values)
                                                                            let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName})
                                                                            let indexRegion = _.findIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name})
                                                                            if (indexRegion > -1) {
                                                                                a.hospices_ids[indexHospice].regions.splice(indexRegion, 1);
                                                                            } else {
                                                                                a.hospices_ids[indexHospice].regions.push(reg)
                                                                            }
                                                                            setValues(a)
                                                                        }}
                                                                    />
                                                                </div>)}</React.Fragment>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid> ) : (
                                                <Grid item xs={12} style={{display: "flex", padding: 8}}>
                                                    <Grid item xs={12}>
                                                        {orgData[i].regions.map((reg, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                                        <div className={classes.region}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.region_name}</span>
                                                                            <Checkbox
                                                                                checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name}) > -1}
                                                                                style={{height: 20}}
                                                                                color="primary"
                                                                                onChange={() => {
                                                                                    let a = _.cloneDeep(values)
                                                                                    let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName})
                                                                                    let indexRegion = _.findIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name})
                                                                                    if (indexRegion > -1) {
                                                                                        a.hospices_ids[indexHospice].regions.splice(indexRegion, 1);
                                                                                    } else {
                                                                                        a.hospices_ids[indexHospice].regions.push(reg)
                                                                                    }
                                                                                    setValues(a)
                                                                                }}
                                                                            />
                                                                        </div>)}</React.Fragment>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Grid>
                                            )}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        <div style={{
                            width: 590,
                            paddingTop: 8,
                            paddingLeft: 10,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            {userData.hospice_organization_id !== null && (
                                <div style={{width: 180}}>
                                    <Select
                                        className={classes.z}
                                        classNamePrefix="select"
                                        placeholder="Select provider..."
                                        name="color"
                                        onChange={(e) => {
                                            setValues({...values, dme_id: e.value})
                                        }}
                                        options={contractedProvidersList}
                                    />
                                </div>
                            )}
                            <div style={{width: 180}}>
                                <Select
                                    className={classes.z}
                                    classNamePrefix="select"
                                    placeholder="Select month..."
                                    name="color"
                                    onChange={(e) => {
                                        setValues({...values, month: e.value})
                                    }}
                                    options={[
                                        {value: '01', label: 'January'},
                                        {value: '02', label: 'February'},
                                        {value: '03', label: 'March'},
                                        {value: '04', label: 'April'},
                                        {value: '05', label: 'May'},
                                        {value: '06', label: 'June'},
                                        {value: '07', label: 'July'},
                                        {value: '08', label: 'August'},
                                        {value: '09', label: 'September'},
                                        {value: 10, label: 'October'},
                                        {value: 11, label: 'November'},
                                        {value: 12, label: 'December'},
                                    ]}
                                    styles={selectStyles}
                                />
                            </div>
                            <div style={{width: 180}}>
                                <Select
                                    className={classes.z}
                                    classNamePrefix="select"
                                    placeholder="Select year..."
                                    // defaultValue={colourOptions[0]}
                                    onChange={(e) => {
                                        setValues({...values, year: e.value})
                                    }}
                                    name="color"
                                    options={years}
                                    styles={selectStyles}
                                />
                            </div>
                            {userData.hospice_organization_id === null && (
                                <div style={{width: 180}}>

                                </div>
                            )}
                        </div>
                    </Grid>
                    <div style={{display: 'flex', justifyContent: "center", paddingTop: 30}}>
                        <Button variant='contained' color={"primary"} onClick={generateReport}>Generate Report</Button>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 20,
                        marginBottom: 12
                    }}>
                        <ExportForm
                            fileName={`DME_FinRep_${moment().format('MMDDYYYY_HHMM')}.xlsx`}
                            exportValues={exportValues}
                            headers={headers}
                            excelHeaders={excelHeaders}
                            showPrint={false}
                        />
                    </div>
                    {/*<MaterialTable*/}
                    {/*    title={'Generated Report:'}*/}
                    {/*    options={{*/}
                    {/*        search: false*/}
                    {/*    }}*/}
                    {/*    columns={[{title: 'Hospice', field: 'hospice_name'},*/}
                    {/*        {title: 'Team', field: 'team_name'},*/}
                    {/*        {title: 'Facility', field: 'facility_name'},*/}
                    {/*        {title: 'Patient ID', field: 'patient_id'},*/}
                    {/*        {*/}
                    {/*            title: 'Patient Name', field: 'first_name',*/}
                    {/*            render: rowData => <div>*/}
                    {/*                {rowData.first_name + " " + rowData.last_name}*/}
                    {/*            </div>*/}
                    {/*        },*/}
                    {/*        {title: 'Medical Record Number', field: 'medical_record'},*/}
                    {/*        {title: 'Item Name', field: 'item_name'},*/}
                    {/*        {*/}
                    {/*            title: 'Delivery Date', field: 'delivery_completed',*/}
                    {/*            render: rowData => <div>*/}
                    {/*                {moment(rowData.delivery_completed).format('YYYY-MM-DD')}*/}
                    {/*            </div>*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            title: 'Pickup Date', field: 'pickup_completed',*/}
                    {/*            render: rowData => <div>*/}
                    {/*                {moment(rowData.pickup_completed).format('YYYY-MM-DD')}*/}
                    {/*            </div>*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*    data={results}*/}
                    {/*/>*/}
                    <Table
                        search={false}
                        filtering={true}
                        columns={columns}
                        items={results}
                        backGroundColor={"rgba(237, 242, 244, 0.73)"}
                        textColor={"rgba(46, 50, 54, 1)"}
                    />
                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>

            }
        </div>
    )
};
const useStyles = makeStyles((theme) => ({
    div: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        flexGrow: 1,
        // maxWidth: 1920,
        height: '90vh',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 48,
            paddingRight: 20,
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 48,
            paddingRight: 10,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
        },

    },
    h1: {
        paddingTop: 58,
        textAlign: 'left',
        color: '#00223D',
        fontSize: 28,
        fontWeight: "bold",
        lineHeight: '38px',
        borderBottom: '1px solid #B2D1D9',
        marginBottom: 4,
        textTransform: "uppercase"
    },
    container: {
        paddingTop: 12,
        maxWidth: 1200,
    },
    title: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 200
    },
    maintitle: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 220
    },
    select: {
        padding: 12,
        display: "flex",
        flexDirection: "column",
        justifyContent: "left"

    },
    z: {
        zIndex: 300
    },
    org: {
        border: '1px solid lightgrey',
        borderRadius: 4,
        marginRight: 12,
        height: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    region: {
        border: '1px solid lightgrey',
        height: 40,
        borderRadius: 4,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    }
}));
