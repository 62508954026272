import React, {useEffect, useState} from "react";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import {componentTitles} from "../../../constants/names/titles";
import MaterialTable from "material-table";
import {
    getAllDmeRoles,
    getAllDmes,
    getAllHospiceRoles,
    getAllHospices, getAllPortalRoles, getAuditData,
    getUsersByOrgAndRoles
} from "../helpers/dataGetter";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import {labels} from "../../../constants/inputs/labels";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

export const AuditTrail = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [organization, setOrganization] = useState("");
    const [organizationType, setOrganizationType] = useState(props.portal === true ? "Hospice" : '');
    const [hospiceOrganizations, setHospiceOrganizations] = useState([]);
    const [hospiceRoles, setHospiceRoles] = useState([]);
    const [dmeRoles, setDmeRoles] = useState([]);
    const [portalRoles, setPortalRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [dmeOrganizations, setDmeOrganizations] = useState("");
    const [loading, setLoading] = useState(false);
    const [userList, setUserList] = useState([]);
    const [startDate, setStartDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    const isLarge = useMediaQuery('(min-width:1280px)');
    const columns = [
        {title: tableColumnTitles.operation, field: 'operation', width: 800},
        {title: tableColumnTitles.username, field: 'username', width: 800},
        {
            title: tableColumnTitles.createdDate, field: 'created_date', width: 800,
            render: rowData => <div>
                {moment(rowData.created_date).format('LLL')}
            </div>
        },
        {title: tableColumnTitles.statusCode, field: 'status_code', width: 800},
    ];

    const [audit, setAudit] = useState([]);

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllHospices(setHospiceOrganizations);
        getAllDmes(setDmeOrganizations);
    }, []);

    useEffect(()=> {
        if (props.portal === true){
            getAllPortalRoles(setPortalRoles)
        } else if (organizationType === 'Dme'){
            getAllDmeRoles(setDmeRoles)
        } else if (organizationType === 'Hospice'){
            getAllHospiceRoles(setHospiceRoles);
        }

    }, [organizationType]);

    useEffect(()=> {
        if(organizationType !== '' && organization !== '' && selectedRole !==''){
            getUsersByOrgAndRoles(organizationType, organization.value,organization.value,selectedRole.value,setUserList );
        }
    },[organizationType, organization, selectedRole]);

    const getData = async () => {
        setLoading(true);
        await getAuditData(selectedUser, startDate, endDate, setAudit);
        setLoading(false)
    };
    return (
        <Grid container justify={"center"} alignItems={"center"} direction={"column"}>
            <Grid item xs={12} style={{ width:'80vw'}}>
                <Typography variant={'h3'} style={{
                    paddingTop: 28,
                    textAlign: 'left',
                    color: '#00223D',
                    fontSize: 28,
                    fontWeight: "bold",
                    lineHeight: '38px',
                    borderBottom: '1px solid #B2D1D9',
                }}>
                    {props.portal === true ? 'Patient Portal Audit Trail' : 'Audit Trail'}
                </Typography>
            </Grid>
            <Grid container style={{width: '80vw', backgroundColor:'white',marginTop:1,marginBottom:12,padding:12, paddingBottom:20,
                borderRadius:4,
                zIndex:50
            }} spacing={1}>
                {props.portal !== true && (
                    <Grid item xs={6} sm={6} md={3} lg={2} style={{zIndex:6}}>
                        <InputLabel style={{display:"flex"}}>{labels.organizationType}</InputLabel>
                        <Select
                            options={[
                                {value: "Dme", label: "DME Providers"},
                                {value: "Hospice", label: "Hospice Providers"}]}
                            onChange={(e) => {
                                setOrganizationType(e.value);
                                setOrganization("");
                            }}
                            isLoading={hospiceOrganizations.length === 0 && dmeOrganizations.length === 0}
                            disabled={hospiceOrganizations.length === 0 && dmeOrganizations.length === 0}
                        />
                    </Grid>
                )}

                <Grid item xs={6} sm={6} md={3} lg={2} style={{zIndex:5}}>
                    <InputLabel style={{display:"flex"}}>{labels.selectOrganization}</InputLabel>
                    <Select
                        options={organizationType !== "" ?
                            organizationType === "Dme" ? dmeOrganizations.map(item => {
                                return {label: item.dme_name, value: item.dme_organization_id}
                            }) : hospiceOrganizations.map(item => {
                                return {label: item.hospice_name, value: item.hospice_organization_id}
                            }) : []
                        }
                        onChange={(e) => setOrganization(e)}
                        value={organization && organization}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2} style={{zIndex:4}}>
                    <InputLabel style={{display:"flex"}}>{labels.selectRole}</InputLabel>
                    {props.portal === true ? (
                        <Select
                            options={organizationType !== "" ?
                                portalRoles.map(item => {
                                    return {label: item.name, value: item.role_id}
                                }) : []
                            }
                            onChange={(e) => {
                                setSelectedRole(e);
                            }}
                        />
                    ) : (
                        <Select
                            options={organizationType !== "" && props.portal !== true ?
                                organizationType === "Dme" ? dmeRoles.map(item => {
                                    return {label: item.name, value: item.role_id}
                                }) : hospiceRoles.map(item => {
                                    return {label: item.name, value: item.role_id}
                                }) : []
                            }
                            onChange={(e) => {
                                setSelectedRole(e);
                            }}
                        />
                    )}

                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={2} style={{zIndex:3}}>
                    <InputLabel style={{display:"flex"}}>{labels.selectUser}</InputLabel>
                    <Select
                        options={userList.length !== 0 ?
                            userList.map(item => {
                                return {label: `${item.first_name} ${item.last_name} ( ${item.username} )`, value: item.user_id}
                            }) : []
                        }
                        onChange={(e) => {
                            setSelectedUser(e.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={4} style={{display:'flex',padding:0, paddingTop:2,
                }}>
                    <div style={{width:'98%'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    inputVariant="outlined"
                                    placeholder='MM/DD/YYYY'
                                    size={"small"}
                                    id="date-of-birth"
                                    label="Start Date *"
                                    format="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{
                                        backgroundColor:'white',
                                        width:'100%'
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{width:8}}></div>
                    <div style={{ width:'98%'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    margin="normal"
                                    style={{
                                        backgroundColor:'white',
                                        width:'100%'
                                    }}
                                    inputVariant="outlined"
                                    placeholder='MM/DD/YYYY'
                                    size={"small"}
                                    id="date-of-birth"
                                    label="End Date *"
                                    format="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(e) => {
                                        setEndDate(e);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                <Grid item xs={12} sm={2} md={3} lg={12} style={{padding:0,paddingLeft:8,paddingTop:!isLarge ? 10: 0,display:'flex', alignContent:'center', alignItems:'center', justifyContent:'flex-end'}}>
                    <Button fullWidth={!isLarge} variant='contained' color='primary'
                    onClick={getData}
                    >Search</Button>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{overflowX: 'scroll', width: '80vw'}}>
                <MaterialTable
                    isLoading={loading}
                    title={componentTitles.auditTrail}
                    columns={columns}
                    data={audit}
                    options={{
                        pageSize: 10,
                    }}
                />
            </Grid>
        </Grid>
    )
};
