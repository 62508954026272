import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

export const PortalAccounts = (props) => {
    const {patient} = props;
    let history = useHistory();
    return (
        <React.Fragment>
            <Grid container style={{paddingTop:24}}>
                <Grid item xs={6}>
                    <Grid container>
                    <h3>Patient Account</h3>
                    </Grid>
                    <Grid item xs={12} style={{display:'flex', padding:16}}>
                        {patient.has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>{patient.first_name + " " + patient.last_name} has already portal account</span>) : (
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={()=> {
                                    history.push({
                                        pathname: '/admin/portaluser',
                                        state: {  hospice_organization_id:patient.hospice_organization_id,
                                            region_id:patient.region_id,
                                            team_id:patient.team_id,
                                            first_name:patient.first_name,
                                            last_name: patient.last_name,
                                            type: "Patient",
                                            phone_number: patient.phone_number,
                                            patient_id:patient.patient_id
                                        }
                                    })
                                }}
                            >
                                Create Patient Portal Account
                            </Button>)}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <h3>
                        Caregiver Accounts
                        </h3>

                    </Grid>
                    {patient.caregiver_first_id && (
                        <Grid container spacing={1} key={1} style={{borderBottom:'1px solid lightgrey',marginBottom:12}}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Name'
                                               value={patient.caregiver_first_name_primary + " " + patient.caregiver_last_name_primary}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Phone Number'
                                               value={patient.caregiver_phone_number_primary}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Relation'
                                               value={patient.caregiver_relation_primary}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex'}}>
                                {patient.cargiver_first_has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>
                                    {patient.caregiver_first_name_primary + " " + patient.caregiver_last_name_primary}
                                    has already portal account</span>) : (
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={()=> {
                                            history.push({
                                                pathname: '/admin/portaluser',
                                                state: {  hospice_organization_id:patient.hospice_organization_id,
                                                    region_id:patient.region_id,
                                                    team_id:patient.team_id,
                                                    first_name:patient.caregiver_first_name_primary,
                                                    last_name: patient.caregiver_last_name_primary,
                                                    type: 'CaregiverPrimary',
                                                    phone_number: patient.caregiver_phone_number_primary,
                                                    patient_id: patient.patient_id,
                                                    caregiver_id:patient.caregiver_first_id,
                                                }
                                            })
                                        }}
                                    >
                                        Create portal access
                                    </Button>)}
                            </Grid>
                        </Grid>
                    )}
                    {patient.caregiver_second_id && (
                        <Grid container spacing={1} key={1} style={{borderBottom:'1px solid lightgrey', marginBottom:12}}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Name'
                                    value={patient.caregiver_first_name_second + " " + patient.caregiver_last_name_second}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Phone Number'
                                    value={patient.caregiver_phone_number_second}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Relation'
                                    value={patient.caregiver_relation_second}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex'}}>
                                {patient.cargiver_second_has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>
                                    {patient.caregiver_first_name_second + " " + patient.caregiver_last_name_second}
                                    has already portal account</span>) : (
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={()=> {
                                            history.push({
                                                pathname: '/admin/portaluser',
                                                state: {  hospice_organization_id:patient.hospice_organization_id,
                                                    region_id:patient.region_id,
                                                    team_id:patient.team_id,
                                                    first_name:patient.caregiver_first_name_second,
                                                    last_name: patient.caregiver_last_name_second,
                                                    type: 'CaregiverSecondary',
                                                    phone_number: patient.caregiver_phone_number_second,
                                                    patient_id: patient.patient_id,
                                                    caregiver_id:patient.caregiver_second_id,
                                                }
                                            })
                                        }}
                                    >
                                        Create portal access
                                    </Button>)}
                            </Grid>
                        </Grid>
                    )}
                    {patient.caregiver_third_id && (
                        <Grid container spacing={1} key={1} style={{borderBottom:'1px solid lightgrey',marginBottom:12}}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Name'
                                               value={patient.caregiver_first_name_third + " " + patient.caregiver_last_name_third}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Phone Number'
                                               value={patient.caregiver_phone_number_third}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <DisabledInput label='Relation'
                                               value={patient.caregiver_relation_third}
                                               width={'100%'}/>
                            </Grid>
                            <Grid item xs={12} style={{display:'flex'}}>
                                {patient.cargiver_third_has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>
                                {patient.caregiver_first_name_third + " " + patient.caregiver_last_name_third}
                                    has already portal account</span>) : (
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={()=> {
                                            history.push({
                                                pathname: '/admin/portaluser',
                                                state: {  hospice_organization_id:patient.hospice_organization_id,
                                                    region_id:patient.region_id,
                                                    team_id:patient.team_id,
                                                    first_name:patient.caregiver_first_name_third,
                                                    last_name: patient.caregiver_last_name_third,
                                                    type: 'CaregiverTertiary',
                                                    phone_number: patient.caregiver_phone_number_third,
                                                    patient_id: patient.patient_id,
                                                    caregiver_id:patient.caregiver_third_id,
                                                }
                                            })
                                        }}
                                    >
                                        Create portal access
                                    </Button>)}
                            </Grid>
                        </Grid>
                    )}
                </Grid>

            </Grid>
        </React.Fragment>
    )
};

const DisabledInput = (props) => {
    return (
        <div>
            <div style={{
                marginBottom: -9, zIndex: 2,
                position: 'relative',
                width: props.width ? props.width : '100%',
                marginLeft: '5px',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <span
                    style={{
                        backgroundColor: 'white',
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginLeft: 2,
                        fontSize: 12,
                        color: '#979797'
                    }}
                >
                    {props.label}
                </span>
            </div>

            <div
                style={{
                    height: 34,
                    // width:200,
                    zIndex: 1,
                    display: 'flex',
                    fontSize: 14,
                    justifyContent: 'flex-start',
                    border: '1px solid #F0F0F0',
                    borderRadius: '2px',
                    paddingTop: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    lineHeight: '19px',
                    paddingBottom: 3
                }}
            >
                {props?.value?.length > 20 ?
                    <Tooltip title={props.value}>
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",}}
                        >{props.value}</span>
                    </Tooltip> : <span style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",}}
                    >{props.value}</span>
                }

            </div>
        </div>
    )
};