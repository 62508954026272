import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";
import {endpoints} from "../../../constants/endpoints";
import moment from "moment";

export default function UnauthorizedAccess(){
    const [unauthorizedList, setUnauthorizedList] = useState([]);
    const [timezone, setTimeZone] = useState('default');
    function getUnauthorizedAccessList(){
        const token = JSON.parse(localStorage.getItem('key'));
        const userData = jwt(token.accessToken);
        checkTimezone(userData.payload.timezone);
        fetch(endpoints.getUnauthorizedLogs,{
            method:"GET",
            headers:{
                "Content-Type":"application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
        }).then(res => res.json())
            .then(res => setUnauthorizedList(res.data))

    }
    useEffect(()=>{
       getUnauthorizedAccessList()
    },[])




    function checkTimezone(timezone){
        if (timezone === "Hawaii Time [UTC-10]"){
            setTimeZone("-10:00")
        } else if (timezone === "Alaska Time [UTC-9]"){
            setTimeZone("-09:00")
        } else if (timezone === "Pacific Time [UTC-8]"){
            setTimeZone("-08:00")
        } else if (timezone === "Mountain Time [UTC-7]"){
            setTimeZone("-07:00")
        } else if (timezone === "Central Time [UTC-6]"){
            setTimeZone("-06:00")
        } else if (timezone === "Eastern Time [UTC-5]"){
            setTimeZone("-05:00")
        }
    }
    return(
        <Grid container style={{padding:16}}>
            <Grid container>
                <Grid item xs={12} style={{fontSize:20, fontWeight:'bold', textAlign:'left', padding:16}}>
                    Following users had tried to access restricted resources and got the Unauthorized screen:
                </Grid>
                {unauthorizedList.map((entry, i)=> {
                    return(
                        <Grid item lg={3} md={4} sm={6} xs={12} style={{padding:8}}>
                            <Grid container style={{textAlign:'left', padding:16, backgroundColor:"white", borderRadius:4, border:'1px solid whitesmoke'}}>
                                <Grid item xs={12}>
                                    Name: <strong>{entry.full_name}</strong>
                                </Grid>
                                <Grid item xs={12}>
                                    Action: <strong>{entry.operation}</strong>
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Username:  <strong>{entry.username}</strong> - #{entry.user_id}
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Email: <strong>{entry.email}</strong>
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Date: <strong>{moment(entry.created_date).zone(timezone).format("LLL")}</strong>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
