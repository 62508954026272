import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode";
import {useHistory} from "react-router-dom";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from "@material-ui/core/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useDispatch} from "react-redux";
import {logout} from "../../redux/actions";

export default function UnauthorizedRoute(props){
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    function recordUnauthorizedAttempt(){
        const token = JSON.parse(localStorage.getItem('key'));
        const userData = jwt(token.accessToken);
        setUsername(userData.payload.username);
        fetch(endpoints.addUnauthorizedLog,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                userId: userData.payload.user_id,
                operation: props?.location?.state?.detail
            }),
        }).then(res => res.json())

    }
    useEffect(()=>{
        props?.location?.state?.detail && recordUnauthorizedAttempt()
    },[])
    return (
        <Grid container style={{fontSize:24, height:'calc(100vh - 60px)'}} justify='center' alignContent={'center'} alignItems={'center'}>
            <Grid item xs={8} style={{ background:'white', borderRadius:4}}>
                <Grid container alignItems={'center'} alignContent={'center'} style={{padding:48, paddingLeft:12, paddingRight:36, paddingBottom:12}}>
                    <Grid item xs={4}>
                        <LockOutlinedIcon style={{fontSize:200, color:'#4F889E'}}/>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container style={{textAlign:'left'}}>
                            <Grid item xs={12} style={{fontSize:36, fontWeight:'bold', color:"#4F889E"}}>
                                Whoops!
                            </Grid>
                            <Grid item xs={12} style={{fontSize:20, paddingTop:8}}>
                                This URL is valid but you are not authorized for this content. Please contact <a href={'/support'} style={{textDecoration:'none'}}>Support</a>.
                            </Grid>
                            <Grid item xs={12} style={{fontSize:16, paddingTop:16}}>
                                You are currently signed as: <strong style={{color:"#4F889E"}}>{username}</strong>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop:16}}>
                                <Button color={'primary'} size={'small'} variant={'outlined'} onClick={()=> {
                                    dispatch(logout());
                                    history.push('/login');
                                }}>Sign in as a different user</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{display:'flex', paddingTop:36}}>
                        <Button startIcon={<ArrowBackIcon/>} color={'primary'} size={"small"} onClick={()=> history.push("/")}>Go Back</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
