import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import Input from '@material-ui/core/Input';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import SearchIcon from "../../../assets/icon_search.svg";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        paddingBottom: '15px',
        marginBottom: '5px',
        overflow: "visible",
        width:'100%'
    },
    searchIcon: {
        color: '#546e7a',
        textAlign: 'left'
    },
    keywords: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'left',
        width: '100%',
        textAlign: 'left',
        verticalAlign: 'center'
    },
    input: {
        '&::placeholder': {
            color: 'blue'
        }
    },
    criteria: {
        margin: theme.spacing(1)
    },
    criterias: {
        padding: theme.spacing(0),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingLeft: '7%',
        height: '48px'
    },
    selects: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    inNetwork: {
        marginLeft: '-7px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    menuItem: {
        padding: 0
    },
    margin: {
        margin: theme.spacing(1),
    },
    searchButton: {
        color: '#FFF',
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: 'none',
        paddingLeft: 43,
        paddingRight: 43,
        "&:hover": {
            backgroundColor: '#004175'
        },
        maxWidth:"100%"
    },
    clearButton: {
        color: '#00223D',
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: '#fff',
        border:'1px solid #00223D',
        paddingLeft:22,
        paddingRight:22,
        paddingTop:9,
        paddingBottom:9,
        maxWidth:135,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 22,
            paddingRight: 22,
        },
    },
    filterOptions: {
        zIndex: '11',
    },
    filterContainer: {
        paddingDown: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    filterName: {
        width: "200px"
    }
}));

export const TabSearchBar = (props) => {
    const classes = useStyles();
    const [input, setInput] = useState(props.filtersValue.input);
    useEffect(() => {
        setInput(props.filtersValue.input)
    }, [props.filtersValue.input]);
    useEffect(() => {
        if (input !== ''){
            props.handleInputChange(input)
        }
    }, [input]);
    return (
        <Card className={classes.root} style={{boxShadow: 'none', backgroundColor: '#F9FAFC',}}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <div className={classes.keywords} style={{boxShadow: 'none', backgroundColor: 'none'}}>
                        <Grid item sm={7} xs={7}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #A3C7D2',
                                height: '100%'
                            }}>
                                <img
                                    src={SearchIcon}
                                    alt="logo"
                                    width='24'
                                    className={classes.cursor}
                                    style={{marginRight: 16, marginLeft: 18}}
                                />
                                <Input
                                    id={"searchInput"}
                                    disableUnderline
                                    onChange={(e) => setInput(e.target.value)}
                                    placeholder={props.placeholder ? props.placeholder : "Search..."}
                                    value={input}
                                    style={{width: '98%'}}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={4}>
                            <Button className={classes.searchButton} variant='contained' color='primary'
                                    onClick={()=>{
                                        props.getPatients && props.getPatients(5,0);
                                        props.setPage && props.setPage(0);
                                        props.setRowsPerPage && props.setRowsPerPage(5);
                                    }}>{props.buttonText ? props.buttonText : 'SEARCH'}</Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        boxShadow: 'none', backgroundColor: 'none',
                        display: 'flex',
                        alignItems: 'left',
                        width: '100%',
                        textAlign: 'left',
                        verticalAlign: 'center'
                    }}>
                    <Grid item lg={7} md={7} sm={7} xs={7} style={{display:'flex', alignItems:'center', marginRight:0, paddingTop:8}}>
                        <Grid container spacing={1} className={classes.filterContainer}>
                            {
                                props.filterOptions.map((filter, index) => (
                                    filter.isSelect ?
                                        <Grid item lg={3} md={4} sm={6} xs={12} key={index} style={{zIndex: 300 - index}}>
                                            <Select
                                                fullwidth
                                                isClearable
                                                className={classes.filterOptions}
                                                isSearchable={true}
                                                name={filter.name}
                                                placeholder={filter.placeholder}
                                                value={(filter.value === "All" || filter.value === undefined) ? null : [{
                                                    label: filter.value,
                                                    value: filter.value
                                                }]}
                                                options={filter.options}
                                                onChange={(event) => {
                                                    const obj = {
                                                        target: {
                                                            ...event,
                                                            name: filter.name,
                                                        }
                                                    };
                                                    props.handleFilterUpdate(obj);
                                                }}
                                            />
                                        </Grid> :
                                        <Grid item xs={2} className={classes.filter} key={index}>
                                            <Typography variant="subtitle2" className={classes.filterName}>
                                                {filter.value ? filter.positive : filter.negative}
                                            </Typography>
                                            <Switch checked={filter.value}
                                                    onClick={(event) => {
                                                        const obj = {
                                                            target: {
                                                                name: event.target.name,
                                                                value: !filter.value
                                                            }
                                                        };
                                                        props.handleFilterUpdate(obj)
                                                    }}
                                                    name={filter.name}
                                                    color="primary"
                                            />
                                        </Grid>
                                ))}
                        </Grid>
                    </Grid>
                        <Grid item xs={1}></Grid>
                    <Grid item xs={4} style={{textAlign: 'left', paddingTop: '4px'}}>
                        <Button variant="outlined" className={classes.clearButton} onClick={props.clearFilters}>CLEAR FILTERS</Button>
                    </Grid>
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
};
