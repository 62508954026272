import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes';
import {BrowserRouter} from 'react-router-dom';
import {applyMiddleware, createStore} from 'redux';
import allReducers from './redux/reducers';
import {Provider} from 'react-redux';
import {save, load} from "redux-localstorage-simple";

const createStoreWithMiddleware
    = applyMiddleware(
    save() // Saving done here
)(createStore);

const store = createStoreWithMiddleware(
    allReducers,
    load(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Routes/>
        </Provider>
    </BrowserRouter>, document.getElementById('root'));