import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Tooltip from "@material-ui/core/Tooltip";
import BootstrapInput from '../../../common/bootstrapInput/bootstrapInput';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CustomInputField } from "../../../common/InputField/InputField";
import { validationTypes } from "../../../constants/validations/validationTypes";
import { patientRecordNames } from "../../../constants/inputs/patientRecordNames";
import { labels } from "../../../constants/inputs/labels";
import PossibleDuplicatesDrawer from "./possibleDuplicates/PossibleDuplicatesDrawer";
import { placeholders } from "../../../constants/inputs/paceholders";
import { errorMessages } from "../../../constants/messages/responseMessages";
import { tooltips } from "../../../constants/inputs/tooltips";
import { sectionTitles } from "../../../constants/names/titles";
import RSelect from "react-select";
import _ from "lodash"
import jwt from "jwt-decode";
import { getZipErrorMessage } from "./patientHelper";

const useStyles = makeStyles((theme) => ({
    formControl: {
        paddingTop: 10,
        display: "flex",
        textAlign: "left",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    spacing: 8
}));

export default function PatientDemographics(props) {
    const {
        validType, handleInput, hardValidationCheck, softValidationCheck, showPossibleDuplicates, setShowPossibleDuplicates,
        hardValidation, setHardValidation, handleSelectRequiredInput, handleRequiredInput, patient, missingValues, handlePatientPhoneInput,
        possibleDuplicates, zipStatus, invalidMedicalRecord, checkDuplicateMedicalRecord, edit, handleSelectInput, validContract
    } = props;
    const classes = useStyles();
    const [phone, setPhone] = useState(true);
    const token = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
    const sortedDmeProvidersList = props.dmeProvidersList.sort((a, b) => a.label.localeCompare(b.label));
    const sortedHospiceList = props.hospiceList.sort((a, b) => a.label.localeCompare(b.label));
    const sortedRegionList = props.regionList.sort((a, b) => a.label.localeCompare(b.label));
    const sortedNursesOptions = props.nursesOptions.sort((a, b) => a.label.localeCompare(b.label));
    return (
        <React.Fragment>
            <Grid container justify={"flex-start"} alignItems={"flex-start"} spacing={2}>
                <Grid item xs={12} style={{ display: "flex" }}>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                        {sectionTitles.patientDemographics}
                    </Typography>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"stretch"} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            hardValidation={hardValidation}
                            setHardValidation={setHardValidation}
                            isRequired={false}
                            size={"small"}
                            // isMissing={missingValues.medical_record}
                            validationType={validationTypes.lettersAndNumbers}
                            name={patientRecordNames.medical_record}
                            label={labels.medical_record_number}
                            value={patient.medical_record}
                            onChange={handleInput}
                            onBlur={hardValidationCheck}
                            isValid={validType.medical_record}
                            checkDuplicates={checkDuplicateMedicalRecord}
                            duplicate={invalidMedicalRecord}
                        />
                    </Grid>
                    {edit ? <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={false}
                            size={"small"}
                            validationType={validationTypes.onlyNumbers}
                            name={labels.id}
                            label={labels.id}
                            value={edit ? edit : ""}
                            disabled={true}
                        />
                    </Grid> : ""}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.first_name}
                            placeholder={placeholders.first_name}
                            validationType={validationTypes.onlyLettersAndSpacesNames}
                            name={patientRecordNames.first_name}
                            label={labels.first_name}
                            value={patient.first_name}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.first_name}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={false}
                            isMissing={false}
                            validationType={validationTypes.onlyLettersAndSpacesNames}
                            name={patientRecordNames.MI}
                            label={labels.mi}
                            value={patient.MI}
                            onChange={handleInput}
                            onBlur={handleInput}
                            isValid={validType.MI}
                            size={"small"}
                        />
                    </Grid>
                    {edit ? <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: 33 }}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.last_name}
                            placeholder={placeholders.last_name}
                            validationType={validationTypes.onlyLettersAndSpacesNames}
                            name={patientRecordNames.last_name}
                            label={labels.last_name}
                            value={patient.last_name}
                            onChange={handleRequiredInput}
                            isValid={validType.last_name}
                            onBlur={hardValidationCheck}
                            hardValidation={hardValidation}
                            setHardValidation={setHardValidation}
                            size={"small"}
                        />
                    </Grid> : <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.last_name}
                            placeholder={placeholders.last_name}
                            validationType={validationTypes.onlyLettersAndSpacesNames}
                            name={patientRecordNames.last_name}
                            label={labels.last_name}
                            value={patient.last_name}
                            onChange={handleRequiredInput}
                            isValid={validType.last_name}
                            onBlur={hardValidationCheck}
                            hardValidation={hardValidation}
                            setHardValidation={setHardValidation}
                            size={"small"}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <FormControl className={classes.formControl}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    inputVariant="outlined"
                                    placeholder='MM/DD/YYYY'
                                    size={"small"}
                                    id="date-of-birth"
                                    label="Date of Birth *"
                                    InputProps={{
                                        style: {
                                            border: missingValues.date_of_birth === true && "1px solid red",
                                        }
                                    }}
                                    format="MM/dd/yyyy"
                                    value={patient.date_of_birth}
                                    onChange={(e) => {
                                        props.handleBirthdayInput(e)
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {missingValues.date_of_birth &&
                                <FormHelperText style={{ color: 'red' }}>{tooltips.isRequired}
                                </FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <FormControl
                            required
                            error={missingValues.gender}
                            style={{ display: "flex", textAlign: "left" }}
                        >
                            <InputLabel>Gender</InputLabel>
                            <Select
                                labelId="team-select-label"
                                id="gender_select"
                                name="gender"
                                value={patient.gender}
                                style={{
                                    border: missingValues.gender === true && "1px solid red",
                                    borderRadius: 4
                                }}
                                onChange={handleSelectRequiredInput}
                                onBlur={handleSelectRequiredInput}
                                input={<BootstrapInput />}
                                defaultValue={'All'}
                            >
                                <MenuItem value={"All"}><em> Select Gender</em></MenuItem>
                                <MenuItem value={`Male`}>Male</MenuItem>
                                <MenuItem value={`Female`}>Female</MenuItem>
                                <MenuItem value={`Other`}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red', textAlign: 'left' }}>
                            {missingValues.gender && errorMessages.required_field}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: 32 }}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.patient_weight}
                            placeholder={placeholders.patient_weight}
                            validationType={validationTypes.floatingNumbers}
                            name={patientRecordNames.patient_weight}
                            label={labels.patient_weight}
                            value={patient.patient_weight}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.patient_weight}
                            enableNoZeroValue={true}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: 32 }}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.patient_height_in_feet}
                            placeholder={placeholders.patient_height_in_feet}
                            validationType={validationTypes.onlyNumbers}
                            name={patientRecordNames.patient_height_in_feet}
                            label={labels.patient_height_in_feet}
                            value={patient.patient_height_in_feet}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.patient_height_in_feet}
                            enableNoZeroValue={true}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: 32 }}>
                        <CustomInputField
                            isRequired={false}
                            // isMissing={missingValues.patient_height_in_inches}
                            placeholder={placeholders.patient_height_in_inches}
                            validationType={validationTypes.onlyNumbers}
                            name={patientRecordNames.patient_height_in_inches}
                            label={labels.patient_height_in_inches}
                            value={patient.patient_height_in_inches}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.patient_height_in_inches}
                            enableNoZeroValue={true}
                            size={"small"}
                        />
                    </Grid>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"flex-start"} style={{ paddingTop: 20 }}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                            {sectionTitles.address}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"flex-start"} spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.patient_address1}
                            placeholder={placeholders.patient_address1}
                            name={patientRecordNames.patient_address1}
                            label={labels.address1}
                            value={patient.patient_address1}
                            onChange={handleRequiredInput}
                            onBlur={softValidationCheck}
                            isValid={true}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                            id={patientRecordNames.patient_address2}
                            name={patientRecordNames.patient_address2}
                            label={labels.address2}
                            placeholder={placeholders.patient_address2}
                            value={patient.patient_address2}
                            fullWidth
                            variant={"outlined"}
                            size={"small"}
                            onChange={handleInput}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6} md={6} lg={3}>*/}
                    {/*    /!*<InputLabel style={{*!/*/}
                    {/*    /!*    fontSize: '0.8em',*!/*/}
                    {/*    /!*    // paddingBottom: '10px',*!/*/}
                    {/*    /!*    textAlign: 'left'*!/*/}
                    {/*    /!*}}>{labels.requiredPhone}</InputLabel>*!/*/}
                    {/*    <PhoneInput*/}
                    {/*        country={'us'}*/}
                    {/*        placeholder={placeholders.phone}*/}
                    {/*        disableCountryCode={true}*/}
                    {/*        containerStyle={{width: '100%'}}*/}
                    {/*        inputStyle={{width: '100%'}}*/}
                    {/*        value={patient.phone_number}*/}
                    {/*        onlyCountries={['us']}*/}
                    {/*        onChange={(e) => {*/}
                    {/*            if (e.length === 10) {*/}
                    {/*                setPhone(true)*/}
                    {/*            }*/}
                    {/*            handlePatientPhoneInput(e)*/}
                    {/*        }}*/}
                    {/*        inputProps={{*/}
                    {/*            label: 'test',*/}
                    {/*            required: true,*/}
                    {/*            id:"patient_phone"*/}
                    {/*        }}*/}
                    {/*        onBlur={(e) => {*/}
                    {/*            if (e.target.value.length === 14) {*/}
                    {/*                setPhone(true)*/}
                    {/*            } else {*/}
                    {/*                setPhone(false)*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        isValid={phone}*/}
                    {/*    />*/}
                    {/*    <FormHelperText style={{*/}
                    {/*        color: 'red',*/}
                    {/*        textAlign: 'left'*/}
                    {/*    }}>{!phone && errorMessages.invalid_phone}</FormHelperText>*/}
                    {/*    <FormHelperText style={{*/}
                    {/*        color: 'red',*/}
                    {/*        textAlign: 'left'*/}
                    {/*    }}>{missingValues.phone_number && errorMessages.required_field}</FormHelperText>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                            style={{ width: '100%' }}
                            helperText={missingValues.zip && errorMessages.required_field}
                            error={missingValues.zip}
                            required
                            id={patientRecordNames.zip}
                            name={patientRecordNames.zip}
                            label={labels.zip}
                            variant={"outlined"}
                            value={patient.zip}
                            size={"small"}
                            onChange={(e) => {
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    props.handleZipInput(e.target.value, true)
                                } else {
                                    props.handleZipInput(e.target.value, false)
                                }
                            }}
                            onBlur={(e) => {
                                softValidationCheck()
                                if (/^[0-9]*$/.test(e.target.value)) {
                                    props.handleZipInput(e.target.value, true)
                                } else {
                                    props.handleZipInput(e.target.value, false)
                                }
                            }}
                        />
                        <FormHelperText
                            style={{ color: 'red', textAlign: 'left' }}
                        >
                            {getZipErrorMessage(zipStatus)}
                        </FormHelperText>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.city}
                            validationType={validationTypes.onlyLettersAndSpaces}
                            name={patientRecordNames.city}
                            label={labels.city}
                            value={patient.city}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.city}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <CustomInputField
                            isRequired={true}
                            isMissing={missingValues.state}
                            validationType={validationTypes.onlyLettersAndSpaces}
                            name={patientRecordNames.state}
                            label={labels.state}
                            value={patient.state}
                            onChange={handleRequiredInput}
                            onBlur={handleRequiredInput}
                            isValid={validType.state}
                            size={"small"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        {/*<InputLabel style={{*/}
                        {/*    fontSize: '0.8em',*/}
                        {/*    // paddingBottom: '10px',*/}
                        {/*    textAlign: 'left'*/}
                        {/*}}>{labels.requiredPhone}</InputLabel>*/}
                        <PhoneInput
                            country={'us'}
                            placeholder={placeholders.phone}
                            disableCountryCode={true}
                            containerStyle={{ width: '100%' }}
                            inputStyle={{
                                width: '100%',
                                border: missingValues.phone_number === true && "1px solid red"
                            }}
                            value={patient.phone_number}
                            onlyCountries={['us']}
                            onChange={(e) => {
                                if (e.length === 10) {
                                    setPhone(true)
                                }
                                handlePatientPhoneInput(e)
                            }}
                            inputProps={{
                                label: 'test',
                                required: true,
                                id: 'patient_phone'
                            }}
                            onBlur={(e) => {
                                if (e.target.value.length === 14) {
                                    setPhone(true)
                                } else {
                                    setPhone(false)
                                }
                            }}
                            isValid={phone}
                        />
                        <FormHelperText style={{
                            color: 'red',
                            textAlign: 'left'
                        }}>{!phone && errorMessages.invalid_phone}</FormHelperText>
                        <FormHelperText style={{
                            color: 'red',
                            textAlign: 'left'
                        }}>{missingValues.phone_number && errorMessages.required_field}</FormHelperText>
                    </Grid>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"flex-start"} spacing={2}>
                    {
                        token.role_id === 6 && !edit && <Grid item xs={12} sm={6} md={6} lg={3}>
                            <div style={{ paddingTop: 10, textAlign: "left" }}>
                                <InputLabel>{labels.dmeProvider}</InputLabel>
                                <RSelect
                                    fullWidth
                                    inputId='dme_select'
                                    isSearchable
                                    placeholder={placeholders.selectProvider}
                                    value={_.findIndex(props.dmeData, { "dme_organization_id": props.selectedDme }) > -1 && props.selectedDme !== null && [{
                                        value: props.dmeData[_.findIndex(props.dmeData, { "dme_organization_id": props.selectedDme })].dme_name,
                                        label: props.dmeData[_.findIndex(props.dmeData, { "dme_organization_id": props.selectedDme })].dme_name
                                    }]}
                                    options={sortedDmeProvidersList}
                                    onChange={(event) => {
                                        props.setSelectedDme(event.value)
                                    }}
                                />
                            </div>
                            <FormHelperText style={{ color: 'red', textAlign: 'left' }}>
                                {missingValues.hospice_organization_id && errorMessages.required_field}
                            </FormHelperText>
                        </Grid>
                    }
                    {
                        token.hospice_organization_id === null && !edit && <Grid item xs={12} sm={6} md={6} lg={3}>
                            <div style={{ paddingTop: 10, textAlign: "left" }}>
                                <InputLabel>{labels.hospiceOrganization}</InputLabel>
                                <RSelect
                                    fullWidth
                                    inputId='hospice_select'
                                    isSearchable
                                    // isClearable
                                    placeholder={placeholders.selectHospice}
                                    value={_.findIndex(props.hospiceData, { "hospice_id": patient.hospice_organization_id }) > -1 && patient.hospice_organization_id !== '' && [{
                                        value: props.hospiceData[_.findIndex(props.hospiceData, { "hospice_id": patient.hospice_organization_id })].hospice_name,
                                        label: props.hospiceData[_.findIndex(props.hospiceData, { "hospice_id": patient.hospice_organization_id })].hospice_name
                                    }]}
                                    options={sortedHospiceList}
                                    onChange={(event) => {
                                        const obj = {
                                            name: "hospice_organization_id",
                                            ...event,
                                        };
                                        props.handleRSInput(obj)
                                    }}
                                />
                            </div>
                            <FormHelperText style={{ color: 'red', textAlign: 'left' }}>
                                {missingValues.hospice_organization_id && errorMessages.required_field}
                            </FormHelperText>
                        </Grid>
                    }
                    {token.hospice_organization_id === null && !edit && <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div style={{ paddingTop: 10, textAlign: "left" }}>
                            <InputLabel>{labels.region}</InputLabel>
                            <RSelect
                                fullWidth
                                inputId='region_select'
                                isSearchable
                                isClearable
                                placeholder={placeholders.selectRegion}
                                value={_.findIndex(props.regionData, { "hospice_region_id": patient.region_id }) > -1 && patient.region_id !== '' && [{
                                    value: props.regionData[_.findIndex(props.regionData, { "hospice_region_id": patient.region_id })].name,
                                    label: props.regionData[_.findIndex(props.regionData, { "hospice_region_id": patient.region_id })].name
                                }]}
                                options={sortedRegionList}
                                onChange={(event) => {
                                    const obj = {
                                        name: "region_id",
                                        ...event,
                                    };
                                    props.handleRSInput(obj)
                                }}
                            />
                        </div>
                        <FormHelperText style={{ color: validContract ? 'green' : 'red', textAlign: 'left' }}>
                            {validContract ? 'Valid contract.' : 'No contract present. Please select region or create contract.'}
                        </FormHelperText>
                    </Grid>
                    }
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <FormControl
                            error={missingValues.care_setting}
                            style={{ display: "flex", textAlign: "left" }}
                        >
                            <InputLabel>Care Setting</InputLabel>
                            <Select
                                labelId={patientRecordNames.care_setting}
                                id={patientRecordNames.care_setting}
                                name={patientRecordNames.care_setting}
                                value={patient.care_setting}
                                onChange={handleSelectInput}
                                onBlur={handleSelectInput}
                                input={<BootstrapInput />}
                                defaultValue={'All'}
                            >
                                <MenuItem value="All"><em> Select Care Setting</em></MenuItem>
                                <MenuItem value={`Patient Home`}>Patient Home</MenuItem>
                                <MenuItem value={`Facility`}>Facility</MenuItem>
                                <MenuItem value={`Inpatient unit`}>Inpatient Unit</MenuItem>
                            </Select>
                        </FormControl>
                        <FormHelperText style={{
                            color: 'red',
                            textAlign: 'left'
                        }}>{missingValues.care_setting && errorMessages.required_field}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <FormControl
                            style={{ display: "flex", textAlign: "left" }}
                            required={patient.care_setting === 'Facility'}
                            error={patient.care_setting === 'Facility' && missingValues.facility}
                            disabled={patient.care_setting !== 'Facility'}
                        >
                            <InputLabel>Facility</InputLabel>
                            <Select
                                labelId="facility-select-label"
                                id="facility-select"
                                name="facility"
                                value={patient.facility}
                                onChange={handleSelectRequiredInput}
                                onBlur={handleSelectRequiredInput}
                                input={<BootstrapInput />}
                                defaultValue={'All'}
                            >
                                <MenuItem value="All"><em>Select Facility</em></MenuItem>
                                {props.facilitiesData && props.facilitiesData.map((item, index) => {
                                    return (
                                        <MenuItem value={item.facility_id} key={index}>{item.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red', textAlign: 'center' }}>
                            {missingValues.facility && errorMessages.required_field}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <FormControl
                            // required
                            style={{ display: "flex", textAlign: "left" }}
                        >
                            <InputLabel>Team</InputLabel>
                            <Select
                                labelId="team-select-label"
                                id="team_select"
                                name="team_id"
                                value={patient.team_id}
                                onChange={handleSelectRequiredInput}
                                onBlur={handleSelectRequiredInput}
                                input={<BootstrapInput />}
                                defaultValue={'All'}
                            >
                                <MenuItem value="All"><em> Select Team</em></MenuItem>
                                {props.teamsData && props.teamsData.sort((a, b) => a.team_name.localeCompare(b.team_name)).map((item, index) => {
                                    return (
                                        <MenuItem value={item.team_id} key={index}>{item.team_name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red', textAlign: 'center' }}>
                            {missingValues.team && errorMessages.required_field}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <div style={{ paddingTop: 10, textAlign: "left" }}>
                            <InputLabel>{labels.nurse}</InputLabel>
                            <RSelect
                                inputId='nurse_select'
                                fullWidth
                                id={"select_nurse"}
                                isSearchable
                                placeholder={"Select Nurse"}
                                value={_.findIndex(props.nurses, { "nurse_id": patient.nurse_id }) > -1 && patient.nurse_id !== '' && [{
                                    value: props.nurses[_.findIndex(props.nurses, { "nurse_id": patient.nurse_id })].name,
                                    label: props.nurses[_.findIndex(props.nurses, { "nurse_id": patient.nurse_id })].name
                                }]}
                                options={sortedNursesOptions}
                                onChange={(event) => {
                                    const obj = {
                                        name: "nurse_id",
                                        ...event,
                                    };
                                    props.handleRSInput(obj)
                                }}
                            />

                        </div>
                        <FormHelperText style={{ color: 'red', textAlign: 'center' }}>
                            {missingValues.nurse_id && errorMessages.required_field}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
            <PossibleDuplicatesDrawer
                isOpen={showPossibleDuplicates} // drawer value (open / hidden )
                setShowPossibleDuplicates={setShowPossibleDuplicates} // setter for drawer (open / hidden)
                possibleDuplicates={possibleDuplicates} //array of possible duplicates
            />
        </React.Fragment>
    );
}
