import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode"
import {status} from "../../constants/status/status";

export const checkUsersForThisEmail = async (email, roleName, orgType) => {
    const token = JSON.parse(localStorage.getItem('key'));

    const rolePromise = await fetch(endpoints.getRoleId + roleName + "/" + orgType, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const roleData = await rolePromise.json();

    if (rolePromise.status === status.success) {
        const promise = await fetch(endpoints.checkUserByEmailAndRole, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(
                {
                    role_id: roleData.data,
                    email: email
                })
        });
        const response = await promise.json();
        return response.status === "success";
    } else {
        return false;
    }
};

export const checkTokenAvailability = async () => {
    const localToken = JSON.parse(localStorage.getItem('key'));
    if (localToken !== null && localToken !== undefined){
        const token = jwt(localToken.accessToken);
        const {exp} = token;
        const currentTime = new Date().getTime();
        return currentTime < exp * 1000;
    } else {
        return false
    }
};

export const checkPaceHospice = async (hospiceId) => {
    if (hospiceId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const paceHospicePromise = await fetch(endpoints.getPaceSettings + hospiceId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const resData = await paceHospicePromise.json();
        return resData.data[0].ispace;
    }

};

export const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export const formatNumber = (number, version) => {
    if(number) {
        let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            switch (version) {
                case "parentheses":
                    return `(${match[1]}) ${match[2]}-${match[3]}`
                default:
                    return match[1] + '-' + match[2] + '-' + match[3]
            }
        }
    }
    return number;
}

export const convertImgToBase64 = (url, callback) => {
    let canvas = document.createElement('CANVAS');
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img,0,0);
        let dataURL = canvas.toDataURL('image/png');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
    };
    img.src = url;
}

export const getHospiceLogo = async (setter, token, hospiceId) => {
    const promise = await fetch(endpoints.getHospiceLogo + hospiceId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    })
    const response = await promise.json();
    if(response.status === "success"){
        setter(response.data);
    }
}
