import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {getAllSurveys} from "../../../utils/helpers/dataGetter";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../../constants/names/titles";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ViewSurvey from "../../../../redux/viewSurvey";

export default function AllSurveys (){
    const [showModal, setShowModal] = useState(false);
    const [rowData, setRowData] = useState();
    const columns = [
        {title: 'Edit', field: 'manufacturer_id', width: 20,
            render: rowData => <Grid container>
                <Tooltip title={'Edit Survey'}>
                    <IconButton
                        onClick={()=> {
                            history.push({
                                pathname: '/admin/survey/edit',
                                state: {
                                    rowData:rowData,
                                    edit:true
                                }
                            })
                        }}
                    >
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
        },
        {title: 'Name', field: 'survey_name', width: 500},
        {title: 'View', field: 'survey_name', width: 200,
            render: rowData => <Grid container>
                <Button variant={'outlined'} color={'primary'} onClick={()=> {
                    setShowModal(true);
                    setRowData(rowData);
                }}>View Survey</Button>
            </Grid>
        },
    ];

    const [surveys, setSurveys] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllSurveys(setSurveys);
    }, []);

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.surveyList}
                    </Typography>
                </Grid>
                <Grid item>
                    <MaterialTable
                        title={componentTitles.surveyList}
                        columns={columns}
                        data={surveys}
                        options={{
                            pageSize: 10,
                        }}
                        />
                </Grid>
            </Grid>
            <Dialog open={showModal}
                    fullWidth
                    maxWidth={'xl'}
                    onClose={()=> setShowModal(false)}
            >
                <DialogContent>
                    <ViewSurvey rowData={rowData}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
};