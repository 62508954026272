import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import Tooltip from "@material-ui/core/Tooltip";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {labels} from "../../../constants/inputs/labels";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from "react-select";
import {checkEmail, checkUsername, getPossibleUsernames} from "../createUserHelper";
import {getValidationMessage} from "../../../common/InputField/validationMessages/InputFieldErrorMessage";
import {placeholders} from "../../../constants/inputs/paceholders";
import _ from "lodash";
import {CircularProgress, IconButton, ListItem} from "@material-ui/core";
import {sectionTitles} from "../../../constants/names/titles";
import {tooltips} from "../../../constants/inputs/tooltips";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Dialog from "@material-ui/core/Dialog";
import {endpoints} from "../../../constants/endpoints";
import {userTypes} from "../../../constants/types/userTypes";
import {useSnackbar} from "notistack";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {makeStyles} from "@material-ui/core/styles";


const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};

export default function GeneralInformations(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        rolesList, regionList, handleTitleSelect, handleStatus,
        handleTeamSelect, handleRoleSelect, handleRegionSelect, handleInputChange, user,
        hospicesList, handleHospiceSelect, missingValues, possibleEmail,
        setPossibleEmail, validEmail, setValidEmail, restrictedDomains, validUsername, setValidUsername,
        isEdit, hospiceData, regionData, teamData, teamList, checkAvailability, deleteUser, userType,
    } = props;
    const [phone, setPhone] = useState(true);
    const [openDrawer,setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    const requestPasswordReset = async () => {
        const promise = await fetch(endpoints.requestPassword, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: {
                    username:user.username,
                    email:user.email
                }})
        });

        const response = await promise.json();
        if (response.status === "success") {
            enqueueSnackbar('An email to reset the password was sent to the user.', {
                variant: 'success',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        } else {
            enqueueSnackbar('Unable to reset password', {
                variant: 'warning',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        }
    };

    const sortedHospiceList = hospicesList.sort((a, b) => a.label.localeCompare(b.label));
    React.useEffect(() => {
        if(possibleEmail.length > 1 && user.first_name && user.last_name) setOpenDrawer(true);
    },[possibleEmail])


    if (hospiceData.length > 0) {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {sectionTitles.generalInformation}
                </Typography>

                <Grid container>
                    <Grid container direction="row" className={classes.item}>
                        <Grid item xs={12} sm={6} >
                            {userType === userTypes.hospice ? <Select
                                isDisabled={props.isEdit}
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={sortedHospiceList}
                                placeholder={placeholders.selectHospice}
                                value={user.organization_id === '' || (isEdit && hospiceData === []) ? null : [{
                                    label: hospiceData[_.findIndex(hospiceData, {"hospice_organization_id": user.organization_id})].hospice_name,
                                    value: user.organization_id,
                                }]}
                                onChange={event => {
                                    handleHospiceSelect(event)
                                }}
                            /> : <TextField
                                label={"Organization"}
                                disabled={true}
                                variant={"outlined"}
                                size={"small"}
                                value={user.organization_id !== '' && hospiceData[_.findIndex(hospiceData, {"hospice_organization_id": user.organization_id})].hospice_name}
                            />}
                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                {missingValues.organization_id && tooltips.isRequired}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={6} sm={3} className={classes.userStatus}>
                            <FormControl component="fieldset">
                                <FormLabel>{sectionTitles.userStatus}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                                         checked={user.status}
                                                         onChange={handleStatus}
                                        />}
                                    />
                                </FormGroup>
                                <FormHelperText>{getMessage(messageTypes.userStatus, user.status)}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={3} className={classes.userStatus}>
                                {isEdit &&
                                <FormControl component="fieldset">
                                    <FormLabel>{sectionTitles.deleteUserStatus}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                             checked={user.deleted}
                                                             onChange={() => setOpen(true)}
                                            />}
                                        />
                                    </FormGroup>
                                </FormControl>}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} direction="row" className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={labels.first_name}
                                id={`first_name`}
                                name="first_name"
                                fullWidth
                                placeholder={placeholders.addFirstName}
                                value={user.first_name}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                            />
                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                {missingValues.first_name && tooltips.isRequired}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={labels.last_name}
                                id={`last_name`}
                                name="last_name"
                                fullWidth
                                placeholder={placeholders.addLastName}
                                value={user.last_name}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                            />
                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                {missingValues.last_name && tooltips.isRequired}
                            </FormHelperText>
                        </Grid>
                    </Grid>

                        <Grid container spacing={3} direction="row" className={classes.item}>
                            <Grid item xs={12} sm={6} md={4}>
                                    <React.Fragment>
                                        <TextField
                                            label={labels.email}
                                            id={`email`}
                                            name="email"
                                            fullWidth
                                            placeholder={placeholders.email}
                                            value={user.email}
                                            variant="outlined"
                                            size="small"
                                            onChange={handleInputChange}
                                            onBlur={(event) => {
                                                checkEmail(event.target.value, restrictedDomains, setValidEmail);
                                                checkAvailability("email", user.email);
                                            }}
                                        />
                                        {
                                            user.email.trim() !== "" && getValidationMessage(validEmail)
                                        }
                                    </React.Fragment>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                    <React.Fragment>
                                        <TextField
                                            fullWidth
                                            variant={"outlined"}
                                            size={"small"}
                                            id={"username"}
                                            label={labels.username}
                                            name={"username"}
                                            value={user.username}
                                            onChange={handleInputChange}
                                            placeholder={placeholders.username}
                                            onBlur={() => {
                                                checkUsername(user.username, setValidUsername);
                                                checkAvailability("username", user.username);
                                            }}
                                            disabled={isEdit}
                                        />
                                        {
                                            user.username.trim() !== "" && getValidationMessage(validUsername)
                                        }
                                    </React.Fragment>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.item} alignItems={'flex-end'} alignContent={'flex-end'} spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel style={{textAlign: 'left'}}>{labels.requiredPhone}</InputLabel>
                                <PhoneInput
                                    containerStyle={{display: 'flex',width:'100%',marginTop:'5px'}}
                                    inputStyle={{paddingLeft: '10px'}}
                                    name="phone"
                                    country={'us'}
                                    disableCountryCode={true}
                                    placeholder={placeholders.phone}
                                    onlyCountries={['us']}
                                    value={user.phone}
                                    isValid={phone}
                                    onChange={(e) => {
                                        if (e.length === 10) {
                                            setPhone(true);
                                        }
                                        let event = {
                                            target: {
                                                name: "phone",
                                                value: e
                                            }
                                        };
                                        handleInputChange(event)
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 14) {
                                            setPhone(true);
                                        } else {
                                            setPhone(false);
                                        }
                                    }}
                                />
                                <FormHelperText style={{
                                    color: 'red',
                                    textAlign: 'left'
                                }}>{!phone && "Invalid phone number"}</FormHelperText>
                                <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                    {missingValues.phone && tooltips.isRequired}
                                </FormHelperText>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} style={{display: 'flex'}}>
                                {props.isEdit && (
                                    <Button
                                        onClick={requestPasswordReset}
                                        variant={"contained"}
                                        style={{width:'100%'}}
                                        color={"primary"}
                                    >
                                        {buttonText.sendPasswordResetUser}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                    <Grid container style={{paddingTop: '30px'}} spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            {regionData.length > 0 && userType === userTypes.hospice &&
                            <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={regionList}
                                placeholder={placeholders.selectRegion}
                                value={user.region === null ? null : [{
                                    label: regionData[_.findIndex(regionData, {"region_id": user.region})].name,
                                    value: regionData[_.findIndex(regionData, {"region_id": user.region})].name
                                }]}
                                // value={phone}
                                onChange={event => {
                                    handleRegionSelect(event)
                                }}
                            />}
                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                {missingValues.region && tooltips.isRequired}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            {teamData.length > 0 && userType === userTypes.hospice &&
                            <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={teamList}
                                placeholder={placeholders.selectTeam}
                                value={user.team === null ? null : [{
                                    label: teamData[_.findIndex(teamData, {"team_id": user.team})].team_name,
                                    value: teamData[_.findIndex(teamData, {"team_id": user.team})].team_name
                                }]}
                                onChange={event => {
                                    handleTeamSelect(event)
                                }}
                            />
                            }
                        </Grid>
                    </Grid>

                    <Grid container style={{paddingTop: '30px'}} spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={[
                                    {value: 'Mr', label: 'Mr'},
                                    {value: 'Mrs', label: 'Mrs'},
                                ]}
                                placeholder={placeholders.selectTitle}
                                value={user.title === '' ? null : [{
                                    label: user.title,
                                    value: user.title
                                }]}
                                onChange={event => {
                                    handleTitleSelect(event)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            {userType === userTypes.hospice ? (
                                <React.Fragment>
                                    {rolesList.length > 0 && (
                                        <React.Fragment>
                                            <Select
                                                styles={selectStyles}
                                                isSearchable
                                                fullWidth
                                                options={rolesList}
                                                placeholder={placeholders.selectRole}
                                                value={user.role_id === '' && rolesList.length > 0 ? null : [{
                                                    label: rolesList[user.role_id - 7].label,
                                                    value: user.role_id
                                                }]}
                                                onChange={event => {
                                                    handleRoleSelect(event)
                                                }}
                                            />
                                            <FormHelperText style={{color: 'red', textAlign: 'left'}}>
                                                {missingValues.role_id && tooltips.isRequired}
                                            </FormHelperText>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <TextField
                                    label={"Role"}
                                    disabled={true}
                                    size={"small"}
                                    variant={"outlined"}
                                    value={user.role_id !== '' && rolesList[_.findIndex(rolesList,{"value":Number(user.role_id)})].label}
                                />
                            )}

                        </Grid>
                    </Grid>

                    {!isEdit && possibleEmail.length>1 && (
                        <React.Fragment key='bottom'>
                            <SwipeableDrawer
                                anchor='bottom'
                                open={openDrawer}
                                onClose={() => setOpenDrawer(false)}
                            >
                                <ul style={{listStyle: 'none', margin:0,padding:0}}>
                                    {possibleEmail.map((possibleUsername,idx) => idx === 0 ? (
                                        <div key={idx} style={{display: 'flex', alignItems: 'center', paddingLeft: 40, paddingTop:20}}>
                                            <span style={{fontSize: 16,fontWeight: 600}}>{possibleUsername}</span>
                                            <IconButton style={{marginLeft:10}} onClick={(e) => {e.preventDefault(); setOpenDrawer(false)}}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </div>
                                    ) : (
                                        <ListItem button key={idx} style={{paddingLeft: 48, display: 'flex', alignItems: 'center'}}
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      const event = {
                                                          target: {
                                                              name: "username",
                                                              value: possibleUsername
                                                          }
                                                      }
                                                      handleInputChange(event);
                                                      checkUsername(event.target.value, setValidUsername);
                                                      checkAvailability("username", event.target.value);
                                                      setOpenDrawer(false);
                                                  }}>
                                            <span>{possibleUsername}</span>
                                            <IconButton
                                                style={{marginLeft: 5}}>
                                                <CheckIcon style={{fill: 'green'}}/>
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </ul>
                            </SwipeableDrawer>
                        </React.Fragment>
                    )}
                </Grid>


                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getMessage(messageTypes.deleteUser)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant={"contained"} color={"primary"} autoFocus>
                            {buttonText.negative}
                        </Button>
                        <Button color={"secondary"} variant={"contained"} onClick={() => {
                            deleteUser();
                            setOpen(false);
                        }}>
                            {buttonText.proceed}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    } else {
        return <CircularProgress/>
    }
}

const useStyles = makeStyles((theme) => ({
    item: {
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px'
        },
    },
    userStatus: {
        paddingTop: '10px'
    }
}));
