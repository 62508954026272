import React from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let _ = require('lodash');

export const exportContractDetails = (print, contractData, hospiceData, dmeData) => {

    const types = {
        fieldType: "type",
        fieldName: "name",
        fieldValue: "value",
        taxes: "taxes",

    }
    const getContractTerms = (field) => {
        switch (field){
            case types.fieldType:
                if(contractData.dme_days !== null){
                    return "DME Days"
                }
                if(contractData.per_diem_fee !== null){
                    return "Patient Days"
                }
                if(contractData.fee_for_service === true){
                    return "Fee for Service"
                }
                break;
            case types.fieldName:
                if(contractData.dme_days !== null){
                    return "DME Days"
                }
                if(contractData.per_diem_fee !== null){
                    return "Per Diem Fee"
                }
                if(contractData.fee_for_service === true){
                    return ""
                }
                break;
            case types.fieldValue:
                if(contractData.dme_days !== null){
                    return "$" + contractData.dme_days
                }
                if(contractData.per_diem_fee !== null){
                    return "$" + contractData.per_diem_fee
                }
                if(contractData.fee_for_service === true){
                    return ""
                }
                break;
            case types.taxes:
                if(contractData.tax_rate !== null){
                    return "Yes"
                }
                return "No"
            default:
                break;
        }
    }

    let formularyItems = [
        [
            {
                text: `Formulary Items`,
                fillColor: '#eeeeee',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, true, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
        ],
    ];
    let limitedFormulary = [[
        {
            text: `Item`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap %`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap QTY`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Price`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Daily/Monthly`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let oofItems = [[
        {
            text: `Item`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap %`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap QTY`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Price`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Daily/Monthly`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]];
    let purchaseItems = [[
        {
            text: `Item`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap %`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Risk Cap QTY`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Price`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
        {
            text: `Daily/Monthly`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
    ]]



    contractData.pd.map(item => {
        formularyItems.push(
            [
                {
                    text: `${item.name}`,
                    fillColor: 'white',
                    fontSize: 10,
                    alignment: 'left',
                    bold: true,
                    border: [true, true, true, true],
                    margin: [0, 2, 0, 2],
                    textTransform: 'uppercase',
                },
            ],
        )
    })
    contractData.pi.map(item => {
        purchaseItems.push([{
            text: `${item.name}`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
            {
                text: `${item.item_type.risk !== null ? item.risk : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.contracted_quantity !== null ? item.contracted_quantity : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.price !== null ? "$" + item.price : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.monthly === true ? "Monthly" : "Daily"}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },])
    })
    contractData.lf.map(item => {
        limitedFormulary.push([{
            text: `${item.name}`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
            {
                text: `${item.item_type.risk !== null ? item.risk : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.contracted_quantity !== null ? item.contracted_quantity : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.price !== null ? "$" + item.price : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.monthly === true ? "Monthly" : "Daily"}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },])
    })
    contractData.oof.map(item => {
        oofItems.push([{
            text: `${item.name}`,
            fillColor: 'white',
            fontSize: 10,
            alignment: 'left',
            bold: true,
            border: [true, false, true, true],
            margin: [0, 2, 0, 2],
            textTransform: 'uppercase',
        },
            {
                text: `${item.item_type.risk !== null ? item.risk : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.contracted_quantity !== null ? item.contracted_quantity : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.price !== null ? "$" + item.price : ""}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },
            {
                text: `${item.item_type.monthly === true ? "Monthly" : "Daily"}`,
                fillColor: 'white',
                fontSize: 10,
                alignment: 'left',
                bold: true,
                border: [true, false, true, true],
                margin: [0, 2, 0, 2],
                textTransform: 'uppercase',
            },])
    })

    let template = {
        content: [
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [481, 230],
                    heights: ['*', 100],
                    alignment: 'justified',
                    body: [
                        [
                            {
                                text: `Contract Details`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, true, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },


                        ]
                    ]
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        if(i===0){
                            return 0;
                        }
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return null;
                    },
                    paddingBottom: function (i, node) {
                        return 2;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        // return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [230, 230],
                    // heights: ['*', 100],
                    // alignment: 'justified',
                    body: [
                        [
                            {
                                text: `Hospice Provider`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, true, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },
                            {
                                text: `DME Provider`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, true, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },
                        ],
                        [
                            {
                                border: [true, true, true, true],
                                stack: [
                                    {
                                        ul: [
                                            {text: `${hospiceData.hospice_name}`, listType: 'none'},
                                            {text: `${hospiceData.address1}`, listType: 'none'},
                                            {text: `${hospiceData.address2}`, listType: 'none'},
                                            {text: `${hospiceData.city} ${hospiceData.state} ${hospiceData.zip}`, listType: 'none'},
                                            {text: ' ', listType: 'none'},
                                        ]
                                    }
                                ]
                            },
                            {
                                border: [true, true, true, true],
                                stack: [

                                    {
                                        ul: [
                                            {text: `${dmeData.dme_name}`, listType: 'none'},
                                            {text: `${dmeData.address1}`, listType: 'none'},
                                            {text: `${dmeData.address2}`, listType: 'none'},
                                            {text: `${dmeData.city} ${dmeData.state} ${dmeData.zip}`, listType: 'none'},
                                            {text: ' ', listType: 'none'},
                                        ]
                                    }
                                ]
                            },
                        ],
                        [
                            {
                                border: [true, true, true, true],
                                text: "Type",
                            },
                            {
                                border: [true, true, true, true],
                                text: getContractTerms(types.fieldType),
                            },

                        ],
                        [
                            {
                                border: [true, true, true, true],
                                text: getContractTerms(types.fieldName),
                            },
                            {
                                border: [true, true, true, true],
                                text: getContractTerms(types.fieldValue),
                            },
                        ],
                        [
                            {
                                border: [true, true, true, true],
                                text: "Subject to Taxes?",
                            },
                            {
                                border: [true, true, true, true],
                                text: getContractTerms(types.taxes),
                            },
                        ],
                    ]
                },
            },
            '\n\n',
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [481],
                    body: formularyItems,
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [481],
                    heights: ['*', 100],
                    alignment: 'justified',
                    body: [
                        [
                            {
                                text: `Limited Formulary`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, false, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },


                        ]
                    ]
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [80, 77, 80, 60, 100],
                    body: limitedFormulary,
                },
            },

            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [481],
                    heights: ['*', 100],
                    alignment: 'justified',
                    body: [
                        [
                            {
                                text: `Out-of-Formulary`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, false, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },


                        ]
                    ]
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [80, 77, 80, 60, 100],
                    body: oofItems,
                },
            },

            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [481],
                    heights: ['*', 100],
                    alignment: 'justified',
                    body: [
                        [
                            {
                                text: `Purchase Items`,
                                fillColor: '#eeeeee',
                                fontSize: 10,
                                alignment: 'left',
                                bold: true,
                                border: [true, false, true, true],
                                margin: [0, 2, 0, 2],
                                textTransform: 'uppercase',
                            },


                        ]
                    ]
                },
            },
            {
                layout: {
                    defaultBorder: false,
                    hLineWidth: function (i, node) {
                        return 1;
                    },
                    vLineWidth: function (i, node) {
                        return 1;
                    },
                    hLineColor: function (i, node) {
                        if (i === 1 || i === 0) {
                            return 'black';
                        }
                        return 'black';
                    },
                    vLineColor: function (i, node) {
                        return 'black';
                    },
                    hLineStyle: function (i, node) {
                        return null;
                    },
                    paddingLeft: function (i, node) {
                        return 10;
                    },
                    paddingRight: function (i, node) {
                        return 10;
                    },
                    paddingTop: function (i, node) {
                        return 2;
                    },
                    paddingBottom: function (i, node) {
                        return null;
                    },
                    fillColor: function (rowIndex, node, columnIndex) {
                        return '#333';
                    },
                },
                table: {
                    headerRows: 1,
                    widths: [80, 77, 80, 60, 100],
                    body: purchaseItems,
                },
            },
        ],
        styles: {
            notesTitle: {
                fontSize: 10,
                bold: true,
                margin: [0, 50, 0, 3],
            },
            notesText: {
                fontSize: 10,
            },
            small: {
                fontSize: 10
            },
            end: {
                width: '100%',
                textAlign: 'right'
            }
        },
        defaultStyle: {
            columnGap: 20,
        },
        footer: function (currentPage, pageCount) {
            return {
                margin: 10,
                columns: [
                    {
                        fontSize: 9,
                        text: [
                            {
                                text: 'Page' + currentPage.toString() + ' of ' + pageCount,
                            }
                        ],
                        alignment: 'center',
                        margin: [0, 4, 0, 3],
                    }
                ]
            };
        },
    };
    if (print === 'download') {
        pdfMake.createPdf(template).download(`Export Contract ${dmeData.dme_name}-${hospiceData.hospice_name}.pdf`);
    } else if (print === 'print') {
        pdfMake.createPdf(template).print();
    }
};
