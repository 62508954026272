import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import CancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import {endpoints} from "../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import {emailValidation, usernameValidation} from "../../../constants/validations/validationTypes";
import {getRestrictedDomains} from "../createUserHelper";
import _ from "lodash";
import {logout} from "../../../redux/actions";
import {sectionTitles} from "../../../constants/names/titles";
import {checkTokenAvailability} from "../../../common/utils/utils";
import PortalGeneralInfo from "./PortalGeneralInfo";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

export default function HospicePortalUser(props) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    let history = useHistory();
    const dispatch = useDispatch();
    const [wasSuccessful, setWasSuccessful] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [user_id, setUser_id] = useState();
    const [first_role, setFirst_Role] = useState(0);
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstStep, setFirstStep] = useState(true);
    const [secondStep, setSecondStep] = useState(true);
    const [entered, setEntered] = useState(false);
    const [hospicesList, setHospiceList] = useState({});
    const [regionList, setRegionList] = useState({});
    const [rolesList, setRolesList] = useState([{value: '', label: ''}]);
    const [roleCategories, setRolesCategories] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [restrictedDomains, setRestrictedDomain] = useState([]);
    const [possibleEmail, setPossibleEmail] = useState(props.edit ? ["You cannot edit usernames!"] : ["Add First and Last name for suggestions"]);
    const [validEmail, setValidEmail] = useState(emailValidation.empty);
    const [validUsername, setValidUsername] = useState(usernameValidation.empty);
    const [teamData, setTeamData] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [hospiceData, setHospiceData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [accountType, setAccountType] = useState('');
    const [roleId, setRoleId] = useState('');
    const [user, setUser] = useState({
        status: true,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        organization_id: '',
        region: '',
        team: '',
        title: '',
        role_id: '',
        permissions: [],
        username: '',
        deleted: false,
    });

    const getRolePermissions = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.permissions}${roleId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setPermissionList(response.data);
            })
            .catch(err => console.log('error', err))
    };

    const getRoleId = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getRoleId}${props.location.state.type}/Portal`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setRoleId(response.data);
            })
            .catch(err => console.log('error', err))
    };

    useEffect(()=> {
        if (roleId === ''){
            getRoleId()
        }
    },[]);


    const checkAvailability = async (target, value) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        let endpoint;
        if (target === "email") {
            endpoint = endpoints.checkEmailAvailability;
        } else {
            endpoint = endpoints.checkUsernameAvailability;
        }
        const promise = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({value: value})
        });
        const response = await promise.json();
        if (target === "email") {
            if (response.status === "success") {
                validEmail === true && setValidEmail(emailValidation.success)
            } else {
                setValidEmail(emailValidation.emailAlreadyExists)
            }
        } else {
            response.status === "success" ? setValidUsername(usernameValidation.success) : setValidUsername(usernameValidation.usernameAlreadyExists);
        }

    };
    useEffect(() => {
        getRolePermissions();
    }, [roleId]);

    function getCategories() {
        let categories = [];

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        permissionList.map(permission => categories.push(permission.category));
        let uniqueNames = categories.filter(onlyUnique);
        setRolesCategories(uniqueNames);
    }

    const [missingValues, setMissingValues] = React.useState({
        organization_id: null, first_name: null, last_name: null, email: null,
        phone: null, role_id: null, region: null, username: null
    });

    function getHospiceOrganizations() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllHospiceIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].hospice_organization_id, label: response.data[i].hospice_name})
                }
                setHospiceData(response.data);
                setHospiceList(object);

            })
            .catch(err => console.log('error', err))
    }

    function getRegions() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getHospiceRegions + user.organization_id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].name, label: response.data[i].name})
                }
                setRegionData(response.data);
                setRegionList(object);

            })
            .catch(err => {
                console.log('error', err);
                setRegionData([]);
                setRegionList([]);
            })
    }

    const getTeams = () => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getHospiceTeams + user.organization_id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].team_name, label: response.data[i].team_name})
                }
                setTeamList(object);
                setTeamData(response.data)
            })
            .catch(err => {
                console.log('error', err);
                setTeamList([]);
                setTeamData([])
            })
    };

    function getRoles() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.hospice_roles, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].role_id, label: response.data[i].name})
                }
                setRolesList(object);
            })
            .catch(err => console.log('error', err))
    }

    const updatePermissionIDs = () => {
        let list = [];
        permissionList.map(permission => {
            list.push(permission.permission_id);
        });
        setUser({...user, permissions: list})
    };

    useEffect(() => {
        getHospiceOrganizations();
        getRestrictedDomains(setRestrictedDomain);
        let urlParameters = window.location.href.split('/');
        setUser_id(Number(urlParameters[urlParameters.length - 1]))
    }, []);

    useEffect(() => {
        if (user.organization_id) {
            getRegions();
            getRoles();
            getTeams();
        }
    }, [user.organization_id, props.edit]);

    useEffect(() => {
        getCategories();
        if (user.role_id !== first_role) {
            updatePermissionIDs();
        }
    }, [permissionList]);


    function getData() {
        getHospiceOrganizations();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.hospice_user}${user_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setFirst_Role(Number(response.data[0].role_id));
                let arr = [];
                response.data[0].permissions.map(permission => arr.push(Number(permission)));

                setUser(prevState => ({
                    ...prevState,
                    first_name: response.data[0].first_name,
                    last_name: response.data[0].last_name,
                    organization_id: parseInt(response.data[0].hospice_organization_id),
                    email: response.data[0].email,
                    team: parseInt(response.data[0].team_id),
                    title: response.data[0].title,
                    role_id: response.data[0].role_id,
                    phone: response.data[0].phone,
                    status: response.data[0].status,
                    region: parseInt(response.data[0].region_id),
                    permissions: arr,
                    username: response.data[0].username,
                    deleted: response.data[0].deleted,
                }));
                setValidUsername(usernameValidation.success);
                setValidEmail(emailValidation.success);
            }).catch(err => console.log('error', err))
    }

    useEffect(() => {
        if (props.edit && user_id !== undefined) {
            getData();
        }
    }, [user_id]);

    useEffect(() => {
        getRoles();
    }, [user.role_id]);

    useEffect(() => {
        if (user.role_id !== '') {
            if (props.edit && validEmail === emailValidation.success) {
                setFirstStep(true);
            } else if (validUsername === usernameValidation.success && validEmail === emailValidation.success) {
                setFirstStep(true);
            } else {
                setFirstStep(false);
            }
        } else {
            setFirstStep(false);
        }
    }, [user, validUsername, validEmail]);

    function handleInput(e, type) {
        setUser({...user, [e.target.name]: e.target.value})
    }

    function handleRequiredInput(e, type) {
        setUser({...user, [e.target.name]: e.target.value})
    }

    const handleStatus = () => {
        const values = {...user};
        values.status = !user.status;
        setUser(values);
    };

    const deleteUser = () => {

        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.deleteUser + user_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                if (response.status === 204) {
                    setUser({...user, deleted: true});
                    setTimeout(() => history.push("/admin/hospiceusers/all"), 1000);
                }
            })
            .catch(err => console.log('error', err))
    };

    const handleHospiceSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.organization_id = event.value;
        updateHospice.team = "";
        updateHospice.role_id = "";
        updateHospice.region = "";
        if (event.value !== '') {
            setMissingValues({...missingValues, organization_id: false})
        } else {
            setMissingValues({...missingValues, organization_id: true})
        }
        setUser(updateHospice);
    };

    const handleRegionSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.region = regionData[_.findIndex(regionData, {"name": event.value})].region_id;
        if (event.value !== '') {
            setMissingValues({...missingValues, region: false})
        } else {
            setMissingValues({...missingValues, region: true})
        }
        setUser(updateHospice);
    };

    const handleTeamSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.team = teamData[_.findIndex(teamData, {"team_name": event.value})].team_id;
        setUser(updateHospice);
    };
    const handleRoleSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.role_id = event.value;
        setMissingValues({...missingValues, role_id: false});
        setUser(updateHospice);
    };
    const handleTitleSelect = (event) => {
        const updateHospice = {...user};
        updateHospice.title = event.value;
        setUser(updateHospice);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const values = {...user};
        if (name === "first_name") {
            values.first_name = value;
            if (value !== '') {
                setMissingValues({...missingValues, first_name: false});
            } else {
                setMissingValues({...missingValues, first_name: true});
            }
        } else if (name === "last_name") {
            values.last_name = value;
            if (value !== '') {
                setMissingValues({...missingValues, last_name: false});
            } else {
                setMissingValues({...missingValues, last_name: true});
            }
        } else if (name === "phone") {
            values.phone = value;
            if (value !== '') {
                setMissingValues({...missingValues, phone: false});
            } else {
                setMissingValues({...missingValues, phone: true});
            }
        } else if (name === "email") {
            values.email = value;
            if (value !== '') {
                setMissingValues({...missingValues, email: false});
            } else {
                setMissingValues({...missingValues, email: true});
            }
        } else if (name === "username") {
            values.username = value;
        }
        setUser(values);
    };

    const steps = ['General Informations'];

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <PortalGeneralInfo
                    regionList={regionList}
                    hospicesList={hospicesList}
                    handleHospiceSelect={handleHospiceSelect}
                    handleStatus={handleStatus}
                    handleRequiredInput={handleRequiredInput}
                    handleInput={handleInput}
                    missingValues={missingValues}
                    user={user}
                    handleInputChange={handleInputChange}
                    handleRegionSelect={handleRegionSelect}
                    handleTeamSelect={handleTeamSelect}
                    handleRoleSelect={handleRoleSelect}
                    handleTitleSelect={handleTitleSelect}
                    rolesList={rolesList}
                    possibleEmail={possibleEmail}
                    validEmail={validEmail}
                    setValidEmail={setValidEmail}
                    restrictedDomains={restrictedDomains}
                    setPossibleEmail={setPossibleEmail}
                    setValidUsername={setValidUsername}
                    validUsername={validUsername}
                    isEdit={!!props.edit}
                    hospiceData={hospiceData}
                    teamData={teamData}
                    teamList={teamList}
                    regionData={regionData}
                    checkAvailability={checkAvailability}
                    deleteUser={deleteUser}
                    hospice_organization_id={props.location.state.hospice_organization_id}
                    team_id={props.location.state.team_id}
                    region_id={props.location.state.region_id}
                    first_name={props.location.state.first_name}
                    last_name={props.location.state.last_name}
                    type={props.location.state.type}
                    phone_number={props.location.state.phone_number}
                    accountType={accountType}
                    setAccountType={setAccountType}
                />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        if (activeStep === 0 && accountType === 'email' &&
            props.location.state.hospice_organization_id &&
            //props.location.state.region_id &&
            //props.location.state.team_id &&
            props.location.state.first_name &&
            props.location.state.last_name &&
            user.email.length > 0 &&
            user.username.length > 0 &&
            user.title.length > 0
        ) {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.createPortalUser, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    first_name:  props.location.state.first_name,
                    last_name:  props.location.state.last_name,
                    organization_id: parseInt(props.location.state.hospice_organization_id),
                    email: user.email,
                    region: props.location.state.region_id,
                    team:props.location.state.team_id,
                    title: user.title,
                    role_id: roleId,
                    phone: null,
                    username: user.username,
                    permissions:user.permissions,
                    patient_id:props.location.state.patient_id,
                    caregiver_id:props.location.state.caregiver_id,
                })
            })
                .then(response => {
                    if (response.status === 201) {
                        enqueueSnackbar(`Portal account created with success`, {
                            variant: 'success',
                            autoHideDuration: 3000,
                            anchorOrigin: {vertical: "top", horizontal: "right"},
                        });
                    } else {
                        enqueueSnackbar(`Unable to create Portal Account`, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            anchorOrigin: {vertical: "top", horizontal: "right"},
                        });
                    }
                    history.push(`/patients/patientProfile/${props.location.state.patient_id}`)
                })
                .catch(err => console.log('error', err));


        }
        if (activeStep === 0 && accountType === 'phone' &&
            props.location.state.hospice_organization_id &&
            //props.location.state.region_id &&
            //props.location.state.team_id &&
            props.location.state.first_name &&
            // props.location.state.last_name &&
            user.username.length > 0 &&
            // user.phone.length === 10 &&
            user.title.length > 0
        ) {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.createPortalUser, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    first_name:  props.location.state.first_name,
                    last_name:  props.location.state.last_name,
                    organization_id: parseInt(props.location.state.hospice_organization_id),
                    email: null,
                    region: props.location.state.region_id,
                    team:props.location.state.team_id,
                    title: user.title,
                    role_id: roleId,
                    phone: user.phone,
                    username: user.username,
                    permissions:user.permissions,
                    patient_id:props.location.state.patient_id,
                    caregiver_id:props.location.state.caregiver_id,
                })
            })
                .then(response => {
                    if (response.status === 201) {
                        setWasSuccessful(true);
                        setIsLoading(false);
                    } else {
                        setWasSuccessful(false);
                        setIsLoading(false);
                    }
                    setActiveStep(activeStep + 1);
                })
                .catch(err => console.log('error', err));

        }
        if (!firstStep && activeStep === 0) {
            setMissingValues({
                email: user.email.length === 0,
                username: validUsername,
                title: user.title.length === 0,
            })
        }
        if (activeStep === 2) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {
            setActiveStep(activeStep + 1);
            if (props.edit) {
                const token = JSON.parse(localStorage.getItem('key'));
                fetch(`${endpoints.hospice_user_edit}${user_id}`, {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify(user)
                })
                    .then(response => {
                        if (response.status === 200) {
                            setWasSuccessful(true);
                            setIsLoading(false);
                        } else {
                            setWasSuccessful(false);
                            setIsLoading(false);
                        }
                    })
                    .catch(err => console.log('error', err));
            } else {
                const token = JSON.parse(localStorage.getItem('key'));
                fetch(endpoints.hospice_user, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify(user)
                })
                    .then(response => {
                        if (response.status === 201) {
                            setWasSuccessful(true);
                            setIsLoading(false);
                        } else {
                            setWasSuccessful(false);
                            setIsLoading(false);
                        }
                    })
                    .catch(err => console.log('error', err));
            }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    useEffect(() => {
        if (
            props.location.state.hospice_organization_id &&
            props.location.state.first_name.length &&
            props.location.state.last_name.length &&
            //props.location.state.region_id &&
            props.location.state.type &&
            roleId !== "" &&
            validUsername === usernameValidation.success &&
            ((accountType === 'phone' && props.location.state.phone_number.length === 10 ) || (
                accountType === 'email' && user.email.length && validEmail === emailValidation.success
            ))
        ) {
            setFirstStep(true);
        } else {
            setFirstStep(false);
        }
        if (true) {
            setSecondStep(true);
        } else {
            setSecondStep(false);
        }
        if (true) {
            setEntered(true);
        }
    }, [user, accountType]);

    return (
        <React.Fragment>
            {(isLogged) ? <React.Fragment>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                            {props.edit ? sectionTitles.editHospiceUser : sectionTitles.addNewPortalUser}
                        </Typography>
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    {isLoading ? (
                                        <div style={{display: "flex", justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <Typography variant="h5" gutterBottom>
                                                {(wasSuccessful && props.edit) && 'Success'}
                                                {(wasSuccessful && !props.edit) && 'Success'}
                                                {!wasSuccessful && props.edit && 'Unable to edit'}
                                                {!wasSuccessful && !props.edit && 'Unable to register'}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {(wasSuccessful && props.edit) && 'User has been updated.'}
                                                {wasSuccessful && !props.edit && 'User has been created.'}
                                                {!wasSuccessful && !props.edit && 'Please try again'}
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <div className={classes.buttons}>
                                        {entered ? <CancelActionAlert
                                            flag={cancellingAlertFlags.newPatient}
                                            CSS={classes.button}
                                            alertLevel={alertLevel.warn}
                                            target={"/admin"}
                                        /> : <Button
                                            variant={'outlined'}
                                            color="primary"
                                            onClick={() => {
                                                history.push('/admin')
                                            }} className={classes.button}>
                                            Cancel
                                        </Button>
                                        }
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                        )}
                                        {activeStep === 0 && (
                                            <Tooltip
                                                title={firstStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={firstStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Create User'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {activeStep === 1 && (
                                            <Tooltip
                                                title={secondStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Create User'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {activeStep === 2 && (
                                            <Tooltip
                                                title={secondStep === true || false ? '' : "Please fill all required fields"}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {(activeStep === steps.length - 1 && props.edit) && 'Edit User'}
                                                    {(activeStep === steps.length - 1 && !props.edit) && 'Create User'}
                                                    {activeStep !== steps.length - 1 && 'Next'}
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </main>
            </React.Fragment> : ''}
        </React.Fragment>
    );
}