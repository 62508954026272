import jwt from "jwt-decode";

export const checkSuperAdmin = (permissions, type) => {
    let token = localStorage.getItem('key');
    let userType, userPermissions;
    let response = false;
    let forbidden = false;

    if (JSON.parse(token) !== null && token.accessToken !== null) {
        token = JSON.parse(token);
        let decoded = jwt(token.accessToken);
        userType = decoded.payload.type;
        userPermissions = decoded.payload.permissions;
        if (decoded.payload.role_id === 6) {
            if (userPermissions !== undefined && userPermissions !== []) {
                permissions.map(required_permission => {
                    if (!forbidden && (type === userType || type === undefined)) {
                        if (userPermissions.includes(required_permission)) {
                            response = true;
                        } else {
                            response = false;
                            forbidden = true;
                        }
                    }
                });
            }
        }
    }
    return response
};