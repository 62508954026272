import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import DuplicatesList from "./possibleDuplicates";
import {labels} from "../../../../constants/inputs/labels";

const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer({isOpen, possibleDuplicates, setShowPossibleDuplicates}) {
    const classes = useStyles();
    const [state, setState] = React.useState({right: true});
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        setShowPossibleDuplicates(open);
    };
    useEffect(() => {
        setState({'right': isOpen})
    }, [isOpen]);

    const list = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
            onClick={toggleDrawer('right', true)}
            onKeyDown={toggleDrawer('right', false)}
        >
            <div onClick={toggleDrawer('right', false)} style={{backgroundColor: '#F5F5F5', height: '100%',}}>
                <div style={{padding: '10px', textAlign: 'center'}}>{labels.possibleDuplicates}</div>
                <DuplicatesList possibleDuplicates={possibleDuplicates}/>
            </div>
        </div>
    );
    return (
        <div>
            <React.Fragment key={'right'}>
                <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
                    {list()}
                </Drawer>
            </React.Fragment>
        </div>
    );
}