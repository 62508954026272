import {makeStyles, withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenu = withStyles({
    paper: {
        fontSize: '15px'
    },
})((props) => (
    <Menu
        style={{color: 'red'}}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export const StyledMenuItem = withStyles((theme) => ({
    root: {
        padding: "6px 10px",
        fontSize: '15px',
        lineHeight: "1.5"
    }
}))(MenuItem);

export const useStyles = makeStyles((theme) => ({
    button: {
        "&:hover": {
            backgroundColor: '#f5f5f5'
        },
        padding: '4px 4px',
        cursor: 'pointer',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}));