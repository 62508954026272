//make sure that this respects the db naming
export const organizations = {
    hospice: {
        type: "Hospice",
        role: {
            nurse: "Nurse/Clinician",
            clinicalManager: "Clinical Manager",
            billingManager: "Billing Manager",
            corporate: "Corporate Level",
            itContact: "IT Contact",
        }
    },
    dme: {
        type: "DME",
        role: {
            dataEntry: "Data Entry",
            equipmentTechnician: "Equipment Technicians (ET’s)",
            deliveryTechnician: "Delivery Technician",
            manager: "Manager",
            admin: "Admin",
            corporate: "Corporate/Super Admin",
            itContact: "IT Contact",
        }
    },
};