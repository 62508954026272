import userNavMenu from './userNavMenu';
import isLogged from './isLogged';
import username from './username';
import {combineReducers} from 'redux';
import addUserReducer from './addPatient';
import {
    SearchCriteria,
    SearchCriteriaDME,
    SearchCriteriaDmeUsers,
    SearchCriteriaHospice,
    SearchCriteriaHospiceUsers,
    SearchCriteriaOrders,
    SearchCriteriaPatients,
} from "./searchCriterias";

const allReducers = combineReducers({
    userNavMenu,
    isLogged,
    username,
    addUserReducer,
    SearchCriteria,
    SearchCriteriaDME,
    SearchCriteriaHospice,
    SearchCriteriaDmeUsers,
    SearchCriteriaHospiceUsers,
    SearchCriteriaOrders,
    SearchCriteriaPatients
});

export default allReducers;