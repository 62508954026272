import React, {useEffect, useState} from "react";
import {DialogActions, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {useHistory} from "react-router-dom";
import AccessRightsDme from "../../createDmeUser/accessRights";
import AccessRightsHospice from "../../createHospiceUser/accessRights";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {endpoints} from "../../../../constants/endpoints";
import {useDispatch} from "react-redux";
import {PermissionList} from "./components/PermissionList";
import {userTypes} from "../../../../constants/types/userTypes";
import {useSnackbar} from "notistack";
import {status} from "../../../../constants/status/status";
import {light} from "@material-ui/core/styles/createPalette";
import CircularProgress from "@material-ui/core/CircularProgress";


export const PermissionPackages = ({}) => {

    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [packageList, setPackagesList] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState();
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [packageType, setPackageType] = useState([]);
    const [dmeRoles, setDmeRoles] = useState([]);
    const [hospiceRoles, setHospiceRoles] = useState([]);
    const [roleCategories, setRoleCategories] = useState([])
    const [permissionList, setPermissionList] = useState([]);
    const [selectedPackageId, setSelectedPackageId] = useState("");
    const [packageName, setPackageName] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const token = JSON.parse(localStorage.getItem('key'));
    const [editMode, setEditMode] = useState(false);

    const getRolePermissions = async (role, enableAll) => {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.permissions}${role}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const response = await promise.json();
        if (response.status === "success") {
            setPermissionList(response.data);
            enableAll === true && enableAllPermissionForNewRole(response.data);
            getCategories(response.data);
        }
        setLoading(false);
    };

    const getCategories = (data) => {
        let categories = [];
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        data.map(permission => categories.push(permission.category));
        let uniqueNames = categories.filter(onlyUnique);
        setRoleCategories(uniqueNames);
    }

    const getAllPackages = async () => {
        const availableToken = await checkTokenAvailability();
        if (!availableToken) {
            dispatch(logout());
            history.push('/login');
        }
        const promise = await fetch(endpoints.permissionPackage, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const response = await promise.json();
        if (response.status === "success") {
            setPackagesList(response.data);
        }

    }

    const enableAllPermissionForNewRole = (newPermissionList) => {
        const newSelectedPermissions = [];
        newPermissionList.map(permission => {
            newSelectedPermissions.push(permission.permission_id);

        });
        setSelectedPermissions(newSelectedPermissions);
    }

    const getAllRoles = async () => {
        const availableToken = await checkTokenAvailability();
        if (!availableToken) {
            dispatch(logout());
            history.push('/login');
        }
        const hospicePromise = await fetch(endpoints.hospice_roles + userTypes.hospice, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const hospiceResponse = await hospicePromise.json();
        if (hospiceResponse.status === "success") {
            let object = [];
            for (let i = 0; i < hospiceResponse.data.length; i++) {
                object.push({value: hospiceResponse.data[i].role_id, label: hospiceResponse.data[i].name})
            }
            setHospiceRoles(object);
        }
        const dmePromise = await fetch(endpoints.dme_roles, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
        const dmeResponse = await dmePromise.json();
        if (dmeResponse.status === "success") {
            let object = [];
            for (let i = 0; i < dmeResponse.data.length; i++) {
                object.push({value: dmeResponse.data[i].role_id, label: dmeResponse.data[i].name})
            }
            setDmeRoles(object);
        }
    }

    const createPackage = async () => {
        const promise = await fetch(endpoints.permissionPackage, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                name: packageName,
                type: packageType.value,
                roleId: selectedRole.value,
                permissionList: selectedPermissions
            })

        })
        const response = await promise.json();
        if (response.status === "success") {
            enqueueSnackbar("Package Created", {
                variant: "success",
                persist: false,
            })
            getAllPackages();
            setSelectedRole([]);
            setPermissionList([]);
            setPackageName("");
            setOpenModal(false);

        }
        if (response.status === "error" && promise.status === status.conflict) {
            enqueueSnackbar("Package name is already in use.", {
                variant: "info",
                persist: false,
            })
        }
    }

    const getPackagePermissions = async (packageData) => {
        setLoading(true);
        setEditMode(true);
        setSelectedPackageId(packageData.package_id);
        const promise = await fetch(endpoints.getPermissionPackageById + packageData.package_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if (response.status === "success") {
            setPackageName(packageData.name);
            if (packageData.type === "dme") {
                setPackageType({label: 'DME', value: 'dme'});
                setSelectedRole(dmeRoles.filter(item => item.value = packageData.role_id)[0]);
            }
            if (packageData.type === "hospice") {
                setPackageType([{label: 'Hospice', value: 'hospice'}]);
                setSelectedRole(hospiceRoles.filter(item => item.value = packageData.role_id)[0]);
            }
            let arr = [];
            await getRolePermissions(packageData.role_id, false);
            for (const row of response.data) {
                arr.push(row.permission_id);
            }
            setSelectedPermissions(arr);
            setOpenModal(true);
            setLoading(false);
        }
    }

    const updatePackage = async (operation, packageData) => {
        let body;
        setLoading(true);
        if (operation === true) {
            body = {
                operation: "packageActivation",
                packageId: packageData.package_id,
                active: !packageData.active
            }
        }
        if (operation === false) {
            console.log(selectedPackageId);
            body = {
                operation: "updatePermissionList",
                packageId: selectedPackageId,
                permissionList: selectedPermissions,
            }
        }
        const promise = await fetch(endpoints.permissionPackage, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(body),
        })
        const response = await promise.json();
        if (response.status === "success") {
            getAllPackages();
            enqueueSnackbar(operation === "packageActivation" ? "Package status updated." : "Package permissions updated", {
                variant: "success",
                persist: false
            });
            setOpenModal(false);
            setSelectedPackageId("");
        }
        if (response.status === "error") {
            enqueueSnackbar("Something happened, please contact support.", {variant: "error", persist: false});
        }
        setLoading(false);
    }

    useEffect(() => {
        getAllPackages();
        getAllRoles();
    }, [])

    return (
        <Grid container justify={'center'} style={{padding: 24}}>
            <Paper elevation={0} style={{padding: 24, width: '100%', maxWidth: 1100}}>
                <Grid container>
                    <Grid item xs={12} style={{fontSize: 24, fontWeight: 'bold', display: 'flex', paddingBottom: 16}}>
                        Permission Packages
                    </Grid>
                </Grid>
                <Grid container justify={'space-between'} spacing={2} style={{paddingBottom: 12}}>
                    <Grid item md={5} sm={6} xs={12} style={{zIndex: 100}}>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignContent: 'flex-end'
                    }}>
                        <Button variant={'outlined'} color={'primary'} onClick={() => setOpenModal(true)}
                                style={{maxHeight: 40}} disabled={selectedPackage === null}>Add New Package</Button>
                    </Grid>
                </Grid>
                <MaterialTable
                    title=""
                    isLoading={loading}
                    columns={[
                        {title: 'Id', field: 'package_id'},
                        {title: 'Package', field: 'name'},
                        {
                            title: 'Type', field: 'type', size: 20,
                            render: rowData => <span>
                                {rowData.type === "dme" ? "Dme" : "Hospice"}
                            </span>
                        },
                        {
                            title: 'Actions', field: 'actions', width: 240,
                            render: rowData => <Grid container spacing={1}>
                                <Grid item>
                                    <Button variant={'outlined'} color={'primary'} size={'small'}
                                            onClick={() => {
                                                getPackagePermissions(rowData);
                                            }}
                                            startIcon={<VisibilityIcon/>}
                                    >Edit</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant={'outlined'} color={'primary'} size={'small'}
                                            startIcon={rowData.active === true ? <DeleteForeverIcon/> :
                                                <VisibilityIcon/>} s
                                            onClick={() => updatePackage(true, rowData)}
                                            style={rowData.active ? {
                                                color: 'red',
                                                borderColor: 'red'
                                            } : {borderColor: 'red'}}>{rowData.active === true ? "Deactivate" : "Activate"}</Button>
                                </Grid>
                            </Grid>
                        },
                    ]}
                    data={packageList}
                    options={{
                        search: true
                    }}
                />

                <Dialog open={openModal} onClose={() => setOpenModal(false)} fullWidth maxWidth={'lg'}>
                    <DialogContent>
                        <Grid cotaniner style={{minWidth: 400, minHeight: 400}}>
                            <Grid item xs={12} style={{fontSize: 20, fontWeight: 'bold'}}>Add New Package</Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item md={4} sm={6} xs={12} style={{zIndex: 1000}}>
                                        <div style={{paddingTop: 12}}>Type *</div>
                                        <Select
                                            placeholder={'Select type'}
                                            isDisabled={loading || editMode}
                                            options={[
                                                {label: 'DME', value: 'dme'},
                                                {label: 'Hospice', value: 'hospice'},
                                            ]}
                                            value={packageType}
                                            onChange={(e) => {
                                                setPackageType(e);
                                                setPermissionList([]);
                                                setSelectedRole([]);
                                            }}
                                        />
                                    </Grid>

                                    {packageType !== null && (
                                        <Grid item md={4} sm={6} xs={12} style={{zIndex: 900}}>
                                            <div style={{paddingTop: 12}}>Role *</div>
                                            <Select
                                                isDisabled={loading || editMode}
                                                placeholder={'Select Role'}
                                                options={packageType.value === "dme" ? dmeRoles : hospiceRoles}
                                                value={selectedRole}
                                                onChange={(e) => {
                                                    setSelectedRole(e)
                                                    if (e.value) {
                                                        getRolePermissions(e.value, true);
                                                    } else {
                                                        setPermissionList([]);
                                                    }
                                                }}

                                            />
                                        </Grid>
                                    )}
                                    <Grid item md={4} sm={6} xs={12} style={{paddingTop: 34}}>
                                        <TextField
                                            size={"small"}
                                            variant={"outlined"}
                                            fullWidth
                                            value={packageName}
                                            placeholder={"Package Name"}
                                            disabled={loading || editMode}
                                            onChange={(e) => setPackageName(e.target.value)}
                                        />
                                    </Grid>

                                </Grid>
                                {permissionList.length > 0 && <Grid item container xs={12}>
                                    <PermissionList
                                        permissionList={permissionList}
                                        selectedPermissions={selectedPermissions}
                                        setSelectedPermissions={setSelectedPermissions}
                                        roleCategories={roleCategories}
                                        loading={loading}
                                    />
                                </Grid>}
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid item container xs={12} spacing={1} style={{justifyContent: "flex-end", paddingTop: 16}}>
                            <Grid item>
                                <Button variant={"contained"} color={"secondary"} disabled={loading} onClick={() => {
                                    setSelectedRole([]);
                                    setPermissionList([]);
                                    setPackageName("");
                                    setOpenModal(false);
                                    setEditMode(false);
                                }}>Cancel</Button>
                            </Grid>
                            <Grid item>
                                <Button variant={"contained"}
                                        color={"primary"}
                                        disabled={selectedPermissions.length === 0 || packageName.length === 0 || loading === true}
                                        onClick={() => {
                                            editMode === true ? updatePackage(false, selectedPermissions) : createPackage()
                                        }}>
                                    {editMode === true ? "Update Package" : "Create Package"}
                                    {loading === true && <CircularProgress style={{color: 'white'}} size={24}/>}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Grid>
    )

}