import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";

export const retrieveAllHospiceIds = async (hospiceSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAllHospiceIds, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        hospiceSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospices!");
    }
}
export const retrieveAllSurveyData = async (surveySetter) => {
    const data = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAllSurveyData, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        surveySetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all surveys!");
    }
}
export const retrieveAllSurveyUsers = async (userSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAllSurveyUserIds, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        userSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all surveys!");
    }
}