import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {userProfileStyles} from "./UserProfile.style";
import {endpoints} from "../../constants/endpoints";
import {status} from "../../constants/status/status";
import {makeStyles} from "@material-ui/core/styles";
import {UserInformationForm} from "./UserInformationForm";
import jwt from "jwt-decode";
import UserSign from "../../assets/avatar.svg";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import NotificationSettings from "../../common/notificationSettings/notificationSettings";
import {useSnackbar} from "notistack";

export const UserProfile = () => {
    const {enqueueSnackbar} = useSnackbar();
    const token = JSON.parse(localStorage.getItem('key'));
    const user = jwt(token.accessToken);
    let history = useHistory();
    const classes = useStyles();
    const [profile, setProfile] = useState({
        username: user.payload.username,
        first_name: user.payload.first_name,
        last_name: user.payload.last_name,
        email: user.payload.email,
        phone: user.payload.phone,
        timezone: user.payload.timezone ? user.payload.timezone : 'All',
    });

    const handleInput = (event) => {
        setProfile({...profile, [event.target.name]: event.target.value});
    };
    const handleSubmit = () => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.changeTimeZone, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({profile: profile})
        }).then(res => {
            if (res.status === status.success) {
                //notistack de success
                enqueueSnackbar("Information updated successfully", {
                    variant: 'success',
                    autoHideDuration: 4000,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"}
                });
            }
        })
    };


    return (
        <React.Fragment>
            <Grid container justify={"center"} className={classes.root}>
                <Grid item xs={12} className={classes.flexStart}>
                    <Typography className={classes.h1}>
                        USER PROFILE
                    </Typography>
                </Grid>
                <Grid container className={classes.titleContainer}>
                    <Grid item xs={12} sm={12} md={6}>
                        <img
                            src={UserSign}
                            alt="logo"
                            width='128'
                            className={classes.image}
                        />
                        <Typography variant={"h6"} className={classes.userName}>
                            {user.payload.last_name} {user.payload.first_name}
                        </Typography>
                        <Typography className={classes.h4}>
                            Role: {user.payload.role_name}
                        </Typography>
                        <Typography className={classes.h4}>
                            Organization: {(user.payload.dme_name !== "") ? user.payload.dme_name : user.payload.hospice_name}
                        </Typography>
                        {user.payload.hospice_organization_id !== null && user.payload.pbmLink !== null && user.payload.pbmLink !== "" && <a href={user.payload.pbmLink.includes("https://") === false || user.payload.pbmLink.includes("http://") === false ? "https://"+ user.payload.pbmLink : user.payload.pbmLink}
                                                                             target="_blank"
                                                                             rel="noopener noreferrer"
                                                                             style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                        >
                            <Typography className={classes.h4NoColor}>
                                PBM Link
                            </Typography>
                        </a>}
                        {user.payload.hospice_organization_id !== null && user.payload.medicalSuppliesVendorLink !== null && user.payload.medicalSuppliesVendorLink !== "" && <a href={user.payload.medicalSuppliesVendorLink.includes("https://") === false || user.payload.medicalSuppliesVendorLink.includes("http://") === false ? "https://"+ user.payload.medicalSuppliesVendorLink : user.payload.medicalSuppliesVendorLink}
                                                                             target="_blank"
                                                                             rel="noopener noreferrer"
                                                                             style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                        >
                            <Typography className={classes.h4NoColor}>
                                Medical Supplies Vendor Link
                            </Typography>
                        </a>}
                        <Button
                            variant={'outlined'}
                            size="small"
                            className={classes.change}
                            onClick={() => {
                                history.push({pathname: '/profile/edit'})
                            }}
                        >
                            Change Password
                        </Button>
                        <NotificationSettings/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography variant={"h6"} className={classes.title}>
                            User Information
                        </Typography>
                        <UserInformationForm handleInput={handleInput}
                                             handleSubmit={handleSubmit}
                                             values={profile}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => (userProfileStyles));

