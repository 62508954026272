import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useHistory} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PlusSign from "../../assets/Icon_material-add-circle-outline.svg";
import ListSign from "../../assets/Icon_material-format-list-bulleted.svg"
import PatientSign from "../../assets/patient.svg";
import OrderSign from "../../assets/order.svg";
import SupportSign from "../../assets/support.svg";
import PhoneSign from "../../assets/icon_phone.svg";
import EmailSign from "../../assets/e-mail.svg";
import HelpSign from "../../assets/help.svg";
import {dashboardStyle} from "./dashboard.style";
import jwt from "jwt-decode";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import {checkTokenAvailability, formatNumber} from "../../common/utils/utils";
import {logout} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import _ from 'lodash';
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

export default function Dashboard(props) {
    const [showNotification, setShowNotification] = useState(props.location.state ? props.location.state.showNotification : false);
    const [notificationList, setNotificationList] = useState([]);
    const [dismiss, setDismiss] = useState(false);
    const [boolean, setBoolean] = useState(true);
    const history = useHistory();
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [retrievedOncall, setRetrievedOncall] = useState(false);
    const [items, setItems] = useState([]);
    const dispatch = useDispatch();

    async function getUserOptions(){
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        let arr = [];
        const token = JSON.parse(localStorage.getItem("key"));
        if(token) {
            const {type} = jwt(token.accessToken).payload;

            checkPermissions([permissions.read_patients]) && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Patients',
                add: "Add New Patient",
                view: "View All Patients",
                tile: PatientSign,
                firstSign: PlusSign,
                addRoute: "/patients/new",
                viewRoute: "/patients/all"
            });
            checkPermissions([permissions.read_orders]) && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Orders',
                add: "Add New Order",
                view: "View All Orders",
                tile: OrderSign,
                //TODO: make recent orders to actually display recent orders
                // recent: "Recent Orders",
                firstSign: PlusSign,
                addRoute: "/patients/all",
                viewRoute: "/orders"
            });
            type === 'Portal' && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Patient Portal',
                add: null,
                view: "View Patient Profile",
                tile: PatientSign,
                firstSign: PlusSign,
                addRoute: "/patients/new",
                viewRoute: "/portal/profile"
            });
        }
        return arr;
    }
    let token = JSON.parse(localStorage.getItem('key'));
    let decodedToken;
    const isLogged = useSelector(state => state.isLogged);
    const getOnCallData = async () => {
        const token = JSON.parse(localStorage.getItem("key"));
        const {hospice_organization_id, region_id, dme_organization_id} = jwt(token.accessToken).payload;
        let payload={};
        if(hospice_organization_id){
            payload["hospiceId"] = hospice_organization_id;
        }
        if(region_id){
            payload["regionId"] = region_id;
        }
        if(dme_organization_id){
            payload["dmeId"] = dme_organization_id;
        }
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const promise = await fetch(`${endpoints.retrieveOnCallInformation}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(payload)
            }
        );
        const response = await promise.json();
        let clone = await getUserOptions();
        let newItem;
        if (response.status === 'success'){
            for(const item of response.data){
                 newItem = {
                    xl: 3, lg: 4, md: 6, sm: 12, xs: 12,
                    title: `${item.dme_name} - ${item.region_name}`,
                    add: item.region_phone !== null && item.region_phone !== "" ? formatNumber(item.region_phone, "parentheses") : "-",
                    tile: SupportSign,
                    phone: true,
                    firstSign: PhoneSign,
                    email: {
                        address: item.email !== null && item.email !== "" ? item.email : "-",
                        name: `${item.first_name !== null  && item.first_name !== "" ? item.first_name : "-"} ${item.last_name !== null && item.last_name !== "" ? item.last_name : "-"}`
                    },
                    regionHours: `${item.on_call_start_hour !== null && item.on_call_start_hour !== "" ? moment(item.on_call_start_hour).format("LT") : "-"} - ${item.on_call_start_hour !== null && item.on_call_start_hour !== "" ? moment(item.on_call_end_hour).format("LT") : "-"} CST`,
                    ahPhone: item.on_call_phone !== null && item.on_call_phone !== "" ? formatNumber(item.on_call_phone, "parentheses") : "-"
                }
                clone.push(newItem);
            }
            setItems(clone);
        } else {
            newItem = {
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: `No Region Support`,
                add: "-",
                tile: SupportSign,
                phone: true,
                firstSign: PhoneSign,
                email: {
                    address: "-",
                    name: "-"
                },
                regionHours: "-",
                ahPhone: "-"
            }
            clone.push(newItem);
        }
        setRetrievedOncall(true);
    }

    function getNotifications() {
        const token = JSON.parse(localStorage.getItem('key'));
        let user_id = jwt(token.accessToken).payload.user_id;
        fetch(`${endpoints.getUserSystemNotifications}/${user_id}/active`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = [];
                response.data.map(notif => {
                    if (notif.is_read !== true){
                        arr.push(notif)
                    }
                });
                setNotificationList(arr)
            })
            .catch(err => console.log('error', err))
    }

    function updateNotification(type, notificationId){
        const token = JSON.parse(localStorage.getItem('key'));
        let user_id = jwt(token.accessToken).payload.user_id;
        fetch(endpoints.updateUserSystemNotification, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                notificationId: notificationId,
                userId:user_id,
                status:type,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setNotificationList(response.data)
            })
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        if (token !== null) {
            setUsername(decodedToken = jwt(token.accessToken).payload.first_name);
            if (retrievedOncall === false) {
                getOnCallData()
                setRetrievedOncall(true);
            }
            if (props.location.state?.showNotification && boolean === true){
                setBoolean(false);
                getNotifications()
            }
        }
    },[token]);
    return (
        <React.Fragment>
            {isLogged ? (
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.flexStart}>
                        <Typography className={classes.h1}>
                            Welcome {username}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"flex-start"} spacing={2}>
                            {items.map((value, index) => (
                                <Grid key={value} item lg={value.lg} md={value.md} sm={value.sm} xs={value.xs}>
                                    <div
                                        className={classes.box}
                                        style={{borderBottom: index % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2'}}>
                                        <div
                                            className={classes.background}
                                            style={{
                                                background: `transparent url(${value.tile}) 0% 0% no-repeat padding-box`,
                                                backgroundPosition: 'right bottom',
                                                height: '100%'
                                            }}>
                                            <Typography className={classes.h3} style={{fontSize: value.title.length > 20 ? 16 :24}}>
                                                {value.title}
                                            </Typography>
                                            {value.add !== null && <div className={classes.addRoute} style={{marginTop:24}}
                                                                        onClick={() => value.addRoute && history.push(value.addRoute)}>
                                                <img
                                                    src={value.firstSign}
                                                    alt="logo"
                                                    width='25'
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}
                                                />
                                                <Typography
                                                    className={classes.h4}
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}>
                                                    {value.add}
                                                </Typography>
                                            </div>}
                                            {value.phone && (
                                                <div className={classes.phone}>
                                                    <Typography variant={"h3"} className={classes.phoneText}>
                                                        {value?.regionHours ? value?.regionHours : "8:30 am - 5:30 pm CST"}
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.view && (
                                                <div className={classes.view} style={{cursor: 'pointer'}}>
                                                    <img
                                                        src={ListSign}
                                                        alt="logo"
                                                        width='25'
                                                        className={classes.cursor}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    />
                                                    <Typography
                                                        className={classes.h4}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    >
                                                        {value.view}
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.email && (
                                                <React.Fragment>
                                                    <div className={classes.supportEmail}
                                                         onClick={() => window.location = `mailto:${value.email.address}`}>
                                                        <img
                                                            src={EmailSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.cursor}
                                                        />
                                                        {value.email.name.length > 20 ?
                                                            <Tooltip title={value.email.name}>
                                                                <Typography style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",}}
                                                                            className={classes.emailText}>{value.email.name}</Typography>
                                                            </Tooltip> : <Typography
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",}}
                                                                className={classes.emailText}>
                                                                {value.email.name}</Typography>
                                                        }
                                                        {value.email.address.length > 20 ?
                                                            <Tooltip title={value.email.address}>
                                                                <Typography style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",}}
                                                                            className={classes.emailText}>{value.email.address}</Typography>
                                                            </Tooltip> : <Typography
                                                                style={{
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden",
                                                                    whiteSpace: "nowrap",}}
                                                                className={classes.emailText}>
                                                                {value.email.address}</Typography>
                                                        }
                                                    </div>
                                                    {value.ahPhone && <div
                                                        onClick={() => history.push('/support')}
                                                        className={classes.emailContainer}
                                                    >
                                                        <img
                                                            src={HelpSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.email}
                                                        />
                                                        <Typography className={classes.emailText}>
                                                            {"After Hours: " + value.ahPhone}
                                                        </Typography>
                                                    </div>}
                                                </React.Fragment>
                                            )}
                                            {value.recent && (
                                                <Typography className={classes.recent}>
                                                    {value.recent}
                                                </Typography>
                                            )}
                                            {value.recent && (
                                                <div
                                                    className={classes.recentContainer}
                                                    onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                >
                                                    <Typography className={classes.recentItem}>
                                                        Delivery
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Pickup
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Exchange
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Dialog open={showNotification && notificationList.length > 0} PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}  style={{":&focus":{outline: 0}, border:'none', display:'flex',  justifyContent:'center' }}>
                        <div style={{margin:0, padding:0, paddingBottom:0, backgroundColor:'#7C7D7E', maxWidth:600}}>
                            {notificationList.map(notification => {
                                return <React.Fragment>
                                    <Grid item xs={12} style={{display:'flex', fontSize:16, fontWeight:'bold', paddingBottom:12}}>
                                        <Grid container style={{borderRadius:4,
                                            backgroundColor: notification.type === "Informational" ? "#E4EBF1" : notification.type === "Warning" ? "#F6F4DF": notification.type === "Important" ? "#F5B8BF" : "none" ,
                                            border: notification.type === "Informational" ? "2px solid #7B99B5" : notification.type === "Warning" ? "2px solid #CEC129": notification.type === "Important" ? "2px solid #CD1D32" : "none" ,
                                            paddingTop:24, paddingLeft:24, paddingRight:12,paddingBottom:12, textAlign:"left"}}>
                                            <Grid item xs={12} style={{fontSize:24, paddingBottom:12}}>
                                                {notification.title}
                                            </Grid>
                                            <Grid item xs={12} style={{paddingBottom:24}}>
                                                <Typography
                                                    variant="body1"
                                                    style={{whiteSpace: 'pre-line'}}
                                                >
                                                    {notification.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justify={notification.dismissible === true ? "space-between" : 'flex-end'}>

                                                    <Grid item style={{paddingRight:12}}>
                                                        {notification.dismissible === true ? (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={dismiss}
                                                                        onChange={(e)=> {
                                                                            setDismiss(e.target.checked)
                                                                        }}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Do not show anymore"
                                                            />

                                                        ):(
                                                            <Button
                                                                variant={"outlined"}
                                                                color={'primary'}
                                                                onClick={()=> {
                                                                    setShowNotification(false);
                                                                    updateNotification(true, notification.system_notification_id)
                                                                }}
                                                            >
                                                                I Have Read
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant={"contained"} color={'primary'}
                                                                onClick={()=> {
                                                                    setShowNotification(false);
                                                                    if (dismiss){
                                                                        updateNotification(true, notification.system_notification_id)
                                                                    } else {
                                                                        updateNotification(false, notification.system_notification_id)
                                                                    }
                                                                }}
                                                        >
                                                            {notification.dismissible === true ? "Acknowledge" : "Read Later"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            })
                            }
                        </div>
                    </Dialog>
                </Grid>

            ) : <Redirect to='/login'/>}
        </React.Fragment>
    )
}
const useStyles = makeStyles((theme) => (dashboardStyle(theme)));
