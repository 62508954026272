import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {alertTitles} from "../../../../../constants/alert/alertTitle";
import {alertMessages} from "../../../../../constants/alert/alertMessages";
import {buttonText} from "../../../../../constants/alert/alertButtonTexts";
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import {labels} from "../../../../../constants/inputs/labels";
import {SelectItemModal} from "./selectItemModal/selectItemModal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HelpSign from "../../../../../assets/alert-triangle.svg";

export const OrderItem = ({user, item, order, setOrder, handleInput, isPace = false}) => {
    const classes = useStyles();
    const [itemValues, setItemValues] = React.useState ({});
    const [open, setOpen] = React.useState(false);
    const [showApproval, setShowApproval] = useState(false);
    useEffect(() => {
        let obj = {};
        obj = {item_id:item.item_id,
               approval:item.approval,
               prescription_required:item.prescription_required,
               price:item.supplemental_price,
               name:item.name,
               special_item_info: { delivery_method: null, liters_per_minute: item.liters_per_minute },
               quantity:1,
               type:item.type,
        };
        if (item.max_quantity_per_item !== null){
            obj.special_item_info.max_quantity_per_item=item.max_quantity_per_item;
        }
       setItemValues(obj)
    }, [item]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };
    return (
        <Grid item className={classes.container}>
            <Paper className={classes.paper} style={{border: item.prescription_required === true ? "1px solid red" : "1px solid #D5D9DC"}}>
                <div className={classes.item}>
                    <div className={classes.name}>
                        {item.name}
                    </div>
                    <div className={classes.image}>
                        {(item.image === '.jpeg' || item.image === '') ? (
                            <img src={`${process.env.REACT_APP_BUCKET_URL}placeholder.png`} className={classes.img} />
                        ) : (
                            <img src={`${process.env.REACT_APP_BUCKET_URL}${item.image}`} className={classes.img} />
                        )}
                    </div>
                    <div style={{height:20, width:160, display:"flex", justifyContent:'flex-end'}}>{item.prescription_required === true &&
                    <img src={'https://masteritemsimages.s3.amazonaws.com/rx.png'} height='25px' width={'20px'}/>
                    }
                    </div>
                    {item.type === "PD" ? (
                        <React.Fragment>
                            <Grid container justify={'space-between'} alignItems={'center'} style={{paddingRight:26,paddingLeft:26, paddingTop:5}}>
                                {
                                    item.supplemental_price ? (
                                            <React.Fragment>
                                                <div className={classes.type}>
                                                    {isPace === true ? labels.paceFormularyItem : labels.in_formulary}
                                                </div>
                                                <div className={classes.oof_price}>

                                                    ${item.supplemental_price}
                                                </div>
                                            </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className={classes.type}>
                                                {isPace === true ? labels.paceFormularyItem : labels.in_formulary}
                                            </div>
                                            <div className={classes.typePrice}>
                                                {labels.zero_price}
                                            </div>
                                        </React.Fragment>
                                    )
                                }
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (item.approval){
                                        setShowApproval(true);
                                    } else {
                                        handleClickOpen();
                                    }
                                }}
                                className={classes.button}
                            >
                                {buttonText.addToOrder}
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Grid container justify={'space-between'} alignItems={'center'} style={{paddingRight:26,paddingLeft:26, paddingTop:5}}>
                                <div className={classes.out_type}>
                                    {item.type === "OOF" && (isPace === true ? labels.paceOOF : labels.out_of_formulary)}
                                    {item.type === "PI" && labels.purchase_item}
                                    {item.type === "Monthly" && isPace === true && labels.paceMonthly}
                                    {item.type === "LF" && labels.limited_formulary}
                                </div>
                                {
                                    item.supplemental_price ? (
                                        <div className={classes.oof_price}>

                                            ${item.supplemental_price}
                                        </div>
                                    ) : (
                                        <div className={classes.typePrice}>

                                            {labels.zero_price}
                                        </div>
                                    )
                                }
                            </Grid>
                            <div className={classes.dialogButton}>
                                {item.approval ?
                                    // <AlertDialog buttonText={buttonText.addToOrder}
                                    //              type={buttonTypes.standardContainedPrimary}
                                    //              title={alertTitles.outOfFormulary}
                                    //              content={alertMessages.outOfFormulary}
                                    //              confirmActionText={buttonText.outOfFormulary}
                                    //              denyActionText={buttonText.cancel}
                                    //              disabledButton={false}
                                    //              confirmAction={handleClickOpen}
                                    //              buttonCSS={classes.button}
                                    //
                                    // />
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={()=> {
                                                setShowApproval(true)
                                            }}

                                            className={classes.button}
                                        >
                                            {buttonText.addToOrder}
                                        </Button>

                                    </div>
                                :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleClickOpen}
                                        className={classes.button}
                                    >
                                        {buttonText.addToOrder}
                                    </Button>}

                            </div>
                        </React.Fragment>
                    )}
                    <SelectItemModal
                        user={user}
                        itemValues={itemValues}
                        setItemValues={setItemValues}
                        open={open}
                        onClose={handleClose}
                        item={item}
                        order={order}
                        setOrder={setOrder}
                        handleInput={handleInput}
                    />
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={showApproval}
                        onClose={()=> setShowApproval(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div style={{display:"flex", justifyContent:'center',paddingTop:24}}>


                            <img
                                src={HelpSign}
                                alt="logo"
                                width='32'
                            />
                        </div>
                        <DialogTitle id="alert-dialog-title" style={{fontSize:18, fontWeight:600, color:"#1A1818", textAlign:'center'}}>{alertTitles.outOfFormulary}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{fontSize:14, color:"#1A1818"}}>
                                {alertMessages.outOfFormulary}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button onClick={()=>{
                                        handleClickOpen();
                                        setShowApproval(false)
                                    }}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            style={{textTransform: 'none', fontSize:12, fontWeight:'bold'}}>

                                        {buttonText.outOfFormulary}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button onClick={()=> setShowApproval(false)}
                                            variant="outlined"
                                            color="primary"
                                            autoFocus
                                            fullWidth
                                            style={{textTransform: 'none', fontSize:12, fontWeight:'bold', height:40}}>

                                        {buttonText.cancel}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </div>
            </Paper>
        </Grid>

    )
};

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    paper: {
        height: '340px',
        width:"216px",
        border:'1px solid #D5D9DC',
        borderRadius:4,
        boxShadow:"none"
    },
    item: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'column',
    },
    name: {
        paddingTop:11,
        paddingLeft:4,
        paddingRight:4,
        color:"#00223D",
        fontSize:16,
        fontWeight:600,
        margin:0,
        height:55,
        textAlign:'center'
    },
    image: {
        border:'1px solid #F6F7F7',
        marginTop:7,
        height:164,
        width:164,
        display:'flex',
        alignItems: 'center',
        justifyContent: "center"
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto'
    },
    type: {
        color:'#4E6071',
        fontSize:12
    },
    typePrice: {
        fontSize:12,
        backgroundColor:'#D5D9DC',
        color:'#4E6071',
        borderRadius:4,
        padding:'5px 11px'

    },
    out_type: {
        color:'#CD1D32',
        fontSize:12
    },
    button: {
        marginTop:4,
        padding: '10px 19px',
        textTransform:'uppercase',
        fontSize:16,
        fontWeight:'bold'
    },
    oof_price: {
        fontSize:12,
        backgroundColor:'#FBECEE',
        color:'#CD1D32',
        borderRadius:4,
        padding:'5px 11px'
    },
});

