const isLogged = (state = false, action) => {
    switch (action.type) {
        case 'login':
            return !state;
        case 'logout':
            localStorage.setItem('key', JSON.stringify(null));
            return state = false;
        default:
            return state;
    }
};

export default isLogged;