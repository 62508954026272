export const buttonText = {
    positive: "Yes, cancel",
    negative: "No, return",
    cancel: "CANCEL",
    proceed: "Yes, proceed",
    clearItems: "Clear Items",
    addItems: "Add Items",
    requestPickup: "Request Pickup",
    continuePickup: "Continue with Pickup Order",
    outOfFormulary: "ADD TO ORDER",
    yes: "Yes",
    no: "No",
    addToOrder: "ADD TO ORDER",
    placeOrder: "Place Order",
    possibleDuplicates: "SHOW DUPLICATES",
    back: "BACK",
    addNote: "Add Note",
    updatePatient: "UPDATE PATIENT",
    createPatient: "CREATE PATIENT",
    next: "NEXT",
    editHospice: "Edit Hospice",
    editDME: "Edit DME Provider",
    createDME: "Create DME Provider",
    createHospice: "Create Hospice",
    addLots: "Add Lots",
    search: "Search",
    clear: "Clear",
    viewLogs: "View Lots",
    dmeUser: "DME User",
    hospiceUser: "Hospice User",
    itemStatus: "Update Item Status",
    submit: "Submit",
    update_profile: "UPDATE PROFILE",
    order: "Order",
    save: "SAVE",
    requestPassword: "REQUEST PASSWORD",
    requestUsername: "REQUEST USERNAME",
    contract: "Contract",
    uploadMasterList: "Upload Master List",
    uploadMasterListImages: "Upload Master List Images",
    uploadPatientList: "Upload Patient List",
    uploadInventory: "Upload Inventory Items",
    uploadDelivery:"Upload Delivery Orders",
    sendPasswordResetUser: "Send Password Reset",
    saveNewRegion: "Save New Region",
    exportData: "Export Data",
    generateReport:'Generate Report',
    cleaningAndMaintenance:'Cleaning / Maintenance'
};
