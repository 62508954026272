import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Grid from "@material-ui/core/Grid";
import {labels} from "../../../../constants/inputs/labels";
import Link from "@material-ui/core/Link";
import {checkPermissions} from "../../../../common/permissions/checkPermissions";
import {permissions} from "../../../../constants/permissions/permissions";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../../constants/inputs/tooltips";
import Button from "@material-ui/core/Button";
import EditIcon from "../../../../assets/edit-button.svg";
import {checkSuperAdmin} from "../../../../common/permissions/checkSuperAdmin";
import DeletePatient from "../deletePatient/deletePatient";
import CreateOrder from "../../../createOrder/createOrder";
import CheckInventory from "../../../../common/checkInventory/checkInventory";
import RequestPickup from "../../../requestPickup/requestPickup";
import RequestExchange from "../../../requestExchange/requestExchange";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";


const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // onClick={handleLastPageButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

export default function NewPatientsTable(props) {
    const {page, setPage, rowsPerPage, setRowsPerPage, searchType} = props;
    const history = useHistory();
    const classes = useStyles2();

    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows?.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (searchType === 3 ){
            props.searchByLot(rowsPerPage, newPage);
        } else {
            props.getPatients(rowsPerPage, newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        if (searchType === 3 ){
            props.searchByLot(parseInt(event.target.value, 10), 0);
        } else {
            props.getPatients(parseInt(event.target.value, 10), 0);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell align="left">Patient</StyledTableCell>
                        <StyledTableCell align="left">Address</StyledTableCell>
                        <StyledTableCell align="left">Phone Number</StyledTableCell>
                        <StyledTableCell align="center">
                            {(checkPermissions([permissions.update_patients])) ? tableColumnTitles.edit :""}
                        </StyledTableCell>
                        <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!props.loading && props.rows && props.rows.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={6} >
                                <Grid container
                                      style={{minHeight:200}}
                                      justify={'center'} alignItems={'center'} alignCOntent={'center'} direction={'column'}>
                                    <Grid container justify={'center'} alignItems={'center'} alignCOntent={'center'} style={{padding:16}}>
                                        {'No results found'}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>

                    )}
                    {props.loading ? <TableRow>
                        <TableCell colSpan={6} >
                            <Grid container
                                  style={{minHeight:200}}
                                  justify={'center'} alignItems={'center'} alignCOntent={'center'} direction={'column'}>
                                <CircularProgress />
                                <Grid container justify={'center'} alignItems={'center'} alignCOntent={'center'} style={{padding:16}}>
                                    {'Retriving patients...'}
                                </Grid>
                            </Grid>
                        </TableCell>
                        </TableRow>: (
                        <React.Fragment>


                    {
                        // (rowsPerPage > 0
                        //     ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        //     :
                        //
                            props.rows && props.rows.length > 0 && props.rows
                    .map((row) => (
                        <TableRow key={row.patient_id}>
                            <TableCell component="tr" scope="row" style={{ width: 100, paddingTop:12, paddingBottom:12}}>
                                {row.status}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ paddingTop:12, paddingBottom:12}}>
                                <div style={{cursor: "pointer", hover: {background: "grey"}, fontSize: 15}}
                                     onClick={() => {
                                         history.push({
                                             pathname: `/patients/patientProfile/${row.patient_id}`,
                                             patient_id: row.patient_id
                                         })
                                     }}>
                                    <span>{`${row.last_name}, ${row.first_name} ${row.mi ? row.mi : ""}`}</span><br/>
                                    <span style={{
                                        fontSize: 12,
                                        color: 'rgba(117, 117, 117, 1)'
                                    }}>{`${labels.medical_record}: #${row.medical_record}`}</span>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{paddingTop:12, paddingBottom:12}}>
                                <Grid container justify={"center"} alignItems={"flex-start"} direction={"column"}>
                                <Grid item>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        // onClick={handleOnClick}
                                    >
                                        <span>{`${row.address1} ${row.address2 ? row.address2 : ""}, ${row.city}, ${row.state}, ${row.zip}`}</span>
                                    </Link>
                                </Grid>
                            </Grid>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{paddingTop:12, paddingBottom:12}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: "flex-start"}}>
                                <span>{props.formatPhoneNumber(row.phone_number)}
                                </span>
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{paddingTop:12, paddingBottom:12}}>
                                <div style={{display: 'flex', alignItems: "center", alignContent:'center', justifyContent: "center"}}>
                                    {checkPermissions([permissions.update_patients]) && (
                                        <Tooltip title={tooltips.editPatient}>
                                            <Button
                                                size="large"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    history.push({pathname: '/patients/edit', patient_id: row.patient_id})
                                                }}
                                            >
                                                <img
                                                    src={EditIcon}
                                                    alt="logo"
                                                    width='22'
                                                    height='22'
                                                    // className={classes.simpleLogo}
                                                    style={{cursor: 'pointer'}}
                                                />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {checkSuperAdmin([permissions.delete_patients]) && (
                                        <DeletePatient rowData={row}/>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{paddingTop:12, paddingBottom:12}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start', alignContent: 'center'}}>
                                    {checkPermissions([permissions.create_orders]) &&
                                    <React.Fragment>
                                        <CreateOrder renderButton={true} user={row} isPace={row?.ispace}/>
                                        <CheckInventory patient_id={row.patient_id}>
                                            <React.Fragment>
                                                <div style={{width: 4}}>
                                                </div>
                                                <RequestPickup showPickup={true} openOrder={false}
                                                               name={row.mi ? row.first_name + " " + row.mi + " " + row.last_name : row.first_name + " " + row.last_name}
                                                               address={row.address2 ? row.address1 + " " + row.address2 : row.address1}
                                                               patient_id={row.patient_id}
                                                               hospice_id={row.hospice_organization_id}
                                                />
                                                <div style={{width: 4}}></div>
                                                <RequestExchange
                                                    name={row.mi ? row.first_name + " " + row.mi + " " + row.last_name : row.first_name + " " + row.last_name}
                                                    address={row.address2 ? row.address1 + " " + row.address2 : row.address1}
                                                    patientId={row.patient_id}
                                                    hospice_id={row.hospice_organization_id}
                                                    region_id={row.region_id}
                                                />
                                            </React.Fragment>
                                        </CheckInventory>
                                    </React.Fragment>
                                    }
                                </div>
                            </TableCell>

                            </TableRow>
                    ))}

                    {/*{emptyRows > 0 && (*/}
                    {/*    <TableRow style={{ height: 53 * emptyRows }}>*/}
                    {/*        <TableCell colSpan={6} />*/}
                    {/*    </TableRow>*/}
                    {/*)}*/}
                        </React.Fragment>
                    )}

                </TableBody>
            </Table>
            <TablePagination
                style={{ display:"flex", justifyContent:'flex-end',}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </TableContainer>
    );
}
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(237, 242, 244, 0.73)",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
        padding:8
    },
}))(TableCell);
