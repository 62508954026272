export const exchangeActions = {
    pickup: "Pickup",
    deliver: "Deliver",
};

export const itemCategory = {
    oxygen: "oxygen",
    compressor: "compressor",
    airloss: "airloss",
    lift: "lift",
};

export const userNavigation = {
    next: "next",
    back: "back",
}