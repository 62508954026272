import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    itemTop: {
        minWidth: "180px",
        // zIndex: 1,
    },
    itemBottom: {
        minWidth: "180px",
        // zIndex: 0,
    },
    itemDivider: {
        paddingTop: theme.spacing(3),
        borderBottom: "1px solid black",
    }

}));
export const ItemElement = (props) => {
    const classes = useStyles();

    const getElementByType = (item, index, updateIndex) => {

        const {type, name, label} = item;
        switch (type) {
            case "select":
                return (
                    <Grid item xs={2} key={index} className={classes.itemTop} style={{zIndex: 1000 - index - updateIndex}}>
                        {/*<Tooltip title={item.tooltip} aria-label={item.tooltip} placement="top">*/}
                        <Select
                            fullWidth
                            isSearchable
                            name={name}
                            id={label + "_" + updateIndex}
                            placeholder={item.label}
                            value={(item.value[updateIndex] === "" || item.value[updateIndex] === undefined) ? null : [{
                                label: item.value[updateIndex],
                                value: item.value[updateIndex]
                            }]}
                            options={item.options}
                            onChange={(event) => {
                                const obj = {
                                    target: {
                                        ...event,
                                        name: name,
                                    }
                                };

                                props.handleInput(obj, updateIndex);

                            }}
                        />
                        {/*</Tooltip>*/}
                    </Grid>
                );
            case "input":
                return (
                    <Grid item xs={2} key={index} className={classes.itemBottom}>
                        <TextField
                            error={item.value[updateIndex] !== undefined && !item.validator[updateIndex]} // check for opposite of validator because a false valid parent value is a true error value here
                            label={label}
                            required={item.isRequired ? item.isRequired : false}
                            id={name + "_" + updateIndex}
                            name={name}
                            value={item.value[updateIndex]}
                            variant="outlined"
                            size="small"
                            onChange={(event) => {
                                props.handleInput(event, updateIndex)
                            }}
                            onKeyDown={(event) => {
                                event.keyCode === 9 && props.handleKeyPress && props.handleKeyPress(event)
                            }}
                        />
                        <FormHelperText
                            style={{color: 'red', textAlign: 'left'}}
                        >
                            {(item.value[updateIndex] !== undefined && !item.validator[updateIndex]) && (
                                `Invalid/Duplicate Value`)
                            }
                        </FormHelperText>
                    </Grid>
                );
            case "date":
                return (
                    <Grid item xs={3} key={index} className={classes.itemBottom}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id={name + "_" + updateIndex}
                                label={label}
                                format="MM/dd/yyyy"
                                onChange={(event) => props.handleInput(event, updateIndex)
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                );
            case "note":
                return (
                    <Grid item xs={3} key={index} className={classes.itemBottom}>
                        <TextField
                            fullWidth={true}
                            label={name}
                            id={name + "_" + updateIndex}
                            value={item.value[updateIndex]}
                            variant="outlined"
                            size="small"
                            onChange={(event) => props.handleInput(event, updateIndex)}
                        />
                    </Grid>
                );
            default:
                console.log(`[Warning]: Item type ${type} not defined`);
                return null;
        }
    };

    return (
        <Grid container spacing={2} direction="row" className={classes.itemDivider}>
            {
                props.fields.map((field, index) => {
                    return getElementByType(field, index, props.index)
                })
            }
            <Grid item>
                <Tooltip title="Add new item" aria-label="Add new item">
                    <IconButton onClick={() => props.addNewItem(props.index, false)}>
                        <AddCircleIcon color="primary" aria-label="Add new item"/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Remove this item" aria-label="Remove this item">
                    <IconButton onClick={() => props.removeItem(props.index)}>
                        <RemoveCircleIcon color="secondary" aria-label="Remove this item"/>
                    </IconButton>
                </Tooltip>
                {
                    props.allItems.length - 1 === props.index && (
                        <Tooltip title="Duplicate this item" aria-label="Duplicate this item">
                            <IconButton onClick={() => props.addNewItem(props.index, true)}>
                                <ControlPointDuplicateIcon color="primary" aria-label="Duplicate this item"/>
                            </IconButton>
                        </Tooltip>)
                }
            </Grid>
        </Grid>
    )
};