import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {Tooltip} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import ClearIcon from '@material-ui/icons/Clear';
import moment from "moment";
import {useHistory} from "react-router-dom";

export default function NotificationTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const history = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function calculateTime(time){
        let value = '';
        let unitOfMeasure;
        if ( moment().diff(time, 'seconds') < 60){
            value = moment().diff(time, 'seconds');
            unitOfMeasure = moment().diff(time, 'seconds') === 1 ? 'second' : 'seconds';
        } else if (moment().diff(time, 'minutes') < 60){
            value = moment().diff(time, 'minutes');
            unitOfMeasure = moment().diff(time, 'minutes') === 1 ? 'minute' : 'minutes';
        } else if (moment().diff(time, 'hours') < 24){
            value = moment().diff(time, 'hours');
            unitOfMeasure = moment().diff(time, 'hours') === 1 ? 'hour' : 'hours';
        } else {
            value = moment().diff(time, 'days');
            unitOfMeasure = moment().diff(time, 'days') === 1 ? 'day' : 'days';
        }
        return `${value} ${unitOfMeasure} ago`
    }

    return (
        <div className={classes.root}>
            <div className={classes.demo1}>
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                    <AntTab label={
                        <div style={{display:'flex'}}>
                            <span>Delivery</span>
                            <Badge badgeContent={props.notifications.delivery.filter(el => el.is_read === false).length} color="secondary" style={{paddingLeft:4}} />
                        </div>
                            } />
                    <AntTab label={
                        <div style={{display:'flex'}}>
                            <span>Pickup</span>
                            <Badge badgeContent={props.notifications.pickup.filter(el => el.is_read === false).length} color="secondary" style={{paddingLeft:4}} />
                        </div>
                    } />
                    <AntTab label={
                        <div style={{display:'flex'}}>
                            <span>Exchange</span>
                            <Badge badgeContent={props.notifications.exchange.filter(el => el.is_read === false).length} color="secondary" style={{paddingLeft:4}} />
                        </div>
                    } />
                </AntTabs>
                <div style={{display:'flex', justifyContent:'flex-end',  color:'grey'}}>
                    <Button size={"small"}  onClick={() => props.setActiveMenu("main")} ><ArrowBackIcon style={{fontSize:16, color:'grey'}}/> Back</Button>
                </div>
            </div>
            <div>
                {value === 0 && (
                    <React.Fragment>
                        {props.notifications.delivery.length > 0 ? (
                            <div style={{display:'flex', justifyContent:'flex-end', fontSize:12, padding:4, color:"grey"}}>
                            <div className={'mark'}
                                 onClick={()=> {
                                     let action = props.notifications.delivery.some(el => el.is_read === false) ? "read_all" : "delete_all"
                                     props.handleNotification('orders', 'delivery', action, null)
                                 }}
                            >{props.notifications.delivery.some(el => el.is_read === false) ? "Mark all as read" : "Clear all"}</div>
                            </div>
                        ) : (<div className="menu">
                            <div style={{textAlign:'center', paddingTop:16}}>
                                No notifications
                            </div>
                        </div>)}
                        {props.notifications.delivery.map((notification,idx)=> {
                            let time = calculateTime(notification.created_date);
                            return (
                                <div className={'item'} style={{padding:8}} key={idx}>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div style={{width:340, color: notification.is_read ? "grey" : 'black'}}
                                             onClick={()=> {
                                                 history.push(`/refresh${notification.link}`)
                                                 props.setOpen(false);
                                             }}
                                        > {notification.template}</div>
                                        <div style={{display:'flex', width:50, justifyContent:'flex-end'}}>
                                            <BootstrapTooltip title="Clear notification">
                                                <ClearIcon style={{fontSize:18}} className={'clear'}
                                                           onClick={()=> {
                                                               props.handleNotification('orders', 'delivery', 'delete', idx, notification)
                                                           }}
                                                />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip title={`Mark as ${notification.is_read ? 'unread' : 'read'}`}>
                                                <div style={{
                                                    width: '19px',
                                                    height: '19px',
                                                    border: '2px solid #EBECF0',
                                                    borderRadius: '50%',
                                                    display: 'flex',
                                                    alignItems:'center',
                                                    alignContent:'center',
                                                    justifyContent:'center',
                                                }}
                                                     onClick={()=> {
                                                         props.handleNotification('orders', 'delivery', 'read', idx, notification)
                                                     }}
                                                >
                                                    {!notification.is_read  && (
                                                        <div style={{
                                                            width: '10px',
                                                            height: '10px',
                                                            backgroundColor: '#00223D',
                                                            borderRadius: '50%'
                                                        }}>
                                                        </div>
                                                    )}

                                                </div>
                                            </BootstrapTooltip>
                                        </div>
                                    </div>
                                    <div style={{color:'grey', fontSize:13, fontWeight: notification.is_read ? 400 : 'bold'}}>{time}</div>
                                </div>
                            )
                        }
                        )}
                    </React.Fragment>
                )}
                {value === 1 && (
                    <React.Fragment>
                        {props.notifications.pickup.length > 0 ? (
                            <div style={{display:'flex', justifyContent:'flex-end', fontSize:12, padding:4, color:"grey"}}>
                            <div className={'mark'}
                                 onClick={()=> {
                                     let action = props.notifications.pickup.some(el => el.is_read === false) ? "read_all" : "delete_all"
                                     props.handleNotification('orders', 'pickup', action, null)
                                 }}

                            >{props.notifications.pickup.some(el => el.is_read === false) ? "Mark all as read" : "Clear all"}</div>
                            </div>
                        ) : (<div className="menu">
                            <div style={{textAlign:'center', paddingTop:16}}>
                                No notifications
                            </div>
                        </div>)}
                        {props.notifications.pickup.map((notification,idx)=> {
                                let time = calculateTime(notification.created_date);
                                return (
                                    <div className={'item'} style={{padding:8}} key={idx}>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div style={{width:340, color: notification.is_read ? "grey" : 'black'}}
                                                 onClick={()=> {
                                                     history.push(`/refresh${notification.link}`)
                                                     props.setOpen(false);
                                                 }}
                                            > {notification.template}</div>
                                            <div style={{display:'flex', width:50, justifyContent:'flex-end'}}>
                                                <BootstrapTooltip title="Clear notification">
                                                    <ClearIcon style={{fontSize:18}} className={'clear'}
                                                               onClick={()=> {
                                                                   props.handleNotification('orders', 'pickup', 'delete', idx, notification)
                                                               }}
                                                    />
                                                </BootstrapTooltip>
                                                <BootstrapTooltip title={`Mark as ${notification.is_read ? 'unread' : 'read'}`}>
                                                    <div style={{
                                                        width: '19px',
                                                        height: '19px',
                                                        border: '2px solid #EBECF0',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems:'center',
                                                        alignContent:'center',
                                                        justifyContent:'center',
                                                    }}
                                                         onClick={()=> {
                                                             props.handleNotification('orders', 'pickup', 'read', idx, notification)
                                                         }}
                                                    >
                                                        {!notification.is_read  && (
                                                            <div style={{
                                                                width: '10px',
                                                                height: '10px',
                                                                backgroundColor: '#00223D',
                                                                borderRadius: '50%'
                                                            }}>
                                                            </div>
                                                        )}

                                                    </div>
                                                </BootstrapTooltip>
                                            </div>
                                        </div>
                                        <div style={{color:'grey', fontSize:13, fontWeight: notification.is_read ? 400 : 'bold'}}>{time}</div>
                                    </div>
                                )
                            }
                        )}
                    </React.Fragment>
                )}
                {value === 2 && (
                    <React.Fragment>
                        {props.notifications.exchange.length > 0 ? (
                            <div style={{display:'flex', justifyContent:'flex-end', fontSize:12, padding:4, color:"grey"}}>
                            <div className={'mark'}
                                 onClick={()=> {
                                     let action = props.notifications.exchange.some(el => el.is_read === false) ? "read_all" : "delete_all"
                                     props.handleNotification('orders', 'exchange', action, null)
                                 }}

                            >{props.notifications.exchange.some(el => el.is_read === false) ? "Mark all as read" : "Clear all"}</div>
                            </div>
                                ) : (<div className="menu">
                            <div style={{textAlign:'center', paddingTop:16}}>
                                No notifications
                            </div>
                        </div>)}
                        {props.notifications.exchange.map((notification,idx)=> {
                                let time = calculateTime(notification.created_date);
                                return (
                                    <div className={'item'} style={{padding:8}} key={idx}>
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div style={{width:340, color: notification.is_read ? "grey" : 'black'}}
                                                 onClick={()=> {
                                                     history.push(`/refresh${notification.link}`)
                                                     props.setOpen(false);
                                                 }}
                                            > {notification.template}</div>
                                            <div style={{display:'flex', width:50, justifyContent:'flex-end'}}>
                                                <BootstrapTooltip title="Clear notification">
                                                    <ClearIcon style={{fontSize:18}} className={'clear'}
                                                               onClick={()=> {
                                                                   props.handleNotification('orders', 'exchange', 'delete', idx, notification)
                                                               }}
                                                    />
                                                </BootstrapTooltip>
                                                <BootstrapTooltip title={`Mark as ${notification.is_read ? 'unread' : 'read'}`}>
                                                    <div style={{
                                                        width: '19px',
                                                        height: '19px',
                                                        border: '2px solid #EBECF0',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems:'center',
                                                        alignContent:'center',
                                                        justifyContent:'center',
                                                    }}
                                                         onClick={()=> {
                                                             props.handleNotification('orders', 'exchange', 'read', idx, notification)
                                                         }}
                                                    >
                                                        {!notification.is_read  && (
                                                            <div style={{
                                                                width: '10px',
                                                                height: '10px',
                                                                backgroundColor: '#00223D',
                                                                borderRadius: '50%'
                                                            }}>
                                                            </div>
                                                        )}

                                                    </div>
                                                </BootstrapTooltip>
                                            </div>
                                        </div>
                                        <div style={{color:'grey', fontSize:13, fontWeight: notification.is_read ? 400 : 'bold'}}>{time}</div>
                                    </div>
                                )
                            }
                        )}
                    </React.Fragment>
                )}

            </div>
        </div>
    );
}

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        fontSize:10
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 10,
        fontSize:14,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(0),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(1),
    },
    demo1: {
        display:'flex',
        width:'100%',
        justifyContent:'space-between',
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
}));
const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize:14
    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

