import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {useSnackbar} from "notistack";
import {endpoints} from "../../../../constants/endpoints";
import {status} from "../../../../constants/status/status";

export default function CustomSelect(props) {
    const {enqueueSnackbar} = useSnackbar();
    const [state, setState] = useState(props.rowData.ispace);
    const handleChange = (e) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.updatePaceSettings, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({ispace: e.value, hospiceId:props.rowData.hospice_organization_id})
            }).then(res => {
                if (res.status === status.success) {
                    //notistack de success
                    enqueueSnackbar(`Hospice was updated with success`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {vertical: "top", horizontal: "right"},
                    });
                    setState(e.value);
                    props.updateView();
                }
            })

    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={'Select...'}
                    value={state === true ? [{value:true, label:'Yes'}] : [{label:"No", value:false}]}
                    options={[{label:"Yes", value:true},{label:"No", value:false}]}
                    onChange={(e)=> handleChange(e)}
                    style={{width:'100%'}}
                />
            </Grid>

        </Grid>
    )
}