import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Select from "react-select";
import {titles} from "../../../../constants/inputs/tableColumnTitles";
import {pickupTableStyle} from './itemsTableStyle';
import {itemStatus, status} from "../../../../constants/status/status";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {alertTitles} from "../../../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {alertMessages} from "../../../../constants/alert/alertMessages";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import Typography from "@material-ui/core/Typography";
import _ from "lodash"
import {endpoints} from "../../../../constants/endpoints";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {useSnackbar} from "notistack";
import TextField from "@material-ui/core/TextField";
import {labels} from "../../../../constants/inputs/labels";

const ItemsTable = props => {
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {order, setOrder} = props;
    const [openModal, setOpenModal] = useState(false);
    const [statusValue, setStatusValue] = useState("");
    const [comment, setComment] = useState("");
    const [currentItem, setCurrentItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const handlerChangeItemStatus = async () => {
        setLoading(true);
        let clone = _.cloneDeep(order);

        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(`${endpoints.updateItemStatus}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    itemStatus: statusValue.value,
                    itemId: currentItem[0].inventory_item_id,
                    specialItemId: currentItem[0].special_item_id,
                    comment: comment,
                    status:false
                })
            }
        );
        const response = await promise.json();
        let reason = statusValue.value;
        if (response.status === 'success'){
            clone.items[currentItem[1]].item_status = statusValue.label;
            setStatusValue([{label:'',value:""}]);
            setOrder(clone);
            setOpenModal(false);
            enqueueSnackbar("Status updated with success.", {
                persist: false,
                variant: "success",
            });

            const sendEmailNotifications = await fetch(`${endpoints.sendLostEmailNotifications}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        item_name: currentItem[0].item_name,
                        comments: comment ? comment : 'No information',
                        order_id: `P200${props.orderId}`,
                        order_type:'pickup',
                        reason: reason,
                        patient_full_name: `${order.patient_first_name} ${order.patient_last_name}`,
                        care_setting: order.care_setting ? order.care_setting : 'No information',
                        asset: currentItem[0].asset,
                        hospice_id: order.hospice_organization_id,
                        delivery_date: moment(currentItem[0].completed_date).format('L'),
                        dme_id: order.dme_organization_id
                    })
                }
            );
            const response2 = await sendEmailNotifications.json();
        } else {
            enqueueSnackbar("Unable to update item status.", {
                persist: false,
                variant: "error",
            });
        }
        setLoading(false)
    };

    return (
        <Grid container className={classes.wrapper}>
            <Grid container className={classes.container} justify={"space-between"}>
                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                    <Grid xs={1} item className={classes.row}>{titles.remove}</Grid>
                    <Grid xs={6} item className={classes.row}>{titles.itemName}</Grid>
                    <Grid xs={6} item className={classes.row}>{titles.asset}</Grid>
                    <Grid xs={6} item className={classes.row}>{titles.qty}</Grid>
                    {props.userType === 'Hospice' && (
                        <React.Fragment>
                            <Grid xs={6} item className={classes.row}>{titles.deliveryDate}</Grid>
                            <Grid xs={6} item className={classes.row}>{titles.remove}</Grid>
                        </React.Fragment>
                    )}
                    {props.userType === 'DME' && (
                        <React.Fragment>
                            <Grid xs={6} item className={classes.row}>{titles.lot}</Grid>
                            <Grid xs={6} item className={classes.row}>{titles.status}</Grid>
                            <Grid xs={6} item className={classes.row}>{titles.deliveryDate}</Grid>
                            <Grid xs={6} item className={classes.row}>{titles.pickupDate}</Grid>
                            <Grid xs={6} item className={classes.row}>{titles.itemStatus}</Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            {props.items && (
                props.items.map((item, index) => {
                    let includes = _.findIndex(props.selectedItems, {'special_item_id': item.special_item_id}) !== -1;
                    return (
                        <Grid container justify={"space-between"} className={classes.rows}>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <Grid xs={4} item className={classes.checkbox}>
                                    <Checkbox
                                        color="primary"
                                        onChange={(e) => props.handleCheckboxChange(item, index, e.target.checked)}
                                        checked={includes}
                                    />
                                </Grid>
                                <Grid xs={6} item
                                      className={classes.row}>{item.item_name ? item.item_name : item.name}</Grid>
                                <Grid xs={6} item className={classes.row}>{item.asset}</Grid>

                                <Grid xs={6} item className={classes.row}>1</Grid>

                                {props.userType === 'Hospice' && (
                                    <React.Fragment>
                                        <Grid xs={6} item className={classes.row} style={{display:'flex'}}>
                                            <span>
                                                {item.completed_date && moment(item.completed_date).format('L')}
                                            </span>
                                            <span>
                                                {item.exchange_completed_date && moment(item.exchange_completed_date).format('L')}
                                            </span>
                                        </Grid>
                                        <Grid xs={6} item className={classes.row}></Grid>
                                    </React.Fragment>
                                )}
                                {props.userType === 'DME' && (
                                    <React.Fragment>
                                        <Grid xs={6} item className={classes.row}>
                                            {item.lot_no ? (
                                                item.lot_no
                                            ) : (
                                                <div>{''}</div>)
                                                // props.userType === 'DME' && (
                                                //     <div style={{width: '100%'}}>
                                                //         <CreatableSelect
                                                //             formatCreateLabel={(value) => <span>Add lot number</span>}
                                                //             placeholder="Add lot number"
                                                //             isClearable
                                                //             onChange={(e) => {
                                                //                 props.selectAssetHandle(e, index, "lot")
                                                //             }}
                                                //         />
                                                //     </div>
                                                // ))
                                            }
                                        </Grid>
                                        <Grid xs={6} item className={classes.row}>{item.item_status}</Grid>
                                        <Grid xs={6} item className={classes.row}>
                                            <span>
                                                {item.completed_date && moment(item.completed_date).format('L')}
                                            </span>
                                            <span>
                                                {item.exchange_completed_date && moment(item.exchange_completed_date).format('L')}
                                            </span>
                                        </Grid>
                                        <Grid xs={6} item className={classes.row}></Grid>
                                        <Grid xs={6} item className={classes.row}>
                                            <div style={{width: "100%"}}>
                                                {item.item_status && (item.item_status ?
                                                    <Button variant={"outlined"} size={"small"} onClick={() => {
                                                        setOpenModal(true);
                                                        setCurrentItem([item, index]);
                                                    }}>{buttonText.itemStatus}</Button> : <Typography>
                                                        {item.item_status}
                                                    </Typography>)}
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                    )
                })
            )}
            <Dialog
                open={openModal}
                onClose={()=>setOpenModal(false)}
            >
                <DialogTitle>{alertTitles.updateItemStatus}</DialogTitle>
                <DialogContent className={classes.dialog}>
                    <div className={classes.messages}>{alertMessages.changeItemStatus}</div>
                    <div className={classes.warehouseSelect} style={{zIndex:300}}>
                        <Select
                            options={itemStatus}
                            value={currentItem.status && (currentItem.status === "" ? itemStatus[0] : [{
                                value: currentItem.status,
                                label: currentItem.status
                            }])}
                            onChange={(e)=> setStatusValue(e)}
                        />
                    </div>
                    <div style={{paddingTop: 8}}>
                        <CssTextField
                            id={"Note"}
                            name={"Note"}
                            placeholder={'Comments'}
                            values={comment}
                            variant={"outlined"}
                            multiline
                            rows={7}
                            onChange={(e) => setComment(e.target.value)}
                            size='small'
                            fullWidth
                            required
                            InputLabelProps={{
                                shrink: true,
                                style: {letterSpacing: 0.39,}
                            }}
                            inputProps={{style: {fontSize: 14}}}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" disabled={loading} onClick={() => {
                        setOpenModal(false)
                        setComment("");
                    }} color="secondary">
                        {buttonText.cancel}
                    </Button>
                    <Button variant="contained" color="primary" disabled={loading} onClick={() => {
                        handlerChangeItemStatus()
                        setComment("");
                    }}>
                        {buttonText.yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);
export default ItemsTable;

const useStyles = makeStyles((theme) => (pickupTableStyle));
