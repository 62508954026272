export const HospiceProviderStyle = (theme) => {
    return {
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(1168 + theme.spacing(2) * 2)]: {
                maxWidth: 1200,
                marginLeft: 'auto',
                marginRight: 'auto'
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        stepper: {
            padding: theme.spacing(3, 0, 5),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            width: 'auto',
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
    }
};

export const HospiceDetailsStyle = (theme) => {
    return {
        formControl: {
            minWidth: 70,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        item: {
            paddingTop: theme.spacing(2),
        },
        spacing: 8,
        statusSection: {
            display: "flex",
            justifyContent: 'space-between',
            alignContent: 'center',
            paddingTop: "16px"
        },
    }
};

export const HospiceResourcesStyle = (theme) => {
    return {
        root: {
            display: "flex",
        },
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        columnItem: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingTop: "1px",
        },
        columnItemWithPadding: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingTop: "20px",
            paddingBottom: "20px",
            borderTop: "1px solid black",
        },
        rowItem: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
        },
        centerItem: {
            alignSelf: "center",
            justifySelf: "center",
        },
        width: {
            width: "200px",
        }
    }
}
