import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import moment from "moment";
import jwt from "jwt-decode";
import {makeStyles} from "@material-ui/core/styles";

export const OrderNotes = ({order, setOrder}) => {
    const classes = useStyles();
    const [currentNote, setCurrentNote] = React.useState('');
    let userType = localStorage.getItem('key');
    userType= JSON.parse(userType);
    let user = jwt(userType.accessToken);

    const addOrderNote = () => {
        let final = moment().format('LLL');
        const arr = [...order.notes];
        let input = {
            user_id: user.payload.user_id,
            first_name:user.payload.first_name,
            last_name:user.payload.last_name,
            creator: `${user.payload.first_name} ${user.payload.last_name}`,
            created_date: final,
            description: currentNote};
        arr.push(input);
        setOrder({...order, notes: arr});
        setCurrentNote('')
    };

    return (
        <div style={{paddingBottom:'20px'}}>
            <div className={classes.notes}>
                {order.notes && (
                    <div>
                        {order.notes.map(note => {
                            return (
                                <React.Fragment>
                                    <div className={classes.noteContainer}>
                                        <div>{note.description}</div>
                                        <div className={classes.note}>
                                            {`${note.first_name} ${note.last_name}, ${note.created_date}`}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                )}
                <TextField
                    placeholder="Some additional notes can be put here..."
                    label='Add additional note'
                    multiline
                    name='note'
                    rows={2}
                    fullWidth
                    rowsMax={9}
                    variant={'outlined'}
                    value={currentNote}
                    className={classes.noteInput}
                    onChange={(e)=>{setCurrentNote(e.target.value)}}
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing:0.39,}
                    }}
                />
                <div className={classes.button}>
                    <Button
                        disabled={currentNote === ''}
                        variant={"contained"}
                        color={"primary"}
                        onClick={addOrderNote}
                        style={{fontWeight:'bold', fontSize:12}}
                    >ADD NOTE</Button>
                </div>
            </div>
        </div>
    )
};
const useStyles = makeStyles((theme) => ({
    noteSection: {
        padding:"16px 24px",
        fontSize:20,
    },
    notes: {
        paddingTop:24,
    },
    noteContainer: {
        display:'flex',
        backgroundColor:'#F9F9F9',
        borderRadius:2,
        padding: 16,
        marginBottom:12,
        justifyContent:'space-between',
        fontSize:14,
        color:'#2E3236'
    },
    note:  {
        fontStyle:'italic',
        color:"#5D6770",
    },
    // noteInput: {
    //     border:'1px solid #F0F0F0',
    //     padding:6,
    //     borderRadius:'2px',
    //     width:'100%',
    // },
    button: {
        alignContent:'right',
        justifyContent: 'flex-end',
        display:'flex',
        paddingTop:'10px',
        width:'100%',
        paddingBottom:24
    }
}));
