import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import MoodOutlinedIcon from '@material-ui/icons/MoodOutlined';
import IconButton from "@material-ui/core/IconButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import MoodBadOutlinedIcon from '@material-ui/icons/MoodBadOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentVeryDissatisfiedOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import {getAllHospices} from "../../../utils/helpers/dataGetter";
import {endpoints} from "../../../../constants/endpoints";
import {emailValidation, usernameValidation} from "../../../../constants/validations/validationTypes";
import jwt from "jwt-decode";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";

export default function CreateSurvey(props) {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const token = JSON.parse(localStorage.getItem('key'));
    let decoded = jwt(token.accessToken);
    const edit = props.location?.state?.edit;
    const rowData = props.location?.state?.rowData;
    console.log(rowData);
    const [showModal, setShowModal] = useState(false);
    const [hospices, setHospices] = useState([]);
    const [hospicesList, setHospicesList] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState({
        question_text:null,
        question_position:1,
        question_type:"",
        is_active: true,
        is_mandatory: true,
        option_other:null,
        optBool:null,
        opt1: null,
        opt2: null,
        opt3: null,
        opt4: null,
        opt5:null,
        optFreeTextBool:null
    });
    const [questions, setQuestions] = useState(edit ? rowData.questions : []);
    const [surveyOptions, setSurveyOptions] = useState({
        survey_name: edit ? rowData.survey_name : "",
        description:edit ? rowData.description : "",
        end_date:edit ? rowData.end_date : moment().format('YYYY-MM-DD'),
        start_date:edit ? rowData.start_date: moment().format('YYYY-MM-DD'),
        is_open: edit ? rowData.is_open : true,
        hospice_ids: edit ? rowData.hospice_ids : [],
        user_id: edit ? rowData.user_id : decoded.payload.user_id,
        type: edit ? rowData.survey_type : "",
    });
    useEffect(()=> {
        getAllHospices(setHospices);
    }, []);

    useEffect(()=> {
        if (hospices.length >0){
            let arr = [];
            hospices.map(hospice => {
                arr.push({
                    label:hospice.hospice_name,
                    value:hospice.hospice_organization_id
                });

            });
            setHospicesList(arr);
        }
    }, [hospices]);

    const createSurvey= async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.createSurvey, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({ ...surveyOptions, questions
            })
        });
        const response = await promise.json();
        console.log(response)
        enqueueSnackbar("Survey created with success", {
            persist: false,
            variant: "success",
        });
        history.push('/admin/survey/all')
    };

    // const createAdditionalSurvey= async () => {
    //     const token = JSON.parse(localStorage.getItem('key'));
    //     const promise = await fetch(endpoints.createSurvey, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token.accessToken
    //         },
    //         body: JSON.stringify({ ...surveyOptions, questions
    //         })
    //     });
    //     const response = await promise.json();
    //     console.log(response)
    // };

    return (
        <Grid conatainer style={{padding:36, width:'100%'}}>
            <Grid container style={{backgroundColor:'white',padding:36, borderRadius:4}}>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12} style={{paddingBottom:12}}>
                        <TextField
                            size={'small'}
                            variant={'outlined'}
                            label={"Survey Name"}
                            fullWidth
                            style={{paddingBottom:12}}
                            value={surveyOptions.survey_name}
                            onChange={(e)=> setSurveyOptions({...surveyOptions, survey_name: e.target.value})}
                        />
                        <TextField
                            fullWidth
                            size={'small'}
                            variant={'outlined'}
                            label={"Description"}
                            multiline
                            rows={4}
                            value={surveyOptions.description}
                            onChange={(e)=> setSurveyOptions({...surveyOptions, description : e.target.value})}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{zIndex:21}}>
                                <Select
                                    classNamePrefix="select"
                                    placeholder="Select Survey Type"
                                    name="color"
                                    value={surveyOptions.type ? [{label:surveyOptions.type, value:surveyOptions.type}] : ""}
                                    onChange={(e) => {
                                        setSurveyOptions({...surveyOptions, type:e.value})
                                    }}
                                    options={[
                                        {
                                            label:"Delivery",
                                            value:"Delivery"
                                        },
                                        {
                                            label:"Pickup",
                                            value:"Pickup"
                                        },
                                        {
                                            label:"General",
                                            value:"General"
                                        }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} style={{zIndex:20}}>
                                <Select
                                    // className={classes.z}
                                    classNamePrefix="select"
                                    placeholder="Select Hospices"
                                    name="color"
                                    isMulti

                                    onChange={(e) => {
                                        let arr = [];
                                        if (e && e.length > 0){
                                            e.map(hospice => {
                                                arr.push(hospice.value)
                                            });
                                            setSurveyOptions({...surveyOptions, hospice_ids:arr})
                                        } else {
                                            setSurveyOptions({...surveyOptions, hospice_ids:[]})
                                        }
                                    }}
                                    options={hospicesList}
                                />
                            </Grid>
                            <Grid container>
                                <Grid xs={6} style={{padding:8}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container justify="space-around">
                                            <KeyboardDatePicker
                                                margin="normal"
                                                inputVariant="outlined"
                                                placeholder='MM/DD/YYYY'
                                                size={"small"}
                                                label="Start Date *"
                                                format="MM/dd/yyyy"
                                                value={surveyOptions.start_date}
                                                onChange={(e) => {
                                                    setSurveyOptions({...surveyOptions, start_date:moment(e).format('YYYY-MM-DD')});
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{
                                                    backgroundColor:'white',
                                                    width:'100%',
                                                    margin:0
                                                }}
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                    <Grid xs={6} style={{padding:8}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    style={{
                                                        backgroundColor:'white',
                                                        width:'100%',
                                                        margin:0,
                                                    }}
                                                    inputVariant="outlined"
                                                    placeholder='MM/DD/YYYY'
                                                    size={"small"}
                                                    label="End Date *"
                                                    format="MM/dd/yyyy"
                                                    value={surveyOptions.end_date}
                                                    onChange={(e) => {
                                                        setSurveyOptions({...surveyOptions, end_date:moment(e).format('YYYY-MM-DD')});
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item style={{display:'flex'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={surveyOptions.is_open}
                                            onChange={(e)=> {
                                                setSurveyOptions({...surveyOptions, is_open: e.target.checked})
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is active"
                                />
                            </Grid>
                            <Grid item style={{display:'flex'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled
                                            checked={false}
                                            // onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is Anonymous"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}  style={{padding:4}}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}  style={{padding:4, display:'flex', justifyContent:'flex-end'}}>
                        <Button variant={'outlined'} color={'primary'} onClick={()=> setShowModal(true)}>Add Question</Button>
                    </Grid>
                    {
                        questions.length > 0 && (
                            <Grid item xs={12}  style={{padding:4, display:'flex'}}>
                                <Typography>
                                    Question List:
                                </Typography>
                            </Grid>
                        )

                    }

                    {questions.map((question,index)=> {
                        return(
                        <Grid item xs={12} key={index}>
                            <ListItem button style={{padding:12, display:'flex', flexDirection:'column', border:'1px solid lightgrey', borderRadius:4, justifyContent:'space-between', alignItems:'flex-start', alignContent:'flex-start'}}
                                      onClick={()=> {
                                          console.log(questions[index])
                                          setActiveQuestion(questions[index]);
                                          setShowModal(true);
                                      }}
                            >
                                <Typography>
                                    {`Q${index+1}: ${question.question_text}`}
                                </Typography>
                                <Typography style={{color:'grey'}}>
                                    {`Type: ${question.question_type}`}
                                </Typography>
                            </ListItem>
                        </Grid>)
                    })}
                    <Grid item xs={12}  style={{padding:4, display:'flex', justifyContent:'flex-end'}}>
                        <Button
                            disabled={questions.length === 0}
                            variant={'contained'} color={'primary'} onClick={()=> createSurvey()}>Create Survey</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                onClose={()=> setShowModal(false)} open={showModal}>
               <DialogContent style={{minHeight:500}}>
                   <Grid container spacing={2}>
                       <Grid item xs={12} style={{paddingBottom:8}}>
                           <Typography variant={'h6'}>Add new question</Typography>
                       </Grid>
                       <Grid item sm={10} xs={12}>
                           <TextField
                               size={'small'}
                               variant={'outlined'}
                               label={"Question text"}
                               fullWidth
                               style={{paddingBottom:12}}
                               value={activeQuestion.question_text}
                               onChange={(e)=> setActiveQuestion({...activeQuestion, question_text: e.target.value})}
                           />
                       </Grid>
                       <Grid item sm={2} xs={12}>
                           <TextField
                               size={'small'}
                               variant={'outlined'}
                               label={"Position"}
                               fullWidth
                               type="number"
                               style={{paddingBottom:12}}
                               value={activeQuestion.question_position}
                               onChange={(e)=>setActiveQuestion({...activeQuestion, question_position:e.target.value})}
                           />
                       </Grid>
                       <Grid item xs={12}>
                           <Grid container spacing={2}>
                               <Grid item xs={6} style={{zIndex:20}}>
                                   <Select
                                       classNamePrefix="select"
                                       placeholder="Select Question Type"
                                       name="color"
                                       onChange={(e) => {
                                           setActiveQuestion({...activeQuestion, question_type: e.value});
                                       }}
                                       value={activeQuestion.question_type !== "" ? [{label:activeQuestion.question_type, value:activeQuestion.question_type}]: ""}
                                       options={[
                                           {
                                               label:'Free Text',
                                               value:'Free Text'
                                           },
                                           {
                                               label:'Numbers Radio Options',
                                               value:'Numbers Radio Options'
                                           },
                                           {
                                               label:'Smiley Face Radio Options',
                                               value:'Smiley Face Radio Options'
                                           },
                                           {
                                               label:'Text Radio Options',
                                               value:'Text Radio Options'
                                           },
                                           {
                                               label:'Text Checkbox Options',
                                               value:'Text Checkbox Options'
                                           },
                                           {
                                               label:'Yes / No',
                                               value:'Yes / No'
                                           },
                                       ]}
                                   />
                               </Grid>
                               <Grid item xs={6}>
                                   <Grid container>
                                   <Grid item style={{display:'flex'}}>
                                       <FormControlLabel
                                           control={
                                               <Checkbox
                                                   checked={activeQuestion.is_active}
                                                   onChange={(e)=> setActiveQuestion({...activeQuestion, is_active: e.target.checked})}
                                                   name="checkedB"
                                                   color="primary"
                                               />
                                           }
                                           label="Is active"
                                       />
                                   </Grid>
                                   <Grid item style={{display:'flex'}}>
                                       <FormControlLabel
                                           control={
                                               <Checkbox
                                                   checked={activeQuestion.is_mandatory}
                                                   onChange={(e)=> setActiveQuestion({...activeQuestion, is_mandatory: e.target.checked})}
                                                   // onChange={handleChange}
                                                   name="checkedB"
                                                   color="primary"
                                               />
                                           }
                                           label="Is Mandatory"
                                       />
                                   </Grid>
                                   </Grid>
                               </Grid>
                               {
                                   activeQuestion.question_type === "Text Radio Options" && (
                                       <Grid item xs={12}>
                                           <Grid container spacing={2}>
                                               <Grid item xs={6}>
                                                   <TextField
                                                       fullWidth
                                                       label={'Option 1'}
                                                       variant={'outlined'}
                                                       size={'small'}
                                                       onChange={(e)=> setActiveQuestion({...activeQuestion, opt1: e.target.value})}
                                                       value={activeQuestion.opt1}
                                                   />
                                               </Grid>

                                               { activeQuestion.opt1 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 2'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt2: e.target.value})}
                                                           value={activeQuestion.opt2}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt2 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 3'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt3: e.target.value})}
                                                           value={activeQuestion.opt3}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt3 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 4'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt4: e.target.value})}
                                                           value={activeQuestion.opt4}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt4 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 5'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt5: e.target.value})}
                                                           value={activeQuestion.opt5}
                                                       />
                                                   </Grid>
                                               )}
                                           </Grid>

                                       </Grid>
                                   )

                               }
                               {
                                   activeQuestion.question_type === "Text Checkbox Options" && (
                                       <Grid item xs={12}>
                                           <Grid container spacing={2}>
                                               <Grid item xs={6}>
                                                   <TextField
                                                       fullWidth
                                                       label={'Option 1'}
                                                       variant={'outlined'}
                                                       size={'small'}
                                                       onChange={(e)=> setActiveQuestion({...activeQuestion, opt1: e.target.value})}
                                                       value={activeQuestion.opt1}
                                                   />
                                               </Grid>

                                               { activeQuestion.opt1 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 2'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt2: e.target.value})}
                                                           value={activeQuestion.opt2}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt2 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 3'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt3: e.target.value})}
                                                           value={activeQuestion.opt3}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt3 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 4'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt4: e.target.value})}
                                                           value={activeQuestion.opt4}
                                                       />
                                                   </Grid>
                                               )}
                                               { activeQuestion.opt4 !== null && (
                                                   <Grid item xs={6}>
                                                       <TextField
                                                           fullWidth
                                                           label={'Option 5'}
                                                           variant={'outlined'}
                                                           size={'small'}
                                                           onChange={(e)=> setActiveQuestion({...activeQuestion, opt5: e.target.value})}
                                                           value={activeQuestion.opt5}
                                                       />
                                                   </Grid>
                                               )}
                                               <Grid item xs={6} style={{display:'flex', alignItems:'center', alignContent:'center'}}>
                                                   <Checkbox
                                                       color={'primary'}
                                                       checked={activeQuestion.option_other}
                                                       onChange={(e)=> {
                                                           setActiveQuestion({...activeQuestion, option_other:e.target.checked})
                                                       }}
                                                   />
                                                   <Typography>Other option</Typography>
                                               </Grid>
                                           </Grid>

                                       </Grid>
                                   )

                               }
                               <Grid item xs={12} style={{padding:8}}>
                                   <Divider />
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography>
                                       Preview:
                                   </Typography>
                               </Grid>
                               <Grid item xs={12}>
                                   <Typography>
                                       Q: {activeQuestion.question_text}
                                   </Typography>
                               </Grid>
                               {
                                   activeQuestion.question_type === 'Free Text' && (
                                       <Grid item xs={12}>
                                           <TextField
                                               fullWidth
                                               variant={'outlined'}
                                               value={'Response with free text'}
                                               multiline
                                               rows={4}
                                               disabled
                                               size={"small"}
                                           />
                                       </Grid>
                                   )
                               }
                               {
                                   activeQuestion.question_type === "Numbers Radio Options" && (
                                       <Grid item xs={12}>
                                           <FormControl component="fieldset">
                                               <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                                   <FormControlLabel
                                                       value="1"
                                                       control={<Radio color="primary" />}
                                                       label="1"
                                                       labelPlacement="bottom"
                                                   />
                                                   <FormControlLabel
                                                       value="2"
                                                       control={<Radio color="primary" />}
                                                       label="2"
                                                       labelPlacement="bottom"
                                                   />
                                                   <FormControlLabel
                                                       value="3"
                                                       control={<Radio color="primary" />}
                                                       label="3"
                                                       labelPlacement="bottom"
                                                   />
                                                   <FormControlLabel
                                                       value="4"
                                                       control={<Radio color="primary" />}
                                                       label="4"
                                                       labelPlacement="bottom"
                                                   />
                                                   <FormControlLabel
                                                       value="5"
                                                       control={<Radio color="primary" />}
                                                       label="5"
                                                       labelPlacement="bottom"
                                                   />
                                               </RadioGroup>
                                           </FormControl>
                                       </Grid>
                                   )
                               }
                               {
                                   activeQuestion.question_type === "Smiley Face Radio Options" && (
                                       <Grid item xs={12}>
                                           <ToggleButtonGroup
                                               // value={''}
                                               exclusive
                                               size="large"
                                               // onChange={handleAlignment}
                                               aria-label="text alignment"
                                           >
                                               <ToggleButton value="left" aria-label="left aligned" style={{padding:"16px 24px"}}>
                                                   <SentimentVeryDissatisfiedOutlinedIcon style={{fontSize:44}}/>
                                               </ToggleButton>
                                               <ToggleButton value="center" aria-label="centered" style={{padding:"16px 24px"}}>
                                                   <MoodBadOutlinedIcon style={{fontSize:44}}/>
                                               </ToggleButton>
                                               <ToggleButton value="right" aria-label="right aligned" style={{padding:"16px 24px"}}>
                                                   <SentimentSatisfiedOutlinedIcon style={{fontSize:44}} />
                                               </ToggleButton>
                                               <ToggleButton value="justify" aria-label="justified" style={{padding:"16px 24px"}}>
                                                   <MoodOutlinedIcon style={{fontSize:44}}/>
                                               </ToggleButton>
                                               <ToggleButton value="justify" aria-label="justified" style={{padding:"16px 24px"}}>
                                                   <SentimentVerySatisfiedOutlinedIcon style={{fontSize:44}}/>
                                               </ToggleButton>
                                           </ToggleButtonGroup>
                                       </Grid>
                                   )
                               }
                               {
                                   activeQuestion.question_type === "Text Radio Options" && (
                                       <Grid item xs={12}>
                                           <FormControl component="fieldset">
                                               <RadioGroup>
                                                   {
                                                       activeQuestion.opt1 !== null && (
                                                           <FormControlLabel value="female" control={<Radio color={'primary'}/>} label={activeQuestion.opt1} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt2 !== null && (
                                                           <FormControlLabel value="female1" control={<Radio color={'primary'}/>} label={activeQuestion.opt2} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt3 !== null && (
                                                           <FormControlLabel value="female2" control={<Radio color={'primary'}/>} label={activeQuestion.opt3} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt4 !== null && (
                                                           <FormControlLabel value="female3" control={<Radio color={'primary'}/>} label={activeQuestion.opt4} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt5 !== null && (
                                                           <FormControlLabel value="female4" control={<Radio color={'primary'}/>} label={activeQuestion.opt5} />
                                                       )
                                                   }
                                               </RadioGroup>
                                           </FormControl>
                                       </Grid>

                                   )
                               }
                               {
                                   activeQuestion.question_type === "Text Checkbox Options" && (
                                       <Grid item xs={12}>
                                           <FormControl component="fieldset">
                                               <RadioGroup>
                                                   {
                                                       activeQuestion.opt1 !== null && (
                                                           <FormControlLabel value="female" control={<Checkbox color={'primary'}/>} label={activeQuestion.opt1} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt2 !== null && (
                                                           <FormControlLabel value="female1" control={<Checkbox color={'primary'}/>} label={activeQuestion.opt2} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt3 !== null && (
                                                           <FormControlLabel value="female2" control={<Checkbox color={'primary'}/>} label={activeQuestion.opt3} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt4 !== null && (
                                                           <FormControlLabel value="female3" control={<Checkbox color={'primary'}/>} label={activeQuestion.opt4} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.opt5 !== null && (
                                                           <FormControlLabel value="female4" control={<Checkbox color={'primary'}/>} label={activeQuestion.opt5} />
                                                       )
                                                   }
                                                   {
                                                       activeQuestion.option_other === true && (
                                                           <Grid container alignItems={'center'} alignContent='center'>
                                                               <FormControlLabel value="female4" control={<Checkbox color={'primary'}/>} label={"Other:"} />
                                                               <TextField
                                                                size={'small'}
                                                                variant={'outlined'}
                                                                label={'Other option'}
                                                               />
                                                           </Grid>
                                                       )
                                                   }
                                               </RadioGroup>
                                           </FormControl>
                                       </Grid>

                                   )
                               }
                               {
                                   activeQuestion.question_type === "Yes / No" && (
                                       <Grid item xs={12}>
                                           <Grid container spacing={2}>
                                               <Grid item>
                                                   <Button variant='outlined' color={'secondary'}>
                                                       No
                                                   </Button>
                                               </Grid>
                                               <Grid item>
                                                   <Button variant='outlined' color={'primary'}>
                                                       Yes
                                                   </Button>
                                               </Grid>
                                           </Grid>
                                       </Grid>

                                   )
                               }
                               <Grid item xs={12}  style={{padding:4, display:'flex', justifyContent:'flex-end', paddingBottom:12}}>
                                   <Button
                                       disabled={activeQuestion.question_text === null}
                                       variant={'contained'}
                                       color={'primary'}
                                       onClick={()=> {
                                           setQuestions([...questions, activeQuestion]);
                                           setActiveQuestion({
                                               question_text:null,
                                               question_position:questions.length+2,
                                               question_type:"",
                                               is_active: true,
                                               is_mandatory: true,
                                               option_other:null,
                                               optBool:null,
                                               opt1: null,
                                               opt2: null,
                                               opt3: null,
                                               opt4: null,
                                               opt5:null,
                                               optFreeTextBool:null
                                           });
                                           setShowModal(false)

                                       }}
                                   >
                                       Add Question
                                   </Button>
                               </Grid>
                           </Grid>
                       </Grid>
                   </Grid>
               </DialogContent>
            </Dialog>
        </Grid>
    )
}