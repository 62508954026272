import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useHistory} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {checkPermissions} from "../../../../common/permissions/checkPermissions";
import {permissions} from "../../../../constants/permissions/permissions";

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        marginLeft: '10px',
        marginRight: '10px',
        // width:'1100px',
        zIndex: 0,
        minHeight: '75px'
    },
    title: {
        color: '#546e7a',
        paddingLeft: '7%'
    },
    keywords: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'left',
        width: '100%',
        textAlign: 'left',
        verticalAlign: 'center'
    },
    button: {
        color: '#4F88A1',
        border: '1px solid #4F88A1',
        backgroundColor: 'none',
        "&:hover": {
            backgroundColor: 'rgba(165, 196, 210, 0.14)'
        }
    },
    type: {
        // display:"flex",
        // // justifyContent:"flex-end",
        padding: 12,
        paddingRight: 0
    }
}));


export default function PatientsHeader(props) {
    const classes = useStyles();
    let history = useHistory();
    return (
        <div className={classes.root}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <span className={classes.keywords}>
                        <Grid component={'span'} item md={9} xs={9}>
                            <Typography component={'span'} variant="h6" className={classes.title}>
                               View {props.title}
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={3}>
                            <div style={{display: "flex", flexDirection: 'column', alignItems: 'flex-end'}}>
                            <span style={{marginRight: '0px'}}>

                                {(props.hideButton === false || props.hideButton === undefined) && (
                                    <Button
                                        className={classes.button}
                                        //fullWidth
                                        onClick={() => {
                                            history.push(`${props.path}`)
                                        }}
                                    >
                                        Add New {props.buttonText}
                                    </Button>)
                                }

                            </span>

                            </div>
                        </Grid>
                    </span>
                </Grid>
            </Grid>
            {(props.isTab && checkPermissions([permissions.read_patients], "DME")) && <div className={classes.type}>
                <Tabs
                    value={props.searchType}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={props.handleSearchChange}
                    aria-label="disabled tabs example"
                >
                    <Tab style={{border: '1px solid lightgrey', borderRadius: 4}} label="General Search"/>
                    <Tab style={{border: '1px solid lightgrey', borderRadius: 4, borderLeft: 'none'}}
                         label="Search By Lot"/>
                </Tabs></div>
            }
        </div>
    )
}
