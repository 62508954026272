import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../../constants/alert/alertMessages";
import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import _ from "lodash";

const makeDetailsObject = (dmeData) => {
    const obj = {
        status: dmeData.status,
        dme_name: dmeData.dme_name,
        address1: dmeData.address1,
        address2: dmeData.address2,
        city: dmeData.city,
        state: dmeData.state,
        zip: dmeData.zip,
        phone: dmeData.phone,
        second_phone: dmeData.second_phone,
    };
    return obj;
};

export const updateDmeDetails = async (dmeData, token, dmeId, enqueueSnackbar, setIsLoading, setWasSuccessful) => {
    setIsLoading(true);
    const dmeDetails = makeDetailsObject(dmeData, dmeId);
    const promise = await fetch(endpoints.dmeEditStep1 + dmeId, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(dmeDetails)
    });
    notifySnack(enqueueSnackbar, promise.status, snackbarMessages.updateDmeDetails, true);
    setIsLoading(false);
};

export const updateDmeRegions = async (dmeData, token, dmeId, enqueueSnackbar, setIsLoading, setWasSuccessful) => {
    setIsLoading(true);
    const promise = await fetch(endpoints.dmeEditStep2 + dmeId, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({regions: dmeData.regions})
    });
    notifySnack(enqueueSnackbar, promise.status, snackbarMessages.updateDmeRegions, true);
    setIsLoading(false);
};
export const updateDmeLinks = async (dmeData, token, dmeId, enqueueSnackbar, setIsLoading, setWasSuccessful) => {
    setIsLoading(true);
    const promise = await fetch(endpoints.dmeEditStep3 + dmeId, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(dmeData.it_contact)
    });
    notifySnack(enqueueSnackbar, promise.status, snackbarMessages.updateDmeLinks, true);
    if (promise.status === 200 || promise.status === 201) {
        setIsLoading(false);
        setWasSuccessful(true);
    } else {
        setIsLoading(false);
        setWasSuccessful(false);
    }
};
export const getRegionData = async (objSetter, regionId, dmeId) => {
    if (regionId && dmeId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.getRegionDetails, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: 'POST',
            body: JSON.stringify({
                regionId: regionId,
                dmeId: dmeId
            })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            let address = {
                address1:"",
                address2:"",
                city:"",
                state:"",
                zip:"",
            };
            let billingContact = {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
            }
            objSetter({
                name: response.data.regionData.name ? response.data.regionData.name : "",
                region_phone: response.data.regionData.region_phone ? response.data.regionData.region_phone : "",
                region_id: response.data.regionData.region_id,
                teams: response.data.teams ? response.data.teams : [],
                warehouses: response.data.warehouses ? response.data.warehouses : [],
                onCallPhone: response.data.regionData.on_call_phone ? response.data.regionData.on_call_phone : "",
                onCallStart: response.data.regionData.on_call_start_hour ? response.data.regionData.on_call_start_hour : "",
                onCallEnd: response.data.regionData.on_call_end_hour ? response.data.regionData.on_call_end_hour : "",
                address_region: response.data.addresses ? [response.data.addresses] : [address],
                billing_contact_region: response.data.billingContact ? [response.data.billingContact] : [billingContact]
            });
        } else {
            console.log("[Error]: Error encountered while getting region data!");
        }
    }
};
export const getRegions = async (objSetter, dmeId) => {
    if (dmeId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.getDmeRegions}${dmeId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();

        if (response.status === endpointStatus.success) {
            objSetter(response.data);
        } else {
            console.log("[Error]: Error encountered while getting regions!");
        }
    }
};
export const processDME = async (objSetter, data, operationType, dmeId, enqueueSnackbar, setIsModified, history) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const payload = {
        dme_name: data.dme_name,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        phone: data.phone,
        second_phone: data.second_phone,
        status: true
    }
    if (operationType === "create") {
        const promise = await fetch(endpoints.createDMEOrganizationStep1, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "POST",
            body: JSON.stringify(payload)
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            enqueueSnackbar("Organization created.", {variant: "success"});
            objSetter(response.data);
            history.push('/dmeprovider/edit/' + response.data);
        } else {
            console.log("[Error]: Error encountered when creating DME organization!");
        }
    } else if (operationType === "edit") {
        const promise = await fetch(endpoints.updateDMEOrganizationGeneralInformation + dmeId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "PATCH",
            body: JSON.stringify({
                ...payload,
                address_id: data.address_id,
            })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            enqueueSnackbar("Organization updated.", {variant: "success"});
            setIsModified(false);
        } else {
            console.log("[Error]: Error encountered when editing DME organization!");
        }
    }
};
export const saveRegion = async (objSetter, data, dmeId, region, enqueueSnackbar) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.createDMEOrganizationStep2, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        method: "POST",
        body: JSON.stringify({
            dme_name: data.dme_name,
            dmeId: dmeId,
            regions: [region]
        })
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(true);
        enqueueSnackbar("Added new region successfully.", {variant: "success"});
    } else {
        console.log("[Error]: Error encountered while saving region!");
    }
};
export const getOrgData = async (dmeDataSetter, dmeId, contactsSetter, contacts, setLoading) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(`${endpoints.dme_organization}${dmeId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        dmeDataSetter(response.data[0]);
        if (response.data[0].it_contact.length > 0) {
            let clone = _.cloneDeep(contacts);
            clone.it_contact = response.data[0].it_contact;
            contactsSetter(clone);
        }
    } else {
        console.log("[Error]: Error encountered while getting the organization data!");
    }
    setLoading(false);
};

export const processItContact = async (contacts, dmeId, contactsSetter, enqueueSnackbar) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const opCheck = contacts.it_contact[0].it_contact_id;
    const endpoint = opCheck ? endpoints.updateDMEOrganizationItContact + dmeId : endpoints.createDMEOrganizationItContact;
    const method = opCheck ? "PATCH" : "POST"
    const payload = method === "PATCH" ? {content: {it_contact: contacts.it_contact,dmeId:dmeId}} : {it_contact: contacts.it_contact,dmeId:dmeId}
    const promise = await fetch(endpoint, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(payload)
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        enqueueSnackbar('Edited it contact field.', {variant: "success"})
    } else {
        console.log("[Error]: Error encountered while getting the organization data!");
    }
}