import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MaterialTable from "material-table";
import {reportTypes} from "../../../../constants/types/reportTypes";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {DisplatTable, BRDisplayQuarterTable} from "./BRDisplayTable";
import {BRDisplayYearTable} from "./BRDisplayYearTable";
import {BRDisplayOutlierTable} from "./BROutlierTable";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import {BRDisplayLostItemsTable} from "./BRLostItemsTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import {primaryColor} from "../../../../assets/jss/material-kit-react";

export const BRHospiceView = ({handleInput, dmeReference, dmeOptions = [], reportType, startDate = new Date(), endDate = new Date(), tableData = {}, getReport, isLoading}) => {


    return (
        <Grid container spacing={1} style={{paddingTop: 16}}>
            <Grid item xs={12} container justify={"center"} alignContent={"center"} alignItems={"center"} spacing={1}>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Select
                        ref={dmeReference}
                        placeholder="Select Dme"
                        isLoading={dmeOptions.length === 0}
                        options={dmeOptions.map((item) => {
                            return {value: item.dme_organization_id, label: item.dme_name}
                        })}
                        isDisabled={isLoading}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                name: "selectDme",
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Select
                        placeholder="Select Report Type"
                        options={reportTypes.all.map(item => {
                            return {value: item, label: item}
                        })}
                        isDisabled={isLoading}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                name: "reportType",
                            })
                        }}
                        value={{value: reportType, label: reportType}}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            label="Start Month"
                            value={startDate}
                            disabled={isLoading}
                            helperText={reportType === reportTypes.quarterly ? "Quarter Start Month" : "Fiscal Year Start"}
                            onChange={(e) => {
                                handleInput({
                                    value: e,
                                    name: "startDate",
                                })
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {reportType === reportTypes.quarterly && <Grid item xs={12} sm={10} md={8} lg={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            label="End Month"
                            value={moment(startDate).add(2, "month")}
                            disabled
                            helperText={reportType === reportTypes.quarterly ? "Quarter End Month" : "Fiscal Year End"}
                            onChange={(e) => {
                                handleInput({
                                    value: e,
                                    name: "startDate",
                                })
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>}
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Button variant={"contained"}
                            color={"primary"}
                            disabled={isLoading}
                            onClick={(e) => getReport()}>
                        Generate Report {isLoading === true && <CircularProgress style={{color:"blue"}} size={24}/>}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={2}>
                {tableData?.patientDayComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Quarter 1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q1}/>
                    </AccordionDetails>
                </Accordion>}
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Quarter 2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q2}/>
                    </AccordionDetails>
                </Accordion>}
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Quarter 3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q3}/>
                    </AccordionDetails>
                </Accordion>}
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Quarter 4</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q4}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.patientDayComponent && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Year</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayYearTable values={tableData.patientDayComponent}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.outlierSpendComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Outlier Q1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q1}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                { tableData?.outlierSpendComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Outlier Q2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q2}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                { tableData?.outlierSpendComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Outlier Q3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q3}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.outlierSpendComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Outlier Q4</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q4}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.topTenItemsComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Top Ten Items Q1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BarChart
                                width={1000}
                                height={500}
                                data={tableData.topTenItemsComponent.q1}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="totalItems" fill="#8884d8"  onClick={(e) => {
                                    console.log(e)
                                }}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.topTenItemsComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Top Ten Items Q2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BarChart
                                width={1000}
                                height={500}
                                data={tableData.topTenItemsComponent.q2}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="totalItems" fill="#8884d8"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.topTenItemsComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Top Ten Items Q3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BarChart
                                width={1000}
                                height={500}
                                data={tableData.topTenItemsComponent.q3}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="totalItems" fill="#8884d8"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.topTenItemsComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Top Ten Items Q4</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BarChart
                                width={1000}
                                height={500}
                                data={tableData.topTenItemsComponent.q4}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="totalItems" fill="#8884d8"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.lostItemsComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Lost Items Q1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q1}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.lostItemsComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Lost Items Q2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q2}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.lostItemsComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Lost Items Q3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q3}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12} sm={10} md={8} lg={4}>
                {tableData?.lostItemsComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Lost Items Q4</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q4}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
        </Grid>
    )
}