import {endpoints} from "../../../constants/endpoints";
import jwt from "jwt-decode";

export const getDuplicateItems = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAssets, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    objSetter(response.data);
};
export const getManufacturerOptions = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getManufacturers, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    const {data} = response;
    let options = [];
    for (let value in data) {
        options.push({value: data[value].name, label: data[value].name})
    }
    objSetter(options);
};
export const getItemOptions = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAllMasterItems, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    const {data} = response;
    let options = [];
    for (let value in data) {
        data[value].name !== "" && options.push({value: data[value].name, label: data[value].name})
    }
    objSetter(options);
};

export const getManufacturerData = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getManufacturers, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    objSetter(response.data);
};
export const getWarehousesData = async (objSetter, dataSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
    const promise = await fetch(endpoints.getUserWarehouses + userData.user_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    let warehouseList = [];
    if (response.data !== undefined) {
        for (const item of response.data) {
            warehouseList.push({value: item.name, label: item.name})
        }
        objSetter(warehouseList);
        dataSetter(response.data);
    }
};
export const getItemsData = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getAllMasterItems, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    objSetter(response.data);
};