import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../adminDashboard/adminDashboard.css';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import MetricsLogo from "../../reports/images/undraw_metrics_gtu7.svg";
import FinanceLogo from "../../reports/images/undraw_personal_finance_tqcd.svg";
import FileAnalysisLogo from "../../reports/images/undraw_file_analysis_8k9b.svg";
import DashboardLogo from "../../reports/images/undraw_dashboard_nklg.svg";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export function AdminDashboard() {
    const history = useHistory();
    const isLogged = useSelector(state => state.isLogged);
    const [options, setOptions] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (event.target.name === 'users') {
            setOptions(users)
        } else if (event.target.name === 'providers') {
            setOptions(providers)
        } else if (event.target.name === 'contracts') {
            setOptions(contracts)
        } else if (event.target.name === 'audit') {
            setOptions(audit)
        } else if (event.target.name === 'master_inventory') {
            setOptions(master_inventory)
        } else if (event.target.name === 'admin') {
            setOptions(admin)
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            {(isLogged) ? <div className='reports'>
                <Grid container style={{height: 600, maxWidth: 1000, marginTop: 80}} justify='center'
                      alignContent='center'>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img
                                onClick={handleClick}
                                name={'users'}
                                src={MetricsLogo} alt="" width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                            >Users
                            </div>
                        </div>

                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img
                                name='providers'
                                id={'providers'}
                                onClick={handleClick}
                                src={FinanceLogo} alt="" width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                            >Providers
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img
                                name='contracts'
                                id={'contracts'}
                                onClick={handleClick}
                                src={FileAnalysisLogo} alt="" width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                            >Contracts
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img src={DashboardLogo}
                                 onClick={handleClick}
                                 name='audit' alt="" width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                                // className={classes.text}
                            >Audit Trail
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img src={DashboardLogo}
                                 onClick={handleClick}
                                 name='master_inventory' alt="" width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                            >Master Inventory
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={6} style={{padding: 32}}>
                        <div>
                            <img src={DashboardLogo}
                                 onClick={handleClick}
                                 alt="" name='admin' width='155' height='130' style={{
                                objectFit: 'cover',
                                border: '1px solid lightgrey',
                                padding: 12,
                                cursor: 'pointer'
                            }}/>
                            <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
                            >Admin
                            </div>
                        </div>
                    </Grid>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {options.map((option, i) => {
                            return (
                                <StyledMenuItem
                                    id={options[i].path}
                                    onClick={() => {
                                        history.push(options[i].path)
                                    }}
                                    key={i}
                                >
                                    <ListItemText style={{paddingLeft: 10}} primary={options[i].name}/>
                                </StyledMenuItem>
                            )
                        })
                        }
                    </StyledMenu>
                </Grid>
            </div> : ''}
        </React.Fragment>
    )
}


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const users = [
    {
        name: "Create Hospice User",
        path: '/hospiceuser/new'
    }, {
        name: "Create DME User",
        path: '/dmeuser/new'
    }, {
        name: "View Hospice Users",
        path: '/admin/hospiceusers/all'
    }, {
        name: "View DME Users",
        path: '/admin/dmeusers/all'
    }, {
        name: "View Portal Users",
        path: '/admin/portalusers/all'
    }, {
        name: "Permission Packages",
        path: '/admin/permissionPackages'
    },
];
const providers = [
    {
        name: "Create Hospice Organization",
        path: '/hospice/new'
    }, {
        name: "Create DME Provider",
        path: '/dmeprovider/new'
    }, {
        name: "View Hospice Organizations",
        path: '/admin/hospice/all'
    }, {
        name: "View DME Providers",
        path: '/admin/dme/all'
    },{
        name: "DME Research",
        path: '/admin/dmeresearch'
    },{
        name: "Pace Hospices",
        path: '/admin/pacehospice/all'
    },{
        name: "Hospice Type Settings",
        path: '/admin/hospice/type'
    },{
        name: "Hospice Logo",
        path: '/admin/hospice/logo'
    },
];
const contracts = [
    {
        name: "Create Contract",
        path: '/admin/contract/create'
    },
    {
        name: "View Contracts",
        path: '/admin/contract/all'
    },
];
const audit = [
    {
        name: "Audit Trail Report",
        path: '/admin/audittrail'
    },
    {
        name: "Patient Portal Audit Trail",
        path: '/admin/audittrail/portal'
    },
    {
        name: "Unauthorized Access",
        path: '/admin/audit/unauthorized'
    },
];
const master_inventory = [
    {
        name: "View Master Inventory",
        path: '/admin/masterinventory'
    },
    {
        name: "Create Custom Item Package",
        path: '/admin/hospice/custompackages/create'
    },
    {
        name: "View Custom Item Packages",
        path: '/admin/hospice/custompackages/view'
    },
    {
        name: "Add Item Resources",
        path: '/admin/item/documents'
    }
];
const admin = [
    {
        name: "Create Survey",
        path: '/admin/survey/create'
    },
    {
        name: "View All Surveys",
        path: '/admin/survey/all'
    },
    {
        name: "Send Survey",
        path: '/admin/survey/send'
    },
    {
        name: "Restricted Domains",
        path: '/admin/restricteddomains'
    },
    {
        name: "Manufacturers",
        path: '/admin/manufacturers'
    },
    {
        name: "Nurses",
        path: '/admin/nurses'
    },
    {
        name: "Technicians",
        path: '/admin/technicians'
    },
    {
        name: "Organization Features",
        path: '/admin/enablefeatures'
    },
    {
        name: "Bulk Upload",
        path: '/admin/bulk'
    },
];
