export const loginStyle = {
    container: {
        maxWidth: 1200,
        height: '100vh',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems:'center'
    },
    simpleLogoContainer: {
        display: "flex",
        justifyContent: 'center'
    },
    simpleLogoGrid: {
        minWidth: 300,
        maxWidth: 380,
        opacity: 1,
        paddingLeft: 34,
        paddingRight: 36
    },
    simpleLogo: {
        paddingBottom: 18
    },
    contentGrid: {
        minHeight: 460,
        minWidth: 300,
        maxWidth: 380,
        opacity: 1,
        paddingLeft: 34,
        paddingRight: 36
    },
    h2: {
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: '32px',
        paddingTop: 91,
        color: "#00223D",
        textAlign: 'left',
        paddingLeft: '4px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: "left"
    },
    button: {
        marginTop: 24,
        fontSize: 12,
        lineHeight: '16px',
        padding: "12px 50px",
    },
    text: {
        fontSize: 14,
        lineHeight: '14px',
        marginTop: 24,
        color: "#3B748F",
        textAlign: 'left',
        cursor: 'pointer',
    },
    poweredLogo: {
        paddingTop: 180,
        paddingBottom: 24
    },
    desktopGrid: {
        height: 460,
        width: 400,
        backgroundColor: "rgba(147, 187, 201, 0.04)",
        boxShadow: "-3px 3px 21px rgba(41, 68, 81, 0.04)",
        opacity: 1
    },
    h3: {
        fontSize: 28,
        fontWeight: "bold",
        lineHeight: '36px',
        paddingTop: 100
    },
    description: {
        fontSize: 14,
        letterSpacing: 0.39,
        lineHeight: "20px",
        width: '239px',
        textAlign: 'left',
        color: '#1A1818',
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 35
    },
    desktopForm: {
        minHeight: 460,
        minWidth: 300,
        maxWidth: 380,
        boxShadow: "3px 3px 21px rgba(41, 68, 81, 0.04)",
        opacity: 1,
        backgroundColor: 'white',
        paddingLeft: 34,
        paddingRight: 36
    },
    textfield: {
        width: '100%',
        marginTop: 22
    },
    password: {
        width: '100%',
        marginTop: 33,
    }


};