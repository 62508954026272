import React from "react";
import Grid from "@material-ui/core/Grid";

import {IconButton} from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import LabelImportantOutlinedIcon from '@material-ui/icons/LabelImportantOutlined';
import PriorityHighOutlinedIcon from '@material-ui/icons/PriorityHighOutlined';
import SendIcon from '@material-ui/icons/Send';
import Order from "../../../assets/order_icon.svg";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import {getAccordionSummaryMessage} from "../NotificationCenterHelper";
import {notificationAccordionSummaryTypes} from "../../../constants/types/messageTypes";
import Typography from "@material-ui/core/Typography";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);
export const NotificationSubcategoryAccordion = ({classes ,expanded = "", subcategoryName = "Subcategory", handleInput, accordionItems = [], onlyImportant = false}) => {

    const renderIcon = (item) => {
        switch (item.type) {
            case "RequireAttention":
                return  <div><IconButton disabled children={<PriorityHighOutlinedIcon color={"primary"}/>}/></div>;
            case "Informational":
                return  <div><IconButton disabled children={<LabelImportantOutlinedIcon color={"primary"}/>}/></div>
        }
    }

    const renderMessageContent = (message, type) => {
        let messageParts;
        switch (type){
            case notificationAccordionSummaryTypes.openOrder:
            case notificationAccordionSummaryTypes.pendingOrder:
            case notificationAccordionSummaryTypes.processingOrder:
                messageParts = message.split("/");
                return <Grid container alignContent={"center"} alignItems={"center"} justify={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>
                        {messageParts[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={"subtitle2"}>
                        {messageParts[1]}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                        {messageParts[2]}
                        </Typography>
                    </Grid>
                </Grid>
            case notificationAccordionSummaryTypes.surveyUserResponses:
                messageParts = message.split("/");
                return <Grid container alignContent={"center"} alignItems={"center"} justify={"center"}>
                    <Grid item xs={6}>
                        <Typography variant={"subtitle1"}>
                            {messageParts[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={"subtitle2"}>
                            {messageParts[1]}
                        </Typography>
                    </Grid>
                    {/*<Grid item xs={6} >*/}
                    {/*    <Typography variant={"subtitle2"} style={{fontWeight: 600}}>*/}
                    {/*        {messageParts[2]}*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                </Grid>
            case notificationAccordionSummaryTypes.patientCreation:
                messageParts = message.split("/");
                return <Grid container alignContent={"center"} alignItems={"center"} justify={"center"}>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle1"}>
                            {messageParts[0]}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant={"subtitle2"}>
                            {messageParts[1]}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography variant={"subtitle2"} style={{fontWeight: 600}}>
                            {messageParts[2]}
                        </Typography>
                    </Grid>
                </Grid>
        }
    }

    return <Grid item xs={12}>
        <Accordion square expanded={expanded === subcategoryName} onChange={(e) => handleInput({target: {value: subcategoryName}}, "expandSubcategory")}>
            <AccordionSummary>
                {getAccordionSummaryMessage(subcategoryName)}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container style={{height: "100%"}}>
                {accordionItems.map((item, index) =>{
                    if(onlyImportant === true && item.type === "RequireAttention") {
                        return <Grid item className={classes.accordionItem}>
                            <Grid container
                                  style={{borderBottom: '1px solid rgba(0, 0, 0, .125)'}}>
                                <Grid item xs={2}>
                                    {renderIcon(item)}
                                </Grid>
                                <Grid item xs={8}>
                                    {renderMessageContent(item.message, item.messageType)}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.type === "RequireAttention" && <div
                                        onClick={(e) => handleInput({
                                            target: {
                                                name: subcategoryName,
                                                value: item.id
                                            }
                                        }, "subcategoryItemAction")}
                                    >
                                        <IconButton children={<SendIcon color={"primary"}/>}/>
                                    </div>}
                                </Grid>
                            </Grid>
                        </Grid>
                    } else if(onlyImportant === false){
                        return <Grid item className={classes.accordionItem}>
                            <Grid container
                                  style={{borderBottom: index !== accordionItems.length ? '1px solid rgba(0, 0, 0, .125)' : "0px"}}>
                                <Grid item xs={2}>
                                    {renderIcon(item)}
                                </Grid>
                                <Grid item xs={8}>
                                    {renderMessageContent(item.message, item.messageType)}
                                </Grid>
                                <Grid item xs={2}>
                                    {item.type === "RequireAttention" && <div
                                        onClick={(e) => handleInput({
                                            target: {
                                                name: subcategoryName,
                                                value: item.id
                                            }
                                        }, "subcategoryItemAction")}
                                    >
                                        <IconButton children={<SendIcon color={"primary"}/>}/>
                                    </div>}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </Grid>
}
