import React, {forwardRef, useEffect} from 'react';
import MaterialTable from 'material-table';
import './table.css';
import {useHistory} from "react-router-dom";
import {ArrowDownward} from "@material-ui/icons";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

export function Table({items, columns, isLoading, pageSize, filtering, search, searchText, rowClick, customText, backGroundColor, textColor, tableRef, showSearch, title, onOrderChange, onChangePage}) {
    const [data, setData] = React.useState([]);
    let history = useHistory();
    useEffect(() => {
        setData(items)
    }, [items]);
    return (
        <Grid className={'general_table'} item style={{overflowX: 'scroll'}}>
            {rowClick ? (
                <MaterialTable
                    tableRef={tableRef}
                    component={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                        FilterRow: () => <tr/>,
                        Pagination: props => <TablePagination {...props} style={{
                            backgroundColor: "rgba(237, 242, 244, 0.73)",
                            display: "flex"
                        }}/>,
                    }}
                    icons={{
                        Filter: () => <tr/>,
                        SortArrow: forwardRef((props, ref) => <ArrowDownward style={{color: "#77A6BC"}} {...props}
                                                                             ref={ref}/>),

                }}
                isLoading={isLoading}
                options={{
                    rowStyle: { backgroundColor: '#F9FAFC'},
                    emptyRowsWhenPaging: false,
                    grouping: true,
                    toolbar: false,
                    searchText:searchText,
                    filterCellStyle: {
                        visibility:"hidden", width:'0px'
                    },
                    searchFieldStyle: {
                        visibility:"hidden", width:'0px'
                    },
                    filtering: filtering ? true : false,
                    showTitle: false,
                    showEmptyDataSourceMessage: items ? items.length === 0 : true,
                    search: search ? true : false,
                    pageSize: pageSize ? pageSize : 5,
                    headerStyle: {
                        backgroundColor: backGroundColor ? backGroundColor : '#4F88A1',
                        color: textColor ? textColor : '#FFF',
                        height: 20,
                        '&:hover': {color: 'red'}
                    },

                    }}
                    localization={{
                        body: {emptyDataSourceMessage: customText ? customText : "No records to display"},
                        grouping: {
                            placeholder:'Drag bolded headers here to group by'
                        }
                    }}
                    columns={columns}
                    data={data}
                    onRowClick={(event, rowData, togglePanel) => {
                        if (rowData.type === "Delivery") {
                            history.push(`/order/delivery/${rowData.delivery_order_id}`)
                        } else if (rowData.type === "Pickup") {
                            history.push(`/order/pickup/${rowData.pickup_order_id}`)
                        } else if (rowData.type === "Exchange") {
                            history.push(`/order/exchange/${rowData.exchange_order_id}`)
                        }
                    }}
                />) : (
                <MaterialTable
                    tableRef={tableRef}
                    component={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                        FilterRow: () => <tr/>,
                        Pagination: props => <TablePagination {...props} style={{
                            backgroundColor: "rgba(237, 242, 244, 0.73)",
                            display: "flex"
                        }}/>,
                    }}
                    icons={{
                        Filter: () => <tr/>,
                        SortArrow: forwardRef((props, ref) => <ArrowDownward style={{color: "#77A6BC"}} {...props}
                                                                             ref={ref}/>),
                    }}
                    isLoading={isLoading}
                    title={title ? title : ""}
                    options={{
                        rowStyle: {backgroundColor: '#F9FAFC'},
                        searchText: searchText,
                        emptyRowsWhenPaging: false,
                        toolbar: showSearch ? true : false,
                        filterCellStyle: {
                            visibility: showSearch ? "": "hidden", width: showSearch ? '200px' : '0px'
                        },
                        searchFieldStyle: {
                            visibility: showSearch ? "": "hidden", width: showSearch ? '200px' : '0px'
                        },
                        showTitle: title ? true : false,
                        filtering: true,
                        showEmptyDataSourceMessage: items ? items.length === 0 : true,
                        pageSize: pageSize ? pageSize : 5,
                        headerStyle: {
                            backgroundColor: backGroundColor ? backGroundColor : '#4F88A1',
                            color: textColor ? textColor : '#FFF',
                            height: 20,
                            '&:hover': {color: '#FFF'}
                        },
                    }}
                    columns={columns}
                    data={data}
                    onOrderChange={onOrderChange}
                    onChangePage={onChangePage}
                />
            )}
        </Grid>
    );
}