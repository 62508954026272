import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {getUserLastLocations, getUsersOnRoute} from "./auditCheck";
import _ from "lodash"
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SnackBar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {checkTokenAvailability} from "../utils/utils";
import {logout} from "../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
//all users at mount time

export const UserCounter = ({endpoint, timeOfRender, ignoreEndpoints}) => {
    // timeOfRender should be moment().substract(1, "hour");
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(true);
    const history = useHistory();
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        handleUserCount();
        const interval = setInterval(handleUserCount, 5000);
        return () => clearInterval(interval);
    }, []);

    const handleUserCount = async () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        let data = await getUsersOnRoute(endpoint, timeOfRender);
        let activeUserIds = [];
        let activeUsers = [];
        let currentUsers = [];
        if(data?.length > 0) {
            for (const user of data) {
                activeUserIds.push(user.user_id);
                activeUsers.push(user);
            }
            let usersActivity = await getUserLastLocations(activeUserIds, timeOfRender);
            for (const activity of usersActivity) {
                let allGetsPerformed = _.filter(activity, {"method": "GET"});
                let thisPage = _.findLastIndex(allGetsPerformed, {"endpoint": endpoint});
                for (let index = thisPage; index < allGetsPerformed.length; index++) {


                    if (!ignoreEndpoints.includes(allGetsPerformed[index].endpoint) && !currentUsers.includes(activeUsers[usersActivity.indexOf(activity)])) {
                        currentUsers.push(activeUsers[usersActivity.indexOf(activity)]);
                    }
                }
            }
            setUsers(activeUsers);
        }
    };

    return (
        <Grid container justify={"center"} alignItems={"center"}>
            <div>
                <Tooltip title={
                    <Grid container direction={"column"} justify={"center"} alignItems={"center"}>
                        <Grid item>
                            <Typography variant={"subtitle2"}>
                                Users on this page:
                            </Typography>
                        </Grid>
                        <Grid item>
                            {users.map((item, index) => {
                                return (
                                    <Grid item key={index}>
                                        {item.username}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>}
                >
                    {/*<SnackBar
                        anchorOrigin={{vertical: "top", horizontal: "left"}}
                        open={open}
                        // onClose={handleClose}
                        // TransitionComponent={<Slide {...props} direction={"right"}/>}
                        // message={`Number of users on page: ${users.length}`}
                    >
                        <Alert severity="info">
                            Number of users on page: {users.length}
                        </Alert>
                    </SnackBar>*/}
                    <Typography variant={"subtitle1"}>
                        Number of users on page: {users.length}
                    </Typography>
                </Tooltip>
            </div>
        </Grid>
    )
};