import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import AssetSelect from "../../../pages/orders/deliveryOrder/components/assetSelect";
import _ from "lodash";
import MailOutlineRoundedIcon from "@material-ui/icons/MailOutlineRounded";
import MailIcon from "@material-ui/icons/Mail";
import {NotificationTableStyles} from "./NotificationTable.style";

export const NotificationTable = ({items, selectedItems, handleInput}) => {

    const classes = useStyles();
    const getRole = (id) => {
        switch (id) {
            case "11":
                return "Patient";
            case "12":
                return "Primary Caregiver";
            case "13":
                return "Secondary Caregiver";
            case "14":
                return "Tertiary Caregiver";
        }
    }
    const getNotificationsOptions = (option) => {
        switch (option) {
            case "all":
                return "Email And Phone";
            case "phone":
                return "Phone";
            case "email":
                return "Email";
            default:
                return "Opted Out"
        }
    }
    return (
        <Grid container className={classes.wrapper}>
            <Grid container className={classes.container} justify={"space-between"}>
                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                    <Grid xs={8} item className={classes.row}>Full Name</Grid>
                    <Grid xs={6} item className={classes.row}>Role</Grid>
                    <Grid xs={6} item className={classes.row}>Notifications</Grid>
                    <Grid xs={1} item className={classes.row}>Notify</Grid>
                </Grid>
            </Grid>
            {items?.map((item, index) => {
                let isChecked = _.findIndex(selectedItems, {'user_id': item.user_id}) !== -1;
                return (
                    <Grid key={index} container justify={"space-between"} className={classes.rows}>
                        <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                            <Grid xs={8} item className={classes.row}>
                                {item.first_name + " " + item.last_name}
                            </Grid>
                            <Grid xs={6} item className={classes.row}>
                                {getRole(item.role_id)}
                            </Grid>
                            <Grid xs={6} item className={classes.row}>
                                {getNotificationsOptions(item.notifications)}
                            </Grid>
                            <Grid xs={1} item className={classes.checkbox}>
                                {(item.notifications !== "none" && item.notifications !== null) && <Checkbox icon={<MailOutlineRoundedIcon/>}
                                           checkedIcon={<MailIcon/>} name="notify"
                                           checked={isChecked}
                                           color="primary" onChange={(event) => {
                                    handleInput(item, event)
                                }}
                                />}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => NotificationTableStyles(theme));