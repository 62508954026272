export const titles = {
    item: "Item",
    asset: "Asset #",
    deliveryDate: "Delivery Date",
    pickup: "Pick Up",
    itemName: "Item Name",
    remove: "Remove",
    qty: "Qty",
    pickupDate: "Pickup date",
    status: "Status",
    lot: "Lot #",
    action: "Action",
    itemStatus: "Item Status",
    patients: "Patients",
    provider: "DME Provider"
};