import React, { useEffect, useState } from 'react'
import Hidden from "@material-ui/core/Hidden";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { checkPermissions } from "../permissions/checkPermissions";
import { permissions } from "../../constants/permissions/permissions";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SimpleLogo from "../../assets/logo_caption_dove_outline@2x.png";
import BigLogo from "../../assets/logo_caption@2x.png";
import Home from "../../assets/home.svg";
import HomeSelected from "../../assets/home_selected.svg";
import Patient from "../../assets/patient_icon.svg";
import PatientSelected from "../../assets/patient_selected.svg";
import Order from "../../assets/order_icon.svg";
import OrderSelected from "../../assets/order_selected.svg";
import Reports from "../../assets/reports_icon.svg";
import ReportsSelected from "../../assets/reports_selected.svg";
import Inventory from "../../assets/inventory.svg";
import InventorySelected from "../../assets/inventory_selected.svg";
import Settings from "../../assets/settings.svg";
import SettingsSelected from "../../assets/settings_selected.svg";
import Support from "../../assets/help.svg";
import SupportSelected from "../../assets/help_selected.svg";
import { routes } from "../../constants/routes/routes";
import { sidenavStyle } from "./sidenav.style";
import FlashOnIcon from '@material-ui/icons/FlashOn';
import Tooltip from '@material-ui/core/Tooltip';

const TransparentDarkBlueTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgb(0, 34, 61, 0.8)',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
    },
}))(Tooltip);

const drawerWidth = 220;

const useStyles = makeStyles((theme) => sidenavStyle(theme, drawerWidth));

export const MiniDrawer = props => {
    let location = useLocation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.open);
    const history = useHistory();
    const [currentPath, setCurrentPath] = useState('/');

    const handleDrawerClose = () => {
        setOpen(!open);
        props.setOpen(!open);
    };
    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [location.pathname]);
    
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                    paperAnchorLeft: classes.noBorder
                }}
            >
                {['/dashboard', '/'].includes(window.location.pathname) ||
                    (<div className={classes.toolbar}
                        onClick={() => history.push(routes.root)}
                    >
                        <img
                            src={open ? BigLogo : SimpleLogo}
                            alt="logo"
                            width={open ? '148' : '49'}
                            className={classes.pointer}
                        />
                    </div>)}
                <Divider
                    style={['/dashboard', '/'].includes(window.location.pathname) ? { marginTop: "64px" } : { marginTop: "0" }}
                />
                <Hidden smDown>
                    <List className={classes.list}>
                        <TransparentDarkBlueTooltip title="Dashboard" placement="right" disableHoverListener={open}>
                            <ListItem button className={classes.paddingL30}
                                onClick={() => history.push(routes.dashboard)}
                            >
                                <ListItemIcon classes={{ root: classes.icon }}>
                                    <img
                                        src={(currentPath.includes('/dashboard') || currentPath === "/") ? HomeSelected : Home}
                                        alt="logo"
                                        width='20'
                                        className={classes.pointer}
                                    />
                                </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Dashboard"}
                                        style={(currentPath.includes('/dashboard') || currentPath === "/") ? { color: "#00223d" } : { color: "#4F86A0" }}
                                    />
                                )}
                            </ListItem>
                        </TransparentDarkBlueTooltip>

                        <div className={classes.height19} />
                        {
                            checkPermissions([permissions.read_patients]) && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Patients" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.view_patients)}
                                        >
                                            <ListItemIcon classes={{ root: classes.icon }}>
                                                <img
                                                    id={"patientsIcon"}
                                                    src={currentPath.includes('/patients') ? PatientSelected : Patient}
                                                    alt="logo"
                                                    width='20'
                                                    className={classes.pointer}
                                                />
                                            </ListItemIcon>
                                            {open && (
                                                <ListItemText primary={"Patients"}
                                                    style={currentPath.includes('/patients') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )}
                        {
                            checkPermissions([permissions.read_orders]) && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Orders" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.view_orders)}
                                        >
                                            <ListItemIcon classes={{ root: classes.icon }}> <img
                                                id={"ordersIcon"}
                                                src={currentPath.includes('/order') ? OrderSelected : Order}
                                                alt="logo"
                                                width='20'
                                                style={{ cursor: 'pointer' }}
                                            /> </ListItemIcon>
                                            {open && (
                                                <ListItemText primary={"Orders"}
                                                    style={currentPath.includes('/order') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )}
                        {
                            checkPermissions([permissions.billing_report, permissions.active_rental_report]) && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Reports" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.reports)}>
                                            <ListItemIcon classes={{ root: classes.icon }}

                                            > <img
                                                    id={"reportsIcon"}
                                                    src={currentPath.includes('/reports') ? ReportsSelected : Reports}
                                                    alt="logo"
                                                    width='20'
                                                    style={{ cursor: 'pointer' }}
                                                /> </ListItemIcon>
                                            {open && (
                                                <ListItemText primary={"Reports"}
                                                    style={currentPath.includes('/reports') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )
                        }
                        {
                            checkPermissions([permissions.read_inventory]) && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Inventory" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.inventory)}
                                        >
                                            <ListItemIcon classes={{ root: classes.icon }}> <img
                                                id={"inventoryIcon"}
                                                src={currentPath.includes('/inventory') ? InventorySelected : Inventory}
                                                alt="logo"
                                                width='20'
                                                style={{ cursor: 'pointer' }}
                                            /> </ListItemIcon>
                                            {open && (
                                                <ListItemText primary={"Inventory"}
                                                    style={currentPath.includes('/inventory') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )
                        }
                        {
                            checkPermissions([permissions.read_admin], "DME") && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Engagements" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.engagements)}

                                        >
                                            <ListItemIcon classes={{ root: classes.icon }}> <FlashOnIcon color={'primary'} style={{
                                                color: currentPath.includes('/engagements') ? "#00223d" : "#4F86A0"
                                            }} /> </ListItemIcon>
                                            {open && (
                                                <ListItemText primary={"Engagements"}
                                                    style={currentPath.includes('/engagements') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )
                        }
                        {
                            checkPermissions([permissions.read_admin], "DME") && (
                                <React.Fragment>
                                    <TransparentDarkBlueTooltip title="Admin" placement="right" disableHoverListener={open}>
                                        <ListItem button className={classes.paddingL30}
                                            onClick={() => history.push(routes.admin)}

                                        >
                                            <ListItemIcon classes={{ root: classes.icon }}> <img
                                                id={"adminIcon"}
                                                src={currentPath.includes('/admin') ? SettingsSelected : Settings}
                                                alt="logo"
                                                width='20'
                                                id={'admin-navigation'}
                                                style={{ cursor: 'pointer' }}
                                            /> </ListItemIcon>

                                            {open && (
                                                <ListItemText primary={"Admin"}
                                                    style={currentPath.includes('/admin') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                                />
                                            )}
                                        </ListItem>
                                    </TransparentDarkBlueTooltip>
                                    <div className={classes.height19} />
                                </React.Fragment>
                            )
                        }
                        <React.Fragment>
                            <TransparentDarkBlueTooltip title="Support" placement="right" disableHoverListener={open}>
                                <ListItem button className={classes.paddingL30}
                                    onClick={() => history.push(routes.support)}
                                >
                                    <ListItemIcon classes={{ root: classes.icon }}> <img
                                        id={"supportIcon"}
                                        src={currentPath.includes('/support') ? SupportSelected : Support}
                                        alt="logo"
                                        width='20'
                                        style={{ cursor: 'pointer' }}
                                    /> </ListItemIcon>
                                    {open && (
                                        <ListItemText primary={"Support"}
                                            style={currentPath.includes('/support') ? { color: "#00223d" } : { color: "#4F86A0" }}
                                        />
                                    )}
                                </ListItem>
                            </TransparentDarkBlueTooltip>
                            <div className={classes.height19} />
                        </React.Fragment>
                    </List>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        borderRight: '1px solid rgb(229,232,241)'
                    }}>
                        <div />
                        <div>
                            <Divider />
                            <div className={classes.toolbar} onClick={handleDrawerClose} style={{ cursor: 'pointer' }}>
                                <IconButton>
                                    {!open ? <ArrowForwardRoundedIcon className={classes.arrow} /> :
                                        <ArrowBackRoundedIcon className={classes.arrow} />}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Hidden>
            </Drawer>
            <main style={{
                flexGrow: 1,
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {props.children}
                </div>

            </main>
        </div>
    );
};