import React from "react";
import {OrderHeader} from "./orderHeader/orderHeader";
import {SelectItems} from "./selectItems/selectItems";

export const OrderItems = ({setActiveStep, total, user, items, handleInput, order, setOrder, contractedProvidersList, categoriesList, addMore, isPace}) => {
    return (
        <div className={'order_container'}>
            <OrderHeader
                setActiveStep={setActiveStep}
                total={total} user={user}
                handleInput={handleInput}
                order={order}
                setOrder={setOrder}
                contractedProvidersList={contractedProvidersList}
                categoriesList={categoriesList}
                addMore={addMore}
            />
            <SelectItems
                isPace={isPace}
                user={user}
                items={items}
                order={order}
                setOrder={setOrder}
            />
        </div>
    )
};
