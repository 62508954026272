import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {endpoints} from "../../constants/endpoints";
import _ from "lodash"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {NotificationTable} from "./components/NotificationsTable";
import jwt from "jwt-decode";
import AddIcon from "@material-ui/icons/Add";
import {MailOutline} from "@material-ui/icons";

export const DispatchNotification = ({patientId, order, enqueueSnackbar, addHistory, orderId, orderType, newOrderStatus, orderIdentifier}) => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [enableDispatch, setEnableDispatch] = useState(false);
    const token = JSON.parse(localStorage.getItem('key'));
    const user = jwt(token.accessToken);
    const [notifyUsers, setNotifyUsers] = useState([]);

    const handleInput = (data, e) => {
        let array = _.cloneDeep(notifyUsers);
        let exists = _.findIndex(notifyUsers, {"user_id": data.user_id});
        if(exists > -1){
            array.splice(exists, 1);
        } else {
            array.push(data)
        }
        setNotifyUsers(array);

    }

    const notify = async () => {
        const payload = {
            orderId: orderIdentifier + orderId,
            orderType: orderType,
            users: notifyUsers,
        }
        const promise = await fetch(endpoints.dispatchNotification, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(payload)
        });
        let response = await promise.json();
        if (response.status === "success") {
            let description = "Dispatched notification to:";
            for(const item of notifyUsers){
                description += item.first_name + " " + item.last_name + ", ";
            }
            description += `by ${user.payload.first_name} ${user.payload.last_name}`
            await addHistory(orderId, description);
            enqueueSnackbar(description, {variant: "success"});
            setOpen(false);
        } else {
            enqueueSnackbar("Could not notify user", {variant: "success"});
            setOpen(false);
        }
    }

    const getData = async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.getAllPortalAccountsForPatientId}${patientId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const res = await promise.json();
        if (res.status === "success") {
            setUsers(res.data);

        }
        for(const item of res.data){
            if(item.notifications !== "none" && item.notifications !== null){
                setEnableDispatch(true);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [])
    return (
        <div>
            <Button
                disabled={(order.status !== "Processing" && newOrderStatus !== "Processing") || enableDispatch === false}
                variant={"outlined"}
                color={"primary"}
                onClick={() => {
                    setOpen(true);
                    setNotifyUsers([]);
                }}
                startIcon={<MailOutline style={{fontSize:28}}/>}
            >
                DISPATCH NOTIFICATION
            </Button>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                onClose={() => setOpen(false)}
            >
                <DialogTitle id="alert-dialog-slide-title">{"Dispatch Notification"}</DialogTitle>
                <DialogContent>
                    <NotificationTable
                        items={users}
                        selectedItems={notifyUsers}
                        handleInput={handleInput}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary" variant={"outlined"}>
                        Cancel
                    </Button>
                    <Button onClick={() => notify()} color="primary" variant={"outlined"} disabled={notifyUsers.length === 0}>
                        Notify Selected
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}