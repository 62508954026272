import React, {useEffect, useState} from "react";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../constants/names/titles";
import MaterialTable from "material-table";
import {getAllDmes, getAllManufacturers, getAllTechnicians} from "../helpers/dataGetter";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Dialog from "@material-ui/core/Dialog";
import {Label, TextFields} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Select, {components} from "react-select";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import jwt from "jwt-decode";

export const EditTechnicians = (props) => {

    const [technicians, setTechnicians] = useState([]);
    const [technicianOptions,setTechnicianOptions] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [accountData, setAccountData] = useState({
        technician_id: null,
        first_name: "",
        last_name: "",
        dmes: [],
        isactive: true,
    });
    const token = JSON.parse(localStorage.getItem('key'));
    const [dmeList, setDmeList] = useState();
    const [dmeLookup, setDmeLookup] = useState([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllTechnicians(setTechnicians);
        getAllDmes(setDmeLookup);
    }, []);
    useEffect(() => {
        let obj = [];
        for (const item of dmeLookup) {
            obj.push({label: item.dme_name, value: item.dme_organization_id})
        }
        setDmeList(obj)
        obj=[];
        for (const item of technicians) {
            obj.push({label: item.first_name + " " + item.last_name, value: item.technician_id})
        }
        setTechnicianOptions(obj);
    }, [dmeLookup, technicians]);

    const handleTechnicianOperation = () => {
        if(accountData.technician_id === null){
            (async () => {
                setIsLoading(true);
                const promise = await fetch(endpoints.createEditTechnicians,{
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        ...accountData,
                        isActive: accountData.isactive,
                    })
                })
                const response = await promise.json();
                if(response.status === "success"){
                    await getAllTechnicians(setTechnicians);
                    enqueueSnackbar("Technician created!", {variant:"success"});
                    setAccountData({
                        technician_id: null,
                        first_name: "",
                        last_name: "",
                        dmes: [],
                        isactive: true,
                    });
                    setIsLoading(false);
                } else  {
                    setIsLoading(false);
                    enqueueSnackbar("Something happened!", {variant:"warning"});
                }
            })()
        } else {
            // let undeletedDmes
            (async () => {
                setIsLoading(true);
                // let activeDmes = [];
                // for(const dme of accountData.dmes){
                //     const technician = technicians[_.findIndex(technicians,{"technician_id": accountData.technician_id})];
                //     for(const activeDme of technician.dmes)
                // }
                // console.log(accountData);
                const promise = await fetch(endpoints.createEditTechnicians,{
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        ...accountData,
                        isActive: accountData.isactive,
                    })
                })
                const response = await promise.json();
                if(response.status === "success"){
                    await getAllTechnicians(setTechnicians);
                    setAccountData({
                        technician_id: null,
                        first_name: "",
                        last_name: "",
                        dmes: [],
                        isactive: true,
                    });
                    setIsLoading(false);
                    enqueueSnackbar("Technician updated!", {variant:"success"});
                } else  {
                    setIsLoading(false);
                    enqueueSnackbar("Something happened!", {variant:"warning"});
                }
            })()

        }
    }

    const handleInput = (e) => {
        switch (e.name){
            case "technician_id":
                if(e.value){
                    setAccountData({...technicians[_.findIndex(technicians, {"technician_id": e.value})],
                        dmes: [...technicians[_.findIndex(technicians, {"technician_id": e.value})].dmes.map(item => {
                            return item.deleted === false && {label: item.dme_name, value: Number(item.dme_organization_id)}
                        })]
                    });
                }
                else {
                    setAccountData({
                        technician_id: null,
                        first_name: "",
                        last_name: "",
                        dmes: [],
                        isactive: true,
                    });
                }
                break;
            case "first_name":
                setAccountData({...accountData, [e.name]: e.value})
                break;
            case "last_name":
                setAccountData({...accountData, [e.name]: e.value})
                break;
            case "dmes":
                setAccountData({...accountData, [e.name]: e.dmes})
                break;
            case "isactive":
                setAccountData({...accountData, [e.name]: !accountData.isactive})
                break;
        }

    }

    const columns = [
        {title: 'ID', field: 'technician_id', width: 20, editable:'never'},
        {title: "First Name", field: 'first_name', width: 150},
        {title: "Last Name", field: 'last_name', width: 150},
        {title: "Organization", field: 'dmes', readonly: true, width: 300, editable: "never",
            render: rowData => <Grid item>
                <span>{ rowData.dmes && (rowData.dmes.length > 0 && rowData.dmes[0].dme_name + rowData.dmes.map((item, index) => {if(index > 0 && item.deleted === false) return (" " + item.dme_name) }))}</span>
            </Grid>,
        },
        // {title: "User Account", field: 'account', width: 200, align: "center", sorting: false, editable: "never", headerStyle: {
        //         textAlign: 'center'
        //     },
        //     render: rowData => <Grid item>
        //         {0 === 0 ? <Typography variant={"subtitle2"}>
        //             Account Created
        //         </Typography> : <Button variant={"contained"} color={"primary"} onClick={() => {
        //             setAccountData(rowData);
        //             setOpen(true);
        //         }}>
        //             Create Account
        //         </Button>}
        //     </Grid>
        // },
        {title: "Status", field: 'disabled', width: 100, align: "center",sorting: false, editable: "never", headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <Grid item>
                <span style={{color: rowData.isactive === true ? "green" : "red"}}>{rowData.isactive === true ? "Technician is Active" : "Technician is Inactive"}</span>
            </Grid>
        }
    ];

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}} spacing={8}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.technicians}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title={componentTitles.technicians}
                        columns={columns}
                        data={technicians}
                        isLoading={isLoading}
                        options={{
                            pageSize: 10,
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{width: "90%"}}>
                    <Grid container spacing={4} alignItems={"flex-start"} justify={"flex-start"}>
                        <Grid item xs={3} style={{textAlign:"left"}}>
                            <InputLabel>Technician Id</InputLabel>
                            <Select
                                label={"Technician"}
                                name={"technician_id"}
                                options={technicianOptions}
                                isClearable
                                value={technicianOptions[_.findIndex(technicianOptions,{value:accountData.technician_id})]}
                                onChange={(e) => handleInput({...e, name:"technician_id"})}
                            />
                        </Grid>
                        <Grid item xs={2} >
                            <TextField
                                style={{paddingTop: 12}}
                                name={"first_name"}
                                label={"First Name"}
                                fullWidth
                                id={"first_name"}
                                variant={"outlined"}
                                onChange={(e) => handleInput(e.target)}
                                value={accountData.first_name}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                style={{paddingTop: 12}}
                                fullWidth
                                label={"Last Name"}
                                variant={"outlined"}
                                name={"last_name"}
                                id={"last_name"}
                                onChange={(e) => handleInput(e.target)}
                                value={accountData.last_name}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={3} style={{textAlign:"left"}}>
                            <InputLabel>Organizations</InputLabel>
                            <Select
                                isMulti
                                isSearchable
                                name={"dmes"}
                                isClearable={false}
                                options={dmeList}
                                value={accountData.dmes.map(item => {
                                    return dmeList[_.findIndex(dmeList, {value: item.value})]
                                })}

                                onChange={(e) => handleInput({dmes:e, name: "dmes"})}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant={"contained"} color={"primary"} onClick={handleTechnicianOperation}
                                    disabled={accountData.technician_id === null &&
                                    accountData.first_name === "" &&
                                    accountData.last_name === ""
                                    }
                            >
                                {accountData.technician_id === null ? "Create" : "Update"}
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel style={{marginRight: 0}} control={
                                        <Switch checked={accountData.isactive}
                                                onChange={(e) => handleInput(e.target)}
                                                name="isactive"
                                                color="primary"
                                                disabled={accountData.technician_id === null &&
                                                accountData.first_name === "" &&
                                                accountData.last_name === ""
                                                }
                                        />}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormHelperText>
                                {getMessage(messageTypes.disabledTechnician, accountData.isactive)}
                            </FormHelperText>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                      Do you want to continue ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                    <Grid container justify={"flex-start"} alignItems={"flex-start"} direction={"column"} spacing={1}>
                        <Grid item>
                            <Typography variant={"h6"}>
                                An account will be created for the following user:
                            </Typography>
                        </Grid>
                        <Grid item style={{paddingLeft: 8}}>
                            <TextField
                                label={"Name"}
                                variant={"outlined"}
                                value={accountData.name}
                                disabled={true}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item style={{paddingLeft: 8}}>
                            <TextField
                                label={"ID"}
                                variant={"outlined"}
                                value={accountData.manufacturer_id}
                                disabled={true}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item style={{paddingLeft: 8}}>
                            <TextField
                                label={"Organization"}
                                variant={"outlined"}
                                value={accountData.organization}
                                disabled={true}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item style={{paddingLeft: 8}}>
                            <TextField
                                label={"Email"}
                                variant={"outlined"}
                                value={accountData.email}
                                disabled={true}
                                size={"small"}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}
                            variant="contained"
                            color="secondary"
                            autoFocus
                            style={{textTransform: 'none'}}>
                        {buttonText.negative}
                    </Button>
                    <Button onClick={handleTechnicianOperation}
                            variant="contained"
                            color="primary"
                            style={{textTransform: 'none'}}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};