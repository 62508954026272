import React from "react";

export const BRDisplayLostItemsTable = ({values}) => {
    return (
        <table style={{textAlign: "left"}}>
            <thead>
                <tr>
                    <th>Pickup Order Id</th>
                    <th>Patient Name</th>
                    <th>Completed Date</th>
                    <th>Item Name</th>
                    <th>Location</th>
                    <th>Location Description</th>
                </tr>
            </thead>
            <tbody>
            {values.map(item => {
            return (
                <tr>
                    <td>{item.pickup_order_id}</td>
                    <td>{item.first_name + "" + item.last_name}</td>
                    <td>{item.pickup_completed_date ? item.pickup_completed_date : "- - -"}</td>
                    <td>{item.item_name}</td>
                    <td>{item.care_setting ? item.care_setting : "- - -"}</td>
                    <td>{item.facility_name ? item.facility_name : "- - -"}</td>
                </tr>
            )})}
            </tbody>
        </table>
    )
}