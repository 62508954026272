export const validationTypes = {
    onlyNumbers: 'number',
    floatingNumbers: 'floatingNumbers',
    onlyLetters: 'letter',
    onlyLettersAndSpaces: 'letterSpaces',
    onlyLettersAndSpacesNames: 'letterSpacesNames',
    onlyLettersAndAplha: 'letterAlpha',
    lettersAndNumbers: "lettersAndNumbers",
};

export const emailValidation = {
    success: "successEmail",
    invalidEmail: "invalidEmail",
    invalidDomain: "invalidDomain",
    emailAlreadyExists: "emailAlreadyExists",
    empty: "empty",
};

export const usernameValidation = {
    success: "successUsername",
    possibleEmail: "possibleEmail",
    usernameAlreadyExists: "usernameAlreadyExists",
    empty: "empty",

};

