import {endpoints} from "../../../constants/endpoints";
import _ from "lodash"
import {userTypes} from "../../../constants/types/userTypes";
import jwt from 'jwt-decode';

export const setStates = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.states, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let states = [];
    for (let value in data) {
        data[value].name !== "" && states.push({value: data[value].code, label: data[value].name})
    }
    objSetter(states);
};
export const setRoles = async (objSetter, originalEndpoint, type) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let endpoint = originalEndpoint;
    if (type === "Hospice" || type === "Portal"){
        endpoint = endpoint + type;
    }
    let promise = await fetch(endpoint, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let roles = [];
    for (let value in data) {
        data[value].name !== "" && roles.push({value: data[value].name, label: data[value].name})
    }
    objSetter(roles);
};
export const setDmeUserOrganizations = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.dme_organizations, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let dmeOrg = [];
    for (let value in data) {
        data[value].name !== "" && dmeOrg.push({value: data[value].dme_name, label: data[value].dme_name})
    }
    objSetter(dmeOrg);
};
export const setHospiceUserOrganizations = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.hospice_organizations, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let hospiceOrg = [];
    for (let value in data) {
        data[value].name !== "" && hospiceOrg.push({value: data[value].hospice_name, label: data[value].hospice_name})
    }
    objSetter(hospiceOrg);
};
export const searchHospiceUsers = async (filters, objSetter, loadSetter, type = userTypes.hospice) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let rolePromise = await fetch(endpoints.hospice_roles, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let roleResponse = await rolePromise.json();
    const roleData = roleResponse.data;
    let roleIndex = _.findIndex(roleData, ["name", filters.role]);
    const obj = {
        filtersValue: {
            status: filters.isActiveUser,
            role_id: roleIndex !== -1 ? roleData[roleIndex].role_id : filters.role,
            input: filters.input,
            criteria: filters.criteria,
            organization_name: filters.organization,
        }
    };
    loadSetter(true);
    let promise = await fetch(endpoints.getHospiceUsers + type, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token.accessToken
        },
        // body: JSON.stringify(obj)
    });
    let response = await promise.json();
    loadSetter(false);
    const {data} = response;
    if (response.status === "success" && response.data.length > 0) {
        objSetter(data);
    } else {
        objSetter([]);
        loadSetter(false);
    }
};
export const searchDmeUsers = async (filters, objSetter, loadSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let rolePromise = await fetch(endpoints.dme_roles, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let roleResponse = await rolePromise.json();
    const roleData = roleResponse.data;
    let roleIndex = _.findIndex(roleData, ["name", filters.role]);

    const obj = {
        filtersValue: {
            status: filters.isActiveUser,
            role_id: roleIndex !== -1 ? roleData[roleIndex].role_id : filters.role,
            input: filters.input,
            criteria: filters.criteria,
            organization_name: filters.organization,
        }
    };
    loadSetter(true);
    let promise = await fetch(endpoints.searchDmeUsers, {
        method: "get",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token.accessToken
        },
        // body: JSON.stringify(obj)
    });
    let response = await promise.json();
    loadSetter(false);
    const {data} = response;
    if (response.status === "success" && response.data.length > 0) {


        response.data.map(user => {
            let search = [];
            search.push(user.first_name);
            search.push(user.last_name);
            search.push(user.user_id);
            search.push(user.email);
            user.search = search;
            if ( user.status === true){
                user.stringStatus = 'true'
            } else if( user.status === false) {
                user.stringStatus = 'false'
            }
        });
        let newArr = data.filter(user => user.dme_organization_id);
        objSetter(response.data);
    } else {
        objSetter([])
    }
};
export const searchDme = async (filters, objSetter, loadSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const obj = {
        filtersValue: {
            status: filters.isActiveDme,
            state: filters.state,
        }
    };
    loadSetter(true);
    let promise = await fetch(endpoints.searchDme, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(obj)
    });
    let response = await promise.json();
    loadSetter(false);
    const {data} = response;
    if (response.status === "success" && response.data.length > 0) {
        objSetter(data);
    } else {
        objSetter([])
    }
};
export const searchHospice = async (filters, objSetter, loadSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const obj = {
        filtersValue: {
            status: filters.isActiveHospice,
            state: filters.state,
            // profit: filters.isProfitHospice,
        }
    };
    loadSetter(true);
    let promise = await fetch(endpoints.searchHospice, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(obj)
    });
    let response = await promise.json();
    loadSetter(false);
    const {data} = response;
    if (response.status === "success" && response.data.length > 0) {
        objSetter(data);
    } else {
        objSetter([])
    }
};
export const searchTechnicians = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    let decoded = jwt(token.accessToken).payload;
    let promise = await fetch(`${endpoints.getAllTechnicians}${decoded.dme_organization_id}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    if (response.status === "success" && response.data.length > 0) {
        let final = [];
        data.map(name => final.push({label: `${name.first_name} ${name.last_name}`, value: `${name.first_name} ${name.last_name}`}));
        final.unshift({label: `All Technicians`, value: `All Technicians`});
        objSetter(final);
    } else {
        objSetter([])
    }
};