import React, {useEffect, useState} from "react";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../constants/names/titles";
import MaterialTable from "material-table";
import {getAllHospices, getAllNurses} from "../helpers/dataGetter";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";

export const EditNurses = (props) => {

    const [nurses, setNurses] = useState([]);
    const [hospiceList, setHospiceList] = useState([]);
    const [hospiceLookup, setHospiceLookup] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const columns = [
        {title: 'ID', field: 'nurse_id', editable:'never', width: 20},
        {title: tableColumnTitles.nurse, field: 'name', width: 400},
        {title: 'Hospice', field: 'hospice_organization_id', width: 400, lookup: hospiceLookup},
    ];
    const {enqueueSnackbar} = useSnackbar();


    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllNurses(setNurses);
        getAllHospices(setHospiceList);
    }, []);

    useEffect(() => {
        let obj = {};
        for (const item of hospiceList) {
            obj = {...obj, [item.hospice_organization_id]: item.hospice_name};
        }
        setHospiceLookup(obj)
    }, [hospiceList]);

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.nurses}
                    </Typography>
                </Grid>
                <Grid item>
                    <MaterialTable
                        title={componentTitles.nurses}
                        columns={columns}
                        data={nurses}
                        options={{
                            pageSize: 10,
                        }}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.createNurses, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({
                                            nurse_name: newData.name,
                                            hospice_organization_id: newData.hospice_organization_id
                                        })
                                    }).then(res => {
                                        if (res.status === status.created) {
                                            resolve();
                                            setNurses((prevState) => {
                                                const data = [...prevState];
                                                data.push(newData);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })


                                }),
                            // onRowUpdate: (newData, oldData) =>
                            //     new Promise((resolve, reject) => {
                            //         const token = JSON.parse(localStorage.getItem('key'));
                            //         fetch(endpoints.updateNurses + oldData.name, {
                            //             method: "PUT",
                            //             headers: {
                            //                 "Content-Type": "application/json",
                            //                 'Authorization': 'Bearer ' + token.accessToken
                            //             },
                            //             body: JSON.stringify({nurse_name: newData.name, hospice_organization_id: newData.hospice_organization_id})
                            //         }).then(res => {
                            //             if (res.status === status.success) {
                            //                 resolve();
                            //                 if (oldData) {
                            //                     setNurses((prevState) => {
                            //                         const data = [...prevState];
                            //                         data[data.indexOf(oldData)] = newData;
                            //                         return data;
                            //                     });
                            //                 }
                            //             } else {
                            //                 reject();
                            //             }
                            //         })
                            //     }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.deleteNurses + oldData.nurse_id, {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        }
                                    }).then(res => {
                                        if (res.status === status.nocontent) {
                                            resolve();
                                            setNurses((prevState) => {
                                                const data = [...prevState];
                                                data.splice(data.indexOf(oldData), 1);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })


                                }),
                        }}/>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};