import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {IconButton, Typography} from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import Order from '../../assets/order_icon.svg'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {NotificationCenterStyle} from "./NotificationCenter.style";
import {NotificationSubcategoryAccordion} from "./components/NotificationSubcategoryAccordion";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {useSnackbar} from "notistack";
import _ from "lodash";
import {useHistory} from "react-router-dom";

export const NotificationCenter = ({categories, refreshFunction}) => {
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [expanded, setExpanded] = useState('');
    const [selectedCategory, setSelectedCategory] = useState("Orders");
    const [onlyImportant, setOnlyImportant] = useState(false);
    const func1 = () => {
        // debug demo for sharing is caring :)
        let arr = [];
        console.log("start");
        console.time("t1");
        for (let i = 0; i < 100000; i++) {
            arr.push({value: i})
        }
        console.log("stop");
        console.timeEnd('t1');

        arr.map((obj, index) => {
            if (obj.value === 11 || index === 100) {
                debugger;
                console.trace();
            }
        })
    }


    const handleInput = (event, level) => {
        switch (level) {
            case "selectCategory":
                setSelectedCategory(event.target.value);
                setExpanded("");
                break;
            case "expandSubcategory":
                setExpanded(event.target.value);
                break;
            case "toggleOnlyImportant":
                setOnlyImportant(!onlyImportant);
                break;
            case "subcategoryItemAction":
                history.push(`/refresh/order/delivery/${event.target.value}`)
                console.log(event, level);
                break;
            case "refreshAction":
                refreshFunction();
                break;
        }

    }

    const renderAccordions = (category) => {
        let index = _.findIndex(categories, {categoryName: category})
        if (index > -1) {
            return <Grid container>
                {categories[index].categoryData.map((subcategory, index) => {
                    return <NotificationSubcategoryAccordion
                        subcategoryName={subcategory.subcategoryName}
                        expanded={expanded}
                        onlyImportant={onlyImportant}
                        accordionItems={subcategory.entries}
                        handleInput={handleInput}
                        classes={classes}
                    />
                })}
            </Grid>
        } else {
            return null;
        }
    }

    return <Grid container className={classes.w700}>
        <Grid item xs={12} className={classes.titleBar}>
            <Grid container justify={"space-between"} alignItems={"center"} alignContent={"center"}
                  className={classes.titleContent}>
                <Grid item>
                    <Grid container direction={"column"} alignItems={"center"} alignContent={"center"}
                          justify={"center"}>
                        <Grid item>
                            <Typography variant={"subtitle1"} style={{fontWeight:'bold', fontSize:16}}>
                                Notification Center
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/*<Grid item>*/}
                {/*    <FormControlLabel*/}
                {/*        value="Important Only"*/}
                {/*        control={<Switch color="primary"/>}*/}
                {/*        label="Important Only"*/}
                {/*        labelPlacement="bottom"*/}
                {/*        onChange={(e) => handleInput(e, "toggleOnlyImportant")}*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item style={{display:'flex', alignItems:'center', alignContent:'center'}}>
                    <IconButton children={<RefreshIcon/>} color={"primary"}/>
                    <span>Refresh</span>
                    {/*<FormControlLabel*/}
                    {/*    value="Refresh"*/}
                    {/*    // control={}*/}
                    {/*    label="Refresh"*/}
                    {/*    labelPlacement="bottom"*/}
                    {/*    onChange={(e) => handleInput(e, "refreshAction")}*/}
                    {/*/>*/}
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={3} className={classes.categoryColumn}>
            <Grid container direction={"column"}>
                {categories.map((category, index) =>{
                    return <Grid item className={classes.categoryItem} id={index}>
                        <div
                            onClick={(e) => handleInput({target: {value: category.categoryName}}, "selectCategory")}
                        >
                            <IconButton
                                children={<img
                                    id={category.categoryName + "Icon"}
                                    src={category.categoryIcon}
                                    alt="logo"
                                    width='100%'
                                    style={{cursor: 'pointer'}}/>}
                            />
                        </div>
                    </Grid>
                })}
            </Grid>
        </Grid>
        <Grid item xs={9} className={classes.subcategoryColumn}>
            {renderAccordions(selectedCategory)}
        </Grid>
    </Grid>
}

const useStyles = makeStyles((theme) => NotificationCenterStyle(theme))
