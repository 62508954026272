import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import BootstrapInput from '../../../common/bootstrapInput/bootstrapInput';
import {validationTypes} from "../../../constants/validations/validationTypes";
import {CustomInputField} from "../../../common/InputField/InputField";
import PhoneInput from "react-phone-input-2";
import {sectionTitles} from "../../../constants/names/titles";
import {tooltips} from "../../../constants/inputs/tooltips";
import {labels} from "../../../constants/inputs/labels";
import {placeholders} from "../../../constants/inputs/paceholders";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";

export default function CaregiverInformation(props) {
    const {
        validType, handleInput, handleSelectInput, handleSelectRequiredInput,
        handleRequiredInput, patient, missingValues, handleCareGiverPhoneInput,
    } = props;
    const [phone1, setPhone1] = useState(true);
    const [phone2, setPhone2] = useState(true);
    const [phone3, setPhone3] = useState(true);
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{display: "flex"}}>
                    <Typography variant="h6" gutterBottom>
                        {sectionTitles.caregiverInformation}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{display: "flex"}}>
                            <Typography variant="subtitle1" gutterBottom>
                                {sectionTitles.primaryCaregiver}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomInputField
                                isRequired={true}
                                isMissing={missingValues.caregiver_first_name_primary}
                                validationType={validationTypes.onlyLettersAndSpacesNames}
                                name={'caregiver_first_name_primary'}
                                label={labels.requiredPrimaryCaregiverFirstName}
                                value={patient.caregiver_first_name_primary}
                                onChange={handleRequiredInput}
                                onBlur={handleRequiredInput}
                                isValid={validType.caregiver_first_name_primary}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomInputField
                                isRequired={true}
                                isMissing={missingValues.caregiver_last_name_primary}
                                validationType={validationTypes.onlyLettersAndSpacesNames}
                                name={'caregiver_last_name_primary'}
                                label={labels.requiredPrimaryCaregiverLastName}
                                value={patient.caregiver_last_name_primary}
                                onChange={handleRequiredInput}
                                onBlur={handleRequiredInput}
                                isValid={validType.caregiver_last_name_primary}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{paddingTop: 12}}>

                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '10px', textAlign: 'left'}}>
                                {labels.requiredPrimaryCaregiverPhone}
                            </InputLabel>
                            <div>
                                <PhoneInput
                                    name="caregiver_phone_number_primary"
                                    country={'us'}
                                    disableCountryCode={true}
                                    placeholder={placeholders.phone}
                                    onlyCountries={['us']}
                                    containerStyle={{width: '100%'}}
                                    inputStyle={{
                                        width: '100%',
                                        border: missingValues.caregiver_phone_number_primary === true && "1px solid red"
                                    }}
                                    value={patient.caregiver_phone_number_primary}
                                    onChange={(e) => {
                                        if (e.length === 10) {
                                            setPhone1(true)
                                        }
                                        const obj = {
                                            name: "caregiver_phone_number_primary",
                                            value: e,
                                        }
                                        handleCareGiverPhoneInput(obj)
                                    }}

                                    inputProps={{
                                        label: 'test',
                                        required: true,
                                        id: 'caregiver_phone_primary'
                                    }}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 14) {
                                            setPhone1(true)
                                        } else {
                                            setPhone1(false)
                                        }
                                    }}
                                    isValid={phone1}
                                />
                                <FormHelperText style={{
                                    color: 'red',
                                    textAlign: 'left'
                                }}>{!phone1 && tooltips.invalidPhone}</FormHelperText>
                                <FormHelperText style={{
                                    color: 'red',
                                    textAlign: 'left'
                                }}>{missingValues.phone_number && tooltips.isRequired}</FormHelperText>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl
                                fullWidth
                                required
                                style={{display: "flex", textAlign: "left"}}
                                error={missingValues.caregiver_relation_primary}
                            >
                                <InputLabel>{labels.relationToPatient}</InputLabel>
                                <Select
                                    labelId="relation-to-patient1-select-label"
                                    id="relation-to-patient1-select"
                                    name='caregiver_relation_primary'
                                    style={{
                                        border: missingValues.caregiver_relation_primary === true &&  "1px solid red",
                                        borderRadius: 4
                                    }}
                                    value={patient.caregiver_relation_primary}
                                    onChange={handleSelectRequiredInput}
                                    onBlur={handleSelectRequiredInput}
                                    input={<BootstrapInput/>}
                                >
                                    <MenuItem value="All"><em> Select Relation</em></MenuItem>
                                    <MenuItem value={'Spouse'}>Spouse</MenuItem>
                                    <MenuItem value={'Parent'}>Parent</MenuItem>
                                    <MenuItem value={'Child'}>Child</MenuItem>
                                    <MenuItem value={'Relative'}>Relative</MenuItem>
                                    <MenuItem value={'Unknown/Other'}>Unknown/Other</MenuItem>
                                </Select>
                            </FormControl>
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{missingValues.caregiver_relation_primary && tooltips.isRequired}</FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container spacing={2} alignItems={"stretch"}>
                        <Grid item xs={12} style={{display: "flex"}}>
                            <Typography variant="subtitle1" gutterBottom>
                                {sectionTitles.secondaryCaregiver}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="caregiverSecondaryName"
                                label={labels.secondaryCaregiverFirstName}
                                name="caregiver_first_name_second"
                                fullWidth
                                variant={"outlined"}
                                value={patient.caregiver_first_name_second}
                                onChange={handleInput}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="caregiverSecondaryName"
                                label={labels.secondaryCaregiverLastName}
                                name="caregiver_last_name_second"
                                fullWidth
                                variant={"outlined"}
                                value={patient.caregiver_last_name_second}
                                onChange={handleInput}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{paddingTop: 12}}>
                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '10px', textAlign: 'left'}}>
                                {labels.secondaryCaregiverPhone}
                            </InputLabel>
                            <PhoneInput
                                name="caregiver_phone_number_second"
                                country={'us'}
                                disableCountryCode={true}
                                placeholder={placeholders.phone}
                                onlyCountries={['us']}
                                containerStyle={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                                value={patient.caregiver_phone_number_second}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone2(true)
                                    }
                                    const obj = {
                                        name: "caregiver_phone_number_second",
                                        value: e,
                                    }
                                    handleCareGiverPhoneInput(obj)
                                }}

                                inputProps={{
                                    label: 'test',
                                    required: true
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14 || e.target.value.length === 0) {
                                        setPhone2(true)
                                    } else {
                                        setPhone2(false)
                                    }
                                }}
                                isValid={phone2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{height: '80px'}}>
                            <FormControl fullWidth style={{display: "flex", textAlign: "left"}}>
                                <InputLabel>{labels.relationToPatient}</InputLabel>
                                <Select
                                    labelId="relation-to-patient2-select-label"
                                    id="relation-to-patient2-select"
                                    name="caregiver_relation_second"
                                    value={patient.caregiver_relation_second}
                                    onChange={handleSelectInput}
                                    input={<BootstrapInput/>}
                                >
                                    <MenuItem value="All"><em> Select Relation</em></MenuItem>
                                    <MenuItem value={'Spouse'}>Spouse</MenuItem>
                                    <MenuItem value={'Parent'}>Parent</MenuItem>
                                    <MenuItem value={'Child'}>Child</MenuItem>
                                    <MenuItem value={'Relative'}>Relative</MenuItem>
                                    <MenuItem value={'Unknown/Other'}>Unknown/Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    props.showThirdCaregiver === false && <Tooltip placement="top" title={tooltips.showThirdCaregiver}>
                        <IconButton aria-label="delete"
                                    onClick={() => props.setShowThirdCaregiver(true)}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Tooltip>
                }
                {props.showThirdCaregiver === true && <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Grid container spacing={2} alignItems={"stretch"}>
                        <Grid item xs={12} style={{display: "flex"}}>
                            <Typography variant="subtitle1" gutterBottom>
                                {sectionTitles.tertiaryCaregiver}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="caregiverThirdName"
                                label={labels.thirdCaregiverFirstName}
                                name="caregiver_first_name_third"
                                fullWidth
                                variant={"outlined"}
                                value={patient.caregiver_first_name_third}
                                onChange={handleInput}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="caregiverThirdName"
                                label={labels.thirdCaregiverLastName}
                                name="caregiver_last_name_third"
                                fullWidth
                                variant={"outlined"}
                                value={patient.caregiver_last_name_third}
                                onChange={handleInput}
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{paddingTop: 12}}>
                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '10px', textAlign: 'left'}}>
                                {labels.thirdCaregiverPhoneNumber}
                            </InputLabel>
                            <PhoneInput
                                name="caregiver_phone_number_third"
                                country={'us'}
                                disableCountryCode={true}
                                placeholder={placeholders.phone}
                                onlyCountries={['us']}
                                containerStyle={{width: '100%'}}
                                inputStyle={{width: '100%'}}
                                value={patient.caregiver_phone_number_third}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone3(true)
                                    }
                                    const obj = {
                                        name: "caregiver_phone_number_third",
                                        value: e,
                                    }
                                    handleCareGiverPhoneInput(obj)
                                }}

                                inputProps={{
                                    label: 'test',
                                    id: "caregiver_phone_tertiary",
                                    required: true
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14 || e.target.value.length === 0) {
                                        setPhone3(true)
                                    } else {
                                        setPhone3(false)
                                    }
                                }}
                                isValid={phone3}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth
                                         style={{display: "flex", textAlign: "left"}}>
                                <InputLabel>{labels.relationToPatient}</InputLabel>
                                <Select
                                    labelId="relation-to-patient2-select-label"
                                    id="relation-to-patient3-select"
                                    name="caregiver_relation_third"
                                    value={patient.caregiver_relation_third}
                                    onChange={handleSelectInput}
                                    input={<BootstrapInput/>}
                                >
                                    <MenuItem value="All"><em> Select Relation</em></MenuItem>
                                    <MenuItem value={'Spouse'}>Spouse</MenuItem>
                                    <MenuItem value={'Parent'}>Parent</MenuItem>
                                    <MenuItem value={'Child'}>Child</MenuItem>
                                    <MenuItem value={'Relative'}>Relative</MenuItem>
                                    <MenuItem value={'Unknown/Other'}>Unknown/Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        </React.Fragment>
    );
}