import React, {forwardRef, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Table} from "../../common/table/table";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import Select from "react-select";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormHelperText from "@material-ui/core/FormHelperText";
import {AlertDialog} from "../../common/alerts/generalAlert/generalAlert";
import {orderPriorityValues, orderReasons, orderReasonValues} from "../../constants/inputs/values";
import {buttonTypes} from "../../constants/alert/buttonTypes";
import {alertMessages, snackbarMessages} from "../../constants/alert/alertMessages";
import {pickupTypes} from "../../constants/types/pickupTypes";
import {alertTitles} from "../../constants/alert/alertTitle";
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {placeholders} from "../../constants/inputs/paceholders";
import {titles} from "../../constants/inputs/tableColumnTitles";
import {labels} from "../../constants/inputs/labels";
import {getMessage} from "../../constants/messages/messages";
import {messageTypes} from "../../constants/types/messageTypes";
import {componentTitles} from "../../constants/names/titles";
import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode";
import TruckIcon from "../../assets/truck.svg";
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {useSnackbar} from "notistack";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {ArrowDownward} from "@material-ui/icons";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TextField from "@material-ui/core/TextField";
import {checkPaceHospice} from "../../common/utils/utils";
import FileUpload from "../orders/fileUploadOrders/FileIpload";
import {OrderNotes} from "./orderNotes";

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    snackbar: {
        backgroundColor: '#4CAF51'
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#4F86A0',
        border: 'none',
        fontSize: 12,
        fontWeight: 'bold',
        width: '120px',
        height: '40px',
        "&:hover": {
            backgroundColor: '#77A6BC'
        }
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "20px",
    },
    select: {
        minWidth: 200,
        zIndex: 12,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
    addMore: {
       width:190,
       fontWeight:'bold',
        fontSize:12,
        border:'1px solid #00223D',
        color:"#00223D",
        height:40
    },
    cssButton: {
        fontSize:12,
        fontWeight:'bold',
        textTransform:'uppercase',
        height:40
    }
}));

export default function RequestPickup(props) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [showAuthorization, setShowAuthorization] = useState(false);
    const [dmeList, setDmeList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedDme, setSelectedDme] = useState([]);
    const [files, setFiles] = useState([]);
    const [ordersId, setOrdersId] = useState([]);
    let history = useHistory();
    const classes = useStyles();
    const vertical = 'top';
    const horizontal = 'right';
    const [open, setOpen] = React.useState(false);
    const [showNotification, setShowNotification] = React.useState(false);
    const [notes, setNotes] = React.useState([]);
    // duplicate fetch data in data and checkData to perform total pickup check for keeping items if reason is !== discharge || revoke
    const [data, setData] = useState([]);
    const [checkData, setCheckData] = useState([]);
    const [date, setDate] = useState(moment.now);
    const [priority, setPriority] = useState("Routine");
    const [reason, setReason] = useState();
    const [isValidPickup, setValidPickup] = useState(true);
    const [validMessage, setValidMessage] = useState([]);
    const [mode, setMode] = useState(pickupTypes.unset);
    const [orderId, setOrderId] = useState('');
    const [hasItems, setHasItems] = useState(true);
    const [checked, setChecked] = useState(false);
    const [authorization_pace_no, setAuthorization_pace_no] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setMode(pickupTypes.unset);
            setDate(moment.now);
            setReason(null);
            setPriority("Routine");
        }
    };
    const createOrder = async () => {
        setLoadingButton(true);
        let user = JSON.parse(localStorage.getItem('key'));
        let decoded = jwt(user.accessToken);
        let user_id = decoded.payload.user_id;

        let ids = [];
        for (let singleDme in dmeList) {
            let items = [];
            for (let index in data) {
                if (data[index].dme_id){
                    data[index].pickup === true && parseInt(data[index].dme_id) === parseInt(dmeList[singleDme].id) && items.push({
                        "inventory_item_id": data[index].inventory_item_id,
                        "special_item_id": data[index].special_item_id
                    });
                } else if (data[index].exchange_dme_id){
                    data[index].pickup === true && parseInt(data[index].exchange_dme_id) === parseInt(dmeList[singleDme].id) && items.push({
                        "inventory_item_id": data[index].inventory_item_id,
                        "special_item_id": data[index].special_item_id
                    });
                }
                // data[index].pickup === true && parseInt(data[index].dme_id) === parseInt(dmeList[singleDme].id) && items.push({
                //     "inventory_item_id": data[index].inventory_item_id,
                //     "special_item_id": data[index].special_item_id
                // });

            }
            let obj = {
                user_id: user_id,
                patient_id: props.patient_id,
                dme_organization_id: dmeList[singleDme].id,
                preferred_date: moment(date),
                priority_code: priority,
                reason: reason,
                items: items,
                notes: notes,
                type: mode,
                authorization_pace_no:authorization_pace_no,
                history: [{
                    description: `Order created: ${decoded.payload.first_name} ${decoded.payload.last_name} created order on ${moment().format('LLL')}`,
                    created_date: moment()
                }]
            };
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.createPickup, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(obj),
            })
                .then(response => {
                    // notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderCreated, true);
                    return response.json()
                })
                .then(response => {
                    uploadFiles(response.data);
                    const action = key => (
                        <React.Fragment>
                            <Button onClick={() => {
                                history.push("/order/pickup/" + response.data);
                                closeSnackbar(key);
                            }}>
                                View Order
                            </Button>
                            <Button onClick={() => {
                                closeSnackbar(key);
                            }}>
                                Close
                            </Button>
                        </React.Fragment>
                    );

                    enqueueSnackbar(`Order #P200${response.data} has been created with success.`, {
                        variant: 'success',
                        autoHideDuration: 10000,
                        anchorOrigin: {vertical: "top", horizontal: "right"},
                        action,
                    });
                    props.getPatientOrders && props.getPatientOrders();
                    ids.push(response.data);
                    setOrderId(response.data);
                })
                .catch();
        }
        // setShowNotification(true);
        setOrdersId(ids);
        setDate(moment.now);
        setReason(null);
        setPriority(null);
    };
    const hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowNotification(false);
    };
    const viewOrder = (orderId) => {
        history.push(`/order/pickup/${orderId}`);
    };

    const handleInput = (event) => {
        const {name} = event.target;
        switch (name) {
            case "pickup":
                const {findItem} = event.target;
                const index = _.findIndex(data, {'special_item_id': findItem.special_item_id,});

                let values = _.cloneDeep(data);
                values[index].pickup = event.target.value;
                setData(values);
                break;
            case "date":
                setDate(event.target.date);
                break;
            case "priority":
                setPriority(event.target.value);
                break;
            case "reason":
                setReason(event.target.value);
                break;

        }
    };
    useEffect(()=> {
        async function check() {
            let res = await checkPaceHospice(props.hospice_id);
            setShowAuthorization(res);
        }
        check();
    }, []);

    useEffect(() => {
        let messages = [];
        if (!reason) {
            messages.push(alertMessages.missingReason);
            setValidPickup(false);
        }
        if (!priority) {
            messages.push(alertMessages.missingPriority);
            setValidPickup(false);
        }
        if ((reason !== orderReasons.revoked && reason !== orderReasons.discharged && reason !== orderReasons.expired) && mode === pickupTypes.total) {
            let index;
            for (index = 0; index < data.length; index++) {
                if (data[index] && checkData[index] && data[index].pickup !== checkData[index].pickup) {
                    if (!_.includes(messages, alertMessages.reasonTamper)) {
                        messages.push(alertMessages.reasonTamper);
                        messages.push(getMessage(messageTypes.problem));
                    }
                    messages.push(getMessage(messageTypes.item, data[index].item_name));
                    setValidPickup(false);
                }
            }
        }
        if (messages.length === 0 && mode === pickupTypes.total) {
            messages.push(alertMessages.validationsTotalPassed);
            setValidPickup(true);
        }
        if (messages.length === 0 && mode === pickupTypes.itemized) {
            messages.push(alertMessages.validationsItemizedPassed);
            setValidPickup(true);
        }
        setValidMessage(messages);
    }, [reason, priority, data]);

    const getData = async (totalParam) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.pickupItems + totalParam + '/' + props.patient_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();

        setData(response.data);
        setCheckData(response.data);
    };

    const columns = [
        {
            title: titles.item, field: "item_name", width: 500,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.item_name ? rowData.item_name : rowData.name}
                </Typography>
            </Grid>
        },
        {
            title: titles.asset, field: "asset", width: 150,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.asset}
                </Typography>
            </Grid>
        },
        {
            title: titles.provider, field: "dme_name", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.delivery_dme_name ? rowData.delivery_dme_name : rowData.exchange_dme_name}
                </Typography>
            </Grid>
        },
        {
            title: titles.deliveryDate, field: "preferred_date", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {moment(rowData.delivery_completed_date ? rowData.delivery_completed_date : rowData.exchange_completed_date).format('L')}
                </Typography>
            </Grid>
        },
        {
            title: titles.pickup, field: "pickup", width: 140,
            render: rowData => <Grid item className={classes.center}>
                <Switch checked={rowData.pickup}
                        onChange={e => {
                            const obj = {
                                target: {
                                    ...e,
                                    name: "pickup",
                                    findItem: {
                                        ...rowData,
                                    },
                                    value: !rowData.pickup,

                                }
                            };
                            handleInput(obj);
                        }}
                        disabled={(reason !== orderReasons.discharged && reason !== orderReasons.revoked && reason !== orderReasons.expired) && mode === pickupTypes.total}
                        name="status"
                        color="primary"
                />

                <FormHelperText style={{width: 50}}>
                    {rowData.pickup && titles.pickup}
                </FormHelperText>
            </Grid>
        },
    ];

    useEffect(()=> {
        let clone = _.cloneDeep(data);
        if (clone.length > 0){
            let array = [];
            clone.map(item=> {
                if (!array.some(e => (e.id === item.dme_id || e.id === item.exchange_dme_id)) && item.pickup){
                    array.push(
                        {
                            id: item.dme_id ? item.dme_id : item.exchange_dme_id,
                            name:item.delivery_dme_name ? item.delivery_dme_name : item.exchange_dme_name
                        }
                    )
                }
            });
            setDmeList(array);
        }
    }, [data]);

    async function uploadFiles (orderId) {
        for (let i=0; i<files.length; i++){
            await createOrderFilesIds(orderId, files[i])
        }
        setOpen(false);
        setMode(pickupTypes.unset);
        setLoadingButton(false);
    }
    async function createOrderFilesIds(orderId, file){
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'pickup',
            })
        });
        const response = await promise1.json();
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length-1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile (file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  fileName, type:'pickup'})
        });
        const result1 = await promise1.json();
        try{
            const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }

    const getContentType = (mode) => {
        if (mode === pickupTypes.unset) {
            return (
                <Grid container justify="center" alignItems="center" direction={"row"} spacing={3}>
                    <Grid item>
                        <Button
                            // className={classes.button}
                            variant={"outlined"}
                            color={"primary"}
                            size={"small"}
                            style={{height:40, textTransform:'uppercase', width:136, color:'#4F86A0', fontWeight:"bold"}}
                            onClick={() => {
                                setMode(pickupTypes.total);
                                getData(true);
                            }}
                        >
                            Total Pickup
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            // className={classes.button}
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            style={{ textTransform:'uppercase', width:136, backgroundColor:'#4F86A0', }}
                            onClick={() => {
                                setMode(pickupTypes.itemized);
                                getData(false);
                            }}
                        >
                            Itemized Pickup
                        </Button>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Button*/}
                    {/*        // className={classes.button}*/}
                    {/*        variant={"outlined"}*/}
                    {/*        color={"primary"}*/}
                    {/*        size={"small"}*/}
                    {/*        style={{ textTransform:'uppercase',height:40,width:136,color:'#00223D', fontWeight:"bold",border:'1px solid #00223D',}}*/}
                    {/*        onClick={() => {*/}
                    {/*            handleClose()*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        Cancel*/}
                    {/*    </Button>*/}
                    {/*</Grid>*/}
                </Grid>
            )
        } else {
            return (
                <Grid container justify="center" alignitems="center" direction={"column"}>
                    <Grid container justify={'space-between'}>


                    <Grid item
                          justify={mode === pickupTypes.unset ? "center" : 'flex-start'}
                          direction='column'
                          alignContent={mode === pickupTypes.unset ? "center" : 'flex-start'}
                    >
                        <Typography variant="subtitle1" style={{color:'#1A1818', fontSize:20, fontWeight:600}}>
                            {props.name}
                        </Typography>
                        <Typography variant="subtitle1" style={{color:'#2E3236', fontSize:14}}>
                            {props.address}
                        </Typography>
                    </Grid>
                    <Grid item direction="row" className={classes.center}
                    >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                disableToolbar
                                variant={"inline"}
                                placeholder={placeholders.date_MM_DD_YYYY}
                                id="pickup_date"
                                label={labels.pickup_date}
                                format="MM/dd/yyyy"
                                value={date}
                                onChange={(e) => {
                                    const obj = {
                                        target: {
                                            date: e,
                                            name: "date",
                                        }
                                    };
                                    handleInput(obj)
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Select
                            className={classes.select}
                            options={orderPriorityValues}
                            id={"priority"}
                            placeholder={placeholders.priority}
                            value={priority ? [{
                                label: priority,
                                value: priority
                            }] : null}
                            isSearchable
                            onChange={(event) => {
                                const obj = {
                                    target: {
                                        value: event.value,
                                        name: "priority",
                                    }
                                };
                                handleInput(obj);
                            }}
                        />
                        <Select
                            className={classes.select}
                            options={orderReasonValues}
                            placeholder={placeholders.reason}
                            id={"reason"}
                            isSearchable
                            value={reason ? [{
                                label: reason,
                                value: reason
                            }] : null}
                            onChange={(event) => {
                                const obj = {
                                    target: {
                                        value: event.value,
                                        name: "reason",
                                    }
                                };
                                handleInput(obj);
                            }}
                        />
                        {showAuthorization === true && (
                            <React.Fragment>
                            <Grid item style={{display:'flex', flexDirection:'column', alignItems:'flex-start',paddingTop:8,}}>
                                <TextField
                                    placeholder={'Authorization number'}
                                    label={'Authorization number'}
                                    variant={'outlined'}
                                    size={'small'}
                                    value={authorization_pace_no}
                                    onChange={(e)=>{
                                        setAuthorization_pace_no(e.target.value);
                                    }}
                                />

                            </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                    </Grid>
                    <Grid>
                       {dmeList.length >1 && <div style={{height:48}}>

                           <span style={{padding:8, backgroundColor:'#7B99B5', borderRadius:4, color:'white', display:'flex', alignContent:'center', alignItems:'center'}}>
                               <InfoOutlinedIcon /><span style={{marginLeft:8, fontWeight:600, fontSize:16}}>{`Your item selection will result in ${dmeList.length} orders created`}</span>
                           </span>

                       </div>}
                    </Grid>
                    <Grid item className={classes.center}>
                        {/*<Table columns={columns} items={data} isLoading={false} pageSize={10}/>*/}
                        <MaterialTable
                            component={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            components={{
                                Container: props => <Paper {...props} elevation={0}/>,
                                FilterRow: () => <tr />,
                                Pagination: props => <TablePagination {...props} style={{backgroundColor: "rgba(237, 242, 244, 0.73)", display:"flex"}} />,
                            }}
                            icons={{ Filter: () => <tr />,
                                SortArrow: forwardRef((props, ref) => <ArrowDownward style={{color: "#77A6BC"}} {...props} ref={ref} />),
                            }}
                            options={{
                                rowStyle: { backgroundColor: '#F9FAFC'},
                                emptyRowsWhenPaging: false,
                                toolbar: false,
                                filterCellStyle: {
                                    visibility:"hidden", width:'0px'
                                },
                                searchFieldStyle: {
                                    visibility:"hidden", width:'0px'
                                },
                                showTitle: false,
                                filtering:true,
                                showEmptyDataSourceMessage: data ? data.length === 0 : true,
                                pageSize: 10,
                                headerStyle: {
                                    backgroundColor: '#EDF2F4B9',
                                    color: '#2E3236',
                                    fontSize:12,
                                    fontWeight:'bold',
                                    height: 20,
                                    '&:hover': {color: '#FFF'}
                                },
                            }}
                            columns={columns}
                            data={data}
                        />
                    </Grid>
                    <FileUpload
                        files={files}
                        setFiles={setFiles}
                    />
                    <OrderNotes notes={notes} setNotes={setNotes}/>
                </Grid>
            )
        }
    };
    // const checkPatientInventory = async (patient_id) => {
    //     setChecked(true);
    //         const token = JSON.parse(localStorage.getItem('key'));
    //         const promise = await fetch(endpoints.pickupItems + "true" + '/' + patient_id, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': 'Bearer ' + token.accessToken
    //             },
    //         });
    //         let response = await promise.json();
    //         let {data} = response;
    //         if (data && data.length === 0) {
    //            setHasItems(false);
    //         } else {
    //             setHasItems(true);
    //         }
    //     };

    useEffect(()=> {
        if(props.autoTrigger === true){
            setOpen(true);
            setMode(pickupTypes.total);
            setReason(props.reason);
            setPriority("Routine");
            getData(true);
        }
        // if (props.patient_id && !checked && hasItems === null){
        //     checkPatientInventory(props.patient_id);
        //     setChecked(true);
        // }
    },[props.autoTrigger]);


    return (
        <React.Fragment>
            {(props.showPickup && hasItems) ? <Button
                variant="outlined"
                size="small"
                className={props.addMore ? classes.addMore : classes.button}
                startIcon={ props.addMore ? <AddIcon style={{fontSize:28}}/> :
                    <img
                    src={TruckIcon}
                    alt="logo"
                    width='16'
                    height='15'
                    // className={classes.simpleLogo}
                    style={{cursor:'pointer'}}
                />}
                onClick={handleClickOpen}
            >
                {props.addMore ? 'PICKUP MORE ITEMS' : 'NEW PICKUP'}
            </Button> : <div style={{width:120}}></div>}
            <Dialog
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={mode !== pickupTypes.unset ? 'md' : 'sm'}
                open={open || props.openOrder}
                // onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container direction="column" justify="center" alignItems={"center"} alignContent={"center"}>
                        <Grid container
                              justify={mode === pickupTypes.unset ? "center" : 'flex-start'}
                              alignItems={"center"}
                              direction={"row"} style={{ borderBottom:'1px solid #A3C7D2'}}>
                            <Typography variant="h6" style={{fontSize:24,fontWeight:'bold', textTransform:'uppercase', color:'#00223D'}}>
                                {componentTitles.pickup}
                            </Typography>
                        </Grid>
                        {mode === pickupTypes.unset &&(
                            <Grid container
                                  justify={mode === pickupTypes.unset ? "center" : 'flex-start'}
                                  direction='column'
                                  alignContent={mode === pickupTypes.unset ? "center" : 'flex-start'}
                            >
                                <Typography variant="subtitle1" style={{color:'#1A1818', fontSize:20, fontWeight:600}}>
                                    {props.name}
                                </Typography>
                                <Typography variant="subtitle1" style={{color:'#2E3236', fontSize:14}}>
                                    {props.address}
                                </Typography>
                            </Grid>
                        )}

                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {getContentType(mode)}
                </DialogContent>
                <DialogActions style={{paddingRight:24,paddingBottom:24, paddingTop:16}}>
                    <AlertDialog
                        denyAction={handleClose}
                        type={buttonTypes.standard}
                        title={alertTitles.warn}
                        buttonText={buttonText.cancel}
                        content={alertMessages.cancelPickup}
                        confirmActionText={buttonText.continuePickup}
                        denyActionText={buttonText.positive}
                        disabledButton={loadingButton}
                        buttonCSS={classes.cssButton}
                    />
                    {mode === pickupTypes.total && <AlertDialog
                        type={buttonTypes.standardContainedPrimary}
                        title={alertTitles.totalPickup}
                        buttonText={buttonText.requestPickup}
                        content={validMessage}
                        confirmActionText={buttonText.continuePickup}
                        confirmAction={createOrder}
                        denyActionText={buttonText.negative}
                        disabledButton={!isValidPickup}
                        isLoading={loadingButton}
                        buttonCSS={classes.cssButton}
                    />}
                    {mode === pickupTypes.itemized && <AlertDialog
                        type={buttonTypes.standardContainedPrimary}
                        title={alertTitles.itemizedPickup}
                        buttonText={buttonText.requestPickup}
                        content={validMessage}
                        confirmActionText={buttonText.continuePickup}
                        confirmAction={createOrder}
                        denyActionText={buttonText.negative}
                        disabledButton={!isValidPickup}
                        isLoading={loadingButton}
                        buttonCSS={classes.cssButton}
                    />}
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                ContentProps={{classes: {root: classes.snackbar}}}
                style={{padding: '10px'}}
                key={`${vertical},${horizontal}`}
                open={showNotification}
                autoHideDuration={10000}
                onClose={hideNotification}
                message={ordersId.length > 1 ? `Following orders have been created: ${ordersId.toString()}` : `Order ${ordersId[0]} has been created with success.`}
                action={
                    <React.Fragment>
                        {ordersId.map(order => (
                            <Button style={{color: 'black', marginRight: '6px'}} size="small" onClick={()=>viewOrder(order)}>
                                View Pickup Order #{order}
                            </Button>
                        ))}

                        <IconButton size="small" aria-label="close" color="inherit" onClick={hideNotification}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            >
            </Snackbar>
        </React.Fragment>
    );
}
