export const NotificationCenterStyle = (theme) => {
    return {
        w700: {
            width: 700
        },
        titleBar: {
            backgroundColor: "rgba(237, 242, 244, 0.73)",
            borderBottom: "1px solid whitesmoke"
        },
        titleContent: {
            paddingRight: 30,
            paddingLeft: 30,
            height: "100%",
            width: "100%"
        },
        categoryColumn: {
            backgroundColor: "#F9FAFC",
            height: 400,
            padding: 0,
            borderRight: "1px solid whitesmoke"
        },
        categoryItem: {
            display: "flex",
            width: "100%",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            borderBottom: "1px solid whitesmoke"
        },
        subcategoryColumn: {
            // backgroundColor: "red",
            overflowY: "scroll",
            height: 400
        },
        accordionItem: {
            // paddingRight: 30,
            // paddingLeft: 30,
            width: "100%"
        }
    }
}
