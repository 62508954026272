import makeStyles from "@material-ui/core/styles/makeStyles";

export const exchageStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    snackbar: {
        backgroundColor: '#4CAF51'
    },
    button: {
        color: '#FFFFFF',
        backgroundColor: '#84B4C2',
        fontSize: 12,
        width: '3000px',
        height: '40px',
        "&:hover": {
            backgroundColor: '#BBD3DE'
        },
        border: 'none',
    }
}));

export const patientInventoryStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    snackbar: {
        backgroundColor: '#4CAF51'
    },
    button: {
        color: '#4F88A1',
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "20px",
    },
    select: {
        minWidth: 200,
        zIndex: 12,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
    masterList: {
        maxWidth: 600,
    },
    suggestionContainer: {
        minWidth: 200,
        display: "flex",
    }

}));
