import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHistory} from "react-router-dom";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {packageTypes, predefinedPackages} from "../../../constants/types/predefinedPackageTypes";
import {endpoints} from "../../../constants/endpoints";
import jwt from "jwt-decode";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import {orderPriorityValues} from "../../../constants/inputs/values";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {labels} from "../../../constants/inputs/labels";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import MaterialTable from "material-table";
import CustomizedSlider from "../../createOrder/orderItems/selectItems/orderItem/slider/slider";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import CreateOrder from "../../createOrder/createOrder";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    h1: {
        textAlign: 'left',
        color: '#00223D',
        fontSize: 28,
        fontWeight: "bold",
        lineHeight: '38px',
        borderBottom: '1px solid #B2D1D9',
    },
    h4: {
        fontSize: 26,
        fontWeight: "bold",
        color: "#1A1818"
    },
    h5: {
        fontSize: 14,
        color:"#2E3236"
    }
}));

export const CustomPackageOrders = ({hospiceId, hospiceRegionId, patientId, patientData, getPatientOrders}) => {
    const [open, setOpen] = React.useState(false);
    const [openConfirmation, setConfirmation] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const classes = useStyles();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [dmeId, setDmeId] = useState("");
    const token = JSON.parse(localStorage.getItem('key'));
    const userData = jwt(token.accessToken);
    const [selectedPackage, setSelectedPackage] = useState("");
    const [contractItems, setContractItems] = useState([]);
    const [priority, setPriority] = useState("Routine");
    const [contractedProviders, setContractedProviders] = useState([]);
    const [contractedProvidersList, setContractedProvidersList]= useState([]);
    const [preferredDate, setPreferredDate] = useState(moment());
    const [requiresApproval, setRequiresApproval] = useState(false);
    const [orderItems, setOrderItems] = useState([]);
    const [concentratorLiters, setConcentratorLiters] = useState(5);
    const [oxygenInfo, setOxygenInfo] = useState({});
    const [isContinuous, setIsContinuous] = useState(0);
    const [showList, setShowList] = useState([]);
    const [additionalItems, setAdditionalItems] = useState([]);
    const [unavailableItems, setUnavailableItems] = useState([]);
    const [preparedItems, setPreparedItems] = useState([]);
    const [customPackages, setCustomPackages] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const concentratorNames = {
        five: "Oxygen Concentrator - 5 Liter",
        ten: "Oxygen Concentrator - 10 Liter"
    };
    const dispatch = useDispatch();
    let history = useHistory();

    const handleOpen = () => {
        setOpen(true);
        setPreferredDate(moment());
        setPriority("Routine");
        setSelectedPackage("");
        setRequiresApproval(false);
        setOrderItems([]);
        setIsContinuous(0);
        setOxygenInfo({});
        setShowList([]);
    };

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const handleConfirm = () => {
        createOrder();
        setOpen(false);
    };
    const prepareItems = (pack, contractItems) => {
        let approval = false;
        let items = [];
        let unavailable = [];
        for (const item of pack) {
            let contractedIndex = _.findIndex(contractItems, function (o) {
                if (item.name === "Oxygen Concentrator") {
                    if (concentratorLiters === 5) {
                        return o.name === concentratorNames.five
                    }
                    if (concentratorLiters === 10) {
                        return o.name === concentratorNames.ten
                    }
                }
                return o.name === item.name;
            });
            console.log(contractedIndex, 'indexxxxxxx')
            if (contractedIndex >= 0) {
                if (contractItems[contractedIndex].approval === true) {
                    approval = true;
                }
                if (item.name === "Oxygen Concentrator" || item.name === "Oxygen Tank - E Cylinder USP") {
                    items.push({...contractItems[contractedIndex], special_item_info: {
                            ...oxygenInfo.special_item_info,
                            isContinuous: isContinuous === 1,

                        },
                        quantity: 1
                    });
                } else {
                    items.push({...contractItems[contractedIndex], special_item_info: {}, quantity: 1});
                }
            } else {
                if(item.name === "Oxygen Concentrator") {
                    if (concentratorLiters === 5) {
                        unavailable.push({name: concentratorNames.five, special_item_info: {}, quantity: 1});
                    }
                    if (concentratorLiters === 10) {
                        unavailable.push({name: concentratorNames.ten, special_item_info: {}, quantity: 1});
                    }
                } else {
                    unavailable.push({name: item.name, special_item_info: {},quantity: 1});
                }
            }
        }
        setRequiresApproval(approval);
        setUnavailableItems(unavailable);
        setShowList([...unavailable, ...items]);
        setOrderItems(items);
    };

    useEffect(() => {
        getContractedProviders();
    }, []);
    useEffect(() => {
        if (open === true && dmeId !== "") {
            (async () => {
                const promise = await fetch(`${endpoints.getHospiceContractedItems}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            dme_id: dmeId,
                            hospice_id: hospiceId,
                            hospice_region_id: hospiceRegionId,
                        })
                    }
                );
                const response = await promise.json();
                if (response.data) {
                    setContractItems(response.data);
                    if(selectedPackage !== ""){
                                prepareItems([...preparedItems, ...additionalItems], response.data);

                        // switch (selectedPackage) {
                        //     case packageTypes.newAdmissionPackage:
                        //         break;
                        //     case packageTypes.bedPackage:
                        //         prepareItems([...predefinedPackages.bedPackage, ...additionalItems],response.data);
                        //         break;
                        //     case packageTypes.oxygenPackage:
                        //         prepareItems([...predefinedPackages.oxygenPackage, ...additionalItems],response.data);
                        //         break;
                        // }
                    }
                }
                setLoadingItems(false);
            })()
        }
    }, [open, dmeId, additionalItems, preparedItems]);
    useEffect(() => {
        switch (selectedPackage) {
            case packageTypes.newAdmissionPackage:
                prepareItems([...predefinedPackages.bedPackage, ...predefinedPackages.oxygenPackage, ...additionalItems], contractItems);
                break;
            case packageTypes.bedPackage:
                prepareItems(predefinedPackages.bedPackage, contractItems);
                break;
            case packageTypes.oxygenPackage:
                prepareItems(predefinedPackages.oxygenPackage, contractItems);
                break;
        }
    }, [selectedPackage, concentratorLiters, isContinuous, oxygenInfo, contractItems, additionalItems])

    const handleRemove = (rowData) => {
        if (rowData.item_id){
            let showListIndex = _.findIndex(showList, function (o) {
                return o.item_id === rowData.item_id;
            });
            let cloneShowList = _.cloneDeep(showList);
            cloneShowList.splice(showListIndex,1);
            setShowList(cloneShowList);

            let orderItemsIndex = _.findIndex(orderItems, function (o) {
                return o.item_id === rowData.item_id;
            });
            let cloneOrderItemsList = _.cloneDeep(orderItems);
            cloneOrderItemsList.splice(orderItemsIndex,1);
            setOrderItems(cloneOrderItemsList);
        } else {
            let showListIndex = _.findIndex(showList, function (o) {
                return o.name === rowData.name;
            });
            let cloneShowList = _.cloneDeep(showList);
            cloneShowList.splice(showListIndex,1);
            setShowList(cloneShowList);
        }
        console.log(rowData);
    };
    function getAllCustomPackages(){
        // setItemsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getAllCustomPackage}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    dme_id: dmeId,
                    hospice_id: hospiceId,
                    hospice_region_id: hospiceRegionId,
                    active:true,
                })
            }
        )
            .then(response => {
                return response.json();
            }).then(response => {
            if (response.data.length > 0) {

                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].custom_package_id, label: response.data[i].name})
                }

                setCustomPackages(object);
                // setContractedItems(clonedItems);

                // setItemsLoading(false);
                // setItemsList(clonedItems);
                // setFilteredItemsList(clonedItems)
            } else {
                // setItemsLoading(false)
                // setItemsList([]);
                // setFilteredItemsList([])
            }
        })
            .catch(err => console.log('error', err));
    }
    function getPackageItems(){
        setLoadingItems(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCustomPackageById}${selectedPackage.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                }
            }
        )
            .then(response => {
                return response.json();
            }).then(response => {
            if (response.data.length > 0) {

                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].custom_package_id, label: response.data[i].name})
                }

                setPreparedItems(response.data);

            } else {

            }

        })
            .catch(err => {
                setLoadingItems(false);
                console.log('error', err)
            });
    }
    useEffect(()=> {
        if (selectedPackage.value){
            getPackageItems();
        }
    }, [selectedPackage]);
    useEffect(()=> {
        getAllCustomPackages();
    }, [dmeId]);

    const showCreatedOrder = (id) => {
        const action = key => (
            <React.Fragment>
                <Button onClick={() => {
                    history.push("/order/delivery/" + id)
                    closeSnackbar(key);
                }}>
                    View Order
                </Button>
                <Button onClick={() => {
                    closeSnackbar(key);
                }}>
                    Close
                </Button>
            </React.Fragment>
        );

        enqueueSnackbar(getMessage(messageTypes.orderCreated,id), {
            variant: 'success',
            autoHideDuration: 10000,
            anchorOrigin: {vertical: "top", horizontal: "right"},
            action,
        });
    };

    const createOrder = () => {
        const orderObject = {
            status: requiresApproval === true ? "Pending" : "Open",
            patient_id: patientId   ,
            preferred_date: moment(),
            user_id: userData.payload.user_id,
            dme_organization_id: dmeId,
            hospice_id: hospiceId,
            priority_code: priority,
            approval: requiresApproval,
            items: orderItems,
            region_id: hospiceRegionId,
            notes: [],
            history: [],
        };
        const data = JSON.parse(localStorage.getItem('key'));
        if (preferredDate !== null) {
            fetch(`${endpoints.createOrder}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify(orderObject)
                }
            ).then(res => {
                return res.json();
            }).then(res => {
                getPatientOrders && getPatientOrders();
                if(requiresApproval === true) {
                    fetch(`${endpoints.sendApproveOrderMail}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + data.accessToken
                            },
                            body: JSON.stringify({
                                orderId: res.data,
                                items: orderItems,
                                orderType: "Delivery",
                                link: `${process.env.REACT_APP_API_URL}/order/delivery/${res.data}`
                            })
                        }
                    )
                }
                showCreatedOrder(res.data);
            })
        }

    };

    const getContractedProviders = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${hospiceId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                }
                setContractedProviders(response.data);
                setContractedProvidersList(object);
                if (response.data.length === 1) {
                    setDmeId(response.data[0].dme_id)
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    const columns = [
        {title: "Item Number", field: "index", width: 5,headerStyle: {textAlign: 'center'},
            render: rowData => <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Typography variant={"subtitle1"}>
                    {rowData.tableData.id + 1}
                </Typography>
            </Grid>
        },
        {title: 'Item Name', field: 'name',width: 120,
            render: rowData => {
                let contractedIndex = _.findIndex(contractItems, function (o) {
                    if (rowData.name === "Oxygen Concentrator") {
                        if (concentratorLiters === 5) {
                            return o.name === concentratorNames.five
                        }
                        if (concentratorLiters === 10) {
                            return o.name === concentratorNames.ten
                        }
                    }
                    return o.name === rowData.name;
                });
                if (contractedIndex >= 0) {
                    return <Grid item style={{display: "flex", justifyContent: "flex-start", alignItems: "center",}}>
                        <Typography variant={"subtitle1"} style={{color:"green",}}>
                            {rowData.name}
                        </Typography>
                    </Grid>
                } else {
                    return <Grid item style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                        <Typography variant={"subtitle1"} style={{color:"red",}}>
                            {rowData.name}
                        </Typography>
                    </Grid>
                }
            }
        },
        {
            title: 'Contracted', field: 'contracted', width: 30, headerStyle: {textAlign: 'center'},
            render: rowData => {
                let contractedIndex = _.findIndex(contractItems, function (o) {
                    if (rowData.name === "Oxygen Concentrator") {
                        if (concentratorLiters === 5) {
                            return o.name === concentratorNames.five
                        }
                        if (concentratorLiters === 10) {
                            return o.name === concentratorNames.ten
                        }
                    }
                    return o.name === rowData.name;
                });
                if (contractedIndex >= 0) {
                    return <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <DoneIcon style={{color:"green",fontSize: 24}}/>
                    </Grid>
                } else {
                    return <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <CloseIcon style={{color:"red", fontSize: 24}}/>
                    </Grid>
                }
            }

        },
        {
            title: 'Remove', field: 'contracted', width: 30, headerStyle: {textAlign: 'center'}, sorting:false,
            render: rowData => {
                    return <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <IconButton onClick={()=>handleRemove(rowData)}>
                            <DeleteIcon style={{fontSize: 24}}/>
                        </IconButton>
                    </Grid>

            }
        }

    ]

    const getPackageContent = () => {
        switch (selectedPackage) {
            case packageTypes.newAdmissionPackage:
                return <Grid container direction={"column"} spacing={3}>
                    <Grid item style={{paddingBottom: 16}}>
                        <Grid container>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Concentrator Liters</FormLabel>
                                    <RadioGroup aria-label="Concentrator Liters" name="Liters" value={concentratorLiters} style={{display: "flex"}}
                                                onChange={e => setConcentratorLiters(Number(e.target.value))}>
                                        <FormControlLabel value={5} control={<Radio color={"primary"}/>} label="5 Liter"/>
                                        <FormControlLabel value={10} control={<Radio color={"primary"}/>} label="10 Liter"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Flow</FormLabel>
                                    <RadioGroup aria-label="Flow Mode" name="Flow" value={isContinuous} style={{display: "flex"}}
                                                onChange={e => setIsContinuous(Number(e.target.value))}>
                                        <FormControlLabel value={0} control={<Radio color={"primary"}/>} label="PRN"/>
                                        <FormControlLabel value={1} control={<Radio color={"primary"}/>} label="Continuous"/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item style={{paddingTop:24, display:'flex', alignItems:'center', alignContent:'center'}}>
                                <CustomizedSlider
                                    noCss={true}
                                    min_range={concentratorLiters === 5 ? 0 : 5}
                                    max_range={concentratorLiters === 5 ? 5 : 10}
                                    itemValues={oxygenInfo}
                                    setItemValues={setOxygenInfo}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>;
            case packageTypes.bedPackage:
                return <Grid container direction={"column"}>
                </Grid>;
            case packageTypes.oxygenPackage:
                return <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container>
                    <Grid item style={{paddingBottom: 16}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Select Concentrator Liters</FormLabel>
                            <RadioGroup aria-label="Concentrator Liters" name="Liters" value={concentratorLiters} style={{display: "flex"}}
                                        onChange={e => setConcentratorLiters(Number(e.target.value))}>
                                <FormControlLabel value={5} control={<Radio color={"primary"}/>} label="5 Liter"/>
                                <FormControlLabel value={10} control={<Radio color={"primary"}/>} label="10 Liter"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Select Flow</FormLabel>
                            <RadioGroup aria-label="Flow Mode" name="Flow" value={isContinuous} style={{display: "flex"}}
                                        onChange={e => setIsContinuous(Number(e.target.value))}>
                                <FormControlLabel value={0} control={<Radio color={"primary"}/>} label="PRN"/>
                                <FormControlLabel value={1} control={<Radio color={"primary"}/>} label="Continuous"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item style={{paddingTop:24, display:'flex', alignItems:'center', alignContent:'center'}}>
                        <CustomizedSlider
                            noCss={true}
                            min_range={concentratorLiters === 5 ? 0 : 5}
                            max_range={concentratorLiters === 5 ? 5 : 10}
                            itemValues={oxygenInfo}
                            setItemValues={setOxygenInfo}
                        />
                    </Grid>
                </Grid>
                </Grid>
                </Grid>
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
            >
                Custom DME Package
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"lg"}

            >
                <DialogTitle id="alert-dialog-title">
                    <Typography className={classes.h1}>
                        Order a Package
                    </Typography>
                </DialogTitle>
                <DialogContent
                >
                    <Grid container direction={"column"} style={{minHeight: 500}}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={3}
                                    // lg={3} md={4} sm={12} xs={12}
                                      style={{display:'flex', flexDirection:'column', paddingRight:36,}}>
                                    <Typography className={classes.h4}>
                                        {patientData.first_name + " " + patientData.mi + " " + patientData.last_name}
                                    </Typography>
                                    <Typography className={classes.h5}>
                                        {patientData.address1 + ", " + patientData.address2 + ", " + patientData.city  + ", " + patientData.state+ " " + patientData.zip }
                                    </Typography>
                                </Grid>
                                {contractedProvidersList.length > 1 && <Grid item xs={12} sm={4} md={4} lg={3} style={{zIndex:21}}>
                                    <InputLabel>{labels.dmeProvider}</InputLabel>
                                    <Select options={contractedProvidersList}
                                            onChange={e => {
                                                console.log(dmeId);
                                                if (dmeId !== ''){
                                                    setConfirmation(true);
                                                    setSelectedId(e.value)
                                                } else {
                                                    setDmeId(e.value)
                                                }
                                            }}
                                            id={"dmeProvider"}
                                            // value={{value: priority, label: contractedProviders[_.findIndex(contractedProviders),{dme_id:dmeId}].label}}
                                    />
                                </Grid>}
                                {dmeId !== "" && <Grid item xs={12} sm={4} md={4} lg={3} style={{zIndex: 20}}>
                                    <InputLabel>{labels.package}</InputLabel>
                                    <Select
                                        id={"packageSelector"}
                                        options={customPackages}
                                            onChange={e => setSelectedPackage(e)}
                                    />
                                </Grid>}
                                {dmeId !== "" &&  <Grid item xs={12} sm={4} md={4} lg={3} style={{zIndex:19}}>
                                    <InputLabel>{labels.priority}</InputLabel>
                                    <Select options={orderPriorityValues}
                                            onChange={e => setPriority(e.value)}
                                            value={{value: priority, label: priority}}
                                    />
                                </Grid> }
                                {dmeId !== "" && <Grid item xs={12} sm={4} md={4} lg={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            fullWidth
                                            inputVariant="outlined"
                                            label={"Completed Date"}
                                            format="MM/dd/yyyy"
                                            value={preferredDate}
                                            onChange={(event) => setPreferredDate(event)}
                                            size={"small"}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction={"column"}>
                                <Grid item xs={12}>
                                    {getPackageContent()}
                                </Grid>
                                <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <CreateOrder
                                        renderButton={true}
                                        user={patientData}
                                        table={true}
                                        addMore={true}
                                        orderPackage={true}
                                        additionalItems={additionalItems}
                                        setAdditionalItems={setAdditionalItems}
                                        orderPackageDmeId={dmeId}
                                        isPace={patientData?.ispace}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{overflowX: 'scroll'}}>
                                    <MaterialTable columns={columns} data={showList}
                                                   title={"The following CONTRACTED items will be added to your order"}
                                                   options={{
                                                       search: false,
                                                       tableLayout: "fixed",
                                                       padding:'dense',
                                                       pageSize: 10,
                                                       emptyRowsWhenPaging: false
                                                   }}
                                                   isLoading={loadingItems}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            variant="contained"
                            color="secondary"
                            autoFocus
                            style={{textTransform: 'none'}}>
                        {buttonText.negative}
                    </Button>
                    <Button onClick={handleConfirm}
                            id={"next"}
                            variant="contained"
                            color="primary"
                            disabled={dmeId=== "" || selectedPackage === "" || orderItems.length === 0}
                            style={{textTransform: 'none'}}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmation}
            >
                <DialogContent>
                    <Grid container>
                        <Grid xs={12}>
                            <Typography style={{paddingBottom:24}}>You selected to change the current DME provider. Do you want to continue?</Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Grid container justify={'flex-end'}>
                                <Grid style={{paddingRight:12}}>
                                    <Button variant={'outlined'} color={'secondary'} onClick={()=> setConfirmation(false)}>Cancel</Button>
                                </Grid>
                                <Grid>
                                    <Button variant={'contained'} color={'primary'} onClick={()=> {
                                        setDmeId(selectedId);
                                        setConfirmation(false);
                                    }}>Yes</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
