import {sectionTitles} from "../../../constants/names/titles";
import {routes} from "../../../constants/routes/routes";

export const options = {
    patients:
        {
            add:
                {
                    label: sectionTitles.addNewPatient,
                    path: routes.add_patient,
                },
            view:
                {
                    label: sectionTitles.view_patients,
                    path: routes.view_patients,
                }
        }
    ,
    user: [
        {
            label: sectionTitles.user_info,
            path: routes.user_information,
        },
        {
            label: sectionTitles.my_profile,
            path: routes.changePassword,
        },
        {
            label: sectionTitles.sign_out,
            path: routes.login,
        }
    ],
};