export const NotificationTableStyles = (theme) => {
    return {
        wrapper: {
            // borderRadius: 4,
            //         // border: '1px solid #E0E0E0'
            paddingTop:16
        },
        container: {
            height: 96,
            color: '#2E3236',
            fontSize: 13,
            fontWeight:'bold',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            backgroundColor: '#EDF2F4B9',
            maxWidth: 1428,
            width: '101%',
            minWidth: 800,
            margin: -1,
        },
        row: {
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            padding: 16,
            fontSize: '0.875rem',
        },
        rows: {
            paddingTop: 8,
            paddingBottom: 8,
            borderBottom: '1px solid #E0E0E0',
            backgroundColor:'#fff',
            fontWeight:600,
            minWidth: 800,
            fontSize:14
        },
        checkbox: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            padding: 2,
            fontSize: '0.875rem',
        }
    }
}