import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {useHistory, useLocation} from "react-router-dom";
import {checkPermissions} from "../permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import {routes} from "../../constants/routes/routes";
import BigLogo from "../../assets/logo_caption@2x.png";
import SimpleLogo from "../../assets/logo_caption_dove_outline@2x.png";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeSelected from "../../assets/home_selected.svg";
import Home from "../../assets/home.svg";
import ListItemText from "@material-ui/core/ListItemText";
import PatientSelected from "../../assets/patient_selected.svg";
import Patient from "../../assets/patient_icon.svg";
import OrderSelected from "../../assets/order_selected.svg";
import Order from "../../assets/order_icon.svg";
import ReportsSelected from "../../assets/reports_selected.svg";
import Reports from "../../assets/reports_icon.svg";
import InventorySelected from "../../assets/inventory_selected.svg";
import Inventory from "../../assets/inventory.svg";
import SettingsSelected from "../../assets/settings_selected.svg";
import Settings from "../../assets/settings.svg";
import {sideDrawerStyle} from "./sideDrawer.style";
import SupportSelected from "../../assets/help_selected.svg";
import Support from "../../assets/help.svg";
import FlashOnIcon from "@material-ui/icons/FlashOn";

const drawerWidth = 220;

export default function SideDrawer(props) {
    let location = useLocation();
    const history = useHistory();
    const classes = useStyles();

    const open = true;
    const [currentPath, setCurrentPath] = useState('/');
    const [state, setState] = React.useState({
        top: false,
        left: props.open,
        bottom: false,
        right: false,
    });
    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [location.pathname]);

    const toggleDrawer = (anchor, open) => (event) => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
        props.setDrawerState(open)
    };
    useEffect(() => {
        if (props.open) {
            setState({...state, left: true})
        }
    }, [props.open]);

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={classes.toolbar}
                 onClick={() => history.push(routes.root)}
            >
                <img
                    src={open ? BigLogo : SimpleLogo}
                    alt="logo"
                    width={open ? '148' : '49'}
                    className={classes.pointer}
                />
            </div>
            <Divider/>
            <List className={classes.list}>
                <ListItem button className={classes.paddingL30}
                          onClick={() => history.push(routes.dashboard)}
                >
                    <ListItemIcon classes={{root: classes.icon}}>
                        <img
                            src={(currentPath.includes('/dashboard') || currentPath === "/") ? HomeSelected : Home}
                            alt="logo"
                            width='20'
                            className={classes.pointer}
                        />
                    </ListItemIcon>
                    {open && (
                        <ListItemText primary={"Dashboard"}
                                      style={(currentPath.includes('/dashboard') || currentPath === "/") ? {color: "#00223d"} : {color: "#4F86A0"}}
                        />
                    )}
                </ListItem>
                <div className={classes.height19}/>
                {
                    checkPermissions([permissions.read_patients]) && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.view_patients)}
                            >
                                <ListItemIcon classes={{root: classes.icon}}>
                                    <img
                                        src={currentPath.includes('/patients') ? PatientSelected : Patient}
                                        alt="logo"
                                        width='20'
                                        className={classes.pointer}
                                    />
                                </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Patients"}
                                                  style={currentPath.includes('/patients') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )}
                {
                    checkPermissions([permissions.read_orders]) && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.view_orders)}
                            >
                                <ListItemIcon classes={{root: classes.icon}}> <img
                                    src={currentPath.includes('/order') ? OrderSelected : Order}
                                    alt="logo"
                                    width='20'
                                    className={classes.pointer}
                                /> </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Orders"}
                                                  style={currentPath.includes('/order') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )}
                {
                    checkPermissions([permissions.billing_report, permissions.active_rental_report]) && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.reports)}>
                                <ListItemIcon classes={{root: classes.icon}}
                                >
                                    <img
                                        src={currentPath.includes('/reports') ? ReportsSelected : Reports}
                                        alt="logo"
                                        width='20'
                                        className={classes.pointer}
                                    />
                                </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Reports"}
                                                  style={currentPath.includes('/reports') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )
                }
                {
                    checkPermissions([permissions.read_inventory]) && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.inventory)}
                            >
                                <ListItemIcon classes={{root: classes.icon}}> <img
                                    src={currentPath.includes('/inventory') ? InventorySelected : Inventory}
                                    alt="logo"
                                    width='20'
                                    className={classes.pointer}
                                /> </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Inventory"}
                                                  style={currentPath.includes('/inventory') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )
                }
                {
                    checkPermissions([permissions.read_admin], "DME") && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.engagements)}

                            >
                                <ListItemIcon classes={{root: classes.icon}}> <FlashOnIcon color={'primary'} style={{
                                    color: currentPath.includes('/engagements') ? "#00223d" : "#4F86A0"
                                }}/> </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Engagements"}
                                                  style={currentPath.includes('/engagements') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )
                }
                {
                    checkPermissions([permissions.read_admin], "DME") && (
                        <React.Fragment>
                            <ListItem button className={classes.paddingL30}
                                      onClick={() => history.push(routes.admin)}
                            >
                                <ListItemIcon classes={{root: classes.icon}}> <img
                                    src={currentPath.includes('/admin') ? SettingsSelected : Settings}
                                    alt="logo"
                                    width='20'
                                    className={classes.pointer}
                                /> </ListItemIcon>
                                {open && (
                                    <ListItemText primary={"Admin"}
                                                  style={currentPath.includes('/admin') ? {color: "#00223d"} : {color: "#4F86A0"}}
                                    />
                                )}
                            </ListItem>
                            <div className={classes.height19}/>
                        </React.Fragment>
                    )
                }
                <React.Fragment>
                    <ListItem button className={classes.paddingL30}
                              onClick={() => history.push(routes.support)}
                    >
                        <ListItemIcon classes={{root: classes.icon}}> <img
                            src={currentPath.includes('/support') ? SupportSelected : Support}
                            alt="logo"
                            width='20'
                            style={{cursor: 'pointer'}}
                        /> </ListItemIcon>
                        {open && (
                            <ListItemText primary={"Support"}
                                          style={currentPath.includes('/support') ? {color: "#00223d"} : {color: "#4F86A0"}}
                            />
                        )}
                    </ListItem>
                    <div className={classes.height19}/>
                </React.Fragment>
            </List>
        </div>
    );

    return (
        <div>
            {['left', 'right', 'top', 'bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
const useStyles = makeStyles((theme) => sideDrawerStyle(theme, drawerWidth));