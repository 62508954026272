import React, {useEffect, useState} from 'react';
import './engagements.css';
import {useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Redirect, useHistory} from "react-router-dom";
import PlusSign from "../../assets/Icon_material-add-circle-outline.svg";
import jwt from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import ListSign from "../../assets/Icon_material-format-list-bulleted.svg";
import EmailSign from "../../assets/e-mail.svg";
import HelpSign from "../../assets/help.svg";
import {dashboardStyle} from "../dashboard/dashboard.style";
import BiSign from "../../assets/icon_business-intelligence.svg";
import FrSign from "../../assets/icon_financial-reports.svg";
import PrSign from "../../assets/icon_performance-reports.svg";
import TrSign from "../../assets/icon_transaction-reports.svg";
import PatientSign from "../../assets/patient.svg";


export default function Engagements() {
    const history = useHistory();
    const classes = useStyles();
    const [username, setUsername] = useState("");
    let token = JSON.parse(localStorage.getItem('key'));
    let decodedToken;
    const isLogged = useSelector(state => state.isLogged);
    // const financial = [
    //     {
    //         name: "Generate Billing Summary Report",
    //         path: '/reports/create/billingreport'
    //     },
    // ];
    // const transactions = [
    //     {
    //         name: "Generate Active Rental Report",
    //         path: '/reports/create/activerental'
    //     },
    // ];
    // const isLogged = useSelector(state => state.isLogged);
    // const [options, setOptions] = React.useState([]);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    //     if (event.target.name === 'financial') {
    //         setOptions(financial)
    //     } else if (event.target.name === 'transactions') {
    //         setOptions(transactions)
    //     }
    // };
    const items = [
        {
            xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
            title: 'Survey Responses',
            add: "All Responses",
            // view: "View All Orders",
            tile: FrSign,
            // recent: "Recent Orders",
            firstSign: PlusSign,
            addRoute: "/admin/surveyresponse",
            // viewRoute: "/reports"
        },
        {
            xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
            title: 'System Notifications',
            add: "Create Notification",
            view: "Send System Notifications",
            tile: TrSign,
            firstSign: PlusSign,
            addRoute: "/admin/system/notifications",
            viewRoute: "/admin/system/notifications/send"
        }
    ];
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    useEffect(() => {
        if (token !== null) {
            setUsername(decodedToken = jwt(token.accessToken).payload.first_name);
        }
    },[token]);
    return (
        <React.Fragment>
            {isLogged ? (
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.flexStart}>
                        <Typography className={classes.h1}>
                            Welcome {username}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"flex-start"} spacing={2}>
                            {items.map((value, index) => (
                                <Grid key={value} item lg={value.lg} md={value.md} sm={value.sm} xs={value.xs}>
                                    <div
                                        className={classes.box}
                                        style={{borderBottom: index % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2'}}>
                                        <div
                                            className={classes.background}
                                            style={{
                                                background: `transparent url(${value.tile}) 0% 0% no-repeat padding-box`,
                                                backgroundPosition: 'right bottom',
                                                height: '100%'
                                            }}>
                                            <Typography className={classes.h3}>
                                                {value.title}
                                            </Typography>
                                            <div className={classes.addRoute}
                                                 onClick={() => value.addRoute && history.push(value.addRoute)}>
                                                <img
                                                    src={value.firstSign}
                                                    alt="logo"
                                                    width='25'
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}
                                                />
                                                <Typography
                                                    className={classes.h4}
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}>
                                                    {value.add}
                                                </Typography>
                                            </div>
                                            {value.phone && (
                                                <div className={classes.phone}>
                                                    <Typography variant={"h3"} className={classes.phoneText}>
                                                        8:30 am - 5:30 pm CST
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.view && (
                                                <div className={classes.view}>
                                                    <img
                                                        src={ListSign}
                                                        alt="logo"
                                                        width='25'
                                                        className={classes.cursor}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    />
                                                    <Typography
                                                        className={classes.h4}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    >
                                                        {value.view}
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.email && (
                                                <React.Fragment>
                                                    <div className={classes.supportEmail}
                                                         onClick={() => window.location = `mailto:${value.email}`}>
                                                        <img
                                                            src={EmailSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.cursor}
                                                        />
                                                        <Typography
                                                            className={classes.emailText}>{value.email}</Typography>
                                                    </div>
                                                    <div
                                                        onClick={() => history.push('/support')}
                                                        className={classes.emailContainer}
                                                    >
                                                        <img
                                                            src={HelpSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.email}
                                                        />
                                                        <Typography className={classes.emailText}>
                                                            Visit Support Page
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {value.recent && (
                                                <Typography className={classes.recent}>
                                                    {value.recent}
                                                </Typography>
                                            )}
                                            {value.recent && (
                                                <div
                                                    className={classes.recentContainer}
                                                    onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                >
                                                    <Typography className={classes.recentItem}>
                                                        Delivery
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Pickup
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Exchange
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            ) : <Redirect to='/login'/>}
        </React.Fragment>
    )
}
const useStyles = makeStyles((theme) => (dashboardStyle(theme)));
//     return (
//         <React.Fragment>
//             {(isLogged) ? <div className='reports'>
//                 <Grid container style={{height: 500, maxWidth: 1200}} justify='center' alignContent='center'>
//                     <Grid item md={3} sm={6} xs={6}>
//                         <div
//                             // onClick={()=>{
//                             //     history.push('/patients/new')
//                             // }}
//                         >
//                             <img
//                                 onClick={handleClick}
//                                 name={'lorin'}
//                                 src={MetricsLogo} alt="" height='120'
//                                 style={{border: '1px solid lightgrey', padding: 12, cursor: 'pointer'}}/>
//                             <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
//                                 // className={classes.text}
//                             >Business Intelligence
//                             </div>
//                         </div>
//
//                     </Grid>
//                     <Grid item md={3} sm={6} xs={6}>
//                         <div
//                             // onClick={()=>{
//                             //     history.push('/patients/new')
//                             // }}
//                         >
//                             <img
//                                 name='financial'
//                                 onClick={handleClick}
//                                 src={FinanceLogo} alt="" height='120' width='160'
//                                 style={{border: '1px solid lightgrey', padding: 12, cursor: 'pointer'}}/>
//                             <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
//                                 // className={classes.text}
//                             >Financial Reports
//                             </div>
//                         </div>
//                     </Grid>
//                     <Grid item md={3} sm={6} xs={6}>
//                         <div
//                             // onClick={()=>{
//                             //     history.push('/patients/new')
//                             // }}
//                         >
//                             <img
//                                 name='transactions'
//                                 onClick={handleClick}
//                                 src={FileAnalysisLogo} alt="" height='120'
//                                 style={{border: '1px solid lightgrey', padding: 12, cursor: 'pointer'}}/>
//                             <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
//                                 // className={classes.text}
//                             >Transaction Reports
//                             </div>
//                         </div>
//                     </Grid>
//                     <Grid item md={3} sm={6} xs={6}>
//                         <div
//                             // onClick={()=>{
//                             //     history.push('/patients/new')
//                             // }}
//                         >
//                             <img src={DashboardLogo} alt="" height='120'
//                                  style={{border: '1px solid lightgrey', padding: 12, cursor: 'pointer'}}/>
//                             <div style={{fontSize: 16, padding: 12, cursor: 'pointer'}}
//                                 // className={classes.text}
//                             >Performance Reports
//                             </div>
//                         </div>
//                     </Grid>
//                     <StyledMenu
//                         id="customized-menu"
//                         anchorEl={anchorEl}
//                         keepMounted
//                         open={Boolean(anchorEl)}
//                         onClose={handleClose}
//                     >
//                         {options.map((option, i) => {
//                             return (
//                                 <StyledMenuItem
//                                     onClick={() => {
//                                         history.push(options[i].path)
//                                     }}
//                                 >
//                                     <ListItemText style={{paddingLeft: 10}} primary={options[i].name}/>
//                                 </StyledMenuItem>
//                             )
//                         })
//                         }
//
//                     </StyledMenu>
//                 </Grid>
//             </div> : ''}
//         </React.Fragment>
//     )
// }
//
//
// const StyledMenu = withStyles({
//     paper: {
//         border: '1px solid #d3d4d5',
//     },
// })((props) => (
//     <Menu
//         elevation={0}
//         getContentAnchorEl={null}
//         anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'center',
//         }}
//         transformOrigin={{
//             vertical: 'top',
//             horizontal: 'center',
//         }}
//         {...props}
//     />
// ));
//
// const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);