import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {DmeProviderRegionsStyle} from "./DmeProviderRegions.style";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types'
import Tabs from "@material-ui/core/Tabs";
import {AddCircleOutlined} from "@material-ui/icons";
import Tab from "@material-ui/core/Tab";
import {inputType} from "../../../../constants/types/inputType";
import _ from "lodash";
import {Region} from "./Region";
import {endpoints} from "../../../../constants/endpoints";
import {sectionTitles} from "../../../../constants/names/titles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Grid container>{children}</Grid>
                </Box>
            )}
        </div>
    );
}/**/
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};
const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

export const ProviderRegions = ({regionIdentifiers,regionList, handleInput, regionData, newRegionTeam, newRegionWarehouse, loading, updatingField, enableWarehouses}) => {

    const classes = useStyles();
    const [value, setValue] = useState(0)



    return <Grid container>
        <Grid item xs={12}>
            <Typography style={{display: "flex", fontSize: 20, fontWeight: 'bold'}}>
                {sectionTitles.regions}
            </Typography>
        </Grid>
        <Grid item xs={12} className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                name={"tableMovement"}
                value={value}
                onChange={(e, newValue) => {
                    const event = {
                        inputEventType: inputType.standard,
                        target: {
                            name: "tableMovement",
                            value: newValue,
                        }
                    };
                    setValue(newValue);
                    handleInput(event);
                }}
                className={classes.tabs}
            >
                {regionIdentifiers.length > 0 && regionIdentifiers.map((region, index) => {
                    return <Tab key={index} label={<div style={{width: '100%'}}>
                        <div style={{textAlign: 'left', fontWeight: 'bold'}}>{region.name}</div>
                        <div style={{
                            textAlign: 'left',
                            fontSize: 12,
                            marginTop: -8
                        }}>{region.region_id ? `ID: ${region.region_id}` : ""}</div>
                    </div>}  {...a11yProps(index)} disabled={loading}/>
                })}
                <Tab label="Add Region" icon={<AddCircleOutlined/>} {...a11yProps(regionIdentifiers.length)}
                     disabled={loading}/>
            </Tabs>
            <React.Fragment>
                {regionIdentifiers.length > 0 && regionIdentifiers.map((region, index) => {
                    return <TabPanel key={index} value={value} index={index}>
                        <Region
                            handleInput={handleInput}
                            regionData={regionData}
                            isNewRegion={false}
                            enableWarehouses={enableWarehouses}
                            regionList={regionList}
                            newRegionTeam={newRegionTeam}
                            newRegionWarehouse={newRegionWarehouse}
                            loading={updatingField}
                        />
                    </TabPanel>
                })}
                <TabPanel value={value} index={regionIdentifiers.length}>
                    <Region
                        handleInput={handleInput}
                        regionData={regionData}
                        isNewRegion={true}
                        enableWarehouses={enableWarehouses}
                        regionList={regionList}
                        newRegionTeam={newRegionTeam}
                        newRegionWarehouse={newRegionWarehouse}
                        value={value}
                        setValue={setValue}
                    />
                </TabPanel>
            </React.Fragment>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Grid>
    </Grid>
}

const useStyles = makeStyles((theme) => (DmeProviderRegionsStyle(theme)));