import React, {useEffect, useState} from 'react';
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import Grid from "@material-ui/core/Grid";

export default function MapContainer() {

    const [currentPosition, setCurrentPosition] = useState({});

    const defaultCenter = {
        lat: 41.3851, lng: 2.1734
    };

    const success = (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const currentPosition = {
            lat: latitude,
            lng: longitude
        };
        setCurrentPosition(currentPosition);
    };

    const mapStyles = () => {
        return {
            marginTop: "-20px",
            height: "100%",
            width: "100%"
        }
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success);
    });

    return (
        <React.Fragment>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{width: '600px', height: '600px', position: 'absolute'}}>
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey='AIzaSyB6xHfPLxTArJQQzUVAs2EV6CZG6UT9HCU'
                >
                    <GoogleMap
                        id='example-map'
                        mapContainerStyle={mapStyles()}
                        draggable={true}
                        zoom={14}
                        center={currentPosition.lat ? currentPosition : defaultCenter}
                    >
                    </GoogleMap>
                </LoadScript>
            </Grid>
        </React.Fragment>
    )
};