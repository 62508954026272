import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import {placeholders} from "../../../../constants/inputs/paceholders";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

export function Table({items, columns, order, setOrder, setSelectedItems}) {
    const [data, setData] = React.useState([]);
    useEffect(() => {
        setData(items)
    }, [items]);
    const setElements = (rows) => {
        setSelectedItems(rows);
    };
    return (
        <div className={'generaltable'} style={{paddingBottom: '20px'}}>
            <MaterialTable
                component={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>,
                    FilterRow: () => <tr />,
                    Pagination: props => <TablePagination {...props} style={{backgroundColor: "rgba(237, 242, 244, 0.73)", display:"flex"}} />,
                }}
                title={'Order Items:'}
                isLoading={false}
                localization={{
                    body: {
                        emptyDataSourceMessage: placeholders.empty_cart
                    }
                }}
                options={{
                    sorting:false,
                    showSelectAllCheckbox:false,
                    toolbar: false,
                    paging: false,
                    search: false,
                    headerStyle: {
                        backgroundColor: 'rgba(237, 242, 244, 0.73)',
                        color: '#2E3236',
                        fontSize:12,
                        fontWeight:'bold',
                        height: 20,
                        '&:hover': {color: '#FFF'}
                    },
                    selectionProps: {
                        color:'red',
                        '&$checked': {
                            color: '#3D70B2',
                        },
                    },
                    // selection: true

                }}
                onSelectionChange={(rows) => setElements(rows)}
                columns={columns}
                data={data}
            />
        </div>
    );
}