import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash";
import {endpoints} from "../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';

export default function ItemAuditTrail(props) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState([]);

    const handleClose = () => {
        setShowModal(false)
    };

    const getData = async (asset) => {
          const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.getItemAudit}${asset}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        let res = [];
        response.data.map(item => {
            if (item.exchange_order_id){
                if (item.delivery_order_id){
                    let clone = _.cloneDeep(item);
                    let clone2 = _.cloneDeep(item);
                    clone.exchange_order_id = null;
                    clone2.delivery_order_id = null;
                    res.push(clone);
                    res.push(clone2);
                } else if (item.pickup_order_id) {
                    let clone = _.cloneDeep(item);
                    let clone2 = _.cloneDeep(item);
                    clone.pickup_order_id = null;
                    clone2.exchange_order_id = null;
                    res.push(clone);
                    res.push(clone2);
                }
            } else if (item.pickup_order_id){
                let clone = _.cloneDeep(item);
                let clone2 = _.cloneDeep(item);
                clone.pickup_order_id = null;
                clone2.delivery_order_id = null;
                res.push(clone);
                res.push(clone2);
            } else {
                res.push(item);
            }
        });
        setValues(res);
        setLoading(false);
        console.log(response, 'res', props.asset)
    };
    useEffect(() => {
        if (values.length === 0 && showModal) {
            getData(props.asset);
        }
    }, [showModal]);

    useEffect(() => {
        if (values.length > 0 && showModal) {
            setLoading(false);
        }
    }, [values]);


    return (
        <React.Fragment>
            <Button variant='outlined' color='primary'
                    onClick={() => setShowModal(true)}
            >
               Audit Trail
            </Button>
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Audit Trail
                </DialogTitle>
                <DialogContent>
                    {!loading ? (
                            <React.Fragment>
                                <Grid container style={{minWidth:320}}>
                                    <Grid item xs={4}>
                                        <h4>Date</h4>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <h4>Action</h4>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <h4>Patient</h4>
                                    </Grid>
                                </Grid>
                                {values.map((value, key) => <React.Fragment key={key}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <h4>
                                                {value.delivery_completed && value.delivery_order_id && moment(value.delivery_completed).format("L")}
                                                {value.pickup_completed && value.pickup_order_id && moment(value.pickup_completed).format("L")}
                                                {value.exchange_completed && value.exchange_order_id && moment(value.delivery_completed).format("L")}
                                                {value.exchange_completed === null && value.delivery_completed === null && 'In progress'}
                                            </h4>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <h4>
                                                {value.delivery_order_id && "Delivery"}
                                                {value.pickup_order_id && "Pickup"}
                                                {value.exchange_order_id && "Exchange"}
                                            </h4>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <h4>
                                                {value.delivery_first_name && `${value.delivery_first_name} ${value.delivery_last_name}`}
                                                {value.pickup_first_name && `${value.pickup_first_name} ${value.pickup_last_name}`}
                                                {value.exchange_first_name && `${value.exchange_first_name} ${value.exchange_last_name}`}
                                            </h4>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)}
                            </React.Fragment>
                        ) :
                        <div style={{
                            height: 430,
                            width: '100vw',
                            maxWidth: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center'
                        }}>
                            <CircularProgress/>
                        </div>}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center'
    },
    closeButton: {
        position: 'absolute',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});