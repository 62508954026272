import React from "react";

export const inventoryStyle = {
    inventory: {
        marginTop:20,
        marginRight:'auto',
        marginLeft:'auto',
        paddingLeft:20,
        paddingRight:20,
        maxWidth:1200
    },
};