import React from "react";
import Grid from "@material-ui/core/Grid";
import {Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {sectionTitles} from "../../../constants/names/titles";


export const ExchangeItemSuggestions = ({suggestions, handleInput}) => {

    return (
        <Grid container justify={"center"} alignItems={"center"} spacing={1} direction={"column"} >
            <Typography variant={"subtitle2"}>{sectionTitles.itemSuggestions}</Typography>
            {
                suggestions.map((item, index) => <Grid container key={index} justify={"center"} alignItems={"center"} direction={"row"} spacing={1}>
                    <Grid item xs={3}>
                        <Checkbox
                            color={"primary"}
                            checked={item.checked}
                            name={"suggestion"}
                            onChange={(event) => {
                                const obj = {
                                    ...event,
                                    target: {
                                        ...event.target,
                                        name: "suggestion",
                                        index: index,
                                    }
                                };
                                handleInput(obj)
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant={"subtitle2"}>
                            {item.name}
                        </Typography>
                    </Grid>
                </Grid>)
            }
        </Grid>
    )
};