export const BulkStyle = (theme) => {
    return {
        button: {
            margin: theme.spacing(2),
            marginLeft:0,
            marginBottom:0,
            padding: '9px 18px'
        },
        selectInput: {
            paddingRight: theme.spacing(2),
            maxWidth:426
        },
        icon: {
            paddingRight: '5px',
            fontSize: '1.5em'
        },
        root: {
            height: '60px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '50px'
        },
        title: {
            fontWeight: "normal"
        },
        container: {
            padding: 24,
            paddingTop: 36
        }
    }
}
