import {snackbarMessages} from "../../constants/alert/alertMessages";

export const notifySnack = (enqueueSnackbar, statusCode, message, enableSuccess) => {
    switch (statusCode) {
        case 200:
            enableSuccess === true && (message ?
                enqueueSnackbar(message, {
                    persist: false,
                    variant: "success",
                })
                :
                enqueueSnackbar(snackbarMessages.actionCompletedSuccessfully, {
                    persist: false,
                    variant: "success",
                }));
            break;
        case 201:
            enableSuccess === true && (message ?
                enqueueSnackbar(message, {
                    persist: false,
                    variant: "success",
                })
                :
                enqueueSnackbar(snackbarMessages.resourceCreatedSuccessfully, {
                    persist: false,
                    variant: "success",
                }));
            break;
        case 204:
            enableSuccess === true && (message ?
                enqueueSnackbar(message, {
                    persist: false,
                    variant: "info",
                })
                :
                enqueueSnackbar(snackbarMessages.resourceDeletedSuccessfully, {
                    persist: false,
                    variant: "info",
                }));
            break;
        case 401:
            message ?
                enqueueSnackbar(message, {
                    persist: false,
                    variant: "info",
                })
                :
                enqueueSnackbar(snackbarMessages.contactSupport, {
                    persist: false,
                    variant: "info",
                });
            break;
        case 404:
            break;
        case 400:
        case 409:
            message ?
                enqueueSnackbar(message, {
                    persist: false,
                    variant: "warning",
                })
                :
                enqueueSnackbar(snackbarMessages.contactSupport, {
                    persist: false,
                    variant: "warning",
                });
            break;
        case 500:
            enqueueSnackbar(snackbarMessages.contactSupport, {
                persist: false,
                variant: "error",
            });
            break;
    }
};