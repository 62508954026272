import React, {useState} from "react";
import {Table} from './reviewTable/table';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import {OrderNotes} from "./orderNotes/orderNotes";
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import Select from 'react-select'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FileUpload from "../../../orders/fileUploadOrders/FileIpload";

export const OrderReview = ({items, exchangeItems, setExchangeCart, notes, setNotes, patientInventory, handleRemove, handleClearAll, handleInput, priority, preferredDate, notifyHospice, authorization_pace_no, setAuthorization_pace_no, showAuthorization, files, setFiles}) => {
    const columns = [
        {
            title: '', field: 'in_formulary', width: 20, sorting: false,
            render: rowData => <div>
                {rowData.type === 'PD' ?
                    <CheckCircleIcon style={{color: 'green'}}/> :
                    <LocalOfferIcon style={{color: 'red'}}/>
                }
            </div>
        },
        {title: 'Item Name', field: 'name', width: 600},
        {
            title: 'Qty', field: 'quantity', width: 260,
            render: rowData =>
                rowData.quantity ? <Grid container style={{marginLeft: "-50px"}}>
                <div style={{display: "flex", alignItems: 'center'}}>
                    <IconButton
                        onClick={() => {
                            if (rowData.quantity > 1) {
                                let orderClone = [...exchangeItems];
                                let index = orderClone.findIndex(i => i.inventory_item_id === rowData.inventory_item_id);
                                orderClone[index].quantity = (orderClone[index].quantity - 1);
                                setExchangeCart(orderClone)
                            }
                        }}
                    >
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                    <div style={{width: '50px'}}>
                        <TextField
                            id="outlined-number"
                            disabled={rowData.hasAsset}
                            value={rowData.quantity}
                            inputProps={{min: 0, style: {textAlign: 'center'}}}
                            variant="outlined"
                            size={'small'}
                            onChange={(e) => {
                                let orderClone = [...exchangeItems];
                                let index = orderClone.findIndex(i => i.inventory_item_id === rowData.inventory_item_id);
                                orderClone[index].quantity = (Number(e.target.value));
                                setExchangeCart(orderClone)
                            }}
                        />
                    </div>
                    {rowData.hasAsset ? <Tooltip title={'Duplicate item'}>
                        <IconButton
                            onClick={() => {
                                let orderClone = [...exchangeItems];
                                let index = orderClone.findIndex(i => i.inventory_item_id === rowData.inventory_item_id);
                                let clone = {...rowData};
                                orderClone.splice(index + 1, 0, clone)
                                setExchangeCart(orderClone)
                            }}
                        >
                            <ControlPointDuplicateIcon/>
                        </IconButton>
                    </Tooltip> : <Tooltip title={'increase quantity'}><IconButton
                        onClick={() => {
                            let orderClone = [...exchangeItems];
                            let index = orderClone.findIndex(i => i.inventory_item_id === rowData.inventory_item_id);
                            orderClone[index].quantity = (orderClone[index].quantity + 1);
                            setExchangeCart(orderClone)
                        }}
                    >
                        <AddCircleOutlineIcon/>
                    </IconButton></Tooltip>
                    }
                </div>
            </Grid> : 1
        },
        {
            title: 'Action', field: 'action', width: 320,
        },
        {
            title: 'Clinical Settings', field: 'clinical_input', width: 320,
            render: rowData => <div style={{textAlign: 'left'}}>
                {rowData.special_item_info && <div>
                    {rowData.special_item_info.min_range && (
                        <React.Fragment>
                            <div> {`Min. ${rowData.special_item_info.min_range}`} </div>
                            <div> {`Max. ${rowData.special_item_info.max_range}`} </div>
                        </React.Fragment>
                    )}
                    {rowData.special_item_info.isContinuous && (
                        <div> {`With continuous oxygen therapy`} </div>
                    )}
                    {rowData.special_item_info.humidifier_for_compressor && (
                        <div> {`Humidifier bottle: ${rowData.special_item_info.humidifier_for_compressor}%`} </div>
                    )}
                    {rowData.special_item_info.weight && (
                        <div> {`Patient Weight: ${rowData.special_item_info.weight}`} </div>
                    )}
                    {rowData.special_item_info.size_lift && (
                        <div> {`Size: ${rowData.special_item_info.size_lift}`} </div>
                    )}
                </div>}
            </div>
        },
        {
            title: 'Authorization', field: 'in_formulary', width: 20,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.approval ? (
                    <span className={'inactive_status'}> Yes </span>
                ) : (
                    <span className={'active_status'}> No </span>
                )}
            </div>
        },
        {
            title: 'Remove', field: 'remove', width: 20, sorting: false,
            render: rowData => <div>
                <Tooltip placement="top" title="Remove item">
                    <IconButton
                        onClick={(event) => {
                            const obj = {
                                ...event,
                                target:{
                                    value: rowData
                                }
                            };
                            handleRemove(obj);
                        }}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
    ];

    return (
        <div className={'order_container'}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
            <Grid container justify={"flex-start"} alignItems={"center"} style={{maxWidth: 1200}}>
                <Grid item xs={2}>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder={'Select...'}
                        value={priority !== '' && [{value:priority, label:priority}]}
                        options={[{label:"Routine", value:"Routine"},{label:"Priority", value:"Priority"}]}
                        onChange={(e)=> {
                            let obj = {target: {name:'priority_code',value:e.label}};
                            handleInput(obj)
                        }}
                    />
                </Grid>
                <Grid item>
                    <div className={'picker'}>
                        <div className={'date_label'}>Preferred date:</div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    placeholder={'MM/dd/yyyy'}
                                    value={preferredDate}
                                    onChange={(e)=>{
                                        let obj = {target: {name:'preferredDate',value:e}}
                                        handleInput(obj)
                                    }}
                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                {showAuthorization === true && (
                    <Grid item style={{display:'flex', flexDirection:'column', alignItems:'flex-start',paddingTop:16,}}>
                        <TextField
                            placeholder={'Authorization number'}
                            label={'Authorization number'}
                            variant={'outlined'}
                            size={'small'}
                            value={authorization_pace_no}
                            onChange={(e)=>{
                                setAuthorization_pace_no(e.target.value)
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Table columns={columns} items={exchangeItems} order={exchangeItems} setOrder={setExchangeCart} handleClearAll={handleClearAll} backGroundColor={'#F2F6F7'} textColor={"#2E3236"}/>
            <FileUpload files={files} setFiles={setFiles}/>
            <OrderNotes notes={notes} setNotes={setNotes} handleInput={handleInput} notifyHospice={notifyHospice}/>
        </div>
    )
};
