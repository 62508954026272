/*export const ONE_MINUTE = 60000;

// Show inactivity dialog after 10 minutes (expressed in ms)
export const INACTIVITY_ALERT_TIMEOUT = 10 * ONE_MINUTE;
// Allow user to spend 5 more minutes in the app, after original inactivity dialog message (expressed in ms)
export const INACTIVITY_EXTRA_TIMEOUT = 5 * ONE_MINUTE;*/

// Show inactivity dialog after 10 minutes (expressed in ms)
export const INACTIVITY_ALERT_TIMEOUT = 600000;
// Allow user to spend 5 more minutes in the app, after original inactivity dialog message (expressed in ms)
export const INACTIVITY_EXTRA_TIMEOUT = 60000;

