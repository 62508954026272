export const userMenu = () => {
    return {
        type: 'Clicked'
    }
};
export const appClick = () => {
    return {
        type: 'NotClicked'
    }
};
export const login = (name) => {
    return {
        type: 'login',
        name
    }
};
export const logout = () => {
    return {
        type: 'logout'
    }
};
export const addMedicalRecord = (name) => {
    return {
        type: 'addMedicalRecord',
        payload: name
    }
};
export const addFirstName = (name) => {
    return {
        type: 'addFirstName',
        payload: name
    }
};
export const addMiddleName = (name) => {
    return {
        type: 'addMiddleName',
        payload: name
    }
};
export const addLastName = (name) => {
    return {
        type: 'addLastName',
        payload: name
    }
};
export const addAddress1 = (name) => {
    return {
        type: 'addAddress1',
        payload: name
    }
};
export const addAddress2 = (name) => {
    return {
        type: 'addAddress2',
        payload: name
    }
};
export const addZip = (name) => {
    return {
        type: 'addZip',
        payload: name
    }
};
export const addCity = (name) => {
    return {
        type: 'addCity',
        payload: name
    }
};
export const addState = (name) => {
    return {
        type: 'addState',
        payload: name
    }
};
export const addPhone = (name) => {
    return {
        type: 'addPhone',
        payload: name
    }
};
export const addCareSetting = (name) => {
    return {
        type: 'addCareSetting',
        payload: name
    }
};
export const addTeam = (name) => {
    return {
        type: 'addTeam',
        payload: name
    }
};
export const addFacility = (name) => {
    return {
        type: 'addFacility',
        payload: name
    }
};
export const addBirthday = (name) => {
    return {
        type: 'addBirthday',
        payload: name
    }
};
export const addGender = (name) => {
    return {
        type: 'addGender',
        payload: name
    }
};
export const addWeight = (name) => {
    return {
        type: 'addWeight',
        payload: name
    }
};
export const addHeight1 = (name) => {
    return {
        type: 'addHeight1',
        payload: name
    }
};
export const addHeight2 = (name) => {
    return {
        type: 'addHeight2',
        payload: name
    }
};
export const addCaregiverFirstName = (name) => {
    return {
        type: 'addCaregiverFirstName',
        payload: name
    }
};
export const addCaregiverSecondFirstName = (name) => {
    return {
        type: 'addCaregiverSecondFirstName',
        payload: name
    }
};
export const addCaregiverFirstPhone = (name) => {
    return {
        type: 'addCaregiverFirstPhone',
        payload: name
    }
};
export const addCaregiverSecondPhone = (name) => {
    return {
        type: 'addCaregiverSecondPhone',
        payload: name
    }
};
export const addFirstCaregiverRelation = (name) => {
    return {
        type: 'addFirstCaregiverRelation',
        payload: name
    }
};
export const addSecondCaregiverRelation = (name) => {
    return {
        type: 'addSecondCaregiverRelation',
        payload: name
    }
};
export const addNote = (name) => {
    return {
        type: 'addNote',
        payload: name
    }
};
export const addDiasee = (name) => {
    return {
        type: 'addDiasee',
        payload: name
    }
};
export const resetState = () => {
    return {
        type: 'resetState'
    }
};
export const editPatient = (patient) => {
    return {
        type: 'editPatient',
        payload: patient
    }
};
export const editId = (id) => {
    return {
        type: 'editId',
        payload: id
    }
};
export const deleteId = (id) => {
    return {
        type: 'deleteId',
        payload: id
    }
};
export const setInputValue = (payload) => {
    return {
        type: 'setInputValue',
        payload: payload
    }
};
export const setCriteriaValue = (payload) => {
    return {
        type: 'setCriteriaValue',
        payload: payload
    }
};
export const setNurseValue = (payload) => {
    return {
        type: 'setNurseValue',
        payload: payload
    }
};
export const setTeamValue = (payload) => {
    return {
        type: 'setTeamValue',
        payload: payload
    }
};
export const setCareSettingValue = (payload) => {
    return {
        type: 'setCareSettingValue',
        payload: payload
    }
};
export const setInactiveValue = (payload) => {
    return {
        type: 'setInactiveValue',
        payload: payload
    }
};
export const setCriteriaDME = (payload) => {
    return {
        type: "setCriteriaDME",
        payload: payload
    }
};
export const setCriteriaHospice = (payload) => {
    return {
        type: "setCriteriaHospice",
        payload: payload
    }
};
export const setCriteriaDmeUsers = (payload) => {
    return {
        type: "setCriteriaDmeUsers",
        payload: payload
    }
};
export const setCriteriaHospiceUsers = (payload) => {
    return {
        type: "setCriteriaHospiceUsers",
        payload: payload
    }
};
export const setCriteriaOrders = (payload) => {
    return {
        type: "setCriteriaOrders",
        payload: payload
    }
};
export const setCriteriaPatients = (payload) => {
    return {
        type: "setCriteriaPatients",
        payload: payload
    }
};
