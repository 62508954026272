import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {getAllHospices} from "../../../utils/helpers/dataGetter";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../../constants/names/titles";
import MaterialTable from "material-table";
import CustomSelect from "./customSelect";

export default function HospiceTypes(props) {

    const columns = [
        {title: 'Hospice Name', field: 'hospice_name', width: 600, editable:'never', defaultSort:'asc'},
        {title: 'Type', field: 'is_organization_type', width: 200, sorting:false, headerStyle:{textAlign: 'center'},
            render: rowData => {
                return(
                    <CustomSelect rowData={rowData} updateView={updateView}/>
                )
            }
        },
    ];

    const [hospices, setHospices] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllHospices(setHospices)
    }, []);

    const updateView = () => {
        getAllHospices(setHospices)
    };

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} style={{paddingTop: 24}}>
                <Grid item>
                    <Typography variant={"h6"} style={{paddingBottom: 12}}>
                        {componentTitles.hospicesType}
                    </Typography>
                </Grid>
                <Grid item>
                    <MaterialTable
                        title={componentTitles.hospicesType}
                        columns={columns}
                        data={hospices}
                        options={{
                            pageSize: 10,
                        }}

                        />
                </Grid>
            </Grid>
        </React.Fragment>
    )
};