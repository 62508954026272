import {endpoints} from "../../constants/endpoints";

export const regionsSet = async (objSetter) => {
    const dataToken = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.regions, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + dataToken.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let regions = [];
    for (let value in data) {
        data[value].name !== "" && regions.push({value: data[value].name, label: data[value].name})
    }
    objSetter(regions);
};

export const getNurses = async (nurseSetter, nurseOptionsSetter, hospice_organization_id) => {
    const dataToken = JSON.parse(localStorage.getItem('key'));
    let promise = await fetch(endpoints.hospiceNurses + hospice_organization_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + dataToken.accessToken
        },
    });
    let response = await promise.json();
    const {data} = response;
    let nurses = [];
    for (let value in data) {
        data[value].name !== "" && nurses.push({value: data[value].nurse_id, label: data[value].name})
    }
    nurseOptionsSetter(nurses);
    nurseSetter(response.data);
};