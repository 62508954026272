export const featureEnablerStyle = (theme) => {
    return {
        root: {
            flexGrow: 1,
            // maxWidth: 1920,
            height: '90vh',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },

        },
        h1: {
            paddingTop: 58,
                textAlign: 'left',
                color: '#00223D',
                fontSize: 28,
                fontWeight: "bold",
                lineHeight: '38px',
                borderBottom: '1px solid #B2D1D9',
                marginBottom: 4,
                textTransform: "uppercase"
        },
        topPadding: {
            paddingTop: 16
        }
    }
}