import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {sectionTitles, strings} from "../../../constants/names/titles";
import {supportStyles} from "./Support.style";
import {SupportForm} from "./components/ContactForm";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import SupportSign from "../../../assets/doc_icon.svg";
import HelpBG from "../../../assets/help_background.svg";
import {makeStyles} from "@material-ui/core/styles";
import QuickGuide from "../../../assets/supportDocs/Quick Reference Guide - Caption App.pdf"
import AddNewPatientGuide from "../../../assets/supportDocs/Adding a New Patient.pdf"
import OrderingGuidelines from "../../../assets/supportDocs/DME Task Priorities and Timeframes.pdf"
import CreatingOrdersGuidelines from "../../../assets/supportDocs/Creating Orders - Delivery and Pickup.pdf"
import PatientSign from "../../../assets/patient.svg";
import PlusSign from "../../../assets/Icon_material-add-circle-outline.svg";
import PhoneSign from "../../../assets/icon_phone.svg";
import ListSign from "../../../assets/Icon_material-format-list-bulleted.svg";
import EmailSign from "../../../assets/e-mail.svg";
import HelpSign from "../../../assets/help.svg";
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import {useHistory} from "react-router-dom";


export const Support = () => {
    const classes = useStyles();
    const [step, setStep] = useState(1);
    const [sentEmail, setSentEmail] = useState(false);
    const history = useHistory();
    const [support, setSupport] = useState({
        name: "",
        email: "",
        phone: "",
        organization: "",
        issue: "",
    });

    const items = [
        {
            xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
            title: 'Contact Support',
            add: "(833) 467-3631",
            tile: SupportSign,
            phone: true,
            firstSign: PhoneSign,
            email: "support@caption.care"
        }]

    const handleInput = (event) => {
        setSupport({...support, [event.target.name]: event.target.value});
    };
    const handleSubmit = () => {
        fetch(endpoints.supportEmail, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: support})
        }).then(res => {
            setStep(step + 1);
            if (res.status === status.success) {
                setSentEmail(true);
            } else {
                setSentEmail(false);
            }
        })
    };

    return (
        <Grid container className={classes.rootContainer}>
                <Grid container justify={"space-evenly"} className={classes.root} spacing={2}>
                    <Grid item lg={12} sm={10} xs={12} className={classes.flexStart}>
                        <Typography className={classes.h1}>
                            Contact Support
                        </Typography>
                    </Grid>
                    <Grid item className={classes.columnComponent}
                          lg={5} sm={10} xs={12}
                    >
                        <Grid item>

                        </Grid>
                        <Grid item className={classes.titleContainer}>
                            {step === 1 ?
                                <React.Fragment>
                                    <Typography variant={"h6"} className={classes.title}>
                                        {sectionTitles.sendMessage}
                                    </Typography>
                                    <SupportForm handleInput={handleInput}
                                                 handleSubmit={handleSubmit}
                                                 values={support}
                                    />
                                </React.Fragment>
                             : <Typography>
                            {sentEmail === true ? (
                                <div style={{width: '100%',height: 600, display:'flex',flexDirection: "column", justifyContent: 'center', alignItems: 'center', padding: 20, gap: 12}}>
                                    <MessageOutlinedIcon style={{fontSize: 96, color: '#4F86A0'}}/>
                                    <Typography style={{textAlign: 'center',paddingTop: 8, fontWeight: 700, fontSize: 20,color: '#00223D'}}>Thank you for contacting our support team.</Typography>
                                    <Typography style={{textAlign: 'center',color: "#4B5563"}}>Your message has been sent and a member of our support team will be in touch with you within the next business day.</Typography>
                                    <Typography style={{textAlign: 'center',color: "#4B5563"}}>Additionally you should be receiving a confirmation email from us within the next few minutes.  Please check your spam and junk mail if you do not receive the confirmation. </Typography>
                                </div>
                            ) : strings.emailNotSent}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                    <Grid item
                          lg={7} sm={10} xs={12}
                    >
                        <div className={classes.border}>
                            <Grid item className={classes.gridContainer}>
                                <Typography variant={"h6"} className={classes.title}>
                                    {sectionTitles.supportDocuments}
                                </Typography>
                            </Grid>
                            <Grid container
                                  justify={"center"}
                                  alignItems={"flex-start"}
                                  direction={"column"}
                                  className={classes.container}
                            >
                                <div
                                    style={{
                                        background: `transparent url(${HelpBG}) 0% 0% no-repeat padding-box`,
                                        backgroundPosition: 'right bottom',
                                        height: '100%',
                                        width: '100%',
                                    }}>
                                    <Grid item>
                                        <div className={classes.url}>
                                            <a href={CreatingOrdersGuidelines}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                                            >
                                                <img
                                                    src={SupportSign}
                                                    alt="logo"
                                                    width='24'
                                                    className={classes.cursor}
                                                    style={{marginRight: 22}}
                                                />
                                                <Typography variant={"h6"}
                                                            className={classes.links}
                                                >
                                                    {strings.helpDoc1}
                                                </Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.url2}>
                                            <a href={AddNewPatientGuide}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                                            >
                                                <img
                                                    src={SupportSign}
                                                    alt="logo"
                                                    width='24'
                                                    className={classes.cursor}
                                                    style={{marginRight: 22}}
                                                />
                                                <Typography variant={"h6"}
                                                            className={classes.links}
                                                >
                                                    {strings.helpDoc2}
                                                </Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.url2}>
                                            <a href={OrderingGuidelines}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                                            >
                                                <img
                                                    src={SupportSign}
                                                    alt="logo"
                                                    width='24'
                                                    className={classes.cursor}
                                                    style={{marginRight: 22}}
                                                />
                                                <Typography variant={"h6"}
                                                            className={classes.links}
                                                >
                                                    {strings.helpDoc3}
                                                </Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.url2}>
                                            <a href={QuickGuide}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}
                                            >
                                                <img
                                                    src={SupportSign}
                                                    alt="logo"
                                                    width='24'
                                                    className={classes.cursor}
                                                    style={{marginRight: 22}}
                                                />
                                                <Typography variant={"h6"}
                                                            className={classes.links}
                                                >
                                                    {strings.helpDoc4}
                                                </Typography>
                                            </a>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"flex-start"} spacing={2}>
                            {items.map((value, index) => (
                                <Grid key={value} item lg={value.lg} md={value.md} sm={value.sm} xs={value.xs}>
                                    <div
                                        className={classes.box}
                                        style={{borderBottom: index % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2'}}>
                                        <div
                                            className={classes.background}
                                            // style={{
                                            //     background: `transparent url(${value.tile}) 0% 0% no-repeat padding-box`,
                                            //     backgroundPosition: 'right bottom',
                                            //     height: '100%'
                                            // }}
                                        >
                                            <Typography className={classes.h3}>
                                                {value.title}
                                            </Typography>
                                            {value.add !== null && <div className={classes.addRoute}
                                                                        onClick={() => value.addRoute && history.push(value.addRoute)}>
                                                <img
                                                    src={value.firstSign}
                                                    alt="logo"
                                                    width='25'
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}
                                                />
                                                <Typography
                                                    className={classes.h4}
                                                    style={{cursor: value.addRoute ? 'pointer' : 'default'}}>
                                                    {value.add}
                                                </Typography>
                                            </div>}
                                            {value.phone && (
                                                <div className={classes.phone}>
                                                    <Typography variant={"h3"} className={classes.phoneText}>
                                                        8:30 am - 5:30 pm CST
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.view && (
                                                <div className={classes.view} style={{cursor:'pointer'}}>
                                                    <img
                                                        src={ListSign}
                                                        alt="logo"
                                                        width='25'
                                                        className={classes.cursor}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    />
                                                    <Typography
                                                        className={classes.h4}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    >
                                                        {value.view}
                                                    </Typography>
                                                </div>
                                            )}
                                            {value.email && (
                                                <React.Fragment>
                                                    <div className={classes.supportEmail}
                                                         onClick={() => window.location = `mailto:${value.email}`}>
                                                        <img
                                                            src={EmailSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.cursor}
                                                        />
                                                        <Typography
                                                            className={classes.emailText}>{value.email}</Typography>
                                                    </div>
                                                    <div
                                                        onClick={() => history.push('/support')}
                                                        className={classes.emailContainer}
                                                    >
                                                        <img
                                                            src={HelpSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.email}
                                                        />
                                                        <Typography className={classes.emailText}>
                                                            Visit Support Page
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {value.recent && (
                                                <Typography className={classes.recent}>
                                                    {value.recent}
                                                </Typography>
                                            )}
                                            {value.recent && (
                                                <div
                                                    className={classes.recentContainer}
                                                    onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                >
                                                    <Typography className={classes.recentItem}>
                                                        Delivery
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Pickup
                                                    </Typography>
                                                    <Typography className={classes.recentSpacing}>
                                                        Exchange
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    )
};

const useStyles = makeStyles((theme) => (supportStyles(theme)));

