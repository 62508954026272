import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import {userTypes} from "../../../constants/types/userTypes";

export const getAllDomains = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.restrictedDomains, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting the domains!");
    }
};
export const getAllManufacturers = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getManufacturers, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();

    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting the manufacturers!");
    }
};
export const getAllSurveys = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getALLSurvey, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();

    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all the surveys!");
    }
};
export const getAllNurses = async (objSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.nurses, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const response = await promise.json();

    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting the nurse!");
    }
};
export const getAllWarehouses = async (objSetter, id, setWarehouse) => {
    if (id) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.getAllDmeWarehouses + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();

        if (response.status === endpointStatus.success) {
            objSetter(response.data);
            if (response.data.length === 1) {
                setWarehouse([response.data[0].warehouse_id])
            }
        } else {
            console.log("[Error]: Error encountered while getting the warehouses!");
        }
    }
};
export const getAllAuditTrail = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAuditTrail, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();

    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting the audit trail!");
    }
};
export const getAllHospices = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllHospiceIds, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospices!");
    }
};
export const getAllDmes = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllDmeIds, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all dmes!");
    }
};
export const getAllTechnicians = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllTechniciansAdmin, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all technicians!");
    }
};
export const getAllFeatuers = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.allOrganizationFeatures, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all features!");
    }
};
export const getAllHospiceRoles = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(`${endpoints.getAllHospiceRoles}/${userTypes.hospice}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospice roles!");
    }
};
export const getAllPortalRoles = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllPortalRoles, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospice roles!");
    }
};
export const getAllDmeRoles = async (objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllDmeRoles, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospice roles!");
    }
};
export const getUsersByOrgAndRoles = async (type, hospice_id,dme_id, role_id, objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));

    const promise = await fetch(endpoints.getAllUsersBasedOnOrgAndRole, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        },
        body: JSON.stringify({
            type:type,
            dme_id:dme_id,
            hospice_id:hospice_id,
            role_id:role_id
        })
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospice roles!");
    }
};

export const getAuditData = async (user_id, start_date, end_date, objSetter) => {
    const data = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.getUserAuditById, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + data.accessToken
        },
        body: JSON.stringify({
            user_id:user_id,
            start_date:start_date,
            end_date:`${end_date} 23:59:59`,
        })
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(response.data);
    } else {
        console.log("[Error]: Error encountered while getting all hospice roles!");
    }
};
