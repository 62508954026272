import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Table} from "../../../../common/table/table";
import PatientsHeader from "../../../patients/view-all-patients/patientsHeader/patientsHeader";
import {endpoints} from "../../../../constants/endpoints";
import {routes} from "../../../../constants/routes/routes";
import {setCriteriaHospice} from "../../../../redux/actions";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {useHistory} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {setStates} from "../../../../common/search/tabSearch/TabSearchHelper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import PatientDays from "./patientDays/patientDays";
import {useSnackbar} from "notistack";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";


export default function HospiceTab() {
    let history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [hospice_organizations, setHospice_Organizations] = useState([]);
    const [hospiceDetails, setHospiceDetails] = useState({});
    const dispatch = useDispatch();
    const searchValues = useSelector(state => state.SearchCriteriaHospice);
    const [stringStatus, setStringStatus] = useState('');
    const [profitStatus, setProfitStatus] = useState('');
    const [hospiceFiltersValue, setHospiceFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        state: "All",
        isActiveHospice: true,
        isProfitHospice: true,
    });

    const [stateOptions, setStateOptions] = useState([]);

    const filterOptions = [
        {
            name: "states",
            placeholder: "State",
            options: stateOptions,
            value: hospiceFiltersValue.states,
            isSelect: true
        },
        {
            name: "isActiveHospice",
            value: hospiceFiltersValue.isActiveHospice,
            positive: "Active Hospices",
            negative: "Inactive Hospices"
        },
        {
            name: "isProfitHospice",
            value: hospiceFiltersValue.isProfitHospice,
            positive: "Profit Hospices",
            negative: "Non-Profit Hospices"
        },
    ];

    const handleDeleteCriteria = (chip) => {
        setHospiceFiltersValue({
            ...hospiceFiltersValue,
            criteria: hospiceFiltersValue.criteria.filter(c => chip !== c)
        });
    };

    function handleFilterUpdate(event) {
        setHospiceFiltersValue({...hospiceFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && hospiceFiltersValue.input) {
            if (!hospiceFiltersValue.criteria.includes(hospiceFiltersValue.input)) {
                if (hospiceFiltersValue.criteria[0] === ('All')) {
                    setHospiceFiltersValue({...hospiceFiltersValue, criteria: [hospiceFiltersValue.input], input: ''})
                    // searchHospice(hospiceFiltersValue,setHospice_Organizations, isLoading);
                } else {
                    setHospiceFiltersValue({
                        ...hospiceFiltersValue,
                        criteria: [...hospiceFiltersValue.criteria, hospiceFiltersValue.input],
                        input: ''
                    })
                    // searchHospice(hospiceFiltersValue,setHospice_Organizations, isLoading);
                }
            }
        }
    };
    const handleInputChange = (value) => {
        setHospiceFiltersValue({...hospiceFiltersValue, input: value})
    };

    const clearFilters = () => {
        setHospiceFiltersValue({
            input: '',
            criteria: ['All'],
            state: "All",
            isActiveHospice: true,
            isProfitHospice: true,
        });
        dispatch(setCriteriaHospice(hospiceFiltersValue));

    };
    useEffect(() => {
        dispatch(setCriteriaHospice(hospiceFiltersValue))
        // searchHospice(hospiceFiltersValue,setHospice_Organizations, isLoading);
    }, [hospiceFiltersValue]);

    useEffect(() => {
        if (hospiceFiltersValue.isActiveHospice === true) {
            setStringStatus('true')
        } else if (hospiceFiltersValue.isActiveHospice === false) {
            setStringStatus('false')
        }
        if (hospiceFiltersValue.isProfitHospice === true) {
            setProfitStatus('true')
        } else if (hospiceFiltersValue.isProfitHospice === false) {
            setProfitStatus('false')
        }
    }, [hospiceFiltersValue.isActiveHospice, hospiceFiltersValue.isProfitHospice,]);

    function getHospiceOrganizations() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.all_hospice_organizations, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = [];
                response.data.map(hospice => {
                    if (hospice.status === true){
                        hospice.stringStatus = 'true'
                    } else if(hospice.status === false) {
                        hospice.stringStatus = 'false'
                    }
                    if (hospice.profit === true){
                        hospice.profitStatus = 'true'
                    } else if(hospice.profit === false) {
                        hospice.profitStatus = 'false'
                    }
                    arr.push(hospice);
                });
                setHospice_Organizations(arr);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    }

    useEffect(() => {
        getHospiceOrganizations();
        setStates(setStateOptions);
        // searchHospice(hospiceFiltersValue,setHospice_Organizations, isLoading);
    }, []);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = [
        {
            title: 'Edit', field: 'hospice_organization_id', width: 80,
            render: rowData => <div>
                <Tooltip placement="top" title="Edit provider">
                    <IconButton
                        onClick={() => {
                            history.push({
                                pathname: `${routes.hospice_organization_edit}${rowData.hospice_organization_id}`,
                                name: rowData.hospice_organization_id
                            })
                        }}
                    >
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
        {title: 'Name', field: 'hospice_name', width: 220, defaultFilter:hospiceFiltersValue.input},
        {title: tableColumnTitles.organization, field: 'state', hidden:true,  defaultFilter:hospiceFiltersValue.states},
        {title: tableColumnTitles.organization, field: 'stringStatus', hidden:true,  defaultFilter:stringStatus},
        {title: tableColumnTitles.organization, field: 'profitStatus', hidden:true,  defaultFilter:profitStatus},
        {
            title: 'Address', field: 'address1', width: 238,
            render: rowData => <div>
                <div>{rowData.address1 + ' ' + rowData.address2}</div>
                <div>{rowData.city + ' ' + rowData.state + ' ' + rowData.zip}</div>
            </div>
        },
        {title: 'Phone Number', field: 'phone', width: 140},
        {
            title: 'Status', field: 'status', width: 100,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.status ? (
                    <span className={'active_status'}> Active </span>
                ) : (
                    <span className={'inactive_status'}> Inactive </span>
                )}
            </div>
        },
        {
            title: 'DME Providers', field: 'dmes', width: 220,
            render: rowData => <div>{rowData.dmes && rowData.dmes.map((org, index) => (
                <div className={'organization_row'} key={index}>
                    {org.dme_name}
                </div>
            ))}
            </div>
        },
        {
            title: 'Actions', field: 'hospice_organization_id', width: 200,
            render: rowData => <div>
                <Tooltip placement="top" title="Add Patient Days">
                    <Button variant='outlined' onClick={() => {
                        handleClickOpen();
                        setHospiceDetails(rowData)
                    }}>
                        End of month
                    </Button>
                </Tooltip>
            </div>
        },
    ];

    return (
        <React.Fragment>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center">
                <Grid item xs={10}>
                    <PatientsHeader buttonText={'Hospice Organization'}
                                    path={'/hospice/new'}
                                    title={'Hospice Organizations'}
                    />
                </Grid>
                <Grid item>
                    <TabSearchBar
                        filtersValue={hospiceFiltersValue}
                        handleDeleteCriteria={handleDeleteCriteria}
                        handleInputKeyup={handleInputKeyup}
                        handleInputChange={handleInputChange}
                        clearFilters={clearFilters}
                        filterOptions={filterOptions}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Grid>
                <Table columns={columns} items={hospice_organizations}/>
                <Dialog
                    style={{margin: 0, padding: 0}}
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth={'lg'}
                >
                    <PatientDays
                        hospiceDetails={hospiceDetails}
                        setOpen={setOpen}
                    />
                </Dialog>
            </Grid>
        </React.Fragment>
    );
}