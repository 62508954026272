import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {ItemElement} from "../../../common/addItem/AddItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {AlertDialog} from "../../../common/alerts/generalAlert/generalAlert";
import cancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {itemMaintenanceOptions} from "../../../constants/inputs/values";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {alertMessages} from "../../../constants/alert/alertMessages";
import {alertTitles} from "../../../constants/alert/alertTitle";
import {buttonTypes} from "../../../constants/alert/buttonTypes";
import {validators} from "../../../constants/validations/validators";
import {endpoints} from "../../../constants/endpoints";
import _ from "lodash";
import {
    getManufacturerData,
    getWarehousesData,
    getDuplicateItems,
    getManufacturerOptions,
    getItemOptions
} from "./adderHelper";
import {status} from "../../../constants/status/status";
import {sectionTitles} from "../../../constants/names/titles";
import jwt from "jwt-decode";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

export const ItemizedAdder = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
    const [validationError, setValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState([]);
    const [duplicates, setDuplicates] = useState([]);
    const [allManufacturers, setAllManufacturers] = useState([]);
    const [allWarehouses, setAllWarehouses] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [completionMessage, setCompletionMessage] = useState("");
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [itemOptions, setItemOptions] = useState([]);
    //fields
    const [item, setItem] = useState([""]);
    const [warehouse, setWarehouse] = useState([""]);
    const [manufacturer, setManufacturer] = useState([""]);
    const [asset, setAsset] = useState([""]);
    const [serial_id, setSerial] = useState([""]);
    const [lot, setLot] = useState([""]);
    const [price, setPrice] = useState([""]);
    const [maintenance, setMaintenance] = useState([""]);
    //validators
    const [validAsset, setValidAsset] = useState([true]);
    const [validSerial, setValidSerial] = useState([true]);
    const [validLot, setValidLot] = useState([true]);
    const [validPrice, setValidPrice] = useState([true]);
    const [validItem, setValidItem] = useState([false]);
    const [validWarehouse, setValidWarehouse] = useState([false]);

    const dummyOptions = [
        {value: "Warehouse 1", label: "Warehouse 1"},
        {value: "Warehouse 2", label: "Warehouse 2"},
        {value: "Warehouse 3", label: "Warehouse 3"},
    ]

    const fields = [
        {type: "select", options: itemOptions, name: "item", label: "Item Name", value: item, validator: validItem, isMandatory: true, tooltip: "This Field is Mandatory!"},
        {type: "select", options: warehouseOptions, name: "warehouse", label: "Warehouse", value: warehouse, validator: validWarehouse, isMandatory: true, tooltip: "This Field is Mandatory!"},
        {type: "select", options: manufacturerOptions, name: "manufacturer", label: "Manufacturer", value: manufacturer},
        {type: "input", name: "asset", label: "Asset #", value: asset, validator: validAsset},
        {type: "input", name: "serial", label: "Serial #", value: serial_id, validator: validSerial},
        // {type: "input", name: "lot", label: "Lot #", value: lot, validator: validLot},
        {type: "input", name: "price", label: "Price", value: price, specialCharacter: "$", validator: validPrice},
        {type: "select", options: itemMaintenanceOptions, name: "maintenance", label: "Maintenance", value: maintenance},
    ];

    const handleInput = (event, index) => {
        const {name, value} = event.target;
        let newValidator
        switch (name) {
            case "item":
                let newItem = [...item];
                let newValidItem = [...validItem];
                newItem[index] = value;
                newValidItem[index] = true;
                setItem(newItem);
                setValidItem(newValidItem);
                break;
            case "warehouse":
                let newWarehouse = [...warehouse];
                let newValidWarehouse = [...validWarehouse];
                newWarehouse[index] = value;
                newValidWarehouse[index] = true;
                setWarehouse(newWarehouse);
                setValidWarehouse(newValidWarehouse);
                break;
            case "manufacturer":
                let newManufacturer = [...manufacturer];
                newManufacturer[index] = value;
                setManufacturer(newManufacturer);
                break;
            case "asset":
                let newAsset = [...asset];
                newValidator = [...validAsset];
                newAsset[index] = value;
                newValidator[index] = value !== "" ? validators.regularValidator.test(value) && (_.findIndex(duplicates, {"asset": value}) === -1) : true;
                setAsset(newAsset);
                setValidAsset(newValidator);
                break;
            /*case "lot":
                let newLot = [...lot];
                newValidator = [...validLot];
                newLot[index] = value;
                newValidator[index] = value !== "" ? validators.regularValidator.test(value) : true;
                setLot(newLot);
                setValidLot(newValidator);
                break;*/
            case "price":
                let newPrice = [...price];
                newValidator = [...validPrice];
                newPrice[index] = value;
                newValidator[index] = value !== "" ? validators.priceValidator.test(value) : true;
                setPrice(newPrice);
                setValidPrice(newValidator);
                break;
            case "serial":
                let newSerial = [...serial_id];
                newValidator = [...validSerial];
                newSerial[index] = value;
                newValidator[index] = value !== "" ? validators.regularValidator.test(value) && (_.findIndex(duplicates, {"serial_id": value}) === -1) : true;
                setSerial(newSerial);
                setValidSerial(newValidator);
                break;
            case "maintenance":
                let newMaintenance = [...maintenance];
                newMaintenance[index] = value;
                setMaintenance(newMaintenance);
                break;
            default:
                console.log(`[Warning]: Input type --> ${name} <-- not handled!`);
        }
    }

    const addNewItem = (index, duplicate) => {

        const insertUnderIndex = index + 1;

        let newItem = [...item];
        let newWarehouse = [...warehouse];
        let newManufacturer = [...manufacturer];
        let newAsset = [...asset];
        let newSerial = [...serial_id];
        //let newLot = [...lot];
        let newPrice = [...price];
        let newMaintenance = [...maintenance];

        let newValidItem = [...validItem];
        let newValidWarehouse = [...validWarehouse];
        let newValidAsset = [...validAsset];
        let newValidSerial = [...validSerial];
        //let newValidLot = [...validLot];
        let newValidPrice = [...validPrice];

        if (duplicate) {
            //item fields
            newItem.splice(insertUnderIndex, 0, newItem[index]);
            newWarehouse.splice(insertUnderIndex, 0, newWarehouse[index]);
            newManufacturer.splice(insertUnderIndex, 0, newManufacturer[index]);
            // newAsset.splice(insertUnderIndex, 0, newAsset[index] ? (parseInt(newAsset[index]) + 1).toString() : "");
            newAsset.splice(insertUnderIndex, 0, newAsset[index]);
            newSerial.splice(insertUnderIndex, 0, "");
            //newLot.splice(insertUnderIndex, 0, newLot[index]);
            newPrice.splice(insertUnderIndex, 0, newPrice[index]);
            newMaintenance.splice(insertUnderIndex, 0, newMaintenance[index]);

            //validators
            newValidItem.splice(insertUnderIndex, 0, newValidItem[index]);
            newValidWarehouse.splice(insertUnderIndex, 0, newValidWarehouse[index]);
            newValidAsset.splice(insertUnderIndex, 0, newValidAsset[index]);
            newValidSerial.splice(insertUnderIndex, 0, true);
            //newValidLot.splice(insertUnderIndex, 0, newValidLot[index]);
            newValidPrice.splice(insertUnderIndex, 0, newValidPrice[index]);
        } else {
            //item fields
            newItem.splice(insertUnderIndex, 0, "");
            newWarehouse.splice(insertUnderIndex, 0, "");
            newManufacturer.splice(insertUnderIndex, 0, "");
            newAsset.splice(insertUnderIndex, 0, "");
            newSerial.splice(insertUnderIndex, 0, "");
            //newLot.splice(insertUnderIndex, 0, "");
            newPrice.splice(insertUnderIndex, 0, "");
            newMaintenance.splice(insertUnderIndex, 0, "");

            //validators
            newValidItem.splice(insertUnderIndex, 0, false);
            newValidWarehouse.splice(insertUnderIndex, 0, false);
            newValidAsset.splice(insertUnderIndex, 0, true);
            newValidSerial.splice(insertUnderIndex, 0, true);
            //newValidLot.splice(insertUnderIndex, 0, true);
            newValidPrice.splice(insertUnderIndex, 0, true);
        }

        setItem(newItem);
        setWarehouse(newWarehouse);
        setManufacturer(newManufacturer);
        setAsset(newAsset);
        setSerial(newSerial);
        //setLot(newLot);
        setPrice(newPrice);
        setMaintenance(newMaintenance);

        setValidItem(newValidItem);
        setValidWarehouse(newValidWarehouse);
        //setValidLot(newValidLot);
        setValidSerial(newValidSerial);
        setValidPrice(newValidPrice);
        setValidAsset(newValidAsset);
    }

    const removeItem = (index) => {
        let newItem = [...item];
        let newWarehouse = [...warehouse];
        let newManufacturer = [...manufacturer];
        let newAsset = [...asset];
        let newSerial = [...serial_id];
        //let newLot = [...lot];
        let newPrice = [...price];
        let newMaintenance = [...maintenance];

        let newValidItem = [...validItem];
        let newValidWarehouse = [...validWarehouse];
        let newValidAsset = [...validAsset];
        let newValidSerial = [...validSerial];
        //let newValidLot = [...validLot];
        let newValidPrice = [...validPrice];

        newItem.splice(index, 1);
        newWarehouse.splice(index, 1);
        newManufacturer.splice(index, 1);
        newAsset.splice(index, 1);
        newSerial.splice(index, 1);
        //newLot.splice(index, 1);
        newPrice.splice(index, 1);
        newMaintenance.splice(index, 1);

        newValidItem.splice(index, 1);
        newValidWarehouse.splice(index, 1);
        newValidAsset.splice(index, 1);
        newValidSerial.splice(index, 1);
        //newValidLot.splice(index, 1);
        newValidPrice.splice(index, 1);

        if (index === 0 && item.length === 1) {
            newItem.push("");
            newWarehouse.push("");
            newManufacturer.push("");
            newAsset.push("");
            newSerial.push("");
            //newLot.push("");
            newPrice.push("");
            newMaintenance.push("");

            newValidWarehouse.push(false);
            newValidItem.push(false);
            //newValidLot.push(true);
            newValidAsset.push(true);
            newValidPrice.push(true);
            newValidSerial.push(true);
        }

        setItem(newItem);
        setWarehouse(newWarehouse);
        setManufacturer(newManufacturer);
        setAsset(newAsset);
        setSerial(newSerial);
        //setLot(newLot);
        setPrice(newPrice);
        setMaintenance(newMaintenance);

        setValidItem(newValidItem);
        setValidWarehouse(newValidWarehouse);
        //setValidLot(newValidLot);
        setValidSerial(newValidSerial);
        setValidPrice(newValidPrice);
        setValidAsset(newValidAsset);

    }

    const checkUploadStatus = () => {
        let status = true;
        for (let index = 0; index < item.length; index++) {
            if (validItem[index] === false
                || validWarehouse[index] === false
                || validSerial[index] === false
                || validPrice [index] === false
                //|| validLot[index] === false
                || validAsset [index] === false
            ) {

                status = false;
            }
        }
        if (status === true) {
            setValidationErrorMessage([alertMessages.validItems]);
        } else {
            setValidationErrorMessage([alertMessages.invalidItems]);
        }
        setValidationError(status);
    }

    const uploadInventory = () => {
        let items = [];
        let index;
        let wIndex;
        let mIndex;
        for (index = 0; index < item.length; index++) {
            wIndex = _.findIndex(allWarehouses,{"name": warehouse[index]}) // This should point to the warehouse id here warehouse[index] was here
            mIndex = _.findIndex(allManufacturers,{"name": manufacturer[index]})

            items.push({
                item_name: item[index],
                warehouse_id: allWarehouses[wIndex].warehouse_id.toString(),
                manufacturer_id: allManufacturers[mIndex].manufacturer_id.toString(),
                asset: asset[index],
                serial_id: serial_id[index],
                ///lot: lot[index],
                price: price[index],
                maintenance: maintenance[index],
                status: true,
                dme_organization_id: userData.dme_organization_id,
            })
        }
        const body = {};
        body["items"]=items;
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addInventory,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(body),
        }).then(res => {
            if(res.status === status.created) {
                setCompletionMessage("Inventory Updated");
            }
            else {
                setCompletionMessage("An error occurred");
            }
        })
    }

    const clearAll = () => {
        setItem([""]);
        setWarehouse([""]);
        setManufacturer([""]);
        setAsset([""]);
        setSerial([""]);
        //setLot([""]);
        setPrice([""]);
        setMaintenance([""]);
        setValidWarehouse([false]);
        setValidItem([false]);
        //setValidLot([true]);
        setValidAsset([true]);
        setValidPrice([true]);
        setValidSerial([true]);
    }

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        getDuplicateItems(setDuplicates);
        getManufacturerData(setAllManufacturers);
        getManufacturerOptions(setManufacturerOptions);
        getItemOptions(setItemOptions);
        getWarehousesData(setWarehouseOptions, setAllWarehouses);

    },[])

    useEffect(() => {
        checkUploadStatus();
    }, [validItem, validWarehouse, validAsset, /*validLot,*/ validPrice, validSerial])

    return (
        <React.Fragment>
            <main className={classes.layout}>
                {completionMessage === "" ?
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                        {sectionTitles.addNewItems}
                    </Typography>
                    <Grid container spacing={1} direction="column">
                        {item.map((itemValues, index) =>
                            <Grid item key={index} style={{zIndex: 1000 - index}}>
                                <Typography>
                                    {"Item " + (index + 1)}
                                </Typography>
                                <ItemElement fields={fields}
                                             index={index}
                                             handleInput={handleInput}
                                             addNewItem={addNewItem}
                                             removeItem={removeItem}
                                             allItems={item}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container justify="center">
                        <AlertDialog
                            title={alertTitles.warn}
                            denyActionText={buttonText.negative}
                            confirmActionText={buttonText.proceed}
                            content={alertMessages.clearWarning}
                            confirmAction={clearAll}
                            buttonText={buttonText.clearItems}
                            type={buttonTypes.deleteIcon}
                            disabledButton={false}
                        />
                        <AlertDialog
                            title={alertTitles.warn}
                            denyActionText={buttonText.negative}
                            confirmActionText={buttonText.proceed}
                            content={validationErrorMessage}
                            confirmAction={uploadInventory}
                            buttonText={buttonText.addItems}
                            type={buttonTypes.uploadIcon}
                            disabledButton={!validationError}
                        />
                    </Grid>
                </Paper>
                    : <Paper>
                        <Typography variant={"h5"}>
                            {completionMessage}
                        </Typography>
                    </Paper>
                }
            </main>
        </React.Fragment>
    )
}
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));
