import React from "react";

export const BRDisplayYearTable = ({values}) => {
    // {
    //     "totalPatientDays": 6380,
    //     "cppdPrice": 5.6,
    //     "totalCppdPrice": 35728,
    //     "outlierTotal": 4645,
    //     "totalDmeSpend": 40373,
    //     "outlierSpend": 11.505,
    //     "costPerPatientDay": 6.328
    // }
    return (
        <table style={{textAlign: "left"}}>
            <tr>
                <th>Data Point</th>
                <th>Quarter 1</th>
                <th>Quarter 2</th>
                <th>Quarter 3</th>
                <th>Quarter 4</th>
                <th>Total</th>
            </tr>
            <tr>
                <td>Total Patient Days</td>
                <td>{values.q1.totalPatientDays}</td>
                <td>{values.q2.totalPatientDays}</td>
                <td>{values.q3.totalPatientDays}</td>
                <td>{values.q4.totalPatientDays}</td>
                <td>{values.q1.totalPatientDays + values.q2.totalPatientDays + values.q3.totalPatientDays + values.q4.totalPatientDays}</td>

            </tr>
            <tr>
                <td>CPPD - Patient Days</td>
                <td>{values.q1.cppdPrice}</td>
                <td>{values.q2.cppdPrice}</td>
                <td>{values.q3.cppdPrice}</td>
                <td>{values.q4.cppdPrice}</td>
                <td>{values.q1.cppdPrice + values.q2.cppdPrice + values.q3.cppdPrice + values.q4.cppdPrice}</td>
            </tr>
            <tr>
                <td>Total CPPD - Per Diem</td>
                <td>{values.q1.totalCppdPrice}</td>
                <td>{values.q2.totalCppdPrice}</td>
                <td>{values.q3.totalCppdPrice}</td>
                <td>{values.q4.totalCppdPrice}</td>
                <td>{((values.q1.totalCppdPrice + values.q2.totalCppdPrice + values.q3.totalCppdPrice + values.q4.totalCppdPrice)/4).toFixed(3)}</td>
            </tr>
            <tr>
                <td>Total DME Spend</td>
                <td>{values.q1.totalDmeSpend}</td>
                <td>{values.q2.totalDmeSpend}</td>
                <td>{values.q3.totalDmeSpend}</td>
                <td>{values.q4.totalDmeSpend}</td>
                <td>{values.q1.totalDmeSpend + values.q2.totalDmeSpend + values.q3.totalDmeSpend + values.q4.totalDmeSpend}</td>
            </tr>
            <tr>
                <td>Outlier Total</td>
                <td>{values.q1.outlierTotal}</td>
                <td>{values.q2.outlierTotal}</td>
                <td>{values.q3.outlierTotal}</td>
                <td>{values.q4.outlierTotal}</td>
                <td>{values.q1.outlierTotal + values.q2.outlierTotal + values.q3.outlierTotal + values.q4.outlierTotal}</td>
            </tr>
            <tr>
                <td>Outlier % of Spend</td>
                <td>{values.q1.outlierSpend}</td>
                <td>{values.q2.outlierSpend}</td>
                <td>{values.q3.outlierSpend}</td>
                <td>{values.q4.outlierSpend}</td>
                <td>{((values.q1.outlierSpend + values.q2.outlierSpend + values.q3.outlierSpend + values.q4.outlierSpend)/4).toFixed(3)}</td>
            </tr>
            <tr>
                <td>Cost Per Patient Day</td>
                <td>{values.q1.costPerPatientDay}</td>
                <td>{values.q2.costPerPatientDay}</td>
                <td>{values.q3.costPerPatientDay}</td>
                <td>{values.q4.costPerPatientDay}</td>
                <td>{((values.q1.costPerPatientDay + values.q2.costPerPatientDay + values.q3.costPerPatientDay + values.q4.costPerPatientDay)/4).toFixed(3)}</td>
            </tr>
        </table>
    )
}