import React, {useEffect, useState} from 'react';
import './inventory.css';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {inventoryStyle} from "../../inventory/inventoryStyle";
import MaterialTable from "material-table";
import ViewAssets from "./../viewAssets/viewAssets";
import {TextField} from "@material-ui/core";
import {endpoints} from "../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwt from 'jwt-decode';
import {sectionTitles, strings} from "../../../constants/names/titles"
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import Select from "react-select";
import ExportForm from "../../../common/exportForm/exportForm";

export default function ExportInventory() {
    const data = JSON.parse(localStorage.getItem('key'));
    const dispatch = useDispatch();
    const user = jwt(data.accessToken);
    const classes = useStyles();
    const isLogged = useSelector(state => state.isLogged);
    let history = useHistory();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [warehouses, setWarehouses] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);

    const [search, setSearch] = React.useState({
        input: '',
        asset: '',
        warehouse: '',
        name: ''
    });
    const dmeID = {
        id: Number(user.payload.dme_organization_id)
    };
    const handleSearch = () => {
        getItems();
    };

    function getItems() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllInventoryItems, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                warehouses:selectedWarehouses,
                dmeID:dmeID.id
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setItems(response.data);
                setIsLoading(false);
                let final = [];
                response.data.map(item => {
                    let newArr = [];
                    newArr.push(item.item_name);
                    newArr.push(item.name);
                    newArr.push(item.asset);
                    newArr.push(item.item_status);
                    newArr.push(item.maintenance);
                    final.push(newArr)
                });
                setExportValues(final);
            })
            .catch(err => console.log('error', err))
    }
    function getWarehouses() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllDmeWarehouses + dmeID.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = [];
                response.data.map(warehouse => {
                    arr.push({
                        label: warehouse.name,
                        value: warehouse.warehouse_id
                    })
                });
                setWarehouses(arr);
                setSelectedWarehouses(arr);
            })
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        getWarehouses();
    }, []);


    const handleInput = (e) => {
        setSelectedWarehouses(e);
    };

    const headers = [
        {text: "Item_Name", style: 'tableHeader'},
        {text: "Warehouse", style: 'tableHeader'},
        {text: "Asset", style: 'tableHeader'},
        {text: "Status", style: 'tableHeader'},
        {text: "Maintenance", style: 'tableHeader'}
    ];
    const excelHeaders = [
        "Item_Name",
        "Warehouse",
        "Asset",
        "Status",
        "Maintenance",
    ];


    return (
        <div>
            {(isLogged) ? <React.Fragment>
                <Grid container justify='space-between' className={classes.inventory} style={{ width:'80vw'}}>
                    <Grid item>
                        <Typography component={'span'} variant="h6">
                            {sectionTitles.exportInventory}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.inventory} justify="flex-start" alignContent='center'
                      alignItems='center'>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', alignContent:'flex-start', width:280}}>
                        <span>Select Warehouses:</span>
                        <Grid style={{ width:'280px'}}>
                            <Select
                                // defaultValue={[options[0]]}
                                isMulti
                                // isLoading={warehouses.length >0}
                                name="colors"
                                value={selectedWarehouses}
                                options={warehouses}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => handleInput(e)}
                            />
                            <Button variant='outlined' color='primary' style={{marginTop:8}} onClick={() => {
                                handleSearch()
                            }} fullWidth>{buttonText.generateReport}</Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid container className={classes.inventory}>
                    <div style={{width: 1200, justifyContent: 'center'}}>
                        {isLoading ? <div></div> : (
                            <Grid>
                                <Grid container justify={'flex-end'} style={{marginBottom:12}}>
                                    <ExportForm
                                        showPrint={true}
                                        headers={headers}
                                        fileName={"Inventory_Export"}
                                        excelHeaders={excelHeaders}
                                        exportValues={exportValues}
                                    />
                                </Grid>

                            <MaterialTable
                                title="Results"
                                columns={[
                                    {
                                        title: 'Item Name',
                                        field: 'item_name',
                                    },
                                    {
                                        title: 'Warehouse',
                                        field: 'name',
                                    },
                                    {title: 'Asset', field: 'asset', filtering: false},
                                    {title: 'Status', field: 'item_status', filtering: false},
                                    {title: 'Maintenance', field: 'maintenance', filtering: false},
                                ]}
                                data={items}
                            />
                            </Grid>
                        )}
                    </div>
                </Grid>
            </React.Fragment> : ''}
        </div>
    )
}
const useStyles = makeStyles((theme) => (inventoryStyle));
