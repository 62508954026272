import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {labels} from "../../../../constants/inputs/labels";
import FormHelperText from "@material-ui/core/FormHelperText";
import {errorMessages, successMessages} from "../../../../constants/messages/responseMessages";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {endpoints} from "../../../../constants/endpoints";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {forgotStyle} from "./forgotStyle";

export const ForgotPasswordComponent = (props) => {
    const classes = useStyles();
    const [successMessage, setSuccessMessage] = useState("none");
    const [data, setData] = useState({
        username: "",
        email: "",
        phone: ""
    });
    const [missing, setMissing] = useState({
        username: false,
        email: false,
    });

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
        value.length > 0 ? setMissing({...missing, [name]: false}) : setMissing({...missing, [name]: true});
    };

    const requestPasswordReset = async () => {
        const promise = await fetch(endpoints.requestPassword, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: data})
        });

        const response = await promise.json();
        if (response.status === "success") {
            setSuccessMessage("success");
        } else {
            setSuccessMessage("error");
        }
    };
    return (
        <Grid container className={classes.container}>
            <Grid item item xs={12} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    label={labels.username}
                    name={"username"}
                    required={true}
                    value={data.username}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    error={missing.username}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    label={labels.email}
                    name={"email"}
                    value={data.email}
                    // error={missing.email}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
               <span>OR</span>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
                <CssTextField
                placeholder={"US phone number without country code"}
                    variant={"outlined"}
                    label={labels.phone}
                    name={"phone"}
                    value={data.phone}
                    // error={missing.phone}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    disabled={(data.email.length === 0 && data.phone.length < 10) || data.username === 0}
                    className={classes.button}
                    onClick={requestPasswordReset}
                >
                    {buttonText.requestPassword}
                </Button>
            </Grid>
            {successMessage === "error" && <FormHelperText style={{
                color: 'red',
                textAlign: 'center'
            }}>{errorMessages.invalidCredentials}</FormHelperText>}
            {successMessage === "success" && <FormHelperText style={{
                color: 'green',
                textAlign: 'center'
            }}>{successMessages.mailingSuccess}</FormHelperText>}
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (forgotStyle));