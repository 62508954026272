import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {CustomInputField} from "../../InputField/InputField";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
import {labels} from "../../../constants/inputs/labels";
import {placeholders} from "../../../constants/inputs/paceholders";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import {checkUsersForThisEmail} from "../../utils/utils";
import {organizations} from "../../../constants/types/role";
import {inputType} from "../../../constants/types/inputType";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
    }
}));
export const ContactForm = ({formTitle, handleInput, handlePhoneInput, isRequired, value, isValid, name, isMissing, roleName, orgType, regionIndex, id, enableEmailCheck, isNewRegion}) => {
    const classes = useStyles();
    const [phone, setPhone] = useState(true);
    const [emailFound, setEmailFound] = useState(false);
    const [showEmailWarning, setShowEmailWarning] = useState(false);

    const handleFormInput = (event) => {
        if (orgType === organizations.hospice.type) {
            if (regionIndex >= 0) {
                handleInput(regionIndex, null, event);
            } else {
                handleInput(event);
            }
        }
        if (orgType === organizations.dme.type) {
            handleInput({
                        ...event,
                        position:regionIndex,
                        inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                    });
        }
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom style={{display: "flex"}}>
                {formTitle}
            </Typography>
            <Grid container spacing={3} className={classes.root}>
                <Grid item sm={4}>
                    <CustomInputField
                        id={id}
                        isRequired={isRequired}
                        isMissing={isMissing.first_name}
                        name={name.first_name}
                        label={labels.first_name}
                        value={value.first_name}
                        onBlur={(event) => handleFormInput(event)}
                        onChange={(event) => handleFormInput(event)}
                        variant={"outlined"}
                        size={"small"}
                        isValid={isRequired ? isValid.first_name : true}
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomInputField
                        id={id}
                        isRequired={isRequired}
                        isMissing={isMissing.last_name}
                        name={name.last_name}
                        label={labels.last_name}
                        value={value.last_name}
                        onBlur={(event) => handleFormInput(event)}
                        onChange={(event) => handleFormInput(event)}
                        variant={"outlined"}
                        size={"small"}
                        isValid={isRequired ? isValid.last_name : true}
                    />
                </Grid>
                <Grid item sm={4}>
                    <CustomInputField
                        id={id}
                        isRequired={isRequired}
                        isMissing={isMissing.email}
                        name={name.email}
                        label={labels.email}
                        value={value.email}
                        variant={"outlined"}
                        size={"small"}
                        onBlur={(event) => {
                            handleFormInput(event);
                            if (enableEmailCheck === true) {
                                if (event.target.value === "") {
                                    setShowEmailWarning(false);
                                } else {
                                    setShowEmailWarning(true);
                                }
                                let result;
                                (async () => {
                                    if (event.target.value !== "") {
                                        result = await checkUsersForThisEmail(event.target.value, roleName, orgType)
                                        setEmailFound(result);
                                    }
                                })()
                            }
                        }}
                        onChange={(event) => handleFormInput(event)}
                        isValid={isRequired ? isValid.email : true}
                    />
                    {showEmailWarning && (emailFound ? <FormHelperText
                            style={{color: 'green', textAlign: 'left'}}
                        >
                            {getMessage(messageTypes.emailFound)}
                        </FormHelperText> :
                        <FormHelperText
                            style={{color: 'red', textAlign: 'left'}}
                        >
                            {getMessage(messageTypes.emailNotFound)}
                        </FormHelperText>)}
                </Grid>
                <Grid item sm={4}>
                    <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                        {labels.phone}
                    </InputLabel>
                    <PhoneInput
                        id={name.phone + "_" + id}
                        name={name.phone}
                        label={labels.phone}
                        country={'us'}
                        disableCountryCode={true}
                        onlyCountries={['us']}
                        value={value.phone}
                        placeholder={placeholders.phone}
                        onChange={(e) => {
                            if (e.length === 10) {
                                setPhone(true)
                            }
                            const obj = {
                                target: {
                                    name: name.phone,
                                    value: e,
                                }
                            };
                            if (orgType === organizations.hospice.type) {
                                if (regionIndex >= 0) {
                                    handlePhoneInput(regionIndex, obj);
                                } else {
                                    handlePhoneInput(obj);
                                }
                            } else if (orgType === organizations.dme.type) {
                                handleInput({
                                    target:{
                                        name: name.phone,
                                        value: e,
                                    },
                                    position: regionIndex,
                                    type: "change",
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                })
                            }
                        }

                        }
                        inputProps={{
                            label: 'test',
                            required: true,
                            id: name.phone + "_" + id
                        }}
                        onBlur={(e) => {
                            if (e.target.value.length === 14) {
                                setPhone(true)
                                if(orgType === organizations.dme.type) {
                                    handleInput({
                                        target: {
                                            name: name.phone,
                                            value: e,
                                        },
                                        position: regionIndex,
                                        inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                    })
                                }
                            } else {
                                setPhone(false)
                            }
                        }}
                        isValid={phone}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
};