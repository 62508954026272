export const successMessage = {status: 'success'};
export const errorMessage = {status: 'error'};
export const warningMessage = {status: 'warning'};
export const status = {
    success: 200,
    error: 500,
    notfound: 404,
    unauthorized: 401,
    conflict: 409,
    created: 201,
    bad: 400,
    nocontent: 204,
};

export const itemStatus = [
    // {value:"OK", label:"OK"},
    {value: "Lost", label: "Lost"},
    {value: "Misappropriated", label: "Misappropriated"},
    {value: "Damaged", label: "Damaged"},
    {value: "Quarantine", label: "Quarantine"},
];

export const activeStatus = {Available: "Available", "In use": "In Use", "Maintenance": "Maintenance"};
export const inactiveStatus = {
    Lost: "Lost",
    Destroyed: "Destroyed",
    Misappropriated: "Misappropriated",
    Quarantine: "Quarantine"
};