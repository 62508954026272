import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import _ from "lodash";

export const getRegions = async (objSetter, hospiceId) => {
    if (hospiceId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.getHospiceRegions}${hospiceId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();

        if (response.status === endpointStatus.success) {
            objSetter(response.data);
        } else {
            console.log("[Error]: Error encountered while getting regions!");
        }
    }
};

export const getRegionData = async (objSetter, regionId, hospiceId) => {
    if (regionId && hospiceId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.hospiceRegionData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: 'POST',
            body: JSON.stringify({
                regionId: regionId,
                hospiceId: hospiceId
            })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            let address = {
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
            };
            let billingContact = {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
            }
            objSetter({
                name: response.data.regionData.name ? response.data.regionData.name : "",
                region_phone: response.data.regionData.region_phone ? response.data.regionData.region_phone : "",
                region_id: response.data.regionData.region_id,
                teams: response.data.teams ? response.data.teams : [],
                warehouses: response.data.warehouses ? response.data.warehouses : [],
                address_region: response.data.addresses ? [response.data.addresses] : [address],
                billing_contact_region: response.data.billingContact ? [response.data.billingContact] : [billingContact]
            });
        } else {
            console.log("[Error]: Error encountered while getting region data!");
        }
    }
};
export const processHospice = async (objSetter, data, operationType, hospiceId, enqueueSnackbar, setIsModified, history) => {
    const token = JSON.parse(localStorage.getItem('key'));
    if (operationType === "create") {
        const promise = await fetch(endpoints.hospice_organizations, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "POST",
            body: JSON.stringify(data)
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            enqueueSnackbar("Organization created.", {variant: "success"});
            objSetter(response.data);
            history.push(`/hospice/edit/${response.data}`);
        } else {
            console.log("[Error]: Error encountered when creating Hospice organization!");
        }
    } else if (operationType === "edit") {
        const promise = await fetch(endpoints.hospiceEditStep1 + hospiceId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "PATCH",
            body: JSON.stringify({
                ...data,
                address_id: data.address_id,
            })
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            enqueueSnackbar("Organization updated.", {variant: "success"});
            setIsModified(false);
        } else {
            console.log("[Error]: Error encountered when editing DME organization!");
        }
    }
};
export const saveRegion = async (objSetter, data, hospiceId, region, enqueueSnackbar) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(endpoints.createHospiceRegion, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        method: "POST",
        body: JSON.stringify({
            hospice_name: data.hospice_name,
            hospiceId: hospiceId,
            regions: [region]
        })
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        objSetter(true);
        enqueueSnackbar("Added new region successfully.", {variant: "success"});
    } else {
        console.log("[Error]: Error encountered while saving region!");
    }
};
export const getOrgData = async (hospiceDataSetter, hospiceId, contactsSetter, contacts, setLoading, setFacilities) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const promise = await fetch(`${endpoints.hospice_organization}${hospiceId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        }
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        hospiceDataSetter(response.data[0]);
        setFacilities(response.data[0].facilities);
        if (response.data[0].it_contact[0] !== null && response.data[0].it_contact.length > 0) {
            let clone = _.cloneDeep(contacts);
            clone.it_contact = response.data[0].it_contact;
            contactsSetter(clone);
        }
    } else {
        console.log("[Error]: Error encountered while getting the organization data!");
    }
    setLoading(false);
};

export const updateThirdStep = async (contacts, hospice, hospiceId, enqueueSnackbar) => {
    const token = JSON.parse(localStorage.getItem('key'));
    const payload = {...hospice, ...contacts, id: hospiceId}
    const promise = await fetch(endpoints.hospiceEditStep3 + hospiceId, {
        method: "PATCH",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(payload)
    });
    const response = await promise.json();
    if (response.status === endpointStatus.success) {
        enqueueSnackbar('Information updated with success.', {variant: "success"})
    } else {
        console.log("[Error]: Error encountered while getting the organization data!");
    }
}