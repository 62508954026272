import React from 'react';
import {useSelector} from 'react-redux';
import {ItemizedAdder} from "./ItemizedAdder";

export default function AddNewItem() {
    const isLogged = useSelector(state => state.isLogged);
    return (
        <div>
            {(isLogged) ? <div className='inventory'>
                <ItemizedAdder/>
            </div> : ''}
        </div>
    )
}