const addUserReducer = (state = {}, action) => {
    switch (action.type) {
        case "addFirstName": {
            state = {...state, first_name: action.payload};
            break;
        }
        case "addMiddleName": {
            state = {...state, MI: action.payload};
            break;
        }
        case "addLastName": {
            state = {...state, last_name: action.payload};
            break;
        }
        case "addMedicalRecord": {
            state = {...state, medical_record: action.payload};
            break;
        }
        case "addAddress1": {
            state = {...state, patient_address1: action.payload};
            break;
        }
        case "addAddress2": {
            state = {...state, patient_address2: action.payload};
            break;
        }
        case "addZip": {
            state = {...state, zip: action.payload};
            break;
        }
        case "addCity": {
            state = {...state, city: action.payload};
            break;
        }
        case "addState": {
            state = {...state, state: action.payload};
            break;
        }
        case "addPhone": {
            state = {...state, phone_number: action.payload};
            break;
        }
        case "addCareSetting": {
            state = {...state, care_setting: action.payload};
            break;
        }
        case "addTeam": {
            state = {...state, team: action.payload};
            break;
        }
        case "addFacility": {
            state = {...state, facility: action.payload};
            break;
        }
        case "addBirthday": {
            state = {...state, date_of_birth: action.payload};
            break;
        }
        case "addGender": {
            state = {...state, gender: action.payload};
            break;
        }
        case "addWeight": {
            state = {...state, patient_weight: action.payload};
            break;
        }
        case "addHeight1": {
            state = {...state, patient_height_in_feet: action.payload};
            break;
        }
        case "addHeight2": {
            state = {...state, patient_height_in_inches: action.payload};
            break;
        }
        case "addCaregiverFirstName": {
            state = {...state, caregiver_first_name_primary: action.payload};
            break;
        }
        case "addCaregiverSecondFirstName": {
            state = {...state, caregiver_first_name_second: action.payload};
            break;
        }
        case "addCaregiverFirstPhone": {
            state = {...state, caregiver_phone_number_primary: action.payload};
            break;
        }
        case "addCaregiverSecondPhone": {
            state = {...state, caregiver_phone_number_second: action.payload};
            break;
        }

        case "addFirstCaregiverRelation": {
            state = {...state, caregiver_relation_primary: action.payload};
            break;
        }
        case "addSecondCaregiverRelation": {
            state = {...state, caregiver_relation_second: action.payload}
            break;
        }
        case "addNote": {
            state = {...state, note: action.payload};
            break;
        }
        case "addDiasee": {
            state = {...state, infectious_disease_protocol: action.payload};
            break;
        }
        case "resetState": {
            state = {};
            break;
        }
        case "editPatient": {
            state = {editPatient: action.payload};
            break;
        }
        case "editId": {
            state = {...state, editId: action.payload};
            break;
        }
        case "deleteId": {
            state = {...state, deleteId: action.payload};
            break;
        }
        default:
    }
    return state;
};

export default addUserReducer;
