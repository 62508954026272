import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from "react-router-dom";
import './createOrder.css';
import 'date-fns';
import {OrderItems} from './orderItems/orderItems';
import {endpoints} from "../../constants/endpoints";
import {OrderReview} from './orderReview/orderReview';
import jwt from 'jwt-decode';
import moment from 'moment';
import _ from "lodash";
import {useSnackbar} from "notistack";
import CartIcon from "../../assets/shopping-cart.svg";
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import {createOrderStyle} from "./createOrder.style";
import AddIcon from '@material-ui/icons/Add';
import {checkPaceHospice} from "../../common/utils/utils";
import FileUpload from "../orders/fileUploadOrders/FileIpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import patientData from "../patients/pacientPage/patientData";

export default function CreateOrder(props) {
    const patientInfo = props.user;
    let history = useHistory();
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    //snackbar position
    const vertical = 'top';
    const horizontal = 'right';

    //modal hooks
    const [showCreateOrder, setShowCreateOrder] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [contractedProviders, setContractedProviders] = useState([]);
    const [dmeId, setDmeId] = useState("");
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [filteredItemsList, setFilteredItemsList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [patient_id, setPatientId] = useState(props.user.patient_id);
    const [order_id, setOrder_id] = useState('');
    const steps = ['Create Order', 'Review Order'];
    const [total, setTotal] = useState(0);
    let token = localStorage.getItem('key');
    let currentUser = JSON.parse(token);
    let user = jwt(currentUser.accessToken);
    const [order, setOrder] = useState({
        status: "Open",
        patient_id: props.user.patient_id,
        search: '',
        preferred_date: moment(),
        user_id: Number(user.payload.user_id),
        category: '',
        dme_organization_id: '',
        hospice_id: props.user.hospice_organization_id,
        priority_code: 'Routine',
        approval: false,
        // items: props.orderPackage === true ? props.additionalItems : [],
        items:[],
        notes: [],
        history: [{
            description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
            created_date: moment()
        }]
    });

    useEffect(()=> {
        setOrder({...order,
            patient_id: props.user.patient_id,
            hospice_id: props.user.hospice_organization_id,
            region_id: props.user.region_id
        });
        setPatientId(props.user.patient_id);
    }, [props.user.patient_id]);

    const handleInput = (event) => {
        const {name, value} = event.target;
        setOrder({...order, [name]: value});
    };

    useEffect(() => {
        let sum = 0;
        order.items.map(item => sum = sum + item.quantity);
        setTotal(sum);
    }, [order]);

    const handleShowCreateOrder = () => {
        setActiveStep(0);
        setShowCreateOrder(true);
    };
    const handleHideCreateOrder = (event, reason) => {
        setShowCreateOrder(false);
        setOrder({
            status: "Open",
            patient_id: props.user.patient_id,
            search: '',
            preferred_date: moment(),
            user_id: Number(user.payload.user_id),
            category: '',
            dme_organization_id: '',
            hospice_id: props.user.hospice_organization_id,
            priority_code: 'Routine',
            approval: false,
            items: [],
            notes: [],
            history: [{
                description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                created_date: moment()
            }]
        });
        setItemsList([]);
        setFilteredItemsList([]);
        setDmeId("");
        if (reason === 'clickaway') {
            return;
        }
    };
    function createEmailNoteTemplate(note) {
        let string = '';
        string += `<br> <strong>Creator:</strong> ${note.first_name} ${note.last_name}`;
        string += `<br> <strong>Created Date:</strong> ${note.created_date}`;
        string += `<br> <strong>Description:</strong> ${note.description}`;
        return string;
    }

    const createOrder = () => {
        setLoading(true);
        let orderClone = _.cloneDeep(order);
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.createOrder}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                },
                body: JSON.stringify({...orderClone, dme_organization_id: dmeId !== "" ? dmeId : order.dme_organization_id,
                    patient_id: patient_id
                })
            }
        )
            .then(response => {
                if (response.status === 201) {
                    // setShowCreateOrder(false);
                    // setShowSnackbar(true);
                    props.getPatientOrders && props.getPatientOrders();
                } else {
                    console.log('error')
                }
                return response.json();
            }).then(response => {
                setOrder_id(response.data);
                if (order.items.some(item => item.approval === true)) {
                    let patient;
                    if (order.first_name){
                        patient = `${order.first_name} ${order.last_name}`
                    } else if (props.orderProps && props.orderProps.first_name){
                        patient = `${props.orderProps.first_name} ${props.orderProps.last_name}`
                    } else if (patientInfo.first_name){
                        patient = `${patientInfo.first_name} ${patientInfo.last_name}`
                    }
                    fetch(`${endpoints.sendApproveOrderMail}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + data.accessToken
                            },
                            body: JSON.stringify({
                                orderId: `D100${response.data}`,
                                items: order.items,
                                orderType: "Delivery",
                                link: `${process.env.REACT_APP_API_URL}order/delivery/${response.data}`,
                                domain: `${process.env.REACT_APP_API_URL}`,
                                patient: patient,
                                note: order.notes[0] ? createEmailNoteTemplate(order.notes[0]) : (props.orderProps ? (props.orderProps.notes[0] ? createEmailNoteTemplate(props.orderProps.order.notes[0]) : "No notes") : "No notes")
                            })
                        }
                    ).then(() => console.log('email sent'))
            }
                if (files.length > 0){
                    uploadFiles(response.data);
                } else {
                    setShowSnackbar(true);
                    setShowCreateOrder(false);
                    setLoading(false);
                }
            })
            .then(response => {
                setOrder({
                    status: "Open",
                    patient_id: props.user.patient_id,
                    search: '',
                    preferred_date: moment(),
                    user_id: Number(user.payload.user_id),
                    category: '',
                    dme_organization_id: '',
                    hospice_id: props.user.hospice_organization_id,
                    priority_code: 'Routine',
                    approval: false,
                    items: [],
                    notes: [],
                    history: [{
                        description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                        created_date: moment()
                    }]
                });
                setItemsList([]);
                setFilteredItemsList([]);
                setDmeId("");
        })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const getContractedProviders = () => {
        if (true){
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${user.payload.hospice_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                }
                setContractedProviders(response.data);
                setContractedProvidersList(object);
                if (response.data.length === 1) {
                    setDmeId(response.data[0].dme_id);
                    setOrder({...order, dme_organization_id:response.data[0].dme_id})
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
        }
    };

    const getCategoriesList = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCategoriesMasterItems}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length > 0) {
                    let object = [{value: "All", label: "All"}];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].category, label: response.data[i].category})
                    }
                    setCategoriesList(object);
                } else {
                    setCategoriesList([]);
                }
            })
            .catch(err => console.log('error', err))
    };
    const hideSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };
    const viewOrder = () => {
        history.push(`/order/delivery/${order_id}`);
    };

    const renderButton = () => {
        return (
            <React.Fragment>
            {props.addMore ? (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<AddIcon style={{fontSize:28}}/>}
                        onClick={handleShowCreateOrder}
                        style={{
                            fontSize:12,
                            fontWeight:'bold',
                            border:'1px solid #00223D',
                        }}
                    >
                        {props.table ? 'ADD NEW ITEMS' : 'NEW DELIVERY'}
                    </Button>
                    ) :
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    startIcon={<img
                        src={CartIcon}
                        alt="logo"
                        width='16'
                        height='15'
                        // className={classes.simpleLogo}
                        style={{cursor:'pointer'}}
                    />}
                    onClick={handleShowCreateOrder}
                >
                    {props.table ? 'ADD ITEMS' : 'NEW DELIVERY'}
                </Button>
            }
            </React.Fragment>
        )
    };

    async function uploadFiles (orderId) {
        for (let i=0; i<files.length; i++){
            await createOrderFilesIds(orderId, files[i])
        }
        await setShowSnackbar(true);
        await setShowCreateOrder(false);
        setLoading(false);
    }
    async function createOrderFilesIds(orderId, file){
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'delivery',
            })
        });
        const response = await promise1.json();
        console.log(response.data.rows[0], 'response db')
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length-1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile (file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  fileName, type:'delivery'})
        });
        const result1 = await promise1.json();
            console.log(result1.data, 'link upload');
            try{
                const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
                console.log(promiseS3, 'ressssss');
            } catch (e) {
                console.log(e.stack);
            }
        }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <OrderItems setActiveStep={setActiveStep} total={total} user={props.user}
                                   items={filteredItemsList}
                                   isPace={props.isPace}
                                   setOrder={setOrder} handleInput={handleInput} order={order}
                                   contractedProvidersList={contractedProvidersList}
                                   categoriesList={categoriesList} addMore={props.addMore}
                />;
            case 1:
                return <div style={{display: 'flex', justifyContent: 'center'}}>
                    <OrderReview
                        order={order}
                        setOrder={setOrder}
                        files={files}
                        setFiles={setFiles}
                    />
                </div>;
            default:
                console.log('Unknown step');
        }
    }
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    const handleNext = () => {
        if (activeStep === 0) {
            if (props.addMore === true && props.orderPackage === true){
                props.setAdditionalItems(order.items);
                setShowCreateOrder(false);
            } else if (props.addMore && !props.isExchange) {
                const token = JSON.parse(localStorage.getItem('key'));
                fetch(endpoints.addDeliveryItems, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        items: order.items,
                        delivery_order_id: props.orderId
                    })
                })
                    .then(response => {
                        if (response.status === 201) {
                            notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAdded, true);
                            let items = order.items.map(function (item) {
                                return item['name'];
                            });
                            const allItems = items.join(', ');
                            props.addHistory(props.orderId, `Items added: ${user.payload.first_name} ${user.payload.last_name} added ${allItems} on ${moment(new Date()).format("LLL")}`);
                            if (order.items.some(item => item.approval === true)) {
                                fetch(`${endpoints.sendApproveOrderMail}`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({
                                            orderId: `D100${props.orderId}`,
                                            items: order.items,
                                            orderType: "Delivery",
                                            link: `${process.env.REACT_APP_API_URL}order/delivery/${response.data}`,
                                            domain: `${process.env.REACT_APP_API_URL}`,
                                            patient: `${order.first_name ? order.first_name : props.orderProps.first_name} ${order.last_name ? order.last_name : props.orderProps.last_name}`,
                                            note: order.notes[0] ? createEmailNoteTemplate(order.notes[0]) : (props.orderProps ? (props.orderProps.notes[0] ? createEmailNoteTemplate(props.orderProps.order.notes[0]) : "No notes") : "No notes")
                                        })
                                    }
                                ).then(() => console.log('email sent'))
                                if (order.status === "Open"){
                                    props.changeOrderStatus('Pending')
                                }

                            }
                            props.getOrderDetails();
                        }
                    })
                    .catch(err => console.log('error', err));
                setShowCreateOrder(false);
                setOrder({
                    status: "Open",
                    patient_id: props.user.patient_id,
                    search: '',
                    preferred_date: null,
                    user_id: Number(user.payload.user_id),
                    category: '',
                    dme_organization_id: '',
                    hospice_id: props.user.hospice_organization_id,
                    priority_code: '',
                    approval: false,
                    items: [],
                    notes: [],
                    history: [{
                        description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                        created_date: moment()
                    }]
                });
            } else if(props.addMore && props.isExchange) {
                const token = JSON.parse(localStorage.getItem('key'));
                fetch(endpoints.addExchangeOrderItems, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        items: order.items,
                        exchange_order_id: props.exchangeId,
                        type: "Dispatched"
                    })
                })
                    .then(response => {
                        if (response.status === 201) {
                            notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAdded, true);
                            let items = order.items.map(function (item) {
                                return item['name'];
                            });
                            const allItems = items.join(', ');
                            props.addHistory(props.exchangeId, `Items added: ${user.payload.first_name} ${user.payload.last_name} added ${allItems} on ${moment(new Date()).format("LLL")}`);
                            props.getOrderDetails();
                        }
                    })
                    .catch(err => console.log('error', err));
                setShowCreateOrder(false);
                setOrder({
                    status: "Open",
                    patient_id: props.user.patient_id,
                    search: '',
                    preferred_date: null,
                    user_id: Number(user.payload.user_id),
                    category: '',
                    dme_organization_id: '',
                    hospice_id: props.user.hospice_organization_id,
                    priority_code: '',
                    approval: false,
                    items: [],
                    notes: [],
                    history: [{
                        description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                        created_date: moment()
                    }]
                });
            } else {
                setActiveStep(activeStep + 1);
            }

        }
        if (activeStep === 1) {
            createOrder();
        }
    };
    useEffect(() => {
        if (user.payload.hospice_organization_id !== null) {
            if (contractedProvidersList.length<1){
                getContractedProviders();
            }
            setOrder((prevState )=> {
                return {
                ...prevState,
                    dme_organization_id: props.orderPackageDmeId ?  props.orderPackageDmeId : order.dme_organization_id,
                    hospice_id: user.payload.hospice_organization_id,
                  region_id: user.payload.region_id}
            })
        } else {
            //TODO, get here when create dme provider
            setOrder({
                ...order,
                dme_organization_id: props.orderPackageDmeId ? props.orderPackageDmeId : user.payload.dme_organization_id,
                hospice_id: props.user.hospice_organization_id,
                region_id: props.user.region_id
            })
        }
    }, [order.dme_organization_id, props.orderPackageDmeId, props.user.patient_id]);
    useEffect(() => {
        if (contractedProvidersList.length === 1) {
            setOrder({...order, dme_organization_id: contractedProvidersList[0].dme_id})
        }
    }, [contractedProvidersList]);
    useEffect(() => {
        if (showCreateOrder === true && (order.dme_organization_id !== ''|| props.orderDmeId)) {
            const data = JSON.parse(localStorage.getItem('key'));
            let body;
            if (user.payload.hospice_organization_id !== null) {
                body = {
                    dme_id: dmeId !== "" ? dmeId : order.dme_organization_id,
                    hospice_id: order.hospice_id,
                    hospice_region_id: order.region_id,
                };
                if (props.orderDmeId) {
                    body.dme_id = props.orderDmeId
                }
            } else {
                body = {
                    dme_id: props.orderDmeId ? props.orderDmeId : order.dme_organization_id,
                    hospice_id: order.hospice_id,
                    hospice_region_id: order.region_id,
                }
            }
            //TODO check
            fetch(`${endpoints.getHospiceContractedItems}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(response => {
                    return response.json();
                }).then(response => {
                if (response.data.length > 0) {
                    let clonedItems = _.cloneDeep(response.data);
                    clonedItems.map(item => {
                        if (!item.max_quantity_per_item) {
                            item.max_quantity_per_item = 10;
                        }
                    });
                    setItemsList(clonedItems);
                    setFilteredItemsList(clonedItems);
                } else {
                    setItemsList([]);
                    setFilteredItemsList([]);
                }
            })
                .catch(err => console.log('error', err));
        }
    }, [order.dme_organization_id, dmeId, showCreateOrder]);

    useEffect(() => {
        let cloneItemList = _.cloneDeep(itemsList);
        let result = [];
        if (order.category !== '' && order.category !== "All" && order.category !== null) {
            cloneItemList.map(item => {
                if (item.category && _.includes(item.category.toLowerCase(), order.category.toLowerCase())) {
                    result.push(item)
                }
            })
        } else {
            result = cloneItemList;
        }
        setFilteredItemsList(result)
    }, [order.category]);
    useEffect(() => {
        if (order.search !== '') {
            let lorin = _.cloneDeep(itemsList);
            let result = [];
            lorin.map(item => {
                if (_.includes(item.name.toLowerCase(), order.search.toLowerCase())) {
                    result.push(item)
                } else if (_.includes(item.tags, order.search.toLowerCase())) {
                    result.push(item)
                }
            });
            setFilteredItemsList(result)
        } else {
            setFilteredItemsList(itemsList)
        }

    }, [order.search]);

    useEffect(() => {
        if (categoriesList.length === 0 && showCreateOrder) {
            getCategoriesList()
        }
    }, [showCreateOrder]);
    return (
        <React.Fragment>
            {props.renderButton && renderButton()}
            <Dialog
                className={'order'}
                aria-describedby="alert-dialog-description"
                // fullWidth={true}
                maxWidth="xl"
                //                 //                 // maxWidth={'lg'}

                open={showCreateOrder}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={classes.main} >
                <DialogContent style={{padding:0}}>
                    {getStepContent(activeStep)}
                </DialogContent>
                <DialogActions>
                    {props.addMore ? (
                        <Button
                            onClick={handleNext}
                            color="primary"
                            variant="contained"
                            autoFocus
                            disabled={order.items.length === 0}
                            style={{ fontWeight:'bold', fontSize:12,color:'#fff',
                                border:order.items.length !== 0 ? '1px solid #00223D' : 'none'
                            }}
                        >
                            ADD ITEMS
                        </Button>
                    ):(
                        <Button
                            onClick={handleNext}
                            color="primary"
                            variant="contained"
                            autoFocus
                            disabled={(activeStep === steps.length - 1 && order.items.length=== 0) || (order.dme_organization_id === "" || loading)}
                            style={{ fontWeight:'bold', fontSize:12,
                                border:(activeStep === steps.length - 1 && order.items.length=== 0) || (order.dme_organization_id === "" || loading) ? 'none' : '1px solid #00223D',
                                height:40}}
                        >
                            {loading ? (
                                <CircularProgress style={{color:'white'}} size={24}/>
                            ): (
                                <React.Fragment>
                                    {(activeStep !== steps.length - 1) ? 'NEXT' : 'CREATE ORDER'}
                                </React.Fragment>
                            )}

                        </Button>
                    )}
                    <Button
                        onClick={handleHideCreateOrder}
                        color="default"
                        variant="outlined"
                        style={{width: '90px', fontWeight:'bold', fontSize:12, color:'#00223D',border:'1px solid #00223D',height:40}}
                    >
                        CANCEL
                    </Button>
                    {activeStep === 1 && (
                        <Button
                            onClick={handleBack}
                            color="primary"
                            variant="outlined"
                            style={{width: '90px', fontWeight:'bold', fontSize:12, color:'#00223D', border:'1px solid #00223D', height:40}}
                        >
                            BACK
                        </Button>

                    )}

                </DialogActions>
                </div>
            </Dialog>
            {/* show success snackbar: */}
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                ContentProps={{classes: {root: classes.snackbar}}}
                style={{padding: '10px'}}
                key={`${vertical},${horizontal}`}
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={hideSnackbar}
                message={`Order #D100${order_id} was successfully created!`}
                action={
                    <React.Fragment>
                        <Button style={{color: 'black', marginRight: '6px'}} size="small" onClick={viewOrder}>
                            View Order
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={hideSnackbar}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            >
            </Snackbar>
        </React.Fragment>
    );
}
const useStyles = makeStyles((theme) => (createOrderStyle(theme)));
