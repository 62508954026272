import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {sectionTitles} from "../../../constants/names/titles";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {placeholders} from "../../../constants/inputs/paceholders";
import {labels} from "../../../constants/inputs/labels";
import moment from 'moment';
import {AlertDialog} from "../../../common/alerts/generalAlert/generalAlert";
import {buttonTypes} from "../../../constants/alert/buttonTypes";
import {alertTitles} from "../../../constants/alert/alertTitle";
import {alertMessages} from "../../../constants/alert/alertMessages";
import {withStyles} from "@material-ui/core";

const CustomCheckbox = withStyles({
    root: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        },
        "&:checked": {
            color: "#4F86A0"
        },
        "&:disabled": {
            color: "#4F86A0"
        }
    },
    checked: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    },
    disabled: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    }
})(Checkbox);

export default function Finalize(props) {
    const useStyles = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        },
        notesSection: {
            display: 'flex',
            border: '1px solid #CCCCCC',
            backgroundColor: '#F2F2F2',
            borderRadius: 4,
            padding: 16,
            marginBottom: 12,
            justifyContent: 'space-between',
        },
        author: {
            fontStyle: 'italic',
            color: "#B6B6B6",
        }
    });
    const {
        currentNote, setCurrentNote, handleNoteDelete, handleActiveNoteDelete, patient,
        handleInfectiousChange, handleNoteInput, initialNotes, additionalNotes, addNote,
    } = props;
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom style={{display: "flex", paddingBottom: 40}}>
                {sectionTitles.clinicalNotes}
            </Typography>
            <Grid container spacing={2} alignItems={"stretch"}>
                {patient.note.filter((i, indeX) => (indeX < initialNotes)).map((note, index) => {
                    return (
                        <Grid item key={index} xs={12}>
                            <div key={index} className={classes.notesSection}>
                                <div>{note.description}</div>
                                <div
                                    className={classes.author}>{note.first_name + " " + note.last_name + ", " + moment(note.created_date).format('LLL')}</div>
                            </div>
                            <Divider style={{margin: '10px'}}/>
                        </Grid>
                    )
                })}
                {patient.note.filter((i, index) => (index > initialNotes - 1 && index < initialNotes + additionalNotes)).map((note, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <TextField
                                placeholder=""
                                multiline
                                name='note'
                                rows={3}
                                rowsMax={5}
                                fullWidth
                                value={note}
                                style={{
                                    border: '1px solid gray',
                                    padding: '6px',
                                    borderRadius: '5px',
                                    width: '99%'
                                }}
                                InputProps={{classes}}
                                onChange={(e) => {
                                    if (e.target.value.length) {
                                        handleNoteInput(e, index + initialNotes)
                                    } else {
                                        handleNoteInput(e, index + initialNotes)
                                        handleNoteDelete(index + initialNotes - 1)
                                    }
                                }}
                            />
                            <Divider style={{margin: '5px'}}/>
                        </Grid>
                    )
                })}
            </Grid>
            <TextField
                placeholder={placeholders.addAdditionalNote}
                multiline
                name='note'
                rows={2}
                rowsMax={9}
                value={currentNote}
                style={{
                    border: '1px solid gray',
                    padding: '6px',
                    borderRadius: '5px',
                    width: '99%'
                }}
                onChange={(e) => {
                    if (e.target.value.length) {
                        handleNoteInput(e, initialNotes + additionalNotes)
                        setCurrentNote(e.target.value);
                    } else {
                        handleNoteInput(e, initialNotes + additionalNotes)
                        handleActiveNoteDelete(initialNotes + additionalNotes - 1)
                        setCurrentNote(e.target.value);
                    }
                }}
                InputProps={{classes}}
            />
            <div style={{
                alignContent: 'right',
                justifyContent: 'flex-end',
                display: 'flex',
                paddingTop: '10px',
                width: '100%'
            }}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        addNote();
                        setCurrentNote('')
                    }}>{buttonText.addNote}</Button>

            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom style={{display: "flex"}}>
                        {sectionTitles.infectiousDiseasePrecautions}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.contact}
                                        onChange={handleInfectiousChange}
                                        name="contact"
                                        color="primary"
                                    />
                                }
                                label={labels.contact}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.droplet}
                                        onChange={handleInfectiousChange}
                                        name="droplet"
                                        color="primary"
                                    />
                                }
                                label={labels.droplet}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.respiratory}
                                        onChange={handleInfectiousChange}
                                        name="respiratory"
                                        color="primary"
                                    />
                                }
                                label={labels.respiratory}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.covid_19}
                                        onChange={handleInfectiousChange}
                                        name="covid_19"
                                        color="primary"
                                    />
                                }
                                label={labels.covid19}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.other}
                                        onChange={handleInfectiousChange}
                                        name="other"
                                    />
                                }
                                label={labels.other}

                            />
                        </Grid>
                        <Grid item>
                            {(patient.infectious_disease_protocol.other) && <TextField
                                required
                                id="other_infectious_disease_protocol"
                                name="other_disease"
                                label={labels.otherDisease}
                                variant={"outlined"}
                                size={"small"}
                                fullWidth
                                placeholder={placeholders.otherDiseaseProtocol}
                                value={patient.infectious_disease_protocol.other_disease}
                                onChange={handleInfectiousChange}
                            />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}