export const patientRecordNames = {
    medical_record: 'medical_record',
    first_name: 'first_name',
    MI: 'MI',
    last_name: 'last_name',
    patient_weight: 'patient_weight',
    patient_address1: 'patient_address1',
    patient_address2: 'patient_address2',
    patient_height_in_feet: 'patient_height_in_feet',
    patient_height_in_inches: 'patient_height_in_inches',
    city: 'city',
    state: 'state',
    zip: 'zip',
    care_setting: 'care_setting',
    note: 'note',
};

