import React from "react";
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {capitalize} from "@material-ui/core";

export const IconAlertTypes = {
    disease: "disease",
    notes: "notes",
}

export const IconAlertActions = {
    display: "display",
    anchor: "anchor",
}

export const IconAlerts = ({iconType = "", action = "", data = []}) => {

    const handleInput = () => {
        switch (action) {
            case IconAlertActions.anchor:
                data[0].current.scrollIntoView({behavior: 'smooth'});
        }
    }

    switch (iconType) {
        case IconAlertTypes.disease:
            if (data.length === 1 && (data[0] === null || data[0].name === null || data[0].name === "")) {
                return null;
            }
            return <Tooltip title={data.map((item, index) => {
                return <div key={index} style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start"
                }}>
                    Infectious Disease Present: {capitalize(item.name)}
                </div>
            })}
            >
                <IconButton size={"small"}>
                    <WarningIcon color={"secondary"}/>
                </IconButton>
            </Tooltip>
        case IconAlertTypes.notes:
            return <Tooltip title={"Notes Present"}>
                <div onClick={handleInput}>
                    <IconButton size={"small"}>
                        <InfoIcon style={{color: "#117C70"}}/>
                    </IconButton>
                </div>
            </Tooltip>
        default:
            return null
    }
}