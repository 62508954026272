import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {useSnackbar} from "notistack";
import {endpoints} from "../../../../constants/endpoints";
import {status} from "../../../../constants/status/status";
import {useHistory} from "react-router-dom";

export default function CustomSelect(props) {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const [state, setState] = useState(props.rowData.is_organization_type);
    const handleChange = (e) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.updateOrganizationType, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({isOrganization: e.value, hospiceId:props.rowData.hospice_organization_id})
            }).then(res => {
                if (res.status === status.success) {
                    //notistack de success
                    enqueueSnackbar(`Hospice was updated with success`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {vertical: "top", horizontal: "right"},
                    });
                    setState(e.value);
                    history.push('/refresh/admin/hospice/type')
                }
            })

    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={'Select...'}
                    value={state === true ? [{value:true, label:'Organization'}] : [{label:"Hospice", value:false}]}
                    options={[{label:"Organization", value:true},{label:"Hospice", value:false}]}
                    onChange={(e)=> handleChange(e)}
                    style={{width:'100%'}}
                />
            </Grid>

        </Grid>
    )
}