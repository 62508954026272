import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import {alertTitles} from "../../../constants/alert/alertTitle";
import {alertMessages} from "../../../constants/alert/alertMessages";
import {redirectLinks} from "../../../constants/alert/alertRedirectLinks";

export const getAlertMessage = (flag) => {
    switch (flag) {
        case cancellingAlertFlags.newPatient:
            return alertMessages.cancelNewPatient;
        case cancellingAlertFlags.editPatient:
            return alertMessages.cancelEditPatient;
        default:
            return alertMessages.alertNotDefined;
    }
};

export const getRedirectRoute = (flag) => {
    switch (flag) {
        case cancellingAlertFlags.editPatient:
        case cancellingAlertFlags.newPatient:
            return redirectLinks.viewAllPatients;
        default:
            console.warn(`[WARN]: Flag -->${flag}'<-- not found`)
    }
};

export const getAlertTitle = (flag) => {
    switch (flag) {
        case alertLevel.warn:
            return alertTitles.warn;
        case alertLevel.error:
            return alertTitles.error;
        default:
            return alertTitles.alert;
    }
};
