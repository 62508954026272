export const SearchCriteria = (state =
  {

  }, action) => {
    switch (action.type) {
        case "setInputValue": {
            state = {...state, input: action.payload};
            break;
        }
        case "setCriteriaValue": {
            state = {...state, criteria: action.payload};
            break;
        }
        case "setNurseValue": {
            state = {...state, nurse_id: action.payload};
            break;
        }
        case "setTeamValue": {
            state = {...state, team: action.payload};
            break;
        }
        case "setCareSettingValue": {
            state = {...state, careSetting: action.payload};
            break;
        }
        case "setInactiveValue": {
            state = {...state, showInactive: action.payload};
            break;
        }
        case "NotClicked":
            break;
        default:
    }
    return state;
};

export const SearchCriteriaDME = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaDME":
            state = action.payload;
            break;
        case "NotClicked":
            break;
        default:

    }
    return state;
};
export const SearchCriteriaHospice = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaHospice":
            state = action.payload;
            break;
        case "NotClicked":
            break;
        default:

    }
    return state;
};
export const SearchCriteriaDmeUsers = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaDmeUsers":
            state = action.payload;
            break;
        case "NotClicked":
            break;
        default:
    }
    return state;
};
export const SearchCriteriaHospiceUsers = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaHospiceUsers":
            state = action.payload;
            break;
        case "NotClicked":
            break;
        default:
    }
    return state;
};

export const SearchCriteriaOrders = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaOrders":
            state = action.payload;
            break;
        case "NotClicked":
            break;
        default:
    }
    return state;
};
export const SearchCriteriaPatients = (state = {

}, action) => {
    switch (action.type) {
        case "setCriteriaPatients":
            state = action.payload;
            break;
        default:
    }
    return state;
};
