import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../../redux/actions';
import {Redirect, useHistory} from 'react-router-dom';
import {endpoints} from "../../constants/endpoints";
import {routes} from "../../constants/routes/routes";
import jwt from 'jwt-decode'
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {useSnackbar} from "notistack";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import Grid from "@material-ui/core/Grid";
import {Hidden} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Logo from "../../assets/logo_caption_full@2x.png";
import SimpleLogo from "../../assets/logo_caption@2x.png";
import PoweredLogo from "../../assets/powered_by_capstone@2x.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {loginStyle} from "./log-in.style";
import moment from "moment";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LogIn(props) {
    const classes = useStyles();
    let tempToken = (new URLSearchParams(window.location.search)).get("token");
    props.setShowNav(false);
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);

    const [expirationDateToken, setExpirationToken] = useState();


    useEffect(() => {
        if (tempToken !== null) {
            const userTempToken = jwt(tempToken);
            props.setLoginToken(userTempToken);
                setExpirationToken(userTempToken)
        }
    }, [tempToken]);

    const onSubmitForm = (e) => {
        setLoading(true);
        setDisabledButton(true);
        let user = {
            username: username,
            password: password,
        };
        fetch(`${endpoints.login}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(user)
            }
        )
            .then(response => {
                if (response.status === 200) {
                } else if (response.status === 204) {
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.invalidUsernamePassword, true);
                    setTimeout(() => history.push("/support"), 1000);
                } else {
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.invalidUsernamePassword, true);
                }
                setDisabledButton(false);
                setLoading(false);
                return response.json();
            }).then(response => {
            const user = jwt(response.accessToken);
            dispatch(login(user.payload.username));
            localStorage.setItem('key', JSON.stringify(response));
            props.setShowNav(true);
            if (user.payload.temporary_password) {
                history.push({
                    pathname: `${routes.password_change}`,
                    temporary_password: true
                })
            }
        })
            .catch(err => {
                setDisabledButton(false);
            });
    };
    return (
        <React.Fragment>
            {(props.loginToken.expiration && props.loginToken.expiration !== '' && props.loginToken.expiration.length !==0 && moment(props.loginToken.expiration).toDate() < new Date() ) ? (
                <Typography
                    variant={"h5"}
                    className={classes.text}
                >
                    LINK is expired.
                </Typography>
            ) : (

                <Grid container justify={"center"} alignContent={"center"}>
                    {(!isLogged) ? (
                        <Grid container align
                              className={classes.container}
                        >
                            <Hidden mdUp>
                                <Grid item
                                      xs={12}
                                      className={classes.simpleLogoContainer}>
                                    <Grid
                                        item
                                        lg={4} md={4} sm={6} xs={12}
                                        className={classes.simpleLogoGrid}>
                                        <img
                                            src={SimpleLogo}
                                            alt="logo"
                                            width='205'
                                            className={classes.simpleLogo}/>
                                    </Grid>
                                </Grid>
                                <Grid item
                                      lg={4} md={4} sm={6} xs={12}
                                      className={classes.contentGrid}>
                                    <Typography
                                        variant={"h2"}
                                        className={classes.h2}
                                    >
                                        LOG IN
                                    </Typography>
                                    <CssTextField
                                        autoFocus
                                        id={'username'}
                                        variant={'outlined'}
                                        label={'Username'}
                                        size={'small'}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        style={{
                                            width: '100%',
                                            marginTop: 22,
                                        }}
                                        defaultValue={username}
                                        onChange={(e) => {
                                            setUsername(e.target.value)
                                        }}
                                        onKeyUp={(event) => {
                                            if (event.key === 'Enter') {
                                                onSubmitForm()
                                            }
                                        }}
                                    />
                                    <CssTextField
                                        id={'password'}
                                        variant={'outlined'}
                                        label={'Password'}
                                        size={'small'}
                                        type="password"
                                        value={password}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        style={{
                                            width: '100%',
                                            marginTop: 33,
                                        }}
                                        onChange={event => {
                                            setPassword(event.target.value)
                                        }}
                                        onKeyUp={(event) => {
                                            if (event.key === 'Enter') {
                                                onSubmitForm()
                                            }
                                        }}
                                    />
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            id='loginButton'
                                            variant='contained'
                                            color='primary'
                                            className={classes.button}
                                            onClick={() => !disabledButton && onSubmitForm()}
                                            disabled={loading}
                                            style={{width:140}}
                                        >
                                            {loading ? <CircularProgress style={{color:'white'}} size={24}/> : "LOGIN"}
                                        </Button>
                                    </div>
                                    <Typography
                                        variant={"h5"}
                                        className={classes.text}
                                        onClick={() => history.push("/forgotCredentials")}
                                    >
                                        Forgot username or password?
                                    </Typography>
                                    <Grid item xs={12}>
                                        <img src={PoweredLogo}
                                             alt=""
                                             name='admin'
                                             width='110'
                                             className={classes.poweredLogo}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid item lg={4} md={4}
                                      className={classes.desktopGrid}>
                                    <Typography
                                        variant={"h3"}
                                        className={classes.h3}
                                    >
                                        Welcome to
                                    </Typography>
                                    <img
                                        src={Logo}
                                        alt="logo"
                                        width='272'
                                    />
                                    <Typography className={classes.description}>
                                        Sign in with an existing account or request access from your admin
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={12}
                                      className={classes.desktopForm}>
                                    <Typography
                                        variant={"h2"}
                                        className={classes.h2}
                                    >
                                        LOG IN
                                    </Typography>
                                    <CssTextField
                                        autoFocus
                                        id={'username'}
                                        username={'username2'}
                                        variant={'outlined'}
                                        label={'Username'}
                                        size={'small'}
                                        value={username}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        className={classes.textfield}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onKeyUp={(e) => e.key === 'Enter' && onSubmitForm()}
                                    />
                                    <CssTextField
                                        id={'password'}
                                        variant={'outlined'}
                                        label={'Password'}
                                        size={'small'}
                                        value={password}
                                        type="password"
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{style: {fontSize: 14}}}
                                        className={classes.password}
                                        onChange={event => setPassword(event.target.value)}
                                        onKeyUp={(e) => e.key === 'Enter' && onSubmitForm()}
                                    />
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            id='loginButton'
                                            variant='contained'
                                            color='primary'
                                            className={classes.button}
                                            onClick={() => !disabledButton && onSubmitForm()}
                                            disabled={loading}
                                            style={{width:'140px'}}
                                        >
                                            {loading ? <CircularProgress style={{color:'white'}} size={24}/> : "LOGIN"}
                                        </Button>
                                    </div>
                                    <Typography
                                        variant={"h5"}
                                        className={classes.text}
                                        onClick={() => history.push("/forgotCredentials")}
                                    >
                                        Forgot username or password?
                                    </Typography>
                                </Grid>
                            </Hidden>
                        </Grid>
                    ) :
                        <Redirect to={{
                            pathname: "/dashboard",
                            // search: "?utm=your+face",
                            state: { showNotification: true }
                        }}/>
                    }
                </Grid>
            )}
        </React.Fragment>
    );
}
const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (loginStyle));
