import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import {HospiceResourcesStyle} from "../HospiceProviderIndex.style";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {ProviderRegions} from "../../createDmeProvider/components/ProviderRegions";
import {inputType} from "../../../../constants/types/inputType";


export const HospiceProviderResources = ({facilities, handleInput, selectedRegionData, regionIdentifiers, newRegionTeam, newFacility, loading, updatingField, regionList}) => {

    const classes = useStyles();
    const [phone, setPhone] = useState(true);
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Hospice Resources
            </Typography>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={6} style={{paddingTop: 12}}>
                    <Typography>Facilities:</Typography>
                    <Tooltip placement="top" title={tooltips.add_facility}>
                        <IconButton aria-label="add"
                                    name={"addNewFacility"}
                                    disabled={loading}
                                    onClick={e => {
                                        handleInput({
                                            ...e,
                                            target: {name: "addNewFacility"},
                                            inputEventType: inputType.buttonAction
                                        });
                                    }}
                        >
                            <AddCircleOutlineIcon name={"addNewFacility"}/>
                        </IconButton>
                    </Tooltip>
                    <TextField
                        name="newFacility"
                        placeholder={placeholders.add_facility}
                        value={newFacility}
                        variant="outlined"
                        disabled={updatingField}
                        size="small"
                        onChange={e => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.standard
                            });
                        }}
                    />
                    <IconButton aria-label="delete" name={"clearNewFacility"}
                                onClick={e => {
                                    handleInput({
                                        ...e,
                                        target: {name: "clearNewFacility"},
                                        inputEventType: inputType.standard
                                    });
                                }}
                    >
                        <DeleteIcon/>
                    </IconButton>
                    {facilities.length > 0 && facilities.map((team, index) => {
                        return <Grid item key={index} style={{display:'flex'}}>
                            <TextField
                                style={{paddingLeft: 48}}
                                name="facility"
                                disabled={updatingField}
                                value={facilities[index].name}
                                placeholder={placeholders.add_facility}
                                variant="outlined"
                                size="small"
                                onChange={e => {
                                    handleInput({
                                        ...e,
                                        position: index,
                                        inputEventType: inputType.editOnBlur
                                    });
                                }}
                                onBlur={e => {
                                    handleInput({
                                        ...e,
                                        position: index,
                                        inputEventType: inputType.editOnBlur
                                    });
                                }}
                            />
                            <IconButton aria-label="delete"
                                        name={"deleteFacility"}
                                        disabled={loading}
                                        onClick={e => {
                                            handleInput({
                                                ...e,
                                                position: index,
                                                target: {name: "deleteFacility"},
                                                inputEventType: inputType.buttonAction
                                            });
                                        }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                            <div style={{display:'flex', alignContent:'center', alignItems:'center', justifyContent:'flex-start'}}>
                                {facilities[index].facility_id ? `ID: ${facilities[index].facility_id}` : ""}
                            </div>
                        </Grid>
                    })}
                </Grid>
                <Grid item>
                    <ProviderRegions
                        regionData={selectedRegionData}
                        regionIdentifiers={regionIdentifiers}
                        newRegionTeam={newRegionTeam}
                        enableWarehouses={false}
                        handleInput={handleInput}
                        loading={loading}
                        updatingField={updatingField}
                        regionList={regionList}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => (HospiceResourcesStyle(theme)));