import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

export const BRDisplayOutlierTable = ({values}) => {

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On Quantity
            </Typography>
            <table style={{textAlign: "left"}}>
                <tr>
                    <th>Item</th>
                    <th>Patient Name</th>
                    <th>Monthly Cost</th>
                    <th>Month</th>
                </tr>
                {values.extendedQuantityData.extendedCostInformation.map(item => {
                    return <tr>
                        <td>{item.billedItem}</td>
                        <td>{item.fullName}</td>
                        <td>{(Number(item.cppd) * Number(item.totalDays)).toFixed(3)}</td>
                        <td>{item.month}</td>
                    </tr>
                })}
            </table>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On Risk
            </Typography>
            <table style={{textAlign: "left"}}>
                <tr>
                    <th>Item</th>
                    <th>Patient Name</th>
                    <th>Monthly Cost</th>
                    <th>Month</th>
                </tr>
                {values.extendedRiskData.extendedData.map(item => {
                    if(item.billedPatients.length > 0){
                        item.billedPatients.map(patient => {
                            return <tr>
                                <td>{item.itemName}</td>
                                <td>{patient.fullName}</td>
                                <td>{(patient.totalPrice).toFixed(3)}</td>
                                <td>{item.month}</td>
                            </tr>
                        })
                    }
                })}
            </table>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On No Risk No Quantity
            </Typography>
            <table style={{textAlign: "left"}}>
                <tr>
                    <th>Item</th>
                    <th>Patient Name</th>
                    <th>Monthly Cost</th>
                    <th>Delivery Date</th>
                    <th>Pickup Date</th>
                    <th>Days Of Service In Quarter</th>
                </tr>
                {values.extendedDirectBillData.map(item => {
                    return <tr>
                        <td>{item.itemName}</td>
                        <td>{item.fullName}</td>
                        <td>{(item.totalCost).toFixed(3)}</td>
                        <td>{moment(item.itemDeliveryDate).format("YYYY-MM-DD")}</td>
                        <td>{item.itemPickupDate && moment(item.itemPickupDate).format("YYYY-MM-DD")}</td>
                        <td>{item.daysOfServiceInQuarter}</td>
                    </tr>
                })}
            </table>
            </Grid>
        </Grid>
    )
}