import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {endpoints} from "../../../constants/endpoints";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import ExportForm from "../../../common/exportForm/exportForm";
import MaterialTable from "material-table";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import jwt from "jwt-decode"
import {Table} from "../../../common/table/table";
import Select from "react-select";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
export const FacilityReport = props => {
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('key'));
    const userData = jwt(token.accessToken);
    const [values, setValues] = useState({
        dme_id: userData.payload.dme_organization_id,
        hospices_ids: [],
        facility_name: ''
    });
    const [orgData, setOrgData] = useState([]);
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [contractedProviders, setContractedProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getContractedProviders = () => {
        if (userData.payload.hospice_organization_id !== null) {
            const data = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.getHospiceContractedProviders}${userData.payload.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                }
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                    }
                    setContractedProviders(response.data);
                    setContractedProvidersList(object);
                    if (response.data.length === 1) {
                        setValues(prevState => {
                            return {...prevState, dme_id:response.data[0].dme_id};
                        });
                    }
                    setIsLoading(false);
                })
                .catch(err => {
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        }
    };

    const getData = () => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        if (userData.payload.hospice_organization_id === null) {
            fetch(endpoints.getHospicesByDme + userData.payload.dme_organization_id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    setOrgData(response.data);
                    setIsLoading(false);
                })
                .catch(err => {
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        } else {
            fetch(`${endpoints.getHospiceRegions}${userData.payload.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let data = _.cloneDeep(response.data);
                    let newArr = [];
                    data.map(region => {
                        newArr.push({
                            region_name:region.name,
                            region_id: region.region_id
                        })
                    });
                    setOrgData([{
                        hospiceName: userData.payload.hospice_name,
                        hospice_id: userData.payload.hospice_organization_id,
                        regions: newArr
                    }]);
                    setValues({
                        ...values, hospices_ids: [{
                            hospiceName: userData.payload.hospice_name,
                            hospice_id: userData.payload.hospice_organization_id,
                            regions: newArr
                        }]
                    });
                    setIsLoading(false);

                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                    setIsLoading(false);
                })
        }
    };

    useEffect(() => {
        if (orgData !== undefined && orgData.length === 0) {
            getData();
            getContractedProviders()
        }
    }, [values.dme_id]);

    const generateReport = () => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.getFacilityReport, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(values)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.patient_id);
                newArr.push(value.first_name + " " + value.last_name);
                newArr.push(value.medical_record);
                newArr.push(value.item_name);
                newArr.push(value.facility_name);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Organization_Name", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient Name", style: 'tableHeader'},
        {text: "Medical Record Number", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Facility Name", style: 'tableHeader'}
    ];
    const excelHeaders = [
        "Organization_Name",
        "Patient_ID",
        "Patient_Name",
        "Medical_Record_Number",
        "Item_Name",
        "Facility_Name"
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
            <Grid container className={classes.container}>
                <Typography className={classes.maintitle}>Facility Report</Typography>
                <Grid container className={classes.select}>
                    <div style={{display: "flex"}}>
                        {userData.payload.type === 'DME' && (
                            <Typography className={classes.title}>Select organizations</Typography>
                        )}
                        <Typography className={classes.title}>Select regions</Typography>
                        <Typography className={classes.title}>Select facilities</Typography>
                    </div>
                    <Grid container>
                        <div style={{width: userData.payload.type === 'DME' ? 620 : 420}}>
                            {orgData !== undefined && orgData.map((org, i) => {
                                return (
                                    <React.Fragment>
                                        {userData.payload.type === 'DME' ? (
                                        <Grid item xs={12} style={{display: "flex", padding: 8, width:800}}>
                                            <Grid item xs={6}>
                                                <div className={classes.org} style={{width:180}}>
                                                <span style={{paddingLeft: 12}}>
                                                    {org.hospiceName}
                                                </span>
                                                    <Checkbox
                                                        checked={_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) > -1}
                                                        style={{height: 20}}
                                                        color="primary"
                                                        onChange={() => {
                                                            let a = _.cloneDeep(values);
                                                            let index = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName});
                                                            if (index > -1) {
                                                                a.hospices_ids.splice(index, 1);
                                                            } else {
                                                                a.hospices_ids[i] = orgData[i];
                                                            }
                                                            setValues(a)
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={5}>
                                                {orgData[i] !== undefined && orgData[i].regions.map((reg, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                                <div className={classes.region} style={{width:200}}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.region_name}</span>
                                                                    <Checkbox
                                                                        checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name}) > -1}
                                                                        style={{height: 20}}
                                                                        color="primary"
                                                                        onChange={() => {
                                                                            let a = _.cloneDeep(values);
                                                                            let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName});
                                                                            let indexRegion = _.findIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name});
                                                                            if (indexRegion > -1) {
                                                                                a.hospices_ids[indexHospice].regions.splice(indexRegion, 1);
                                                                            } else {
                                                                                a.hospices_ids[indexHospice].regions.push(reg);
                                                                            }
                                                                            setValues(a)
                                                                        }}
                                                                    />
                                                                </div>)}</React.Fragment>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid item xs={5} style={{paddingLeft:12}}>
                                                {orgData[i] !== undefined && orgData[i].facilities !== undefined && orgData[i].facilities[0].map((reg, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                                <div className={classes.region} style={{width:200}}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.name}</span>
                                                                    <Checkbox
                                                                        checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].facilities[0], {"name": orgData[i].facilities[0][index].name}) > -1}
                                                                        style={{height: 20}}
                                                                        color="primary"
                                                                        onChange={() => {
                                                                            let a = _.cloneDeep(values);
                                                                            let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName});
                                                                            let indexRegion = _.findIndex(values.hospices_ids[i].facilities[0], {"name": orgData[i].facilities[0][index].name});
                                                                            if (indexRegion > -1) {
                                                                                a.hospices_ids[indexHospice].facilities[0].splice(indexRegion, 1);
                                                                            } else {
                                                                                a.hospices_ids[indexHospice].facilities[0].push(reg);
                                                                            }
                                                                            setValues(a)
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}</React.Fragment>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid> ) : (
                                            <React.Fragment>
                                            <Grid item xs={12} style={{display: "flex", padding: 8}}>
                                                {orgData[i].regions.map((reg, index) => {
                                                    return (
                                                        <React.Fragment>
                                                            {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                                <div className={classes.region}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.region_name}
                                                                    </span>
                                                                    <Checkbox
                                                                        checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name}) > -1}
                                                                        style={{height: 20}}
                                                                        color="primary"
                                                                        onChange={() => {
                                                                            let a = _.cloneDeep(values);
                                                                            let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName});
                                                                            let indexRegion = _.findIndex(values.hospices_ids[i].regions, {"region_name": orgData[i].regions[index].region_name});
                                                                            if (indexRegion > -1) {
                                                                                a.hospices_ids[indexHospice].regions.splice(indexRegion, 1);
                                                                            } else {
                                                                                a.hospices_ids[indexHospice].regions.push(reg);
                                                                            }
                                                                            setValues(a)
                                                                        }}
                                                                    />
                                                                </div>)}
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid item xs={12} style={{display: "flex", padding: 8}}>
                                            {orgData[i].facilities[0].map((reg, index) => {
                                                return (
                                                    <React.Fragment>
                                                        {_.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName}) !== -1 && (
                                                            <div className={classes.region}>
                                                                    <span
                                                                        style={{paddingLeft: 12}}>{reg.region_name}
                                                                    </span>
                                                                <Checkbox
                                                                    checked={values.hospices_ids[i] !== undefined && _.findLastIndex(values.hospices_ids[i].facilities[0], {"name": orgData[i].facilities[0][index].name}) > -1}
                                                                    style={{height: 20}}
                                                                    color="primary"
                                                                    onChange={() => {
                                                                        let a = _.cloneDeep(values);
                                                                        let indexHospice = _.findIndex(values.hospices_ids, {"hospiceName": orgData[i].hospiceName});
                                                                        let indexRegion = _.findIndex(values.hospices_ids[i].facilities[0], {"name": orgData[i].regions[index].name});
                                                                        if (indexRegion > -1) {
                                                                            a.hospices_ids[indexHospice].facilities[0].splice(indexRegion, 1);
                                                                        } else {
                                                                            a.hospices_ids[indexHospice].facilities[0].push(reg);
                                                                        }
                                                                        setValues(a)
                                                                    }}
                                                                />
                                                            </div>)}
                                                    </React.Fragment>
                                                )
                                            })}
                                            </Grid>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            })}

                        </div>
                        <div style={{
                            // width: 290,
                            paddingTop: 8,
                            paddingLeft: 10,
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            {userData.payload.hospice_organization_id !== null && (
                                <div style={{width: 180, zIndex:20}}>
                                    <Select
                                        className={classes.z}
                                        classNamePrefix="select"
                                        placeholder="Select provider..."
                                        name="color"
                                        onChange={(e) => {
                                            setValues({...values, dme_id: e.value})
                                        }}
                                        options={contractedProvidersList}
                                    />
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid>
                        <Button variant='contained' color="primary" onClick={generateReport} style={{marginTop:4, marginLeft:8}}>Generate Report</Button>
                    </Grid>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        maxWidth: 1200,
                        marginTop: 20,
                        marginBottom: 12
                    }}>
                        <ExportForm
                            fileName='Facility Report'
                            exportValues={exportValues}
                            headers={headers}
                            excelHeaders={excelHeaders}
                            showPrint={true}
                        />
                    </div>
                    <Table
                        search={false}
                        filtering={true}
                        columns={[{title: 'Organization', field: 'hospice_name'},
                            {title: 'Patient ID', field: 'patient_id'},
                            {
                                title: 'Patient Name', field: 'first_name',
                                render: rowData => <div>
                                    {rowData.first_name + " " + rowData.last_name}
                                </div>
                            },
                            {title: 'Medical Record Number', field: 'medical_record'},
                            {title: 'Item Name', field: 'item_name'},
                            {title: 'Facility Name', field: 'facility_name'},
                        ]}
                        items={results}
                        backGroundColor={"rgba(237, 242, 244, 0.73)"}
                        textColor={"rgba(46, 50, 54, 1)"}
                    />
                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
            }
        </div>
    )
};

const useStyles = makeStyles((theme) => ({
    div: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        paddingTop: 12,
        maxWidth: 1200,
    },
    title: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 200
    },
    maintitle: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 220
    },
    select: {
        padding: 12,
        display: "flex",
        flexDirection: "column",
        justifyContent: "left"

    },
    org: {
        border: '1px solid lightgrey',
        borderRadius: 4,
        marginRight: 12,
        height: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    region: {
        border: '1px solid lightgrey',
        height: 40,
        borderRadius: 4,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    }
}));
